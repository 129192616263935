/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import styles from './styles.module.scss';
import { useFeatureFlag } from '@/features/globalConfig';
import cx from 'classnames';
import azCommonStyles from '../../../../theme/globals.module.scss';
import { SmartLink as Link } from '../../../../utils/smartLink';
import { Grid } from '../../../Grid';
import { ShareLinkButton } from '@/features/sharableLink';
import { useLabels } from '@/hooks/useLabels';

const labelMap = {
  lbl_ShopAll: 'label_Homepage_content_ShopAll',
  label_Tap_to_Navigate_to_Brand_Page: 'label_Tap_to_Navigate_to_Brand_Page',
};

type TitleNotesProps = {
  productTitle: string;
  productBrand: string;
  brandURL: string;
};

const PdpTitleNotes = (props: TitleNotesProps) => {
  const brandProfileEnabled: boolean = useFeatureFlag('BRAND_PROFILE_FEATURE_ENABLED') === 'true';
  const labels = useLabels(labelMap);
  const { productTitle, productBrand, brandURL } = props;
  const showShopAllLink = brandProfileEnabled && !!brandURL && !!productBrand;
  const ariaLabel = `${labels.lbl_ShopAll} ${productBrand} ${labels.label_Tap_to_Navigate_to_Brand_Page} ${productBrand}`;
  const isSharableLinksEnabled = useFeatureFlag('SHARABLE_LINKS_ENABLED') === 'true';

  const gridSize = {
    xs: isSharableLinksEnabled ? 11 : 12,
    sm: isSharableLinksEnabled ? 11 : 12,
    md: isSharableLinksEnabled ? 11 : 12,
    lg: isSharableLinksEnabled ? 10 : 12,
    xl: isSharableLinksEnabled ? 11 : 12,
  };

  return (
    <Grid container direction="row">
      <Grid
        item
        xs={gridSize.xs}
        sm={gridSize.sm}
        md={gridSize.md}
        lg={gridSize.lg}
        xl={gridSize.xl}
        className={styles.titleContainer}
      >
        <h1
          className={cx(
            azCommonStyles['az-subtitle-3-heavy'],
            azCommonStyles['az-margin-bottom-5xs'],
            styles.productTitle
          )}
          data-testid="product-title"
        >{`${productTitle}`}</h1>
        {showShopAllLink && (
          <Link to={brandURL} ariaLabel={ariaLabel}>
            <span
              className={styles.shopAllBrandNameText}
            >{`${labels.lbl_ShopAll} ${productBrand}`}</span>
          </Link>
        )}
      </Grid>
      {isSharableLinksEnabled ? (
        <Grid item xs={1} sm={1} md={1} lg={2} xl={1} className={styles.shareLinkContainer}>
          <ShareLinkButton pageName="pdp" />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default PdpTitleNotes;
