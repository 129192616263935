/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxios } from '@/lib/axios';
import { DealListData, DealListResponse } from '../interface';
import { requestBaseURL } from '@/config/serviceAPI';
import { AxiosInstance } from 'axios';
import { createQuery } from '@/utils/createReactQuery';
const URL = `${requestBaseURL}/ecomm/b2c/browse/v3/skus`;

type Options = {
  skuId: string | undefined;
};

const getDealList = async (options: Options, axiosInstance?: AxiosInstance) => {
  const { skuId } = options;

  if (!skuId) {
    throw new Error('skuId is required');
  }

  const response = await getAxios(axiosInstance).get<DealListResponse>(`${URL}/${skuId}/deals`);

  return selector(response.data);
};

const selector = (data: DealListResponse): DealListData => data;

const { useData } = createQuery<DealListResponse, Options>('dealList', getDealList);

export const useDealList = ({
  enabled = true,
  skuId,
}: Options & {
  enabled?: boolean;
}) => {
  return useData({
    enabled: enabled && Boolean(skuId),
    skuId,
  });
};
