/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { LineItemFullfillmentOption } from '../interface';

export function checkAvailabilityByGroupTypeId(
  fulfillmentOptions: LineItemFullfillmentOption[] | undefined,
  type: number
): boolean {
  const isAvailable = (option: LineItemFullfillmentOption) =>
    option.groupTypeId === type && option.available;

  if (fulfillmentOptions) {
    return fulfillmentOptions.some(isAvailable);
  }

  return false;
}
