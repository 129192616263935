/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useLabels } from '@/hooks/useLabels';
import { StandaloneYmmeSelect } from './StandaloneYmmeSelect';
import { NavBar } from '@/components/NavBar';
import styles from './styles.module.scss';

type Props = {
  onClose: () => void;
};

const labelMap = {
  label_deal_bundle_set_vehicle_title: 'label_deal_bundle_set_vehicle_title',
  label_deal_bundle_set_vehicle_description: 'label_deal_bundle_set_vehicle_description',
};

export const BundleDealVehicleSelection = ({ onClose }: Props) => {
  const labels = useLabels(labelMap);

  return (
    <div className={styles.bundleDealOptionList}>
      <NavBar showBackButton={false} showCloseButton handleClose={onClose} />
      <div className={styles.vehicleSection}>
        <h2 id="dealsModal" className={styles.sectionTitle}>
          {labels.label_deal_bundle_set_vehicle_title}
        </h2>
        <div className={styles.sectionDescription}>
          {labels.label_deal_bundle_set_vehicle_description}
        </div>
        <div className={styles.separator} />
        <StandaloneYmmeSelect pageType="bundleDealsDrawer" forceMobile={true} />
      </div>
    </div>
  );
};
