/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { CustomLinkName } from '@/types/analytics';
import { clickTrack } from '@/utils/analytics/clickTrack';

export const trackQuantityChange = (
  customLinkName: CustomLinkName,
  productAvailableQuantityList: string[],
  productIdList: string[],
  productRequestedQuantityList: string[]
) => {
  clickTrack({
    eventType: 'quantityChange',
    customLinkName,
    productAvailableQuantityList,
    productIdList,
    productRequestedQuantityList,
  });
};
