/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import Image from '@/components/NextImage';
import styles from './styles.module.scss';
import Price from '@/components/AZCustomComponent/Price';

type Props = {
  price?: string[] | null;
  dealImageUrl: string | null;
  name: string | null;
};
const BundleDetailsCard = ({ price, dealImageUrl, name }: Props) => {
  return (
    <div className={styles.bundleDetailsCard}>
      <div className={styles.bundleDetailsImage}>
        <Image src={dealImageUrl ?? ''} alt="bundle details image" height={68} width={68} />
      </div>

      <div className={styles.bundleDetailsPriceAndName}>
        {Boolean(price) && (
          <div className={styles.bundleDetailsPrice}>
            <Price
              priceType="secondary"
              dealPrice={price}
              wrapperClassName={styles.dealPriceWrapper}
            />
          </div>
        )}
        <div className={styles.bundleDetailsName}>{name}</div>
      </div>
    </div>
  );
};

export default BundleDetailsCard;
