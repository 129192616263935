/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import ExpansionPanel from '@/components/AZCustomComponent/ExpansionPanel';
import { countryCodes } from '@/constants/locale';
import { useFeatureFlag } from '@/features/globalConfig';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import ExpandIcon from '@/public/images/footer/footer_down_arrow.svg';
import azCommonStyles from '@/theme/globals.module.scss';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { getProductReviewsURL } from '@/features/product';
import { useProductDetails } from '@/features/product/api/getProductDetails';
import { useProductSkuDetails } from '@/features/product/api/getProductSkuDetails';
import { SEOProductSchema } from '../SEOProductSchema';
import styles from './styles.module.scss';
import getPosition from '@/utils/crossBrowserHelpers/getPosition';
import scrollToElem from '@/utils/crossBrowserHelpers/scroll';
import { useSelector } from 'react-redux';
import { ReduxState } from '@/types';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { ReviewsContent } from './ReviewsContent';
import Head from 'next/head';

export type BazaarVoiceProps = {
  contentItem?: {
    tablet: boolean;
    desktop: boolean;
    '@type': string;
    themeCode: string;
    name: string;
    mobile: boolean;
    collapsedOnLoadMobile: boolean;
    collapsedOnLoadTablet: boolean;
    collapsedOnLoadDesktop: boolean;
  };
};

const labelMap = {
  seeMoreReviews: 'label_reviews_seeMoreReviews',
  reviewsHeaderLabel: 'Label_bazaarvoice_Reviews_header',
};

export const BazaarVoice = ({ contentItem }: BazaarVoiceProps) => {
  const deviceType = useSelector<ReduxState>(({ appData }) => appData.deviceType);
  const isMobileDevice = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const isABot = deviceType === 'bot';
  const [queryEnabled, setQueryEnabled] = useState(() => isABot || !isMobileDevice);
  const productReviewsEnabled = useFeatureFlag('PRP_ENABLED') === 'true';
  const router = useRouter();
  const { data: productDetailsData, isSuccess: productDetailsReqIsSuccess } = useProductDetails();
  const skuDetailsResult = useProductSkuDetails({
    skuIds:
      productDetailsReqIsSuccess && productDetailsData.product?.itemId
        ? [productDetailsData.product.itemId]
        : null,
  });

  const labels = useLabels(labelMap);
  const locale = useLocale();
  const productReviewsURL =
    productReviewsEnabled &&
    productDetailsData?.product?.hasReviews &&
    Array.isArray(router.query.slug)
      ? `/${getProductReviewsURL(router.query.slug.join('/'))}`
      : undefined;

  const reviewsRef = useRef<HTMLDivElement>(null);

  const isMobileOrTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const RR_AZRMOB_PDP_ENABLED = useFeatureFlag('RR_AZRMOB_PDP_ENABLED');
  const RR_AZRMOB_PDP_TAG = useFeatureFlag('RR_AZRMOB_PDP_TAG');
  const RR_AZRWEB_PDP_ENABLED = useFeatureFlag('RR_AZRWEB_PDP_ENABLED');
  const RR_AZRWEB_PDP_TAG = useFeatureFlag('RR_AZRWEB_PDP_TAG');
  const BZ_FIX: boolean = useFeatureFlag('BZ_FIX') === 'true';

  const isScriptReadyRef = useRef(BZ_FIX);
  const [scriptReady, setScriptReady] = useState(isScriptReadyRef.current);
  const [customHeaderScript, setCustomHeaderScript] = useState<string | undefined>();
  const customHeaderScriptPath =
    isMobileOrTablet && RR_AZRMOB_PDP_ENABLED === 'true'
      ? RR_AZRMOB_PDP_TAG
      : RR_AZRWEB_PDP_ENABLED === 'true'
      ? RR_AZRWEB_PDP_TAG
      : '';

  const handleScriptReady = () => {
    setScriptReady(() => true);
    isScriptReadyRef.current = true;
  };

  useEffect(() => {
    //added to avoid hydration error on pdp https://nextjs.org/docs/messages/react-hydration-error
    setCustomHeaderScript(customHeaderScriptPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customHeaderScriptPath]);

  useEffect(() => {
    const handleEnableQuery = () => {
      setQueryEnabled(true);
      window.removeEventListener('scroll', handleEnableQuery);
    };

    if (!isABot) {
      window.addEventListener('scroll', handleEnableQuery);
    }

    return () => {
      window.removeEventListener('scroll', handleEnableQuery);
    };
  }, [isABot]);

  const skuId = productDetailsReqIsSuccess ? productDetailsData.product?.itemId : null;

  useEffect(() => {
    if (!skuDetailsResult.isSuccess && productDetailsReqIsSuccess) {
      return;
    }

    const hash = window.location.hash;
    if (hash !== '#bv_PDPReviews' || !skuId) {
      return;
    }
    const moveToReviews = () => {
      const reviewElement = document.getElementById('tab-reviews');

      if (reviewElement != null) {
        const client = getPosition(reviewElement);
        scrollToElem(client.y - 75);
      }
    };

    const checkingReviewsInterval = setInterval(() => {
      moveToReviews();
      clearInterval(checkingReviewsInterval);
    }, 2000);

    const intervalCleaner = setTimeout(() => {
      clearInterval(checkingReviewsInterval);
    }, 15000);

    return () => {
      clearInterval(checkingReviewsInterval);
      clearTimeout(intervalCleaner);
    };
  }, [skuId, productDetailsReqIsSuccess, skuDetailsResult.isSuccess]);

  const reviewsEnabled =
    ((isMobileOrTablet && RR_AZRMOB_PDP_ENABLED === 'true') || RR_AZRWEB_PDP_ENABLED === 'true') &&
    queryEnabled;

  return (
    <div>
      {reviewsEnabled && customHeaderScript && !BZ_FIX && (
        <Script src={customHeaderScript} onReady={handleScriptReady}></Script>
      )}
      {reviewsEnabled && customHeaderScript && BZ_FIX && (
        <Head>
          <script async src={customHeaderScript}></script>
        </Head>
      )}
      {reviewsEnabled && locale === countryCodes.us && (
        <div>
          <SEOProductSchema />
          {isMobileDevice ? (
            <ExpansionPanel
              title={labels.reviewsHeaderLabel}
              overrideExpanded
              summary={
                <h2 className={cx(azCommonStyles['az-title-5-medium'], styles.reviewsHeader)}>
                  {labels.reviewsHeaderLabel}
                </h2>
              }
              content={
                skuId && (
                  <ReviewsContent
                    reviewsRef={reviewsRef}
                    skuId={skuId}
                    scriptReady={scriptReady}
                    productReviewsURL={productReviewsURL}
                  />
                )
              }
              expandIcon={<ExpandIcon />}
              defaultExpandedProp={!contentItem?.collapsedOnLoadMobile}
              classes={{
                summary: styles.expansionPanelSummary,
                detailsRoot: styles.expansionPanelDetails,
              }}
            />
          ) : (
            skuId && (
              <ReviewsContent
                reviewsRef={reviewsRef}
                skuId={skuId}
                scriptReady={scriptReady}
                productReviewsURL={productReviewsURL}
              />
            )
          )}
        </div>
      )}
    </div>
  );
};
