/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useContext, useState } from 'react';
import ToggleComponent from '../AZCustomComponent/DeliveryOptionToggle';
import { storeModalType } from '../../constants/storeModal';
import { productInfoConstants } from '../../constants/productInfoConstants';
import { cartConstants } from '../../constants/cart';
import { fulfillmentLabels, FULFILLMENT_METHODS } from '../../constants/fulfillmentConstants';
import styles from './styles.module.scss';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import type { SkuPricingAndAvailabilityFromAPI } from '@/types/availability';
import type { FulfillmentGroup } from '@/types/reduxStore/skuDetails';
import { MyStoreModal } from '../Store/StoreModal/MyStoreModal';
import { SearchStoreModal } from '../Store/StoreModal/SearchStoreModal';
import { SisterStoreContext } from '@/features/fulfillment/context/SisterStoreContext';

type FufillmentOptionsProps = {
  changeShippingType?: (
    shippingType: typeof cartConstants.STOREORDER,
    groupId?: typeof FULFILLMENT_METHODS.STOREORDER,
    maxAvailableQty?: number | undefined,
    sisterStoreAvailable?: boolean | undefined
  ) => void;
  shippingType: string;
  skuPricingAndAvailability?: SkuPricingAndAvailabilityFromAPI | undefined;
  storeSelected: boolean | undefined;
  productName: string;
  inline: boolean;
  hasDealBadge?: boolean;
  dollars?: string;
  cents?: string;
  availabilityInfoVO?: any;
  isCartPage?: boolean | undefined;
  itemData?: any;
  fulfillmentOptionsGroupList?: Array<FulfillmentGroup>;
  dealId?: string;
  skuId?: any;
  quantityInCart: number;
  isSisterStoreFlyoutVisible?: boolean;
  toggleSisterStoreFlyout?: () => void;
};

const labelMap = {
  label_SelectStore: 'label_SelectStore',
  label_pickup: 'label.common.fulfillmentoption.storepickup',
  label_shelf_pickup: 'label.shelf.shipoption.storepickup',
  label_shelf_setting_store_price: 'label.shelf.selectstore.price',
};

const FulfillmentOptions = ({
  changeShippingType,
  shippingType,
  skuPricingAndAvailability,
  storeSelected,
  inline,
  hasDealBadge = true,
  dollars,
  cents,
  availabilityInfoVO,
  isCartPage,
  itemData,
  fulfillmentOptionsGroupList,
  dealId,
  quantityInCart,
  skuId,
}: FufillmentOptionsProps) => {
  const toggleSelectedRadio = (_: any, val: string) => {
    changeShippingType?.(val);
  };

  const labels = useLabels(labelMap);
  const [storeModalOpen, setStoreModalOpen] = useState(false);
  const locale = useLocale();
  const { data: storeData } = useStoreDetailsData();
  const isBopusEnabled = storeData?.bopusEnabled ?? false;
  const isBopusView =
    locale === countryCodes.ptBr ? true : locale === countryCodes.mx && isBopusEnabled;
  const storeAddress = storeData?.address1;
  let storeModalTypeText = storeModalType.myStore;

  const { openSisterStoreFlyout } = useContext(SisterStoreContext);

  const openSisterStore = () => {
    openSisterStoreFlyout({
      skuId: skuId ?? '',
      shippingType,
      quantityInCart: quantityInCart,
      productDetails: itemData,
      dealId: dealId ?? '',
    });
  };

  const toggleStoreModal = () => {
    setStoreModalOpen((status) => !status);
  };

  let storePickupAvailable = false;
  let shippingAvailability;
  let eligibleForNextDay: boolean | undefined = false;
  let showAnotherStore: boolean | undefined = false;
  let onlineStockOptions = '';
  let shipToHomeAvailable = false;
  let numberOfSisterStores = 0;
  let groupFulfillmentDetails;

  if (skuPricingAndAvailability) {
    storePickupAvailable = skuPricingAndAvailability.storePickupAvailable;
    onlineStockOptions = skuPricingAndAvailability.shipToHomeStockLabel;
    shipToHomeAvailable = skuPricingAndAvailability.shipToHomeAvailable;
    if (skuPricingAndAvailability.skuAvailabilityInfo) {
      shippingAvailability =
        skuPricingAndAvailability.skuAvailabilityInfo.shippingAvailabilityMessage;
      eligibleForNextDay = skuPricingAndAvailability.skuAvailabilityInfo.eligibleForNextDay;
      showAnotherStore = isBopusView
        ? skuPricingAndAvailability.skuAvailabilityInfo.showSearchAnotherStoreLink ||
          availabilityInfoVO?.sisterStoreAvailable
        : skuPricingAndAvailability.skuAvailabilityInfo.showSearchAnotherStoreLink;
    }
  } else if (
    itemData &&
    (locale === countryCodes.mx || locale === countryCodes.ptBr) &&
    isBopusView
  ) {
    if (fulfillmentOptionsGroupList) {
      const pickupFulfillmentDetails: any =
        fulfillmentOptionsGroupList.find((g) => g?.groupId === FULFILLMENT_METHODS.STOREORDER) ??
        {};
      groupFulfillmentDetails =
        pickupFulfillmentDetails?.groupFulfillmentOptions.find(
          (x: { groupId: number }) => x?.groupId === FULFILLMENT_METHODS.STOREORDER
        ) ?? {};
      numberOfSisterStores = groupFulfillmentDetails?.numberOfSisterStoreAvailable ?? 0;
      storePickupAvailable =
        groupFulfillmentDetails?.sisterStoreAvailable ||
        (groupFulfillmentDetails?.available &&
          itemData?.itemQuantity <= groupFulfillmentDetails?.availableQty);
      showAnotherStore = groupFulfillmentDetails?.sisterStoreAvailable;
    }
  }

  const estimatedDelivery = shipToHomeAvailable && eligibleForNextDay;
  const availableDeliveryCheck = shipToHomeAvailable || eligibleForNextDay;
  let onlineStockLabel, disableSthButton;

  if (estimatedDelivery) {
    onlineStockLabel = `${fulfillmentLabels.lblOrderBy10PM} ${skuPricingAndAvailability?.estimatedDeliveryMessage}`;
    disableSthButton = false;
  } else if (availableDeliveryCheck) {
    onlineStockLabel =
      skuPricingAndAvailability?.estimatedDeliveryMessage === ''
        ? onlineStockOptions
        : skuPricingAndAvailability?.estimatedDeliveryMessage;
    disableSthButton = false;
  } else {
    onlineStockLabel = onlineStockOptions;
    disableSthButton = true;
  }

  let showFindNearStoreBtn;

  if (locale !== countryCodes.mx) {
    showFindNearStoreBtn = showAnotherStore;
  } else {
    showFindNearStoreBtn =
      skuPricingAndAvailability?.storePickupStockLabel !== productInfoConstants.inStock &&
      showAnotherStore;
  }

  const storeAndAddToCartObj = {
    label: '',
    onClick: toggleStoreModal,
  };

  if (showAnotherStore) {
    storeAndAddToCartObj.label = fulfillmentLabels.label_shipping_AtNearByStores;
    storeAndAddToCartObj.onClick = openSisterStore;
    storeModalTypeText = storeModalType.nearByStore;
  } else if (!storeSelected) {
    storeAndAddToCartObj.label = isBopusView
      ? labels.label_SelectStore
      : fulfillmentLabels.label_shipping_SelectStore;
    storeAndAddToCartObj.onClick = toggleStoreModal;
    storeModalTypeText = storeModalType.searchStore;
  }

  // there was an issue with catalog some products price being less than 18 cents
  // need to revisit this once we get confirmation if that's no longer the case and this can be updated to 0
  const isPriceDefined = () => {
    return !(Number(dollars) === 0 && Number(cents) < 18);
  };

  const isPriceDefinedBopusMX = () => {
    return !(
      (Number(dollars) === 0 && Number(cents) < 18) ||
      (dollars === undefined && cents === undefined)
    );
  };
  const deliveryOptionLabel = isCartPage
    ? groupFulfillmentDetails?.fulfillmentTypeLabel
    : isBopusView
    ? labels.label_pickup
    : labels.label_shelf_pickup;

  const brazilLabelStoreSelect = labels.label_shelf_setting_store_price;

  const toggleList = [
    {
      isSelected: shippingType === cartConstants.STOREORDER,
      deliveryOptionLabel:
        locale === countryCodes.ptBr && !storeData ? brazilLabelStoreSelect : deliveryOptionLabel,
      storeStockLabel: isCartPage
        ? groupFulfillmentDetails?.availableLabel
        : skuPricingAndAvailability?.storePickupStockLabel ?? '',
      isPriceDefined: isBopusView ? isPriceDefinedBopusMX() : isPriceDefined(),
      disabled: !storePickupAvailable,
      buttonIndex: cartConstants.STOREORDER,
      storeDetails: {
        address1: storeAddress,
      },
      toggleStoreModal: toggleStoreModal,
      showFindNearStoreBtn,
      toggleStore: true,
      storeBtn: !showFindNearStoreBtn,
      storeAndAddToCartObj: (storeAndAddToCartObj.label ===
        fulfillmentLabels.label_shipping_SelectStore ||
        storeAndAddToCartObj.label === labels.label_SelectStore ||
        storeAndAddToCartObj.label === fulfillmentLabels.label_shipping_AtNearByStores) && {
        ...storeAndAddToCartObj,
      },
    },
    {
      isSelected: shippingType === cartConstants.ONLINEORDER,
      deliveryOptionLabel: skuPricingAndAvailability?.shipToHomeLabel ?? '',
      storeStockLabel: onlineStockLabel,
      disabled: disableSthButton,
      buttonIndex: cartConstants.ONLINEORDER,
      sthoptions: eligibleForNextDay,
      shippingAvailability,
    },
  ];
  return (
    <div className={styles.fulfillmentOptions}>
      <div className={isCartPage ? '' : styles.productPastContainer} id="productPart">
        <ToggleComponent
          applyNegativeMargin={!hasDealBadge}
          options={toggleList}
          onChange={(event: any, activeValue: string) => {
            toggleSelectedRadio(event, activeValue);
          }}
          selectedValue={shippingType}
          storeSelected={storeSelected}
          inline={inline}
          isBopusView={isBopusView}
          availabilityInfoVO={availabilityInfoVO}
          numberOfSisterStores={numberOfSisterStores}
          locale={locale}
          dollars={dollars}
          cents={cents}
          skuAvailabilityInfo={skuPricingAndAvailability?.skuAvailabilityInfo}
        />
      </div>
      {storeModalOpen ? (
        storeModalTypeText === storeModalType.myStore ? (
          <MyStoreModal closeStoreModal={toggleStoreModal} />
        ) : storeModalTypeText === storeModalType.searchStore ? (
          <SearchStoreModal closeStoreModal={toggleStoreModal} />
        ) : null
      ) : null}
    </div>
  );
};

export { FulfillmentOptions };
