/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { getFulfillmentOptionsByGroup } from '@/utils/itemHelpers/fulfillmentHelpers/getFulfillmentOptionsByGroup';
import { ProductSkuDetails, SkuDetailsFromAPI } from '@/features/product';

export function fromApiSkuDetails(skuDetailsResponse: SkuDetailsFromAPI): ProductSkuDetails {
  const defaultFulfillmentGroupId =
    skuDetailsResponse.skuPricingAndAvailability?.skuAvailabilityInfo?.defaultFulfillmentGroupId ??
    '';
  const fulfillmentOptions = getFulfillmentOptionsByGroup(
    skuDetailsResponse.skuPricingAndAvailability?.skuAvailabilityInfo?.fulfillmentOptions
  );

  return {
    defaultFulfillmentGroupId,
    fulfillmentOptions,
    ...skuDetailsResponse,
  };
}
