/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { storeModalType } from '../../constants/storeModal';
import styles from './styles.module.scss';
import FulfillmentControlStoreModalV2 from './FulfillmentControlStoreModalV2';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { defaultStoreUS } from '@/constants/locale';
import type { FulfillmentOption } from '@/types/reduxStore/skuDetails';
import { useProductListView } from '@/features/shelf/context/useProductListView';
import NextImage from '@/components/NextImage';

export function StorePickupOption({
  option,
  skuId,
  productName,
  handleInstockAnalytics,
  availabilityInfoVO,
  isDealDrawer,
  itemPresentInCart,
  toggleSisterStoreFlyout,
}: {
  option: FulfillmentOption;
  skuId: string | undefined;
  productName: string;
  handleInstockAnalytics?: () => any;
  availabilityInfoVO?: any;
  isDealDrawer?: boolean;
  itemPresentInCart?: boolean;
  toggleSisterStoreFlyout?: () => void;
}) {
  const { isMobileFilterOpen } = useProductListView();
  const { data: storeData } = useStoreDetailsData();
  const isStoreSet = storeData?.storeNumber !== defaultStoreUS;
  const storeAddress = storeData?.address1;
  if (!isStoreSet) {
    return (
      <FulfillmentControlStoreModalV2
        available={option.available}
        renderType={storeModalType.searchStore}
        availabilityInfoVO={availabilityInfoVO}
        numberOfSisterStoreAvailableFromFulFillmentAPI={option?.numberOfSisterStoreAvailable}
        isDealDrawer={isDealDrawer}
        itemPresentInCart={itemPresentInCart}
        toggleSisterStoreFlyout={toggleSisterStoreFlyout}
      />
    );
  }

  const availableLabel = option.available
    ? option.availableLabel
    : `${option.availableLabel} ${storeAddress}`;
  const availabilityClass = !option.available
    ? option?.sisterStoreAvailable
      ? styles.notAvailablePreferredStore
      : styles.notAvailable
    : styles.inStock;
  return (
    <>
      <div className={availabilityClass}>
        {/*A11Y fix: To avoid focus jumping on to this image on mobile when the filter modal is opened */}
        {option.available && !isMobileFilterOpen && (
          <>
            <NextImage src="/images/green-tickmark.svg" alt="" width={14} height={11} />{' '}
          </>
        )}
        {
          <span data-testid={`availability-${skuId}`} className={styles.storePickupAvailability}>
            {availableLabel}
          </span>
        }

        {option.available && (
          <span className={styles.storeLink}>
            <>
              <span className={styles.divider}>|</span>
              <FulfillmentControlStoreModalV2
                available={option.available}
                renderType={storeModalType.myStore}
                isDealDrawer={isDealDrawer}
                handleInstockAnalytics={handleInstockAnalytics}
                availabilityInfoVO={availabilityInfoVO}
                numberOfSisterStoreAvailableFromFulFillmentAPI={
                  option?.numberOfSisterStoreAvailable
                }
                itemPresentInCart={itemPresentInCart}
                toggleSisterStoreFlyout={toggleSisterStoreFlyout}
              />
            </>
          </span>
        )}
      </div>
      {option.sisterStoreAvailable && (
        <FulfillmentControlStoreModalV2
          available={option.available}
          renderType={storeModalType.nearByStore}
          skuId={skuId}
          isDealDrawer={isDealDrawer}
          productName={productName}
          availabilityInfoVO={availabilityInfoVO}
          numberOfSisterStoreAvailableFromFulFillmentAPI={option?.numberOfSisterStoreAvailable}
          itemPresentInCart={itemPresentInCart}
          toggleSisterStoreFlyout={toggleSisterStoreFlyout}
        />
      )}
    </>
  );
}
