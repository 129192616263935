/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import usePrevious from '@/utils/usePrevious';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useProductListData } from '../api/getProductList';
import { ProductListContext } from '../ProductListContext';

export const ProductListProvider = ({ children }: { children: React.ReactNode }) => {
  const [shouldFetchSecondaryProductSkus, setShouldFetchSecondaryProductSkus] = useState(false);
  const router = useRouter();
  const prevRoute = usePrevious(router.asPath);
  useEffect(() => {
    if (router.asPath !== prevRoute) {
      setShouldFetchSecondaryProductSkus(false);
    }
  }, [router.asPath, prevRoute, setShouldFetchSecondaryProductSkus]);
  const results = useProductListData({ shouldFetchSecondaryProductSkus });
  const value = useMemo(
    () => ({ ...results, setShouldFetchSecondaryProductSkus }),
    [results, setShouldFetchSecondaryProductSkus]
  );
  return <ProductListContext.Provider value={value}>{children}</ProductListContext.Provider>;
};
