/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import Skeleton from '../../../Skeleton/Skeleton';
import styles from './RightSection.module.scss';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { useIsStoreSelected } from '@/features/header/hooks/useIsStoreSelected';
import { usePriceHiding } from '@/hooks/usePriceHiding';

const DeliveryOption = ({ className }: { className?: string }) => {
  return (
    <div className={`${styles.skeletonDeliverOptionContainer} ${className || ''}`}>
      <div className={styles.skeletonDeliverOptionCircle} />
      <Skeleton animation="wave" width={212} height={20} variant="rect" />
    </div>
  );
};

const Right = ({ currencySymbol = '$' }) => {
  const locale = useLocale();
  const isStoreSelected = useIsStoreSelected();
  const hidePricing = usePriceHiding();
  const isMxSite = locale === countryCodes.mx;
  const isBR = locale === countryCodes.ptBr;
  return (
    <div className={styles.container}>
      <Skeleton animation="wave" width="90%" height={25} variant="rect" />
      <Skeleton
        className={styles.skeletonMargin}
        animation="wave"
        width="55%"
        height={25}
        variant="rect"
      />
      <Skeleton
        className={styles.skeletonMargin}
        animation="wave"
        width="45%"
        height={25}
        variant="rect"
      />
      <Skeleton
        className={styles.skeletonMargin}
        animation="wave"
        width="60%"
        height={25}
        variant="rect"
      />
      <hr className={styles.horizontalSpacing} />
      <div className={styles.skeletonPriceAndQuantityContainer}>
        <div className={styles.skeletonPriceContainer}>
          {hidePricing || (isBR && !isStoreSelected) ? null : (
            <div className={styles.skeletonDollarSign}>{currencySymbol}</div>
          )}
          <Skeleton animation="wave" width={86} height={34} variant="rect" />
        </div>
        {!isBR && (
          <div className={styles.skeletonQuantityContent}>
            <div className={styles.skeletonQuantityCircle} />
            <div className={styles.skeletonQuantitySquare} />
            <div className={styles.skeletonQuantityCircle} />
          </div>
        )}
      </div>
      {!isMxSite ? (
        <div className={styles.skeletonSplitSquares}>
          <Skeleton
            className={styles.skeletonMargin}
            animation="wave"
            width="33%"
            height={85}
            variant="rect"
          />
          <Skeleton
            className={styles.skeletonMargin}
            animation="wave"
            width="33%"
            height={85}
            variant="rect"
          />
          <Skeleton
            className={styles.skeletonMargin}
            animation="wave"
            width="33%"
            height={85}
            variant="rect"
          />
        </div>
      ) : (
        <>
          <DeliveryOption />
          <DeliveryOption className={styles.skeletonDeliverOptionSecondItem} />
        </>
      )}

      {!isBR && <div className={styles.skeletonBlackBox} />}
    </div>
  );
};

export default Right;
