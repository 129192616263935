/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { useLabel } from '@/hooks/useLabels';
import cx from 'classnames';
import styles from './styles.module.scss';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';
import { formatPrice } from '@/utils/validator/formatPrice';
import { useFeatureFlag } from '@/features/globalConfig';
import { getSafeNumber } from '@/utils/common';

type Props = {
  price: number | string;
  wasPrice: number | string;
  alignRight?: boolean;
  quantity?: number;
};

export const StrikeThroughPriceWrapper = ({
  price,
  wasPrice,
  quantity = 1,
  alignRight = false,
}: Props) => {
  const strikethroughSave = useLabel('label_strikethrough_save');
  const SALES_PRICING_DISPLAY_ENHANCEMENTS_ENABLED =
    useFeatureFlag('SALES_PRICING_DISPLAY_ENHANCEMENTS_ENABLED') === 'true';
  const router = useRouter();
  const isCartPage = router.asPath.includes(routePaths.cart);

  const itemPrice = getSafeNumber(wasPrice);
  const itemDiscountedPrice = getSafeNumber(price);

  if (itemDiscountedPrice === 0) {
    return null;
  }

  const discountAmount = itemPrice - itemDiscountedPrice;
  const discountPercentage = Math.floor((discountAmount / itemPrice) * 100);
  const perUnitDiscountAmount = discountAmount / quantity;

  const discountDisplay =
    SALES_PRICING_DISPLAY_ENHANCEMENTS_ENABLED && perUnitDiscountAmount > discountPercentage
      ? `$${formatPrice(discountAmount)}`
      : `${discountPercentage}%`;

  return itemPrice > itemDiscountedPrice ? (
    <>
      <div
        className={cx(
          alignRight ? styles.discountSectionWCorePrice : styles.discountSection,
          isCartPage ? styles.cart : styles.products
        )}
        data-m-id={`strikeThroughContainer${isCartPage ? 'Cart' : 'Product'}`}
      >
        {!isCartPage && (
          <p
            className={styles.discount}
            data-m-id="strikeThroughDiscount"
          >{`${strikethroughSave} ${discountDisplay}`}</p>
        )}

        <p className={alignRight ? styles.alignRightStrikethrough : styles.strikeprice}>
          <span className="sr-only">was </span>
          <span data-m-id="strikeThroughPrice">{`$${formatPrice(itemPrice)}`}</span>
        </p>
      </div>
    </>
  ) : null;
};

export default StrikeThroughPriceWrapper;
