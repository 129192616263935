/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { useLabels } from '@/hooks/useLabels';
import azCommonStyles from '../../../theme/globals.module.scss';
import styles from './styles.module.scss';

const labelMap = {
  lblClearanceBadge: 'label_clearance_badge',
};

const ClearanceBadge = () => {
  const labels = useLabels(labelMap);

  return (
    <div className={cx(azCommonStyles['az-caption'], styles.clearance)}>
      {labels.lblClearanceBadge}
    </div>
  );
};

export default ClearanceBadge;
