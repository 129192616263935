/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const productInfoConstants = {
  notAvailable: 'Not Available',
  optional: 'Optional',
  outOfStock: 'Out of Stock',
  productAvail: 'ProductAvail',
  sth: 'STH',
  bopus: 'BOPUS',
  inStock: 'In Stock',
  ProductSpec: 'ProductSpec',
  true: 'true',
  dealsModal: 'DEALS_MODAL',
  productSelected: 'product selected',
  productAvailability: 'ProductAvailability',
  listView: 'listView',
  gridView: 'gridView',
  productShelfName: 'PRODUCTSHELF',
  catalogSearchResults: 'catalog_search-results',
  primary: 'primary',
  SEARCH_RESULTS: 'SEARCH_RESULTS',
};
