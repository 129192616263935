/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useLocale } from '@/hooks/useLocale';
import Head from 'next/head';

export type ProductDetailMetadataProps = {
  title: string | undefined;
  metaTitle: string | undefined;
  url: string | undefined;
  description: string | undefined;
  image: string | undefined;
  siteName: string | undefined;
  canonicalURL: string | undefined;
  alternateURL: string | undefined;
  hrefLang: string | undefined;
};

export function ProductDetailMetadata({
  title,
  metaTitle,
  url,
  description,
  image,
  siteName,
  canonicalURL,
  alternateURL,
  hrefLang,
}: ProductDetailMetadataProps) {
  const locale = useLocale();
  return (
    <Head>
      <title key="title">{title}</title>
      {metaTitle && <meta key="metaTitle" name="title" content={metaTitle} />}
      {url && <meta key="metaUrl" property="og:url" content={url} />}
      {description && <meta key="metaDescription" name="description" content={description} />}
      {image && <meta key="metaImage" property="og:image" content={image} />}
      {siteName && <meta key="metaSiteName" property="og:site_name" content={siteName} />}
      <meta key="metaType" property="og:type" content="product" />
      {canonicalURL && (
        <>
          <link key="linkCanonical" rel="canonical" href={canonicalURL} />
          <link
            key="linkAlternateLocale"
            rel="alternate"
            hrefLang={locale.toLowerCase()}
            href={canonicalURL}
          />
          {alternateURL && (
            <link
              key="linkAlternateObj"
              rel="alternate"
              hrefLang={hrefLang || ''}
              href={alternateURL}
            />
          )}
        </>
      )}
    </Head>
  );
}
