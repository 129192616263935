/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { routePaths } from '@/constants/routePaths';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { Notification, Text, View } from '@az/starc-ui';
import { usePageSpecificData } from '@/utils/analytics/usePageSpecificData';
import { CMSYmme } from '@/features/contentstack/components/CMSYmme/CMSYmme';
import { SmartLink } from '@/utils/smartLink';
import styles from './CMSPDPYmme.module.scss';
import { NewCertonaForOos } from '@/components/ProductDetail/NewCertonaForOos';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useIsStoreSelected } from '@/features/header/hooks/useIsStoreSelected';
import { useProductDetails } from '@/features/product/api/getProductDetails';
import { useProductSkuDetails } from '@/features/product/api/getProductSkuDetails';
import { type CertonaRecommendation, type AddCertonaItemObjectType } from '@/features/certona';
import { useFeatureFlag } from '@/features/globalConfig';
import { countryCodes } from '@/constants/locale';

type Props = {
  certonaRecommendations?: CertonaRecommendation[];
  certonaLength?: boolean;
  isFetchingNewContainerAvailability?: boolean;
  setCertonaRecommendationAddedToCart: React.Dispatch<React.SetStateAction<boolean>>;
  setAddedCertonaItemObject: React.Dispatch<React.SetStateAction<AddCertonaItemObjectType | null>>;
};

const labelsMap = {
  ADD_YOUR_VEHCILE: 'label_First_add_your_vehicle',
  GET_AN_EXACT_FIT_FOR_YOUR_VEHICLE: 'hyperlink_YMME_Body_GetAnExactFitForYourVehicle',
  THIS_PART_DOES: 'label_ProductDetailsPage_YMME_this_part_does',
  NOT: 'label_ProductDetailsPage_YMME_not',
  FIT_YOUR_CURRENT_VEHICLE: 'label_ProductDetailsPage_YMME_fit_your_current_vehicle',
  SEE_PARTS_THAT_FIT: 'label_ProductDetailsPage_YMME_see_parts_that_fit',
};

export function CMSPDPYmme({
  // certonaType,
  certonaRecommendations,
  setCertonaRecommendationAddedToCart,
  setAddedCertonaItemObject,
}: Props) {
  const labels = useLabels(labelsMap);
  const locale = useLocale();
  const isBR = locale === countryCodes.ptBr;

  const isCertonaEnabledForOosOrDoesNotFit =
    useFeatureFlag('IS_OOS_OR_DOES_NOT_FIT_CONTAINER_ENABLED') === 'true';

  const { data: headerData } = useHeaderData();
  const hasVehicle = headerData?.vehicleMap?.vehicleId !== '';

  const { setPageSpecificData } = usePageSpecificData();
  const { data: productDetailsData } = useProductDetails();
  const { recordType, breadCrumbs, itemId, vehicleFitFlag } = productDetailsData?.product ?? {};

  const logAnalytics = () => {
    setPageSpecificData({
      productFindingMethod: 'Product Toast Notification',
      pfmLocation: 'Product Toast Notification',
      eventType: 'productToastNotificationClick',
    });
  };

  const { data: skuDetailsData } = useProductSkuDetails({
    skuIds: itemId ? [itemId] : [],
  });

  const itemFulfillmentOptions =
    skuDetailsData?.[0]?.skuPricingAndAvailability?.skuAvailabilityInfo?.fulfillmentOptions;

  const hasStoreSelected = useIsStoreSelected();

  const isOos =
    hasStoreSelected &&
    (hasVehicle || (!hasVehicle && recordType !== 'app')) &&
    itemFulfillmentOptions?.some((item) => item.available || item.sisterStoreAvailable) === false;
  const doesNotFit = hasStoreSelected && hasVehicle && recordType === 'app' && !vehicleFitFlag;

  if (isCertonaEnabledForOosOrDoesNotFit && (isOos || doesNotFit)) {
    return (
      <NewCertonaForOos
        isOos={isOos}
        doesNotFit={doesNotFit}
        logAnalytics={logAnalytics}
        certonaRecommendations={certonaRecommendations}
        setCertonaRecommendationAddedToCart={setCertonaRecommendationAddedToCart}
        setAddedCertonaItemObject={setAddedCertonaItemObject}
      />
    );
  }

  return (
    <>
      {!hasVehicle && productDetailsData?.product?.recordType === 'app' ? (
        <View className={styles.ymmePadding}>
          <CMSYmme
            content={{
              layout: 'Thin',
              text: labels.ADD_YOUR_VEHCILE,
              sub_text: labels.GET_AN_EXACT_FIT_FOR_YOUR_VEHICLE,
            }}
            classes={{
              titleContainerCustomStyle: !isBR ? styles.titleContainer : '',
              ymmeTitleLabel: styles.title,
            }}
            removeMargins
          />
        </View>
      ) : !vehicleFitFlag && recordType === 'app' ? (
        <View padding={{ s: [0, 0, 6, 0], l: [0, 0, 6, 0] }}>
          <Notification
            type="warning"
            text={
              <Text as="p" className={styles.text}>
                {labels.THIS_PART_DOES}{' '}
                <Text weight="bold" as="strong">
                  {labels.NOT}
                </Text>{' '}
                {labels.FIT_YOUR_CURRENT_VEHICLE}{' '}
                <SmartLink
                  to={
                    breadCrumbs?.find((item) => item.pageType === 'ProductShelf')?.seoUrl ??
                    routePaths.blankURL
                  }
                  onClick={logAnalytics}
                >
                  <Text weight="bold" as="strong" decoration="underline">
                    {labels.SEE_PARTS_THAT_FIT}
                  </Text>
                </SmartLink>
              </Text>
            }
          />
        </View>
      ) : null}
    </>
  );
}
