/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { PureComponent } from 'react';
import cx from 'classnames';
import Quantity from '../../AZCustomComponent/QuantityComponent';
import { Label } from '@/features/i18n';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { cartConstants } from '../../../constants/cart';
import azCommonStyles from '../../../theme/globals.module.scss';
import { callToCertona } from '@/features/certona/api/callToCertona';
import { certonaPageType } from '@/constants/certonaPageType';
import { trackCartRemoval } from '../analytics/trackCartRemoval';
import { FULFILLMENT_METHODS } from '../../../constants/fulfillmentConstants';
import styles from './styles.module.scss';
import type { RemoveCartAnalyticsData } from '@/types/analytics';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useFeatureFlag } from '@/features/globalConfig';
import type { AvailabilityInfoVOFromAPIV2 } from '@/types/availability';
import { formatPrice } from '@/utils/validator/formatPrice';

type ParentProps = {
  className?: string;
  data: any;
  availabilityCheck: AvailabilityInfoVOFromAPIV2;
  sisterStoreAvailable: boolean;
  isMini: boolean;
  isSisterStoreFlyout?: boolean;
  updatedValue?: (val: number) => void;
  updateQuantityError?: (errorMsg: boolean) => void;
  updateQuantityErrorItem?: (errorFlag: boolean, errorNdd?: boolean) => void;
  handleQuantityChange?: (
    quantity: number,
    quantityErrorMsg: boolean,
    showQuantityWarningMessage?: boolean,
    fulfillmentId?: number
  ) => void;
  updateTotalQuantity?: (newQuantity: number, updatedOnMount?: boolean) => void;
  pageType?: string;
  updateRequestingOrderItem?: () => void;
  disableRemoveButton?: boolean;
  corePrice?: number;
  disabled?: boolean;
  fulfillmentId?: number;
  setFulfillmentId?: (fulfillmentId: number) => void;
  isNewMessagingShown?: boolean | unknown;
  discountedStrikeThroughPrice?: number;
  filterproduct?: any;
  productTotalPrice?: string;
  hasDealId?: boolean;
};

type ChildProps = {
  labels: any;
  isBopusMexicoStoreEnabled?: boolean;
  strikeThroughPricingEnabled: boolean | unknown;
} & ParentProps;

type State = {
  count: number;
  analyticsPreviousCount: number;
  quantityErrorMsg: boolean;
  isIncOrDecBtnTouched: boolean;
  quantityEntered: number;
};

const labelMap = {
  label_cart_lineItem_MaximumQuantityReached: 'label_cart_lineItem_MaximumQuantityReached',
};

export class CommonCounterPart extends PureComponent<ChildProps, State> {
  constructor(props: ChildProps) {
    super(props);
    this.state = {
      count: this.props.data.itemQuantity,
      analyticsPreviousCount: 1,
      quantityErrorMsg: false,
      isIncOrDecBtnTouched: false,
      quantityEntered: this.props.data.itemQuantity,
    };
  }

  componentDidMount() {
    const { updateTotalQuantity } = this.props;
    this.quantityValidityCheck();
    updateTotalQuantity?.(this.state.count, true);
  }

  componentDidUpdate(prevProps: any) {
    /**Analytics Start
     * Global error analytics will trigger once the cart quantity reaches maximum available quantity.
     */
    /**Analytics End */
    const fulfillmentIdChanged = prevProps.fulfillmentId !== this.props.fulfillmentId;
    if (
      prevProps.data.itemQuantity !== this.props.data.itemQuantity ||
      prevProps.availabilityCheck !== this.props.availabilityCheck ||
      prevProps.data.orderType !== this.props.data.orderType ||
      fulfillmentIdChanged
    ) {
      this.quantityValidityCheck();
      // @ts-expect-error fix type
      this.setState({
        count: this.props.data.itemQuantity,
        ...(prevProps.data.orderType !== this.props.data.orderType ||
        fulfillmentIdChanged ||
        (prevProps.data.itemQuantity !== 1 && this.props.data.itemQuantity === 1)
          ? {
              quantityEntered: 1,
            }
          : {}),
      });
    }
  }

  quantityValidityCheck = () => {
    const { availabilityCheck, isMini, data, updateQuantityErrorItem } = this.props;
    const nddQuantity = availabilityCheck.nddQuantity ?? 0;
    const { itemQuantity, orderType, eligibleForNextDay } = data;
    const isOnlineOrder = orderType === cartConstants.ONLINEORDER;
    const nddQuantityCheck = eligibleForNextDay || nddQuantity > 0;

    if (!isMini && itemQuantity > this.maxQuantityCheck() && !isOnlineOrder) {
      updateQuantityErrorItem?.(true);
    } else if (!isMini && isOnlineOrder && nddQuantityCheck && itemQuantity > nddQuantity) {
      updateQuantityErrorItem?.(false, true);
    } else {
      updateQuantityErrorItem?.(false, false);
    }
  };

  maxQuantityCheck = (fulfilmentChanged: boolean = false, newFulfillmentId?: number) => {
    const { availabilityCheck, fulfillmentId } = this.props;
    const bopusQuantity = availabilityCheck.bopusQuantity ?? 0;
    const sthQuantity = availabilityCheck.sthQuantity ?? 0;
    const sddQuantity = availabilityCheck.sddQuantity ?? 0;
    const nddQuantity = availabilityCheck.nddQuantity ?? 0;
    const updatedFulfillmentId =
      fulfilmentChanged && newFulfillmentId ? newFulfillmentId : fulfillmentId!;
    const maxQuantity = this.selectedFulfilmentQuantity(
      updatedFulfillmentId!,
      bopusQuantity,
      sthQuantity,
      sddQuantity,
      nddQuantity
    );

    return maxQuantity;
  };
  selectedFulfilmentQuantity = (
    id: number,
    bopusQuantity: number,
    sthQuantity: number,
    sddQuantity: number,
    nddQuantity: number
  ) => {
    switch (true) {
      case id === FULFILLMENT_METHODS.SAMEDAY:
        return sddQuantity;
      case id === FULFILLMENT_METHODS.NEXTDAY:
        if (this.props.data.itemQuantity > nddQuantity) {
          return sthQuantity;
        } else {
          return nddQuantity;
        }
      case id === FULFILLMENT_METHODS.ONLINEORDER:
        return sthQuantity;
      case id === FULFILLMENT_METHODS.STOREORDER:
        return bopusQuantity;
      default:
        return bopusQuantity;
    }
  };
  quantityErrorCheck = (checkQuantity: boolean, quantity: number) => {
    const {
      availabilityCheck,
      data,
      isMini,
      updateQuantityError,
      updateQuantityErrorItem,
      updatedValue,
    } = this.props;
    const nddQuantity = availabilityCheck.nddQuantity ?? 0;

    if (
      !isMini &&
      data.orderType !== cartConstants.STOREORDER &&
      nddQuantity > 0 &&
      quantity > nddQuantity
    ) {
      updateQuantityError?.(checkQuantity);
      updateQuantityErrorItem?.(checkQuantity, true);
    } else if (
      !isMini &&
      data.orderType !== cartConstants.STOREORDER &&
      nddQuantity > 0 &&
      quantity <= nddQuantity
    ) {
      updateQuantityError?.(checkQuantity);
      updateQuantityErrorItem?.(checkQuantity, false);
    } else if (!isMini) {
      updateQuantityError?.(checkQuantity);
      updateQuantityErrorItem?.(checkQuantity, false);
    } else {
      this.state.count && updatedValue?.(this.state.count);
    }
  };
  handleChange = async (quantity: number, analyticRequire: boolean, showMaxQtyError?: boolean) => {
    const nddQuantity = this.props.availabilityCheck.nddQuantity;
    const sthQuantity = this.props.availabilityCheck.sthQuantity;
    const isNddAvailable = nddQuantity > 0;
    let maxQuantity = 0;
    let fulfillmentIdforQtyChange;
    const { updateTotalQuantity, handleQuantityChange, updateRequestingOrderItem } = this.props;

    if (
      quantity >= 0 &&
      isNddAvailable &&
      this.props.fulfillmentId === FULFILLMENT_METHODS.ONLINEORDER &&
      nddQuantity >= quantity
    ) {
      // This is for an edge case where user increases qty to and ndd is not available,
      // then decreases back to ndd qty so that fulfillment id should be updated
      this.props?.setFulfillmentId?.(FULFILLMENT_METHODS.NEXTDAY);
      fulfillmentIdforQtyChange = FULFILLMENT_METHODS.NEXTDAY;
      maxQuantity = Math.min(this.maxQuantityCheck(), 99);
    } else if (
      this.props.fulfillmentId === FULFILLMENT_METHODS.NEXTDAY &&
      nddQuantity < quantity &&
      sthQuantity > nddQuantity
    ) {
      this.props?.setFulfillmentId?.(FULFILLMENT_METHODS.ONLINEORDER);
      maxQuantity = Math.min(this.maxQuantityCheck(true, FULFILLMENT_METHODS.ONLINEORDER), 99);
      fulfillmentIdforQtyChange = FULFILLMENT_METHODS.ONLINEORDER;
    } else if (
      this.props.sisterStoreAvailable &&
      this.props.data.fulfillmentId === FULFILLMENT_METHODS.STOREORDER &&
      !this.props.isSisterStoreFlyout
    ) {
      maxQuantity = 0;
    } else {
      maxQuantity = Math.min(this.maxQuantityCheck(), 99);
    }
    const maxQuantityReached = quantity > maxQuantity;
    const adjustedQuantityForNegative = quantity < 0 ? this.state.count : quantity;
    const adjustedQuantity = maxQuantityReached ? maxQuantity : adjustedQuantityForNegative;
    this.setState({
      isIncOrDecBtnTouched: true,
    });
    if (showMaxQtyError) {
      this.setState({
        quantityErrorMsg: true,
      });
    }

    if (!this.props.disableRemoveButton) {
      updateRequestingOrderItem?.();
    }

    this.setState({
      count: adjustedQuantity,
      quantityEntered: quantity === 0 ? 1 : quantity,
    });
    const showQuantityWarningMessage = true;
    this.state.count &&
      handleQuantityChange?.(
        adjustedQuantity,
        false,
        showQuantityWarningMessage,
        fulfillmentIdforQtyChange
      );
    const isQuantityBelowMax = !maxQuantityReached;
    if (isQuantityBelowMax) {
      updateTotalQuantity?.(quantity);
      this.quantityErrorCheck(false, quantity);
    }
  };

  renderMiniCartError = (
    overQuantity: boolean,
    disableAddToCartBtn: boolean,
    isIncOrDecBtnTouched: boolean
  ) => {
    let returnValue = null;

    if (overQuantity) {
      returnValue = (
        <div
          role="alert"
          data-testid="max-qty-reached-error"
          className={cx(azCommonStyles['az-caption'], styles.quantityAvailableError)}
        >
          {isIncOrDecBtnTouched && this.props.labels.label_cart_lineItem_MaximumQuantityReached}
        </div>
      );
    }

    this.props.isMini && this.props.updatedValue?.(this.state.count);
    return returnValue;
  };

  renderEachItemPrice = () => {
    const { count } = this.state;
    const { discountedStrikeThroughPrice, hasDealId, productTotalPrice, data } = this.props;
    let showStrikeThroughPricing = false;
    if (this.props.strikeThroughPricingEnabled) {
      showStrikeThroughPricing = true;
    }
    const discountedPrice = this.props.filterproduct?.skuPricingAndAvailability?.discountedPrice;

    let amount;

    if (discountedStrikeThroughPrice && !hasDealId) {
      amount = discountedStrikeThroughPrice;
    } else if (showStrikeThroughPricing && discountedPrice && !hasDealId) {
      amount = Number(discountedPrice);
    } else {
      amount =
        data?.lineItemPriceInfo?.unitPrice > 0
          ? data.lineItemPriceInfo.unitPrice
          : parseFloat(productTotalPrice ?? '0.00');
    }

    let returnValue = null;

    const eachLabel = this.props.isBopusMexicoStoreEnabled ? (
      <Label label="label_cart_Each" />
    ) : (
      <Label label="label_cart_lineItem_Each" />
    );

    if (count > 1 && amount) {
      returnValue = (
        <div
          className={cx(
            azCommonStyles['az-body-3-regular'],
            azCommonStyles['az-margin-top-4xs'],
            azCommonStyles['az-align-center'],
            styles.unitPrice
          )}
        >
          {eachLabel} ${formatPrice(amount)}
        </div>
      );
    }

    return returnValue;
  };
  removePart: () => Promise<void> = async () => {
    const removeCartAnalyticsData: RemoveCartAnalyticsData = {
      prodSku: this.props.data.productInfo.skuId,
      // @ts-expect-error fix type
      productFulfillmentType: FULFILLMENT_METHODS[this.props.data.orderType],
      originalPartType: this.props.data.productInfo.originalPartType,
      productRepositoryId: this.props.data.productInfo.productId,
      brand: this.props.data.productInfo.brand,
      price: this.props.data.lineItemPriceInfo.retailPrice,
      quantity: this.props.data.itemQuantity,
    };
    await trackCartRemoval(removeCartAnalyticsData);
    const certonaOptions = {
      skuIds: this.props.data.productInfo.skuId,
      certonaType: certonaPageType.removeFromCartEvent,
    };
    await callToCertona(certonaOptions);
  };

  render() {
    const {
      data,
      availabilityCheck,
      pageType,
      className,
      corePrice,
      disabled,
      isNewMessagingShown = true,
      filterproduct,
    } = this.props;
    const { isIncOrDecBtnTouched, quantityEntered, count } = this.state;
    const { itemQuantity, unitAmount } = data;
    // @ts-expect-error validate and fix type error
    const isCorePriceAvailable = +corePrice !== 0;
    const ariaLabelPrice =
      // @ts-expect-error validate and fix type error
      +corePrice * +itemQuantity + +unitAmount * +itemQuantity;
    const extraMargin = pageType === cartConstants.productDetails;
    const bopusQuantity = availabilityCheck.bopusQuantity ?? 0;
    const nddQuantity = availabilityCheck.nddQuantity ?? 0;
    const sthQuantity = availabilityCheck.sthQuantity ?? 0;
    const sddQuantity = availabilityCheck.sddQuantity ?? 0;
    const rootClass = className ? `${styles.counterpart} ${className}` : styles.counterpart;
    let overQuantity = false;
    let overQuantityv2 = false;
    let availableQuantityForSelectedFulfillment;
    if (
      this.props.fulfillmentId === FULFILLMENT_METHODS.NEXTDAY &&
      nddQuantity < count &&
      sthQuantity >= count
    ) {
      this.props?.setFulfillmentId?.(FULFILLMENT_METHODS.ONLINEORDER);
      availableQuantityForSelectedFulfillment = this.selectedFulfilmentQuantity(
        FULFILLMENT_METHODS.ONLINEORDER,
        bopusQuantity,
        sthQuantity,
        sddQuantity,
        nddQuantity
      );
    } else {
      availableQuantityForSelectedFulfillment = this.selectedFulfilmentQuantity(
        this.props.fulfillmentId!,
        bopusQuantity,
        sthQuantity,
        sddQuantity,
        nddQuantity
      );
    }

    overQuantityv2 = quantityEntered > Math.min(availableQuantityForSelectedFulfillment, 99);

    const disableIncrementBtn =
      quantityEntered > Math.min(availableQuantityForSelectedFulfillment, 99);
    let disableAddToCartBtn = false;
    if (data.orderType === cartConstants.STOREORDER) {
      const maxBopusQty = Math.min(bopusQuantity, 99);
      overQuantity = quantityEntered > maxBopusQty;
      disableAddToCartBtn = count > maxBopusQty;
    } else if (data.orderType === cartConstants.ONLINEORDER) {
      // According to current bussiness rules 99 is the max number of items in a STH.
      const maxSTHQty = sthQuantity > 99 ? 99 : sthQuantity;
      const maxSDDQty = sddQuantity > 99 ? 99 : sddQuantity;
      const maxNDDQty = nddQuantity > 99 ? 99 : nddQuantity;

      if (this.props.fulfillmentId === FULFILLMENT_METHODS.SAMEDAY) {
        overQuantity = quantityEntered > maxSDDQty;
        disableAddToCartBtn = quantityEntered > maxSDDQty;
      } else if (this.props.fulfillmentId === FULFILLMENT_METHODS.NEXTDAY) {
        overQuantity = quantityEntered > maxNDDQty;
        disableAddToCartBtn = count > maxNDDQty;
      } else {
        overQuantity = quantityEntered > maxSTHQty;
        disableAddToCartBtn = count > maxSTHQty;
      }
    } else if (data.eligibleForNextDay) {
      overQuantity = quantityEntered > nddQuantity;
      disableAddToCartBtn = count > nddQuantity;
    }

    return (
      <div className={rootClass}>
        {!isNewMessagingShown &&
          !this.props.isSisterStoreFlyout &&
          this.renderMiniCartError(overQuantityv2, disableAddToCartBtn, isIncOrDecBtnTouched)}
        <div className={azCommonStyles['az-align-center']}>
          <Quantity
            onInputChange={this.handleChange}
            onIncrementDecrement={this.handleChange}
            // @ts-expect-error validate and fix type error
            count={count ? parseInt(count) : 1}
            minQuantity={1}
            disableIncrementBtn={disabled || disableIncrementBtn}
            disabled={disabled}
            hasError={
              this.props.isNewMessagingShown && !this.props.isSisterStoreFlyout
                ? false
                : overQuantity
            }
            moreMargin={extraMargin}
            isIncOrDecBtnTouched={isIncOrDecBtnTouched}
            handleZeroInput={this.removePart}
            isCorePriceAvailable={isCorePriceAvailable}
            ariaLabelPrice={ariaLabelPrice}
            filterproduct={filterproduct}
          />
          {this.renderEachItemPrice()}
          {!isNewMessagingShown &&
            this.props.isSisterStoreFlyout &&
            this.renderMiniCartError(overQuantityv2, disableAddToCartBtn, isIncOrDecBtnTouched)}
        </div>
      </div>
    );
  }
}

const CommonCounterPartComp = (props: ParentProps) => {
  const { data: storeDetailsData } = useStoreDetailsData();
  const locale = useLocale();
  const isBopusEnabled = storeDetailsData?.bopusEnabled;
  const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;
  const strikeThroughPricingEnabled = useFeatureFlag('SHOW_STRIKE_THROUGH_PRICING') === 'true';

  return (
    <CommonCounterPart
      labels={useLabels(labelMap)}
      isBopusMexicoStoreEnabled={isBopusMexicoStoreEnabled}
      strikeThroughPricingEnabled={strikeThroughPricingEnabled}
      {...props}
    />
  );
};

export default CommonCounterPartComp;
