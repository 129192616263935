/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type {
  GetDynamicLinkMappingsUsingGetParamsModel,
  GetVehicleDynamicLinkMappingsUsingGetParamsModel,
} from '@/api/types/seo-data-types';
import { requestBaseURL, requestBaseURLGCPExtOrInt } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { createQuery } from '@/utils/createReactQuery';
import { type QueryClient } from '@tanstack/react-query';
import { type AxiosInstance } from 'axios';
import type {
  DLMType,
  DLMResponse,
  VehicleDLMResponse,
  VehicleDynamicLinkOptions,
} from '../interface';

const URL = `${requestBaseURL}/external/product-discovery/seo-data/v1/dynamic-link-mappings`;
const GCP_URL = `${requestBaseURLGCPExtOrInt}/sls/b2c/product-discovery-seo-data-bs/v2/dynamic-link-mappings`;
const VEHICLE_URL = `${requestBaseURL}/external/product-discovery/seo-data/v1/vehicle-dynamic-link-mappings`;
const VEHICLE_GCP_URL = `${requestBaseURLGCPExtOrInt}/sls/b2c/product-discovery-seo-data-bs/v2/vehicle-dynamic-link-mappings`;

type QueryOptions = Omit<GetDynamicLinkMappingsUsingGetParamsModel, 'customerType'> & {
  type: DLMType;
  vehicleOptions?: VehicleDynamicLinkOptions;
  gcpUrlEnabled: boolean;
};

const getDynamicLinkMappings = async (
  options: QueryOptions,
  axiosInstance?: AxiosInstance
): Promise<DLMResponse> => {
  const { country, seoRepositoryId, type: dlmType, gcpUrlEnabled } = options;

  if (dlmType === 'generic') {
    const params: GetDynamicLinkMappingsUsingGetParamsModel = {
      customerType: 'B2C',
      country,
      seoRepositoryId,
    };
    const response = await getAxios(axiosInstance).get<DLMResponse>(gcpUrlEnabled ? GCP_URL : URL, {
      params,
      withCredentials: false,
    });
    return genericSelector(response.data);
  } else {
    const { make, model, year } = options.vehicleOptions ?? {};
    const params: GetVehicleDynamicLinkMappingsUsingGetParamsModel = {
      customerType: 'B2C',
      country,
      seoRepositoryId,
      make: make ?? '',
      model,
      year,
    };
    const response = await getAxios(axiosInstance).get<VehicleDLMResponse>(
      gcpUrlEnabled ? VEHICLE_GCP_URL : VEHICLE_URL,
      {
        params,
        withCredentials: false,
      }
    );
    return vehicleSelector(response.data);
  }
};

const genericSelector = (data: DLMResponse): DLMResponse => {
  return data;
};

const vehicleSelector = (data: VehicleDLMResponse): DLMResponse => {
  return data.map((link) => ({
    keywordTerm: link.vehicleKeywordTerm,
    keywordLinkUrl: link.vehicleKeywordLinkUrl,
    mappingSequenceNumber: link.vehicleMappingSequenceNumber,
  }));
};

const { useData: useDynamicLinkMappingsData, prefetch: prefetchDynamicLinkMappingsData } =
  createQuery('dlm', getDynamicLinkMappings);

export const useDynamicLinkMappings = (
  options: QueryOptions & {
    enabled?: boolean;
  }
) => {
  return useDynamicLinkMappingsData({
    ...options,
  });
};

export const prefetchDynamicLinkMappings = async (
  options: QueryOptions,
  queryClient: QueryClient,
  axiosInstance: AxiosInstance
) => {
  await prefetchDynamicLinkMappingsData(queryClient, options, axiosInstance);
};
