/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState } from 'react';
import { storeModalType } from '../../constants/storeModal';
import styles from './styles.module.scss';
import FulfillmentControlStoreModalV2 from './FulfillmentControlStoreModalV2';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { defaultStoreUS } from '@/constants/locale';
import type { FulfillmentOption } from '@/types/reduxStore/skuDetails';
import { SearchStoreModal } from '../Store/StoreModal/SearchStoreModal';

export function StorePickupOption({
  option,
  skuId,
  productName,
  handleInstockAnalytics,
  isDealDrawer,
  itemPresentInCart,
  toggleSisterStoreFlyout,
}: {
  option: FulfillmentOption;
  skuId: string | undefined;
  productName: string;
  handleInstockAnalytics?: () => any;
  isDealDrawer?: boolean;
  itemPresentInCart?: boolean;
  toggleSisterStoreFlyout?: () => void;
}) {
  const { data: storeData } = useStoreDetailsData();
  const [storeModalOpen, setStoreModalOpen] = useState(false);
  const toggleStoreModal = () => setStoreModalOpen((state: any) => !state);
  const isStoreSet = storeData?.storeNumber !== defaultStoreUS;
  const storeAddress = storeData?.address1;
  if (!isStoreSet) {
    return (
      <FulfillmentControlStoreModalV2
        available={option.available}
        renderType={storeModalType.searchStore}
        numberOfSisterStoreAvailable={option?.numberOfSisterStoreAvailable}
        isDealDrawer={isDealDrawer}
        itemPresentInCart={itemPresentInCart}
        toggleSisterStoreFlyout={toggleSisterStoreFlyout}
      />
    );
  }

  const notAvailableClass = option?.sisterStoreAvailable
    ? styles.notAvailablePreferredStore
    : styles.notAvailable;
  const availabilityClass = !option.available ? notAvailableClass : styles.inStock;

  const availableLabel = (
    <>
      <span className={availabilityClass}>{option.availableLabel.replace(/\sat\s*/gi, '')}</span>
      {` ${option.availableLabel.match(/at/gi)}`}
      {!option.available && (
        <span className={styles.sisterStoreDetails}> {storeAddress?.toLowerCase()}</span>
      )}
    </>
  );

  return (
    <>
      <div className={styles.storePickUpContainer}>
        <div className={styles.inStockLabelContainer}>
          {option.available && (
            <div className={styles.tickmarkContainer}>
              <img src="/images/green-tickmark.svg" alt="" />{' '}
            </div>
          )}
          {
            <span data-testid={`availability-${skuId}`} className={styles.storePickupAvailability}>
              {availableLabel}
            </span>
          }
        </div>

        {option.available && (
          <span className={styles.storeLink}>
            <>
              <FulfillmentControlStoreModalV2
                available={option.available}
                renderType={storeModalType.myStore}
                isDealDrawer={isDealDrawer}
                handleInstockAnalytics={handleInstockAnalytics}
                numberOfSisterStoreAvailable={option?.numberOfSisterStoreAvailable}
                itemPresentInCart={itemPresentInCart}
                toggleSisterStoreFlyout={toggleSisterStoreFlyout}
              />
            </>
          </span>
        )}
      </div>
      {option.sisterStoreAvailable && (
        <FulfillmentControlStoreModalV2
          available={option.available}
          renderType={storeModalType.nearByStore}
          skuId={skuId}
          isDealDrawer={isDealDrawer}
          productName={productName}
          numberOfSisterStoreAvailable={option?.numberOfSisterStoreAvailable}
          itemPresentInCart={itemPresentInCart}
          toggleSisterStoreFlyout={toggleSisterStoreFlyout}
        />
      )}
      {storeModalOpen ? <SearchStoreModal closeStoreModal={toggleStoreModal} /> : null}
    </>
  );
}
