/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { defaultStoreUS } from '@/constants/locale';
import { useHeaderData } from '../api/getHeader';

export const useIsStoreSelected = () => {
  const { data: storeNumber } = useHeaderData({ select: (headerData) => headerData.storeNumber });

  return !!storeNumber && storeNumber !== defaultStoreUS;
};
