/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useFeatureFlag } from '@/features/globalConfig';
import type { PDPComparisonAnalytics } from '@/types/analytics';
import { useProductDetails } from '@/features/product/api/getProductDetails';
import { useProductSkuDetails } from '@/features/product/api/getProductSkuDetails';
import dynamic from 'next/dynamic';
import { useMemo } from 'react';

export type CMSSEOComparisonChartProps = Record<string, never>;

const LazyPDPComparisonTable = dynamic(
  () =>
    import('@/features/pdp/components/PDPComparisonTable/PDPComparisonTable').then(
      (mod) => mod.PDPComparisonTable
    ),
  { ssr: true }
);

export function CMSSEOComparisonChart({}: CMSSEOComparisonChartProps) {
  const { data: productDetails } = useProductDetails();
  const { product } = productDetails ?? {};

  const { data: skuDetailsData } = useProductSkuDetails({
    skuIds: product?.itemId ? [product.itemId] : [],
    enabled: !!product?.itemId,
  });
  const skuDetails = skuDetailsData?.[0];

  const pdpComparisonEnabled = useFeatureFlag('PDP_COMPARISON_ENABLED') === 'true';

  const pdpComparisonAnalyticsData = useMemo(() => {
    if (product) {
      const { originalPartTypeId, brandName, subBrandName, ecommProductId } = product;

      const data: PDPComparisonAnalytics = {
        originalPartTypeId,
        brandName: subBrandName ?? brandName,
        repositoryId: ecommProductId,
      };

      return data;
    }
    return null;
  }, [product]);

  return pdpComparisonEnabled &&
    product &&
    product.pdpComparisonEnabled &&
    product.itemId &&
    product.brandName &&
    product.ecommProductId &&
    pdpComparisonAnalyticsData ? (
    <LazyPDPComparisonTable
      skuId={product.itemId}
      partNumber={product.partNumber}
      productName={product.itemDescription}
      price={
        skuDetails?.skuPricingAndAvailability?.corePrice
          ? skuDetails.skuPricingAndAvailability.retailPrice +
            skuDetails.skuPricingAndAvailability.corePrice
          : skuDetails?.skuPricingAndAvailability?.retailPrice
      }
      averageRating={product.averageRating}
      totalReviews={product.reviewCount}
      parentBrandName={product.brandName}
      subBrandName={product.subBrandName}
      productId={product.ecommProductId}
      productAttributes={product.productAttributes}
      productImage={product.itemImageUrl ?? null}
      analyticsData={pdpComparisonAnalyticsData}
    />
  ) : null;
}
