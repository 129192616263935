/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useLabel } from '@/hooks/useLabels';
import { View, Text } from '@az/starc-ui';
import styles from './styles.module.scss';

export const CompleteTheJobDescription = () => {
  const label = useLabel('label_affinity_the_following_products');
  return (
    <View height="100%" justify="center" className={styles.descriptionContainer}>
      <View align="center" padding={[1, 6]}>
        <Text
          fontFamily="body"
          weight="bold"
          // we can not use 'size' here due to desktop rem value being 20px
          attributes={{ style: { fontSize: '18px' } }}
          lineHeight="140"
        >
          {label}
        </Text>
      </View>
    </View>
  );
};
