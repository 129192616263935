/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useMemo } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useLocale } from '@/hooks/useLocale';
import { currencyLocales } from '@/constants/locale';
import { useProductSkuDeals } from '@/features/product/api/getProductDeals';
import { useProductDetails } from '@/features/product/api/getProductDetails';
import { useProductSkuDetails } from '@/features/product/api/getProductSkuDetails';
import { getPathnameFromAsPath } from '@/utils/urlHelpers';
import { useFeatureFlag } from '@/features/globalConfig';
import type { Currency } from '@/types/i18n';
import { getBaseSchemaUrl } from '@/utils/common';

type SchemaOffers = {
  '@type': string;
  url: string;
  availability: string;
  priceSpecification: UnitPriceSpecificationSchema | UnitPriceSpecificationSchema[];
};

type Schema = {
  '@context': string;
  '@type': string;
  '@id': string;
  name: string;
  image: string;
  description: string;
  mpn: string;
  sku: string;
  brand: { '@type': 'Brand'; name: string };
  offers?: SchemaOffers;
};

type UnitPriceSpecificationSchema = {
  '@type': 'UnitPriceSpecification';
  price: string;
  priceCurrency: Currency;
  priceType:
    | 'https://schema.org/InvoicePrice'
    | 'https://schema.org/ListPrice'
    | 'https://schema.org/MSRP'
    | 'https://schema.org/MinimumAdvertisedPrice'
    | 'https://schema.org/SRP'
    | 'https://schema.org/SalePrice';
};

export const SEOProductSchema = () => {
  const isNewDealsApiEnabled = useFeatureFlag('IS_NEW_DEALS_API_ENABLED') === 'true';

  const { data: productDetailsData, isSuccess: productDetailsSuccess } = useProductDetails();
  const skuIds =
    productDetailsSuccess && productDetailsData?.product?.itemId
      ? [productDetailsData.product.itemId]
      : null;

  const { data: skuDetailsData } = useProductSkuDetails({
    skuIds,
  });
  const { data: skuDealsData } = useProductSkuDeals({
    skuIds,
    enabled: isNewDealsApiEnabled,
  });
  const locale = useLocale();
  const router = useRouter();

  const productSchema = useMemo(() => {
    if (!productDetailsData) {
      return null;
    }

    const productDetails = productDetailsData.product;
    const skuDetails = skuDetailsData?.[0] ?? null;
    const skuPricingAndAvailability = skuDetails?.skuPricingAndAvailability;
    const retailPrice = skuPricingAndAvailability?.retailPrice || 0;
    const corePrice = skuPricingAndAvailability?.corePrice || 0;
    const price = Number(retailPrice + corePrice).toFixed(2);

    const discountedPrice = isNewDealsApiEnabled
      ? skuDealsData?.[0]?.skuDealsInfo?.discountedPrice
      : skuDetails?.skuPricingAndAvailability?.discountedPrice;

    const {
      subBrandName,
      marketingCopy,
      featuresAndBenefits,
      partNumber,
      itemDescription,
      itemId,
      itemImageUrl,
    } = productDetails ?? {};

    const baseUrl = getBaseSchemaUrl(locale);

    let productDescription = '';

    if (marketingCopy) {
      productDescription = marketingCopy;
    } else if (featuresAndBenefits?.length) {
      productDescription = featuresAndBenefits.join(' ');
    }

    const schema: Schema = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      '@id': `${baseUrl}${getPathnameFromAsPath(router.asPath)}`,
      name: itemDescription ?? '',
      image: itemImageUrl ?? '',
      description: productDescription,
      mpn: partNumber ?? '',
      sku: itemId ?? '',
      brand: { '@type': 'Brand', name: subBrandName ?? '' },
    };

    const priceSpecification: UnitPriceSpecificationSchema[] = [];

    if (price !== '0.00') {
      priceSpecification.push({
        '@type': 'UnitPriceSpecification',
        price,
        priceCurrency: currencyLocales[locale],
        priceType: 'https://schema.org/ListPrice',
      });
    }

    if (discountedPrice) {
      priceSpecification.push({
        '@type': 'UnitPriceSpecification',
        price: discountedPrice,
        priceCurrency: currencyLocales[locale],
        priceType: 'https://schema.org/SalePrice',
      });
    }

    if (priceSpecification.length) {
      schema.offers = {
        '@type': 'Offer',
        url: `${baseUrl}${getPathnameFromAsPath(router.asPath)}`,
        availability: 'https://schema.org/InStock',
        priceSpecification,
      };
    }

    return JSON.stringify(schema);
  }, [
    locale,
    router.asPath,
    isNewDealsApiEnabled,
    productDetailsData,
    skuDetailsData,
    skuDealsData,
  ]);

  if (!productSchema) {
    return null;
  }

  return (
    <Head>
      <script
        type="application/ld+json"
        key="productSchema"
        dangerouslySetInnerHTML={{
          __html: productSchema,
        }}
      />
    </Head>
  );
};
