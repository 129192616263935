/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const itemDetail = {
  dealIcon: {
    src: '/images/dealAppliedCheck.svg',
    alt: 'deal applied',
  },
  giftCard: {
    src: '/images/gift-card.svg',
  },
  green: {
    src: '/images/vehicle/green.svg',
    alt: 'green car icon',
  },
  red: {
    src: '/images/vehicle/red.svg',
    alt: 'red car icon',
  },
  info: {
    src: '/images/info.svg',
    alt: 'info',
  },
  downArrow: {
    src: '/images/down-arrow.svg',
    alt: 'expand',
  },
};
