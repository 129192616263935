/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect, useMemo } from 'react';
import { FlyoutHeader } from './FlyoutHeader';
import SisterStoreFlyoutBody from './SisterStoreFlyoutBody';
import styles from './flyout.module.scss';
import OneOrMoreStores from './OneOrMoreStores';
import ShowMore from './ShowMore';
import { useProductList } from '@/features/shelf/context/useProductList';
import { useProductSkuDetails } from '@/features/product/api/getProductSkuDetails';
import { useFetchSisterStoreByStoreNumber } from '../../api/postSisterStores';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useGlobalConfig } from '@/features/globalConfig';
import SisterStoreFlyoutList from './SisterStoreFlyoutList';
import { useState } from 'react';
import { useDealDrawerFulfillmentSelection } from '@/features/deals/api/getDealDrawerFulfillmentSelection';
import { TrapFocus } from '@/components/Dialog/Modal/TrapFocus';
import { eventConstants } from '@/constants/event';
import usePrevious from '@/utils/usePrevious';
import { Spinner } from '@/components/AZCustomComponent/Spinner';

const labelMap: Record<string, string> = {
  tomorrow: 'label_Sister_Store_Flyout_Tomorrow',
  today: 'label_Sister_Store_Flyout_Today',
  inStorePickup: 'label_Sister_Store_Flyout_In_Store_Pickup',
  fromYourStore: 'label_Sister_Store_Flyout_From_Your_Store',
  selectedStore: 'sister_store_flyout_selected_store', // seleccionada
  orderOnline: 'sister_store_flyout_order_online', // Ordena en línea y
  pickupAtStore: 'sister_store_flyout_pickup_at_store', // recoge en tienda
  distance: 'sister_store_flyout_distance',
  inStock: 'label_Sister_Store_Flyout_In_Stock',
  storeNumber: 'label_Sister_Store_Flyout_Store_Number',
  selectStoreAndUpdateCart: 'label_Sister_Store_Flyout_Select_Store_And_Update_Cart',
  selectStoreAndAddToCart: 'label_Sister_Store_Flyout_Select_Store_And_Add_To_Cart',
  selectStore: 'label_Sister_Store_Flyout_Select_Store',
  notAvailable: 'label_Sister_Store_Flyout_Not_Available',
  lblToCart: 'label_ProductDisplayPage_body_ToCart',
  lblFromCart: 'label_FromCart',
  lblAnd: 'labels_order_and',
  lblRemove: 'label_remove',
  lblAdd: 'label_MyAccountVehicle_maintenanceInterval_Add',
  lblLinkToCallPhoneNumber: 'label_Link_to_Call_Phone_Number',
  reduce_quantity: 'sister_store_flyout_reduce_quantity_to_shop_this_store',
  show_more: 'sister_store_flyout_show_more',
  lblShowMoreNearbyStores:
    'label_Show_more_nearby_stores_where_this_product_is_available_for_purchase',
  lblYourStoreHasBeenUpdatedTo: 'label_your_store_has_been_updated_to',
  lblRefundableCoreDepositFlyout: 'label_Refundable_Core_Deposit_Flyout',
};
interface SisterStoreFlyoutProps {
  handleClose: (option?: boolean) => void;
  skuId: string;
  shippingType?: string;
  productDetails?: any;
  quantityInCart: number;
  dealId?: string;
}

export const SisterStoreFlyout = ({
  skuId,
  shippingType,
  productDetails,
  quantityInCart,
  handleClose,
  dealId,
}: SisterStoreFlyoutProps) => {
  const { data: shelfData } = useProductList();
  const fetchSistoreStore = useFetchSisterStoreByStoreNumber();
  const { data: headerData } = useHeaderData();
  const { data: globalData } = useGlobalConfig();
  const sisterStoreData = fetchSistoreStore.data;

  const prevStore = usePrevious(headerData?.storeDetails?.address1);

  const { data: dealDrawerFulfillmentOptions } = useDealDrawerFulfillmentSelection({
    storeNumber: headerData?.storeNumber,
    skuId,
    dealId,
    selectedVehicle: headerData?.vehicleMap?.catalogVehicleId,
  });

  const sisterStoreConstraint = useMemo(() => {
    return (
      dealDrawerFulfillmentOptions?.fullFulfillmentOptions[108]?.dealAvaiableSisterStores ?? []
    );
  }, [dealDrawerFulfillmentOptions?.fullFulfillmentOptions]);

  const { data: skuDetailsResult } = useProductSkuDetails({
    skuIds: [skuId],
    enabled: false,
  });

  const parts = shelfData?.shelfParts;
  const filterProduct = parts?.length
    ? parts.find((part) => String(part.skuNumber) === skuId)
    : skuDetailsResult?.[0];

  const pageProductDetails = !productDetails?.skuPricingAndAvailability
    ? filterProduct
    : productDetails;

  const pdpOrCartAndShelfProductDetails = {
    ...pageProductDetails,
    name: sisterStoreData?.skuDetails?.skuDisplayName ?? productDetails?.itemDescription,
    productImageUrl:
      sisterStoreData?.skuDetails?.productImageUrl ??
      productDetails?.itemMediaUrl?.thumbnailItemImageUrls?.[0] ??
      productDetails?.itemImageUrl,
    skuNumber: sisterStoreData?.skuDetails?.skuNumber ?? productDetails?.itemId,
    partNumber:
      sisterStoreData?.skuDetails?.productPartNumber ??
      pageProductDetails?.partNumber ??
      productDetails?.partNumber,
    price: sisterStoreData?.skuDetails?.productTotalPrice,
    skuPricingAndAvailability: {
      ...(pageProductDetails?.skuPricingAndAvailability || {}),
      corePrice:
        pageProductDetails?.skuPricingAndAvailability?.corePrice ??
        skuDetailsResult?.[0]?.skuPricingAndAvailability?.corePrice,
    },
  };

  const sisterStoreQoh: number[] = useMemo(() => {
    return (
      sisterStoreData?.sisterStores
        ?.filter(
          (sisterStore) =>
            sisterStore.availablityMessage === 'AVAILABLE' &&
            (dealId && sisterStoreConstraint
              ? sisterStoreConstraint.includes(sisterStore.sisterStoreDetail.storeNumber)
              : true)
        )
        .map((sisterStore) => sisterStore.qoh) ?? []
    );
  }, [dealId, sisterStoreConstraint, sisterStoreData?.sisterStores]);

  const miniumCount = Math.min(...sisterStoreQoh);
  const maximumCount = Math.max(...sisterStoreQoh);
  const finiteMinCount = isFinite(miniumCount) ? miniumCount : 1;
  const finiteMaxCount = isFinite(maximumCount) ? maximumCount : 99;
  const [count, setCount] = useState(quantityInCart);
  const [limitCount, setLimitCount] = useState(10);
  const [indexToFocus, setIndexToFocus] = useState(-1);
  const [filterChecked, setFilterChecked] = useState(false);
  const [sisterStoreDisplayList, setSisterStoreDisplayList] = useState(
    sisterStoreData?.sisterStores
  );
  const visibleStores = !filterChecked
    ? sisterStoreQoh?.filter((qoh) => count <= qoh)?.length ?? 1
    : sisterStoreQoh?.length ?? 1;

  useEffect(() => {
    const requestData = {
      storeNumber: headerData?.storeNumber,
      range: globalData?.SISTER_STORE_SEARCH_RADIUS || '150',
      skuId: skuId,
      resultsLimit: globalData?.SISTER_STORE_SEARCH_RESULTS_LIMIT || '35',
    };
    skuId && fetchSistoreStore.mutate(requestData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skuId]);

  useEffect(
    () =>
      setSisterStoreDisplayList(
        sisterStoreData?.sisterStores?.filter((sisterStore) => {
          let filtered =
            sisterStore.qoh > 0 ? (filterChecked ? true : count <= sisterStore.qoh) : false;
          let constrained =
            dealId && sisterStoreConstraint
              ? sisterStoreConstraint.includes(sisterStore.sisterStoreDetail.storeNumber)
              : true;
          return filtered && constrained;
        })
      ),
    [count, filterChecked, dealId, sisterStoreConstraint, sisterStoreData?.sisterStores]
  );

  useEffect(() => {
    setCount(
      /*
        if quantityInCart is 0, set to 1
        else if quantityInCart is less than/equal to maxQuantity, set to quantityInCart
        else set to maxQuantity
      */
      quantityInCart === 0 ? 1 : quantityInCart <= finiteMaxCount ? quantityInCart : finiteMaxCount
    );
  }, [finiteMaxCount, quantityInCart]);

  useEffect(() => {
    const handleEscKeyPress = (e: KeyboardEvent) => {
      if (e.key === eventConstants.escKeyType) {
        handleClose();
      }
    };

    window.addEventListener('keydown', handleEscKeyPress);
    return () => window.removeEventListener('keydown', handleEscKeyPress);
  }, [handleClose]);

  if (!fetchSistoreStore.data || !sisterStoreData?.sisterStores) {
    return (
      <div className={styles.loaderContainer}>
        <Spinner />
      </div>
    );
  }

  return (
    <TrapFocus open>
      <div role="dialog" aria-modal="true" className={styles.drawarBody}>
        <FlyoutHeader
          handleClose={handleClose}
          filterproduct={pdpOrCartAndShelfProductDetails}
          focusCloseRef
        />
        <SisterStoreFlyoutBody
          filterproduct={pdpOrCartAndShelfProductDetails}
          dealId={dealId}
          labelMap={labelMap}
          count={count}
          setCount={setCount}
          shippingType={shippingType}
          miniumCount={miniumCount}
          sisterStoreAvailMaxQty={maximumCount}
        />
        {count > finiteMinCount && (
          <div
            data-test-id="sister-store-flyout-quantity-exceed-toggle"
            className={styles.quantityExceed}
            role={'status'}
          >
            <OneOrMoreStores
              count={count}
              filterChecked={filterChecked}
              setFilterChecked={setFilterChecked}
            />
          </div>
        )}
        <SisterStoreFlyoutList
          sisterStores={fetchSistoreStore}
          skuId={skuId}
          productDetails={productDetails}
          itemData={productDetails}
          filterproduct={pdpOrCartAndShelfProductDetails}
          count={count}
          labelMap={labelMap}
          quantityInCart={quantityInCart}
          handleClose={handleClose}
          filterChecked={filterChecked}
          limitCount={limitCount}
          sisterStoreConstraint={sisterStoreConstraint}
          dealId={dealId}
          sisterStoreDisplayList={sisterStoreDisplayList ?? []}
          indexToFocus={indexToFocus}
          prevStore={prevStore}
        />
        <ShowMore
          labelMap={labelMap}
          numberOfSisterStores={visibleStores}
          limitCount={limitCount}
          setLimitCount={setLimitCount}
          setIndexToFocus={setIndexToFocus}
        ></ShowMore>
      </div>
    </TrapFocus>
  );
};
