/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import { Button } from '@/components/Button';
import { storeModalType } from '../../constants/storeModal';
import { useLabels } from '@/hooks/useLabels';
import styles from './styles.module.scss';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useFeatureFlag } from '@/features/globalConfig';
import { useState } from 'react';
import { MyStoreModal } from '../Store/StoreModal/MyStoreModal';
import { SearchStoreModal } from '../Store/StoreModal/SearchStoreModal';

type ParentProps = {
  available: boolean;
  renderType:
    | typeof storeModalType.myStore
    | typeof storeModalType.searchStore
    | typeof storeModalType.nearByStore;
  skuId?: string;
  productName?: string;
  handleInstockAnalytics?: () => any;
  numberOfSisterStoreAvailableFromFulFillmentAPI?: number;
};
type ChildProps = {
  availabilityInfoVO?: any;
  isDealDrawer?: boolean;
  itemPresentInCart?: boolean;
  toggleSisterStoreFlyout?: () => void;
} & ParentProps;
const labelMap = {
  lblSelectStore: 'label_DetailsPage_body_SelectStore',
  lblAtNearByStores: 'Label_AtNearByStores',
  lblAtNearByStore: 'Label_AtNearByStore',
  label_AvailableNearYou_SisterStores: 'label_AvailableNearYou_SisterStores',
};

export default function FulfillmentControlStoreModalV2({
  available,
  renderType,
  productName = '',
  handleInstockAnalytics,
  availabilityInfoVO,
  numberOfSisterStoreAvailableFromFulFillmentAPI,
  toggleSisterStoreFlyout,
}: ChildProps) {
  const [storeModalOpen, setStoreModalOpen] = useState(false);
  const toggleStoreModal = () => setStoreModalOpen((state) => !state);
  const isBopusNewMsgApplicable = useFeatureFlag('IS_BOPUS_MSG_AVAILABLE_US') === 'true';

  const { data: storeData } = useStoreDetailsData();
  const storeAddress = storeData?.address1;
  const labels = useLabels(labelMap);

  const sisterStores = availabilityInfoVO?.numberOfSisterStoresAvailable
    ? availabilityInfoVO?.numberOfSisterStoresAvailable
    : numberOfSisterStoreAvailableFromFulFillmentAPI;

  const getStoreAddressorModalType = () => {
    if (renderType === storeModalType.myStore) {
      return storeAddress;
    }

    if (renderType === storeModalType.searchStore) {
      return labels.lblSelectStore;
    }

    if (sisterStores > 1) {
      return labels.lblAtNearByStores;
    }

    return labels.lblAtNearByStore;
  };

  const storeAddressorModalType = getStoreAddressorModalType();

  const availableAtNearbyStores = !available && renderType === storeModalType.nearByStore;
  const label = storeAddressorModalType?.includes('At')
    ? storeAddressorModalType?.replace('At', `at ${sisterStores}`)
    : storeAddressorModalType;

  const getButtonClass = () => {
    if (availableAtNearbyStores) {
      return styles.sisterStoreDetails;
    }

    if (renderType === storeModalType.searchStore) {
      return styles.selectStore;
    }

    return styles.storeDetails;
  };

  const content = isBopusNewMsgApplicable ? label : storeAddressorModalType;
  const ariaLabel = `${productName} ${labels.label_AvailableNearYou_SisterStores} ${content}`;

  return (
    <div>
      <div className={styles.searchStoreButton} data-testid="fulfillment-store-button-container">
        {availableAtNearbyStores && (
          <div className={styles.inStockSisterStoreContainer}>
            <span data-testid="sister-store-label">
              {labels.label_AvailableNearYou_SisterStores}
            </span>
          </div>
        )}
        {isBopusNewMsgApplicable && availableAtNearbyStores && ' '}
        <Button
          variant="link"
          customClass={getButtonClass()}
          data-testid="search-store-button"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            e.nativeEvent.stopImmediatePropagation();

            if (availableAtNearbyStores) {
              toggleSisterStoreFlyout?.();
            } else {
              toggleStoreModal();
            }

            if (handleInstockAnalytics && typeof handleInstockAnalytics == 'function') {
              handleInstockAnalytics();
            }
          }}
          ariaLabel={ariaLabel}
        >
          {content}
        </Button>

        {storeModalOpen ? (
          <>
            {renderType === storeModalType.searchStore ? (
              <SearchStoreModal closeStoreModal={toggleStoreModal} />
            ) : null}
            {renderType === storeModalType.myStore ? (
              <MyStoreModal closeStoreModal={toggleStoreModal} />
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
}
