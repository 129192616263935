/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { createItemFulfillmentAvailabilityString } from '@/utils/itemHelpers/fulfillmentHelpers/createItemFulfillmentAvailabilityString';
import type { Store } from '@/types';
import { ProductSkuDetails } from '@/features/product';
import type { SkuDealsFromAPI } from '@/types/availability';
import { ProductModel } from '@/api/types/products-types';

const productDetailAnalytics = (
  store: Store,
  {
    productDetails,
    skuDetailsData,
    autoCorrectedKeyword,
    vehicleId,
    fitsVehicle,
    searchRedirectText,
    vehicleSpecific,
    hasDealBadge,
    isNewDealsApiEnabled,
    skuDealsData,
  }: {
    productDetails: ProductModel;
    skuDetailsData: ProductSkuDetails | null;
    autoCorrectedKeyword: string;
    vehicleId: string;
    fitsVehicle: boolean;
    vehicleSpecific: boolean;
    searchRedirectText: string;
    hasDealBadge: boolean | undefined;
    isNewDealsApiEnabled: boolean;
    skuDealsData: SkuDealsFromAPI[] | undefined;
  }
): {
  autoCorrectedKeyword: string;
  nddaftercutoff: Array<string>;
  productPartTypeIdList: Array<string>;
  sthSpecialOrderList: any[];
  dealBundleCountList: Array<string>;
  bopusList: boolean[];
  dealFlag: boolean;
  fulfillmentAvailabilityList: Array<string>;
  nddEligibleList: boolean[];
  originalPartTypeId: string | undefined;
  productBrand: string | undefined;
  productCorePrice: string;
  productId: string;
  productPartNumberList: Array<string>;
  productLineCode: string;
  productNotAvailable: boolean;
  productPart: string | undefined;
  productPrice: string;
  productRepositoryId: string | undefined;
  sthList: boolean[];
  sthSpecialOrder: boolean;
  vdp: boolean;
  searchResultCount?: string;
  clearanceList: Array<string>;
  sisterStoreAvailableList: Array<string>;
  productFitmentCheckMessage: Array<string>;
  productDealMessage: string[];
} => {
  const {
    partTypeName,
    itemId,
    lineCode,
    partNumber,
    subBrandName,
    originalPartTypeId,
    ecommProductId,
  } = productDetails ?? {};
  const productId = itemId;
  const productLineCode = lineCode ?? '';
  let productNotAvailable = false;
  let originalPartTypeIdFromShelfData = '';
  let productRepositoryIdFromShelfData = '';
  const fulfillmentAvailabilityList: string[] = [];
  const nddaftercutoff: string[] = [];
  const productPartTypeIdList: string[] = [];
  const sthSpecialOrderList: any[] = [];
  const clearanceList: string[] = [];
  const sisterStoreAvailableList: string[] = [];
  const productFitmentCheckMessage: string[] = [];
  const productDealMessage: string[] = [];
  const fulfillmentOptions =
    skuDetailsData?.skuPricingAndAvailability?.skuAvailabilityInfo.fulfillmentOptions;

  const sisterStoreAvailable = fulfillmentOptions?.filter((option) => {
    return option.sisterStoreAvailable === true;
  });

  if (fulfillmentOptions) {
    // @ts-expect-error
    fulfillmentAvailabilityList.push(createItemFulfillmentAvailabilityString(fulfillmentOptions));
  }

  if (sisterStoreAvailable?.length) {
    sisterStoreAvailableList.push('1');
  } else {
    sisterStoreAvailableList.push('');
  }

  !fitsVehicle && !vehicleSpecific
    ? productFitmentCheckMessage.push('')
    : !vehicleId
    ? productFitmentCheckMessage.push('check if this fits your vehicle')
    : fitsVehicle
    ? productFitmentCheckMessage.push('fits your vehicle')
    : productFitmentCheckMessage.push("doesn't fit your vehicle");

  const dealBundleCountList =
    skuDetailsData?.skuPricingAndAvailability?.dealsAvailableQty &&
    skuDetailsData?.skuPricingAndAvailability?.dealsAvailableQty !== 0
      ? [skuDetailsData.skuPricingAndAvailability.dealsAvailableQty.toString()]
      : [''];
  const dealAvailable =
    (skuDetailsData?.skuPricingAndAvailability?.dealsAvailableQty ?? 0) > 0 ? true : false;
  const discountAmount =
    parseFloat(String(skuDetailsData?.skuPricingAndAvailability?.wasRetailPrice)) -
    parseFloat(String(skuDetailsData?.skuPricingAndAvailability?.retailPrice));
  if (
    isNewDealsApiEnabled
      ? skuDealsData?.[0]?.skuDealsInfo?.dealAvailable || discountAmount
      : dealAvailable && hasDealBadge
  ) {
    const discountCalc = discountAmount
      ? Math.floor(
          100 *
            (discountAmount /
              parseFloat(String(skuDetailsData?.skuPricingAndAvailability?.wasRetailPrice)))
        )
      : 0;
    const discountDisplay =
      discountAmount > discountCalc ? `$${discountAmount}` : `${discountCalc}%`;
    if (skuDealsData?.[0]?.skuDealsInfo?.dealAvailable && discountAmount) {
      productDealMessage.push(
        `${
          skuDealsData?.[0]?.skuDealsInfo?.dealConfigurableLabel || 'See Available Deals'
        } - Save ${discountDisplay}`
      );
    } else if (skuDealsData?.[0]?.skuDealsInfo?.dealAvailable) {
      productDealMessage.push(
        `${skuDealsData?.[0]?.skuDealsInfo?.dealConfigurableLabel || 'See Available Deals'}`
      );
    } else {
      productDealMessage.push(`Save ${discountDisplay}`);
    }
  } else {
    productDealMessage.push('');
  }
  const bopusList = [skuDetailsData?.skuPricingAndAvailability?.storePickupAvailable ?? false];
  const nddEligibleList = [skuDetailsData?.aZBadgesFlagVO?.eligibleForNextDay ?? false];
  const sthList = [skuDetailsData?.skuPricingAndAvailability?.shipToHomeAvailable ?? false];
  const dealFlag = skuDetailsData?.skuPricingAndAvailability?.dealAvailable ?? false;
  const productPrice = (
    skuDetailsData?.skuPricingAndAvailability?.retailPrice.toString() ?? ''
  ).toString();
  skuDetailsData?.skuPricingAndAvailability?.skuAvailabilityInfo?.clearanceItem
    ? clearanceList.push('1')
    : clearanceList.push('0');
  const sthSpecialOrder = skuDetailsData?.skuPricingAndAvailability?.specialOrder ?? false;
  const vdp =
    skuDetailsData?.skuPricingAndAvailability?.skuAvailabilityInfo?.availableInVdpStore ?? false;
  const productCorePrice = skuDetailsData?.skuPricingAndAvailability?.corePrice.toString() ?? '';

  if (!bopusList[0] && !sthList[0]) {
    productNotAvailable = true;
  } else {
    productNotAvailable = false;
  }

  return {
    autoCorrectedKeyword,
    nddaftercutoff,
    productPartTypeIdList,
    sthSpecialOrderList,
    dealBundleCountList,
    productPart: partTypeName,
    productId,
    productPartNumberList: [partNumber ?? ''],
    productLineCode,
    productBrand: subBrandName,
    sthSpecialOrder,
    productPrice,
    dealFlag,
    productCorePrice,
    productNotAvailable,
    vdp,
    fulfillmentAvailabilityList,
    bopusList,
    sthList,
    nddEligibleList,
    originalPartTypeId: originalPartTypeIdFromShelfData
      ? originalPartTypeIdFromShelfData
      : originalPartTypeId,
    productRepositoryId: productRepositoryIdFromShelfData
      ? productRepositoryIdFromShelfData
      : ecommProductId,
    ...(!!searchRedirectText && {
      searchResultCount: '1',
    }),
    productFitmentCheckMessage,
    sisterStoreAvailableList,
    clearanceList,
    productDealMessage,
  };
};

export default productDetailAnalytics;
