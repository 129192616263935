/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useLabels } from '@/hooks/useLabels';
import { Button } from '@/components/Button';
import type { Props } from '@/components/Button';
import styles from './styles.module.scss';
import NextImage from '@/components/NextImage';
import { configurableSkuIcon } from '@/constants/images/configurableSkuIcon';

const greenTickMark2 = {
  src: '/images/green-tickmark2.svg',
  alt: '',
  width: 16,
  height: 11,
};

const labelMap = {
  customize: 'label.configurableSku.customizeYourFloorMat',
  setVehicleToCustomize: 'label_set_vehicle_to_see_pricing',
  addedToCart: 'label_CartButton_Added_To_Cart',
  goToCart: 'label_CategoryPage_body_GoToCart',
  add: 'label_MyAccountVehicle_maintenanceInterval_Add',
  toCart: 'label_ProductDisplayPage_body_ToCart',
  addToCart: 'button_ProductDisplayPage_body_AddToCart',
  updatedCart: 'label_updated_cart',
  updateCart: 'label_update_cart',
  checkNearbyStores: 'label_check_nearby_stores',
};

export const SUCCESS_ANIMATION_DURATION = 2000;

// TODO: rewrite passing "onSuccessfulAdd" through a ref to a prop.
export type CartButtonRef = {
  onSuccessfulAdd: (updateCart?: boolean) => void;
};

const CartButton = forwardRef<CartButtonRef, Props>(function CartButton(props, ref) {
  const [successState, setSuccessState] = useState(false);
  const [successUpdateState, setSuccessUpdateState] = useState(false);
  const [dynamicLabel, setDynamicLabel] = useState('');
  const [dynamicAriaLive, setDynamicAriaLive] = useState<
    'off' | 'assertive' | 'polite' | undefined
  >('polite');

  const { children, ariaLabel, variant, className, ...rest } = props;
  const labels = useLabels(labelMap);
  const addToCart = `${labels.add} ${labels.toCart}`;
  const isCustomizableSku =
    children === labels.customize || children === labels.setVehicleToCustomize;

  const setSuccess = (updateCart?: boolean) => {
    updateCart ? setSuccessUpdateState(true) : setSuccessState(true);
    setTimeout(() => {
      updateCart ? setSuccessUpdateState(false) : setSuccessState(false);
    }, SUCCESS_ANIMATION_DURATION);
  };

  useImperativeHandle(
    ref,
    () => ({
      onSuccessfulAdd: (value) => setSuccess(value),
    }),
    []
  );

  useEffect(() => {
    if (successState) {
      setDynamicLabel(labels.addedToCart);
    }
    if (successUpdateState) {
      setDynamicLabel(labels.updatedCart);
    }

    if (children === labels.goToCart) {
      setDynamicLabel(labels.goToCart);
    } else if (children === labels.checkNearbyStores && ariaLabel !== undefined) {
      setDynamicLabel(ariaLabel);
    } else {
      setDynamicLabel('');
    }
    if (
      (children === addToCart ||
        children === labels.addToCart ||
        children === labels.updateCart ||
        children === labels.updatedCart) &&
      ariaLabel !== undefined
    ) {
      setDynamicAriaLive(undefined);
      setDynamicLabel(ariaLabel);
    }
  }, [successState, dynamicLabel, children, labels.goToCart, labels.addedToCart, ariaLabel, labels.addToCart, addToCart, successUpdateState, labels.updatedCart, labels.checkNearbyStores, labels.updateCart]);

  const renderContent = () => {
    if (successState || successUpdateState) {
      return (
        <div>
          <NextImage
            src={greenTickMark2.src}
            alt={greenTickMark2.alt}
            width={greenTickMark2.width}
            height={greenTickMark2.height}
          />
          <span className={styles.success}>
            {successState ? labels.addedToCart : labels.updatedCart}
          </span>
        </div>
      );
    }

    if (isCustomizableSku) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <NextImage
            src={configurableSkuIcon.src}
            alt={configurableSkuIcon.alt}
            width={configurableSkuIcon.width.desktop}
            height={configurableSkuIcon.height.desktop}
          />
          <span>{children}</span>
        </div>
      );
    }

    return children;
  };

  return (
    <Button
      variant={
        children === labels.goToCart || children === labels.checkNearbyStores || isCustomizableSku
          ? 'outlined'
          : variant
      }
      customClass={className}
      {...rest}
      ariaLive={dynamicAriaLive}
      ariaLabel={dynamicLabel}
    >
      {renderContent()}
    </Button>
  );
});

export default CartButton;
