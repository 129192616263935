/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { useFeatureFlag } from '@/features/globalConfig';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';
import { cartConstants } from '@/constants/cart';
import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import { Button } from '../Button';
import type { FulfillmentGroup, FulfillmentOption } from '@/types/reduxStore/skuDetails';
import { SubGroupText } from './SubgroupText';
import { useLabels } from '@/hooks/useLabels';
import StorePickupTime from './StorePickupTime';
import { useIsStoreSelected } from '@/features/header/hooks/useIsStoreSelected';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { SisterStoreContext } from '@/features/fulfillment/context/SisterStoreContext';
import dayjs from 'dayjs';
import { dateFormatterForSTH } from '@/constants/date';
import { Text } from '@az/starc-ui';
import { SearchStoreModal } from '../Store/StoreModal/SearchStoreModal';
import { useMonetateDecisionFlag } from '@/features/kibo';
import { useDeviceDetector } from '@/hooks/useDeviceDetector';

export type FulfillmentOptionButtonsV2Props = {
  itemData?: ItemData;
  fulfillmentOptionsGroupList: Array<FulfillmentGroup>;
  changeShippingType: (
    shippingType: typeof cartConstants.STOREORDER | typeof cartConstants.ONLINEORDER,
    groupId: typeof FULFILLMENT_METHODS.STOREORDER | typeof FULFILLMENT_METHODS.ONLINEORDER,
    maxAvailableQty: number,
    sisterStoreAvailable: boolean,
    fulfillmentTypeId?: number
  ) => void;
  shippingType: typeof cartConstants.STOREORDER | typeof cartConstants.ONLINEORDER;
  skuId: string | undefined;
  productName: string;
  sisterStoreAvailable?: boolean;
  handleInstockAnalytics?: () => any;
  isDealDrawer?: boolean;
  itemPresentInCart?: boolean;
  quantityInCart: number;
  price?: number;
  isCart?: boolean;
  count?: number;
  fulfillmentId: number;
  sddQuantity?: number;
  nddQuantity?: number;
  disableRemoveButton?: boolean;
};
type ItemData = {
  shipToHome: boolean;
  itemQuantity: number;
};
const labelMap = {
  label_next_day: 'label_fulfilment_options_next_day',
  label_same_Day: 'label_fulfilment_options_same_day',
  label_get_it: 'label_fulfilment_options_get_it',
  label_tomorrow: 'label_fulfilment_options_tomorrow',
  label_today: 'label_fulfilment_options_today',
  label_sdd_shipping_price: 'label_fulfilment_options_sdd_shipping_price',
  label_free: 'label_fulfilment_options_free',
  label_free_over_35: 'label_fulfillment_options_free_over_35_v2',
  label_home_delivery: 'label_fulfilment_options_home_delivery',
  label_not_available: 'label_fulfilment_options_not_available',
  label_not_available_near_you: 'label_fulfilment_options_not_available_near_you',
  label_ready: 'label_fulfilment_options_ready',
  label_available_near_you: 'label_fulfilment_options_available_near_you',
  label_instore_pickup: 'label_fulfilment_options_instore_pickup',
  label_get_it_by: 'label_fulfilment_options_get_it_by',
  label_fulfillment_options_over_35: 'label_fulfillment_options_over_35',
  label_fulfillment_select_store: 'label_fulfillment_select_store',
  label_fulfillment_availability: 'label_fulfillment_availability',
};

export function FulfillmentOptionButtonsV2({
  changeShippingType,
  fulfillmentOptionsGroupList,
  productName,
  shippingType,
  skuId,
  itemData,
  handleInstockAnalytics,
  isDealDrawer,
  itemPresentInCart,
  isCart = false,
  quantityInCart,
  count = 0,
  fulfillmentId,
  sddQuantity,
  nddQuantity,
  disableRemoveButton,
}: FulfillmentOptionButtonsV2Props) {
  const labels = useLabels(labelMap);
  const { platform } = useDeviceDetector();
  const subGroupRefs = fulfillmentOptionsGroupList?.map(() => ({ ref: React.createRef<any>() }));
  const isBopusNewMsgApplicable = useFeatureFlag('IS_BOPUS_MSG_AVAILABLE_US') === 'true';
  const router = useRouter();
  const showNewFfmText = useMonetateDecisionFlag('displayNewFulfillmentText');
  const [storeModalOpen, setStoreModalOpen] = useState(false);
  const toggleStoreModal = () => setStoreModalOpen((state) => !state);
  const isStoreSelected = useIsStoreSelected();
  const [storePickUpSelected, setStorePickUpSelected] = useState(
    shippingType === cartConstants.STOREORDER
  );
  const [homeDeliverySelected, setHomeDeliverySelected] = useState(
    shippingType === cartConstants.ONLINEORDER && fulfillmentId !== FULFILLMENT_METHODS.SAMEDAY
  );
  const homeDeliveryButtonRef = useRef<HTMLButtonElement>(null);
  const [sameDayDeliverySelected, setSameDayDeliverySelected] = useState(
    shippingType === cartConstants.ONLINEORDER && fulfillmentId === FULFILLMENT_METHODS.SAMEDAY
  );
  const { data: storeDetails } = useStoreDetailsData();
  const refAriaLiveTag = useRef<HTMLDivElement>(null);
  const { toggleSisterStoreFlyout: toggleFlyout, isSisterStoreFlyoutOpen } =
    useContext(SisterStoreContext);
  const toggleSisterStoreFlyout = () => {
    toggleFlyout({
      skuId: skuId ?? '',
      shippingType: shippingType,
      quantityInCart: quantityInCart,
      productDetails: itemData,
    });
  };

  const addAriaLiveText = () => {
    if (refAriaLiveTag.current) {
      if (refAriaLiveTag.current.textContent === 'selected') {
        setTimeout(() => {
          if (refAriaLiveTag.current) {
            refAriaLiveTag.current.innerHTML = 'selected.';
          }
        }, 50);
      } else {
        setTimeout(() => {
          if (refAriaLiveTag.current) {
            refAriaLiveTag.current.innerHTML = 'selected';
          }
        }, 50);
      }
    }
  };

  const sisterStoreAvailable = (item: FulfillmentGroup) =>
    item.groupFulfillmentOptions.find((group) => group.groupId === FULFILLMENT_METHODS.STOREORDER)
      ?.sisterStoreAvailable ?? false;

  const handleClickStoreOrder = (item: FulfillmentGroup) => {
    const isSisterStoreAvailable = sisterStoreAvailable(item);
    if (!isStoreSelected) {
      toggleStoreModal();
      return null;
    }
    if (
      (optionChecked(FULFILLMENT_METHODS.STOREORDER) && !isSisterStoreAvailable) ||
      disableRemoveButton
    ) {
      return null;
    }
    if (item.available || (router.pathname === '/products/[...slug]' && isSisterStoreAvailable)) {
      addAriaLiveText();

      if (!isSisterStoreFlyoutOpen) {
        changeShippingType(
          cartConstants.STOREORDER,
          item.groupId,
          item.maxAvailableQty,
          sisterStoreAvailable(item),
          FULFILLMENT_METHODS.STOREORDER
        );
        setStorePickUpSelected(true);
        setHomeDeliverySelected(false);
        setSameDayDeliverySelected(false);
      }
    } else if (isSisterStoreAvailable) {
      addAriaLiveText();
      if (item.groupId === FULFILLMENT_METHODS.STOREORDER && router.pathname === routePaths.cart) {
        toggleSisterStoreFlyout();
      }
    }
  };
  const handleClickHomeDelivery = (item: FulfillmentGroup) => {
    if (
      fulfillmentId === FULFILLMENT_METHODS.NEXTDAY ||
      fulfillmentId === FULFILLMENT_METHODS.ONLINEORDER ||
      disableRemoveButton
    ) {
      return null;
    }
    if (item.available) {
      addAriaLiveText();
      const fulfillmentId = nddOptionAvailable(item.groupFulfillmentOptions)
        ? FULFILLMENT_METHODS.NEXTDAY
        : FULFILLMENT_METHODS.ONLINEORDER;
      changeShippingType(
        cartConstants.ONLINEORDER,
        item.groupId,
        fulfillmentId && fulfillmentId === FULFILLMENT_METHODS.NEXTDAY
          ? nddQuantity!
          : item.maxAvailableQty,
        sisterStoreAvailable(item),
        fulfillmentId
      );
      setStorePickUpSelected(false);
      setHomeDeliverySelected(true);
      setSameDayDeliverySelected(false);
    }
  };

  const handleClickSameDayDelivery = (item: FulfillmentGroup) => {
    if (fulfillmentId === FULFILLMENT_METHODS.SAMEDAY || disableRemoveButton) {
      return null;
    }
    if (item.available) {
      addAriaLiveText();
      changeShippingType(
        cartConstants.ONLINEORDER,
        item.groupId,
        sddQuantity!,
        sisterStoreAvailable(item),
        FULFILLMENT_METHODS.SAMEDAY
      );
      setStorePickUpSelected(false);
      setHomeDeliverySelected(false);
      setSameDayDeliverySelected(true);
    }
  };

  const nddOptionAvailable = (groupFulfillmentOptions: FulfillmentOption[]) => {
    return groupFulfillmentOptions.some((option) => {
      if (option.fulfillmentTypeId === FULFILLMENT_METHODS.NEXTDAY && option.available) {
        return isCart ? option.availableQty >= quantityInCart : option.availableQty >= count;
      }
      return false;
    });
  };

  const sddOptionAvailable = (groupFulfillmentOptions: FulfillmentOption[]) => {
    return groupFulfillmentOptions.some((option) => {
      if (option.fulfillmentTypeId === FULFILLMENT_METHODS.SAMEDAY && option.available) {
        return isCart ? option.availableQty >= quantityInCart : option.availableQty >= count;
      }
      return false;
    });
  };

  const standardDeliveryAvailable = (groupFulfillmentOptions: FulfillmentOption[]) => {
    return groupFulfillmentOptions.some((option) => {
      if (
        (option.fulfillmentTypeId === FULFILLMENT_METHODS.ONLINEORDER ||
          option.fulfillmentTypeId === FULFILLMENT_METHODS.CUSTOMIZEDSKU) &&
        option.available
      ) {
        return isCart ? option.availableQty >= quantityInCart : option.availableQty >= count;
      }
      return false;
    });
  };

  const getEstimatedDeliveryText = (fulFillmentOptions: FulfillmentOption[]) => {
    return fulFillmentOptions
      .map((option) => {
        if (
          option.fulfillmentTypeId === FULFILLMENT_METHODS.ONLINEORDER ||
          option.fulfillmentTypeId === FULFILLMENT_METHODS.CUSTOMIZEDSKU
        ) {
          return option.estimatedDeliveryDate?.split(':');
        }
      })
      .filter((item) => {
        return item !== undefined;
      });
  };

  const getHomeDeliveryButtonSubContent = (
    item: {
      groupLabel: any;
      groupFulfillmentOptions: FulfillmentOption[];
    },
    hideButton: boolean
  ) => {
    const prioritizeNddMessage = nddOptionAvailable(item.groupFulfillmentOptions);
    const prioritizeStandardDeliveryMessage =
      !nddOptionAvailable(item.groupFulfillmentOptions) &&
      standardDeliveryAvailable(item.groupFulfillmentOptions);

    const nddEstDeliveryDateString = item.groupFulfillmentOptions.find(
      (option) => option.fulfillmentTypeId === FULFILLMENT_METHODS.NEXTDAY
    )?.estimatedDeliveryDate;
    const estDeliveryDateTime = dayjs(nddEstDeliveryDateString);

    switch (true) {
      case prioritizeNddMessage:
        return (
          <>
            <span
              className={cx(styles.buttonSubtextStyles, styles.threeSplit, {
                [styles.disabledText]: hideButton,
              })}
            >
              {labels.label_get_it}
              <span className={cx(styles.successText, styles.deliveryTodayOrTomorrow)}>
                {estDeliveryDateTime.isTomorrow() ? (
                  <p>{labels.label_tomorrow}</p>
                ) : (
                  <p className={styles.deliveryMessageCapitalized}>
                    {estDeliveryDateTime.format(dateFormatterForSTH.weekDayMonthAndDay)}
                  </p>
                )}
              </span>
            </span>

            <span
              className={cx(styles.freeText, styles.threeSplit, {
                [styles.disabledText]: hideButton,
              })}
            >
              <span className={cx(styles.freeTextUppercase, styles.threeSplit)}>
                {labels.label_free}{' '}
              </span>
              <span
                className={cx(styles.nddFree, styles.threeSplit, {
                  [styles.disabledText]: hideButton,
                })}
              >
                {labels.label_fulfillment_options_over_35}
              </span>
            </span>
          </>
        );

      case prioritizeStandardDeliveryMessage:
        const estDeliveryDate = getEstimatedDeliveryText(item.groupFulfillmentOptions);
        const hasDateRange =
          estDeliveryDate?.[0]?.[1]?.includes('-') || estDeliveryDate?.[1]?.[1]?.includes('-');
        return (
          <>
            <span
              className={cx(styles.buttonSubtextStyles, styles.threeSplit, {
                [styles.disabledText]: hideButton,
                [styles.estDeliveryTextContainerStyles]: hasDateRange,
              })}
            >
              {hasDateRange ? labels.label_get_it : labels.label_get_it_by}
              <span
                className={cx(styles.successText, {
                  [styles.disabledText]: hideButton,
                })}
              >
                <p>
                  {estDeliveryDate?.[0]?.[1].replace(/\b0/g, '') ||
                    estDeliveryDate?.[1]?.[1].replace(/\b0/g, '')}
                </p>
              </span>
            </span>
          </>
        );
      default:
        return (
          <>
            <span
              className={cx(
                styles.buttonSubtextStyles,
                styles.homeDeliveryUnavailable,
                styles.threeSplit,
                {
                  [styles.disabledText]: hideButton,
                }
              )}
            >
              {labels.label_not_available}
            </span>
          </>
        );
    }
  };
  const optionChecked = (id: number) => {
    return fulfillmentId === id;
  };

  const getSameDayDeliveryButtonSubContent = (
    item: {
      groupFulfillmentOptions: FulfillmentOption[];
    },
    disabledButton: boolean
  ) => {
    const startingAt = labels.label_sdd_shipping_price.match(/starting at/i)?.[0] ?? '';
    const price = labels.label_sdd_shipping_price.substring(startingAt.length);
    return sddOptionAvailable(item.groupFulfillmentOptions) ? (
      <>
        <span
          className={cx(styles.buttonSubtextStyles, styles.threeSplit, {
            [styles.disabledText]: disabledButton,
          })}
        >
          {labels.label_get_it}
          <span
            className={cx(styles.deliveryTodayOrTomorrow, {
              [styles.disabledText]: disabledButton,
            })}
          >
            <p>{labels.label_today}</p>
          </span>
        </span>
        <span className={cx(styles.nddFree, styles.threeSplit)}>
          {startingAt}
          <span className={styles.freeText}>{price}</span>
        </span>
      </>
    ) : (
      <>
        <span
          className={cx(
            styles.buttonSubtextStyles,
            styles.homeDeliveryUnavailable,
            styles.threeSplit,
            {
              [styles.disabledText]: disabledButton,
            }
          )}
        >
          {labels.label_not_available}
        </span>
      </>
    );
  };

  const getStorePickupButtonSubContent = (
    item: { available: boolean; groupFulfillmentOptions: FulfillmentOption[] },
    disableButton: boolean
  ) => {
    const availableInSisterStores =
      !item.available &&
      Array.isArray(item.groupFulfillmentOptions) &&
      item.groupFulfillmentOptions[0]?.sisterStoreAvailable;
    const availableinSetStore = item.available;
    const notAvailable = !item.available && Array.isArray(item.groupFulfillmentOptions);
    const notAvailableatSisterStores =
      !item.available &&
      Array.isArray(item.groupFulfillmentOptions) &&
      !item.groupFulfillmentOptions[0]?.sisterStoreAvailable;
    switch (true) {
      case !isStoreSelected:
        return (
          <Text size="062" color="600" className={styles.selectStoreStyles}>
            <Text
              decoration="underline"
              className={styles.selectStoreStyles}
              attributes={{ role: 'button' }}
            >
              <span>{labels.label_fulfillment_select_store}</span>
            </Text>
            <span> {labels.label_fulfillment_availability} </span>
          </Text>
        );
      case availableinSetStore:
        return (
          <span className={styles.bopusAvailableTextContainer}>
            <span
              className={cx(styles.readyToday, {
                [styles.disabledText]: disableButton,
              })}
            >
              {labels.label_ready}
              <span className={cx(styles.successText, styles.storeTimeMargin)}>
                <StorePickupTime storeDetails={storeDetails} />
              </span>
            </span>
            <span
              className={cx(styles.freeTextUppercase, styles.threeSplit, {
                [styles.disabledText]: disableButton,
              })}
            >
              {labels.label_free}
            </span>
          </span>
        );
      case availableInSisterStores:
        return (
          <>
            <span
              className={cx(styles.buttonSubtextStyles, styles.threeSplit, {
                [styles.disabledText]: disableButton,
              })}
            >
              {showNewFfmText
                ? `${item.groupFulfillmentOptions[0].numberOfSisterStoreAvailable} nearby store${
                    item.groupFulfillmentOptions[0].numberOfSisterStoreAvailable! > 1 ? 's' : ''
                  }`
                : labels.label_available_near_you}
            </span>
            <span
              className={cx(styles.freeTextUppercase, styles.threeSplit, {
                [styles.disabledText]: disableButton,
              })}
            >
              {labels.label_free}
            </span>
          </>
        );
      case notAvailable:
        return (
          <span
            className={cx(styles.buttonSubtextStyles, styles.threeSplit, {
              [styles.disabledText]: disableButton,
            })}
          >
            {notAvailableatSisterStores
              ? labels.label_not_available_near_you
              : labels.label_not_available}
          </span>
        );
    }
  };

  useEffect(() => {
    const homeDeliveryButton = homeDeliveryButtonRef.current;
    if (platform !== 'ios' || !homeDeliveryButton) {
      return;
    }

    // trigger a reflow whenever the count updates to avoid button content glitching
    homeDeliveryButton.style.display = 'inline-flex';
    const timeoutId = setTimeout(() => {
      homeDeliveryButton.style.display = '';
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [count, platform]);

  return (
    <div className={styles.fulfilmentGroup}>
      <div className={styles.fulfillmentOptionCardsContainer}>
        <div className={styles.hiddenAriaLiveTag} role="status" ref={refAriaLiveTag}></div>
        {fulfillmentOptionsGroupList.map((item) => {
          const optionChecked = (id: number) => {
            return fulfillmentId === id;
          };
          const sisterStoreAvailable =
            Array.isArray(item.groupFulfillmentOptions) &&
            item.groupFulfillmentOptions[0]?.sisterStoreAvailable;

          const disabled =
            item.groupId === FULFILLMENT_METHODS.ONLINEORDER
              ? (!nddOptionAvailable(item.groupFulfillmentOptions) &&
                  !standardDeliveryAvailable(item.groupFulfillmentOptions)) ||
                !item.available
              : !sisterStoreAvailable && !item.available;
          let disableButton = isBopusNewMsgApplicable
            ? disabled && (item.groupId !== FULFILLMENT_METHODS.STOREORDER || isStoreSelected)
            : !item.available &&
              (item.groupId !== FULFILLMENT_METHODS.STOREORDER || isStoreSelected);
          let disabledSameDayButton = isBopusNewMsgApplicable
            ? !sddOptionAvailable(item.groupFulfillmentOptions)
            : true;
          if (item.groupId === FULFILLMENT_METHODS.ONLINEORDER) {
            return (
              <Fragment key={`${item.groupId}-btn`}>
                <Button
                  aria-roledescription={
                    optionChecked(FULFILLMENT_METHODS.NEXTDAY) ||
                    optionChecked(FULFILLMENT_METHODS.ONLINEORDER)
                      ? 'selected button'
                      : 'button'
                  }
                  data-testid="home-delivery-fulfillment-btn"
                  customClass={cx(styles.v2ButtonStyles, styles.buttonCommonStyles, {
                    [styles.activeButtonStyles]:
                      (optionChecked(FULFILLMENT_METHODS.NEXTDAY) ||
                        optionChecked(FULFILLMENT_METHODS.ONLINEORDER) ||
                        optionChecked(FULFILLMENT_METHODS.CUSTOMIZEDSKU) ||
                        (disabledSameDayButton &&
                          optionChecked(FULFILLMENT_METHODS.SAMEDAY) === true) ||
                        (homeDeliverySelected &&
                          shippingType === cartConstants.ONLINEORDER &&
                          !optionChecked(FULFILLMENT_METHODS.SAMEDAY))) &&
                      !disableButton,
                    [styles.disabledStyles]: disableButton,
                    [styles.v2ButtonShadow]: !disableButton,
                  })}
                  disabled={disableButton}
                  type="button"
                  onClick={() => handleClickHomeDelivery(item)}
                  reference={homeDeliveryButtonRef}
                >
                  <span
                    className={cx(styles.sthCard, {
                      [styles.disabledText]: disableButton,
                    })}
                  >
                    {labels.label_home_delivery}
                  </span>
                  {getHomeDeliveryButtonSubContent(item, disableButton)}
                </Button>
                <Button
                  aria-roledescription={
                    optionChecked(FULFILLMENT_METHODS.SAMEDAY) ? 'selected button' : 'button'
                  }
                  data-testid="same-day-delivery-fulfillment-btn"
                  customClass={cx(styles.v2ButtonStyles, styles.buttonCommonStyles, {
                    [styles.activeButtonStyles]:
                      (optionChecked(FULFILLMENT_METHODS.SAMEDAY) &&
                        sddOptionAvailable(item.groupFulfillmentOptions)) ??
                      (sameDayDeliverySelected && !disabledSameDayButton),
                    [styles.disabledStyles]: disabledSameDayButton,
                    [styles.v2ButtonShadow]: !disabledSameDayButton,
                  })}
                  disabled={disabledSameDayButton}
                  type="button"
                  onClick={() => handleClickSameDayDelivery(item)}
                >
                  <span
                    className={cx(styles.sthCard, {
                      [styles.disabledText]: disabledSameDayButton,
                    })}
                  >
                    {labels.label_same_Day}
                  </span>
                  {getSameDayDeliveryButtonSubContent(item, disabledSameDayButton)}
                </Button>
              </Fragment>
            );
          }

          if (item.groupId === FULFILLMENT_METHODS.STOREORDER) {
            const isButtonSelected = isCart
              ? optionChecked(FULFILLMENT_METHODS.STOREORDER) && !sisterStoreAvailable
              : optionChecked(FULFILLMENT_METHODS.STOREORDER);
            return (
              <Button
                key={`${item.groupId}-btn`}
                aria-roledescription={isButtonSelected ? 'selected button' : 'button'}
                data-testid="store-pickup-fulfillment-btn"
                customClass={cx(styles.v2ButtonStyles, styles.buttonCommonStyles, {
                  [styles.activeButtonStyles]:
                    (isButtonSelected ?? storePickUpSelected) &&
                    (!disableButton ||
                      (shippingType === cartConstants.STOREORDER &&
                        item?.groupFulfillmentOptions?.[0]?.sisterStoreAvailable)),
                  [styles.disabledStyles]: disableButton,
                  [styles.v2ButtonShadow]: !disableButton,
                })}
                disabled={disableButton}
                type="button"
                onClick={() => handleClickStoreOrder(item)}
              >
                <span className={cx(styles.bopusCard, { [styles.disabledText]: disableButton })}>
                  {labels.label_instore_pickup}
                </span>
                {getStorePickupButtonSubContent(item, disableButton)}
              </Button>
            );
          }
        })}
      </div>

      {fulfillmentOptionsGroupList.map((item, buttonIndex) => {
        const defaultCheckedGroup =
          shippingType === cartConstants.STOREORDER
            ? FULFILLMENT_METHODS.STOREORDER
            : FULFILLMENT_METHODS.ONLINEORDER;
        return (
          item.groupFulfillmentOptions &&
          item.groupId === defaultCheckedGroup && (
            <div
              className={styles.subgroupContainer}
              key={`${fulfillmentId}-subgroup`}
              data-testid={`${fulfillmentId}-subgroup`}
            >
              <SubGroupText
                fulfillmentOptionsGroupList={fulfillmentOptionsGroupList}
                itemData={itemData}
                isDealDrawer={isDealDrawer}
                skuId={skuId}
                productName={productName}
                groupId={item.groupId}
                handleInstockAnalytics={handleInstockAnalytics}
                ref={subGroupRefs[buttonIndex].ref}
                itemPresentInCart={itemPresentInCart}
                toggleSisterStoreFlyout={toggleSisterStoreFlyout}
                nddOptionAvailable={nddOptionAvailable(
                  fulfillmentOptionsGroupList.flatMap((group) => group.groupFulfillmentOptions)
                )}
                nextDayDeliverySelected={optionChecked(FULFILLMENT_METHODS.NEXTDAY)}
                sameDayDeliverySelected={optionChecked(FULFILLMENT_METHODS.SAMEDAY)}
                storePickUpSelected={optionChecked(FULFILLMENT_METHODS.STOREORDER)}
              />
            </div>
          )
        );
      })}
      {storeModalOpen && !isStoreSelected && (
        <SearchStoreModal closeStoreModal={toggleStoreModal} />
      )}
    </div>
  );
}
