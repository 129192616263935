/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { seoPageType } from '@/utils/analytics/dataLayer/seoPageType';
import { shelfPageType } from '../constants';

export const shopByPageTypeInfo = (
  shopByPageType: string,
  yearRange: Array<{ year: string; yearId: string }>
) => {
  if (shopByPageType === shelfPageType.shopByMake) {
    seoPageType('SHELF_MPT');
  } else if (shopByPageType === shelfPageType.shopByModel && yearRange.length === 1) {
    seoPageType('SHELF_YVPT');
  } else if (shopByPageType === shelfPageType.shopByModel) {
    seoPageType('SHELF_VPT');
  } else {
    seoPageType('SHELF_PT');
  }
};
