/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import cx from 'classnames';
import azCommonStyles from '../../theme/globals.module.scss';
import ContentContainer from '../AZCustomComponent/ContentContainer';
import { FULFILLMENT_METHODS } from '../../constants/fulfillmentConstants';
import { Label } from '@/features/i18n';
import { defaultStoreUS } from '@/constants/locale';
import styles from './styles.module.scss';
import NextDayOption from './NextDayOption';
import SameDayOption from './SameDayOption';
import { StorePickupOption } from './StorePickupOption';
import { StandardOption } from './StandardOption';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import type { FulfillmentOption } from '@/types/reduxStore/skuDetails';

type Props = {
  groupFulfillmentOptions: Array<FulfillmentOption>;
  itemData?: ItemData;
  skuId: string | undefined;
  productName: string;
  groupId: number;
  handleInstockAnalytics?: () => any;
};

type ItemData = {
  shipToHome: boolean;
  itemQuantity: number;
};

export const SubGroupOptions = React.forwardRef<HTMLTextAreaElement, Props>(
  function SubGroupOptions(
    {
      groupFulfillmentOptions,
      itemData,
      skuId,
      productName,
      groupId,
      handleInstockAnalytics,
    }: Props,
    ref: React.Ref<any>
  ) {
    const { data: storeDetailsData } = useStoreDetailsData();
    const isStoreSet = storeDetailsData?.storeNumber !== defaultStoreUS;

    const isAvailableAtSelectedQty = (option: FulfillmentOption) => {
      // currently itemData will only exists when mounted in mini-cart/cart
      if (itemData?.shipToHome && itemData?.itemQuantity) {
        if (itemData?.itemQuantity > option.availableQty || !option.available) {
          return false;
        }
      }

      return option.available;
    };

    const homeDeliveryAvailable = (groupFulfillmentOptions: FulfillmentOption[]) => {
      return groupFulfillmentOptions.some((option) => !!option.available);
    };

    const optionsToDisplay = getOptionsToDisplay(groupFulfillmentOptions);

    function getOptionsToDisplay(groupFulfillmentOptions: FulfillmentOption[]) {
      const arrayOfSubGroupOptions: FulfillmentOption[] = [];
      groupFulfillmentOptions.forEach((option) => {
        if (option.fulfillmentTypeId === FULFILLMENT_METHODS.STOREORDER) {
          arrayOfSubGroupOptions.push(option);
        } else if (isAvailableAtSelectedQty(option)) {
          switch (option.fulfillmentTypeId) {
            case FULFILLMENT_METHODS.SAMEDAY:
              if (isStoreSet) {
                arrayOfSubGroupOptions.push(option);
              }
              break;

            default:
              arrayOfSubGroupOptions.push(option);
              break;
          }
        }
      });
      arrayOfSubGroupOptions.splice(2);
      return arrayOfSubGroupOptions;
    }

    const renderSwitch = (option: FulfillmentOption) => {
      switch (option.fulfillmentTypeId) {
        case FULFILLMENT_METHODS.STOREORDER:
          return (
            <StorePickupOption
              option={option}
              productName={productName}
              skuId={skuId}
              handleInstockAnalytics={handleInstockAnalytics}
            />
          );

        case FULFILLMENT_METHODS.ONLINEORDER:
          return <StandardOption option={option} />;

        case FULFILLMENT_METHODS.NEXTDAY:
          return <NextDayOption option={option} />;

        case FULFILLMENT_METHODS.SAMEDAY:
          return <SameDayOption option={option} productName={productName} skuId={skuId} />;

        default:
          return <StandardOption option={option} />;
      }
    };

    if (
      groupId === FULFILLMENT_METHODS.ONLINEORDER &&
      !homeDeliveryAvailable(groupFulfillmentOptions)
    ) {
      return (
        <div className={styles.notAvailable}>
          <span data-testid="availability">
            <Label label="label_DetailsPage_body_NotAvailable" />
          </span>
        </div>
      );
    }

    return (
      <div ref={ref}>
        {optionsToDisplay.map((option) => {
          return (
            <ContentContainer
              className={cx(
                azCommonStyles['az-caption'],
                azCommonStyles['az-align-left'],
                styles.toggleListItemContainer
              )}
              key={option.fulfillmentTypeId}
              id={`subGroupItem${option.fulfillmentTypeId}`}
              data-testid="sub-group-item"
            >
              {renderSwitch(option)}
            </ContentContainer>
          );
        })}
      </div>
    );
  }
);
