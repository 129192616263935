/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type {
  Deal,
  DealConstraint,
  DealConstraintSkuDetails,
  DealData,
  SkuDetails,
  ConstraintSelectionHash,
} from '../../../interface';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { useLabels } from '@/hooks/useLabels';
import { vehicleSelectedIconSource } from '@/constants/images/vehicleSelectedIconSource';
import { NavBar } from '@/components/NavBar';
import { extractPriceFromDescription } from '../../utils/extractPriceFromDescription';
import styles from './styles.module.scss';
import { detailsLabelMap } from './helpers/detailsLabelMap';
import ConstraintDetails from './ConstraintDetails';
import BundleDetailsCard from './BundleDetailsCard';
import SelectedVehicleCard from './SelectedVehicleCard';
import AddDealToCartBtn from './AddDealToCartBtn';
import { useAddToCartErrorScroll } from './hooks/useAddToCartErrorScroll';

type Props = {
  parentSkuDetails: SkuDetails;
  skuIsVehicleSpecific: boolean | undefined;
  deal: Deal;
  dealDetails: DealData | undefined;
  constraintSelections: ConstraintSelectionHash | null;
  onCartAddClick: () => void;
  onConstraintSwapClick: (
    constraint: DealConstraint,
    constraintSkuDetails: DealConstraintSkuDetails
  ) => void;
  isSaving: boolean;
  onBackClick: () => void;
  onClose: () => void;
  loadError?: string | null;
  addToCartError?: string | null;
};

export const BundleDealDetails = ({
  parentSkuDetails,
  deal,
  dealDetails,
  constraintSelections,
  onCartAddClick,
  onConstraintSwapClick,
  isSaving,
  onBackClick,
  onClose,
  loadError,
  addToCartError,
}: Props) => {
  const { id: dealId, dealImageUrl, name, description } = deal;
  const labels = useLabels(detailsLabelMap);

  const { scrollerRef } = useAddToCartErrorScroll(addToCartError);
  const preferredVehicle = usePreferredVehicle();
  const price = extractPriceFromDescription(description);

  return (
    <div className={styles.bundleDetails}>
      <NavBar showBackButton showCloseButton handleBack={onBackClick} handleClose={onClose} />
      <div className={styles.bundleDetailsScroller} ref={scrollerRef}>
        <div className={styles.bundleDetailsHeader}>
          <BundleDetailsCard price={price} dealImageUrl={dealImageUrl} name={name} />
          {preferredVehicle?.vehicleName && (
            <SelectedVehicleCard
              label={labels.label_deal_bundle_current_vehicle_title}
              src={vehicleSelectedIconSource.desktop}
              vehicleName={preferredVehicle.vehicleName}
            />
          )}
        </div>
        <ConstraintDetails
          loadError={loadError}
          addToCartError={addToCartError}
          dealDetails={dealDetails}
          constraintSelections={constraintSelections}
          parentSkuDetails={parentSkuDetails}
          onConstraintSwapClick={onConstraintSwapClick}
          dealId={dealId}
          labels={labels}
        />
      </div>
      {!!dealDetails && !loadError && (
        <AddDealToCartBtn
          label={labels.label_deal_bundle_add_to_cart}
          onCartAddClick={onCartAddClick}
          isSaving={isSaving}
          dealId={dealId}
        />
      )}
    </div>
  );
};
