/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const configurableSkuIcon = {
  src: '/images/customizable-sku-icon.png',
  alt: 'Configurable SKU Icon',
  width: { desktop: 24, mobile: 20 },
  height: { desktop: 24, mobile: 20 },
  badge: {
    width: 28,
    height: 28,
  },
};
