/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import azURL, { relPath } from '@/config/serviceAPI';
import { getAxiosForClient } from '@/lib/axios';
import { useMutation } from '@tanstack/react-query';

const URL = azURL(relPath.findNearByStores);

export type StoreData = {
  sisterStores: SisterStore[];
  links: string[];
  skuDetails: SkuDetailsSisterStore;
};

export type SkuDetailsSisterStore = {
  productImageUrl: string;
  productPartNumber: string;
  productTotalPrice: string;
  skuDisplayName: string;
  skuNumber: string;
};

export type SisterStore = {
  distance: number;
  availablityMessage: string;
  qoh: number;
  itemAvailableInStore: boolean;
  sisterStoreDetail: SisterStoreDetail;
};

export type SisterStoreDetail = {
  zip: string;
  storeNumber: string;
  bopusEnabled: boolean;
  address2: string;
  city: string;
  address1: string;
  latitude: number;
  bopusTransactionId?: string;
  active: boolean;
  open24Hours: boolean;
  messageToDisplay: string;
  storeFullHours: StoreFullHours;
  phoneNumber: string;
  nextTransitionDateTime?: string;
  currentTimeZoneDateTime: string;
  currentDay: string;
  openedNow: boolean;
  state: string;
  storeOrderNumber?: string;
  storeOrderedDate?: string;
  longitude: number;
};

type StoreFullHours = {
  Monday: StoreDayDetails;
  Thursday: StoreDayDetails;
  Friday: StoreDayDetails;
  Sunday: StoreDayDetails;
  Wednesday: StoreDayDetails;
  Tuesday: StoreDayDetails;
  Saturday: StoreDayDetails;
};

type StoreDayDetails = {
  id: string;
  close: string;
  open: string;
};

type RequestData = {
  storeNumber: string | undefined;
  range: string;
  skuId: string;
  resultsLimit: string;
};

const postSisterStores = async (values: RequestData) => {
  const response = await getAxiosForClient().post(URL, values);
  return selector(response.data);
};

const selector = (data: StoreData): StoreData => {
  return data;
};

export const useFetchSisterStoreByStoreNumber = () => {
  return useMutation({
    mutationFn: (values: RequestData) => postSisterStores(values),
  });
};
