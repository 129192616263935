/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState } from 'react';
import { useProductDetails } from '@/features/product/api/getProductDetails';
import { BuyersGuideWrapper } from './BuyersGuideWrapper';
import dynamic from 'next/dynamic';

const LazyBuyersGuideModal = dynamic(() =>
  import('./BuyersGuideModal').then((mod) => mod.BuyersGuideModal)
);

export const BuyersGuide = () => {
  const { data: productDetails } = useProductDetails();

  const [showBuyersGuideModal, setShowBuyersGuideModal] = useState(false);

  if (
    !productDetails?.product?.vehicleSpecificFlag ||
    !productDetails.product.lineCode ||
    !productDetails.product.partNumber
  ) {
    return null;
  }

  const buyersGuideData = {
    skuId: productDetails.product.itemId,
    productLineCode: productDetails.product.lineCode,
    partNumber: productDetails.product.partNumber,
  };

  const openBuyersGuide = () => setShowBuyersGuideModal(true);

  return (
    <BuyersGuideWrapper openBuyersGuide={openBuyersGuide}>
      {showBuyersGuideModal && (
        <LazyBuyersGuideModal
          buyersGuideData={buyersGuideData}
          showModal={showBuyersGuideModal}
          setShowModal={setShowBuyersGuideModal}
        />
      )}
    </BuyersGuideWrapper>
  );
};
