/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Component, createRef } from 'react';
import cx from 'classnames';
import { Table } from '../../Table/Table';
import { TableCaption } from '../../TableCaption/TableCaption';
import { TableBody } from '../../TableBody/TableBody';
import Hidden from '../../Hidden';
import { Grid } from '../../Grid';
import azCommonStyles from '../../../theme/globals.module.scss';
import { useLabels } from '@/hooks/useLabels';
import { orangeReadLessArrow, orangeReadMoreArrow } from '../../../constants/images/readMoreLess';
import NextImage from '@/components/NextImage';
import { trackReadMoreLinkPdp } from '@/utils/analytics/track/trackReadMoreLinkPdp';
import ExpansionPanel from '../../AZCustomComponent/ExpansionPanel';
import productStyles from '../styles.module.scss';
import ExpandIcon from '../../../../public/images/footer/footer_down_arrow.svg';
import styles from './styles.module.scss';
import ProductSpecificationSkeleton from './ProductSpecificationSkeleton';
import ProductDescriptionSkeleton from './ProductDescriptionSkeleton';
import { useHeaderData } from '@/features/header/api/getHeader';
import { type ProductSkuDetails } from '@/features/product';
import { useProductDetails } from '@/features/product/api/getProductDetails';
import { useProductSkuDetails } from '@/features/product/api/getProductSkuDetails';
import { ProductModel } from '@/api/types/products-types';
import dynamic from 'next/dynamic';
import ProgressModal from '@/components/AZCustomComponent/ProgressModal';
import { useDeviceType } from '@/utils/useDeviceType';
import { ProductSpecificationRow } from '../ProductSpecificationRow/ProductSpecificationRow';
import { useMediaQuery } from '@/hooks/useMediaQuery';

export type WarrantyModalType = {
  isShown: boolean;
  showModalFrom?: string;
  warrantyType?: string;
};

const LazyWarrantyModal = dynamic(
  () => import('../ProductWarranty').then((mod) => mod.WarrantyModal),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

export type ProductDetailSpecificationProps = {
  warrantyModal?: { isShown: boolean; showModalFrom?: string; warrantyType?: string };
  setWarrantyModal?: ({ isShown }: WarrantyModalType) => void;
  closeWarrantyModal?: () => void;
  shouldOpenSpecsPanel?: boolean;
  setShouldOpenSpecsPanel?: (shouldOpenPanel: boolean) => void;
  shouldShowLoadingSkeleton?: boolean;
};

type ChildProps = {
  skuDetails: ProductSkuDetails | null | undefined;
  labels: typeof labelMap;
  isBot: boolean;
  product: ProductModel | undefined;
  hasVehicle?: boolean;
  isMobile: boolean;
} & ProductDetailSpecificationProps;

type State = {
  seeMore: boolean;
  descMore: boolean;
};

type Ref = {
  current: any;
};

const mobile = 'mobile';
const tablet = 'tablet';
const labelMap = {
  label_ProductDisplayPage_body_PartNo: 'label_ProductDisplayPage_body_PartNo',
  lblWeight: 'label_product_display_page_body_Weight',
  lblWarranty: 'label_product_display_page_body_Warranty',
  label_CategoryPage_body_Notes: 'label_CategoryPage_body_Notes',
  hyperlink_CategoryPage_body_ReadMore: 'hyperlink_CategoryPage_body_ReadMore',
  hyperlink_CategoryPage_body_ReadLess: 'hyperlink_CategoryPage_body_ReadLess',
  label_CategoryPage_body_ProductSpecifications: 'label_CategoryPage_body_ProductSpecifications',
  label_CategoryPage_body_ProductDescription: 'label_CategoryPage_body_ProductDescription',
  label_DetailsPage_body_Location: 'label_DetailsPage_body_Location',
  label_DetailsPage_body_ApplicationSmall: 'label_DetailsPage_body_ApplicationSmall',
  lblSkuNumber: 'Label_Sku_Uppercase',
  label_product_bulletins: 'label_product_bulletins',
  label_safety_data_sheets: 'label_safety_data_sheets',
};

export const productSpecificationConstant = {
  ProductSpec: 'ProductSpec',
  readmoreLinkName: 'readmoreLinkName',
};

class ProductDetailSpecification extends Component<ChildProps, State> {
  constructor(props: ChildProps) {
    super(props);
    this.specificationCall = createRef();
    this.state = {
      seeMore: !props.isBot,
      descMore: true,
    };
  }

  specificationCall: Ref;
  showWarrantyModal = () => {
    this.props.setWarrantyModal?.({
      isShown: true,
      showModalFrom: productSpecificationConstant.ProductSpec,
    });
  };
  handleWindowScroll: (device: string) => void = (device) => {
    if (device === mobile) {
      window.scroll({
        top: window.innerHeight + window.innerWidth,
        behavior: 'smooth',
      });
    } else if (device === tablet && window.orientation === 90) {
      window.scroll({
        top: window.outerWidth,
        behavior: 'smooth',
      });
    } else if (device === tablet) {
      window.scroll({
        top: window.innerHeight,
        behavior: 'smooth',
      });
    }
  };
  specReadLess: (element?: any) => any | void = (element) => {
    if (element) {
      return element.getBoundingClientRect().y;
    }
  };
  examplePart = () => {
    if (this.state.seeMore) {
      this.setState({
        seeMore: false,
      });
    } else {
      this.setState({
        seeMore: true,
      });
      const client = this.specReadLess();

      if (client !== 0) {
        const scrollable = this.specificationCall?.current ?? {};
        scrollable.scrollIntoView({
          behavior: 'smooth',
        });
      } else {
        this.handleWindowScroll(window.certona.device);
      }
    }
  };

  partNumberDisplay = () => {
    const { labels } = this.props;

    if (this.props.product?.itemWeight) {
      return (
        <ProductSpecificationRow
          key={labels.label_ProductDisplayPage_body_PartNo}
          label={labels.label_ProductDisplayPage_body_PartNo}
          value={this.props.product?.partNumber}
          labelTestId="table-cell-part-number"
          compact
        />
      );
    }
  };

  skuNumberDisplay = () => {
    const skuId = this.props.product?.itemId;
    const { labels } = this.props;

    if (skuId) {
      return (
        <ProductSpecificationRow
          key={labels.lblSkuNumber}
          label={`${labels.lblSkuNumber} #`}
          value={skuId}
          labelTestId="table-cell-part-number"
          compact
        />
      );
    }
  };

  weightDisplay = () => {
    const weight = this.props.product?.itemWeight;
    const { labels } = this.props;

    if (weight) {
      return (
        <ProductSpecificationRow
          key={labels.lblWeight}
          label={labels.lblWeight}
          value={weight}
          compact
        />
      );
    }
  };

  locationDisplay = () => {
    const pdpDetails = this.props.product;
    const { labels } = this.props;

    if (pdpDetails?.location) {
      return (
        <ProductSpecificationRow
          key={labels.label_DetailsPage_body_Location}
          label={labels.label_DetailsPage_body_Location}
          value={pdpDetails.location}
          labelTestId="product-details-location"
          valueTestId="product-details-location-value"
          compact
        />
      );
    }
  };

  applicationDisplay = () => {
    const productDetails = this.props.product;
    const { labels } = this.props; // shortcircuit if for some reason cannot retreieve pdpInfo
    if (productDetails?.applicationNotes) {
      const applicationCount = productDetails.applicationNotes.length;
      return (
        <ProductSpecificationRow
          key={labels.label_DetailsPage_body_ApplicationSmall}
          label={labels.label_DetailsPage_body_ApplicationSmall}
          value={productDetails.applicationNotes.map((applicationItem, i) => (
            <p key={`${labels.label_DetailsPage_body_ApplicationSmall}${i}`}>
              {`${applicationCount > 1 ? i + 1 + '. - ' + applicationItem : applicationItem}`}
            </p>
          ))}
          labelTestId="details-application"
          valueTestId="details-application-value"
          compact
        />
      );
    }

    return null;
  };

  renderProdSpec = (more: boolean, specsCount: number) => {
    const productSpecification = this.props.product?.productAttributes ?? [];
    const techNote = this.props.product?.techNote;
    const quickNotes = this.props.product?.quickNote;
    const finalNotes = [quickNotes, techNote].filter(Boolean).join(', ');
    const { labels } = this.props;

    const warrantyDisplay =
      this.props.skuDetails?.warranty ?? '' ? (
        <ProductSpecificationRow
          key={labels.lblWarranty}
          label={labels.lblWarranty}
          value={
            <button
              className={cx(azCommonStyles['az-body-1-heavy'], styles.warranty)}
              onClick={this.showWarrantyModal}
            >
              {this.props.skuDetails?.warranty ?? ''}
            </button>
          }
          labelTestId="table-cell-warranty"
          compact
        />
      ) : null;

    const techNotesDisplay =
      finalNotes && this.props.hasVehicle ? (
        <ProductSpecificationRow
          ref={this.specReadLess}
          key={labels.label_CategoryPage_body_Notes}
          label={labels.label_CategoryPage_body_Notes}
          value={finalNotes}
          valueClassName={cx(azCommonStyles['az-body-2-regular'], styles.notesDescription)}
          id="tech-notes-spec"
          labelTestId="details-notes"
          valueTestId="details-notes-value"
        />
      ) : null;

    const productSpecificationDisplay = productSpecification.map((item, index) => {
      const productSpecList = (
        <ProductSpecificationRow
          key={`${item.name}_${item.value}_less_odd`}
          label={item.name ?? ''}
          value={item.value}
        />
      );

      if (more && index > 5) {
        return null;
      }

      return productSpecList;
    });
    let fullProductSpecificationList = productSpecificationDisplay;
    const productServiceBulletin = this.props.product?.itemPsbUrls ?? [];
    const msds = this.props.product?.itemMsdsUrl ?? null;

    if (productServiceBulletin.length > 0 || msds) {
      const psbRows = (
        <ProductSpecificationRow
          key="product_bulletins_less_odd"
          label={labels.label_product_bulletins}
          valueClassName={styles.bulletinsValue}
          value={
            <>
              {productServiceBulletin.length > 0 &&
                productServiceBulletin.map((item) => (
                  <div key={`${item.title}`}>
                    <a
                      className={`${styles.productServiceBulletinLinks} ${azCommonStyles['az-body-1-heavy']}`}
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </a>
                  </div>
                ))}
              {msds && (
                <div>
                  <a
                    className={`${styles.productServiceBulletinLinks} ${azCommonStyles['az-body-1-heavy']}`}
                    href={msds}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {labels.label_safety_data_sheets}
                  </a>
                </div>
              )}
            </>
          }
        />
      );
      fullProductSpecificationList = productSpecificationDisplay.concat(psbRows);
    }

    return (
      <div>
        <div className={styles.specsTableContainer}>
          <Table className={styles.specsTable}>
            {!this.props.isMobile && (
              <TableCaption captionside="top" dataTestId="product-specification-heading">
                <h2
                  className={cx(
                    azCommonStyles['az-margin-bottom-xxs'],
                    azCommonStyles['az-title-5-medium'],
                    styles.productSpecificationTitle
                  )}
                >
                  {labels.label_CategoryPage_body_ProductSpecifications}
                </h2>
              </TableCaption>
            )}
            <TableBody className={styles.productSpecificationBody}>
              {warrantyDisplay}
              {this.partNumberDisplay()}
              {this.skuNumberDisplay()}
              {this.weightDisplay()}
              {this.locationDisplay()}
              {this.applicationDisplay()}
              {techNotesDisplay}
              {fullProductSpecificationList}
            </TableBody>
          </Table>
        </div>
        <div className={styles.productDescriptionButtons}>
          {specsCount > 6 && (
            <>
              {this.renderProdDescReadMore(labels)}
              {this.renderProdDescReadLess(labels)}
            </>
          )}
        </div>
      </div>
    );
  };

  renderProdDesc = () => {
    const desc = this.props.product?.marketingCopy ?? '';
    const featureAttributes = this.props.product?.featuresAndBenefits ?? [];
    let featureAttributesArr = null;

    if (featureAttributes?.length > 0) {
      featureAttributesArr = featureAttributes.map((featureAttribute: string) => (
        <li
          className={cx(azCommonStyles['az-body-1-regular'], styles.featurelist)}
          key={`${featureAttribute}_feature_attribute`}
        >
          <span className={cx(azCommonStyles['az-padding-right-xxxs'], styles.checkmark)}>
            <NextImage src="/images/checkmark-copy-6.svg" alt="checkmark" width={13} height={10} />
          </span>
          <div>{featureAttribute}</div>
        </li>
      ));
    }

    return (
      <div className={cx(azCommonStyles['az-body-1-regular'], styles.description)}>
        <div
          dangerouslySetInnerHTML={(() => ({
            __html: desc,
          }))()}
        />
        <ul className={styles.feature}>{featureAttributesArr}</ul>
      </div>
    );
  };
  renderProdDescReadMore = (labels: typeof labelMap) => {
    let readMoreLbl: React.ReactNode;

    if (this.state.seeMore) {
      readMoreLbl = (
        <button
          className={cx(azCommonStyles['az-button-text'], styles.readMoreButton)}
          onClick={this.examplePart}
          data-testid="specification-expand"
        >
          {labels.hyperlink_CategoryPage_body_ReadMore}
          <Hidden only={['sm', 'xl', 'lg']}>
            <div className={styles.seeDetailArrowReadMore}>
              <NextImage
                src={orangeReadMoreArrow.src}
                alt={orangeReadMoreArrow.alt}
                className={styles.readMoreArrow}
                width={8}
                height={12}
              />
            </div>
          </Hidden>
        </button>
      );
    }

    return <span className={styles.readMoreButtonContainer}>{readMoreLbl}</span>;
  };
  renderProdDescReadLess = (labels: typeof labelMap) => {
    let readLessLbl: React.ReactNode;

    if (!this.state.seeMore) {
      trackReadMoreLinkPdp('productSpecifications');
      readLessLbl = (
        <button
          className={cx(azCommonStyles['az-button-text'], styles.readMoreButton)}
          onClick={this.examplePart}
          data-testid="specification-expand"
        >
          <Hidden only={['sm', 'xl', 'lg']}>
            <div>
              <NextImage
                src={orangeReadLessArrow.src}
                alt={orangeReadLessArrow.alt}
                className={styles.arrowReadless}
                width={8}
                height={12}
              />
            </div>
          </Hidden>
          {labels.hyperlink_CategoryPage_body_ReadLess}
        </button>
      );
    }

    return <span className={styles.readMoreButtonContainer}>{readLessLbl}</span>;
  };

  renderWarrantyModal = () => {
    const pdpDetails =
      this.props.product?.lineCode &&
      this.props.product?.partNumber &&
      this.props.product?.itemWeight;

    const { warrantyModal, skuDetails } = this.props;
    let pdpDetailsComp = null;
    const warrantyType = skuDetails?.warrantyType ?? this.props.product?.warrantyType ?? '';
    const closeWarrantyModal = () => {
      this.props.setWarrantyModal?.({ isShown: false });
    };

    if (pdpDetails && warrantyModal?.isShown && warrantyType) {
      pdpDetailsComp = (
        <LazyWarrantyModal
          showWarranty={
            warrantyModal.isShown &&
            warrantyModal.showModalFrom === productSpecificationConstant.ProductSpec
          }
          closeWarrantyModal={closeWarrantyModal}
          warrantyType={warrantyType}
        />
      );
    }

    return pdpDetailsComp;
  };

  descriptionMoreOrLess = (labels: typeof labelMap) => {
    return (
      <>
        <h2
          className={cx(azCommonStyles['az-title-5-medium'], styles.productDescriptionTitle)}
          data-testid="product-description-heading"
        >
          {labels.label_CategoryPage_body_ProductDescription}
        </h2>

        <div className={styles.productDescriptionPart}>
          <div>{this.renderProdDesc()}</div>
        </div>
      </>
    );
  };

  descriptionMoreOrLessTablet = (labels: typeof labelMap, prodDesc: any) => {
    const readMoreButton = (
      <button
        className={cx(azCommonStyles['az-button-text'], styles.readMoreButton)}
        onClick={() => this.setState({ descMore: false })}
        data-testid="description-expand"
      >
        {labels.hyperlink_CategoryPage_body_ReadMore}
        <div className={styles.seeDetailArrowReadMore}>
          <NextImage
            src={orangeReadMoreArrow.src}
            alt={orangeReadMoreArrow.alt}
            className={styles.readMoreArrow}
            width={8}
            height={12}
          />
        </div>
      </button>
    );
    const readLessButton = (
      <button
        className={cx(azCommonStyles['az-button-text'], styles.readMoreButton)}
        onClick={() => this.setState({ descMore: true })}
        data-testid="description-expand"
      >
        <div>
          <NextImage
            src={orangeReadLessArrow.src}
            alt={orangeReadLessArrow.alt}
            className={styles.arrowReadless}
            width={8}
            height={12}
          />
        </div>
        {labels.hyperlink_CategoryPage_body_ReadLess}
      </button>
    );
    return (
      <>
        <h2
          className={cx(azCommonStyles['az-title-5-medium'], styles.productDescriptionTitle)}
          data-testid="product-description-heading-md"
        >
          {labels.label_CategoryPage_body_ProductDescription}
        </h2>
        {
          <div
            className={
              this.state.descMore
                ? cx(azCommonStyles['az-body-1-regular'], styles.mobileDesc)
                : undefined
            }
          >
            {this.renderProdDesc()}
          </div>
        }
        <span className={styles.readMoreSmallProdDesc}>
          {prodDesc && this.state.descMore ? readMoreButton : readLessButton}
        </span>
      </>
    );
  };

  descriptionMoreOrLessMobile = (labels: typeof labelMap) => {
    return (
      <ExpansionPanel
        title={labels.label_CategoryPage_body_ProductDescription}
        summary={
          <h2
            className={cx(azCommonStyles['az-title-5-medium'], styles.productDescriptionTitle)}
            data-testid="product-description-heading-sm"
          >
            {labels.label_CategoryPage_body_ProductDescription}
          </h2>
        }
        content={<div>{this.renderProdDesc()}</div>}
        classes={{
          summary: productStyles.expansionPanelSummary,
          detailsRoot: productStyles.expansionPanelDetails,
        }}
        defaultExpandedProp={this.props.isBot}
        expandIcon={<ExpandIcon />}
      />
    );
  };

  renderSpecifications = () => {
    const { labels } = this.props;
    const prodDesc = this.props.product?.marketingCopy ?? '';
    return (
      <>
        <Hidden only={['sm', 'md']} implementation="js">
          {this.descriptionMoreOrLess(labels)}
        </Hidden>
        <Hidden only={['xl', 'lg', 'md']} implementation="js">
          {this.descriptionMoreOrLessMobile(labels)}
        </Hidden>
        <Hidden only={['xl', 'lg', 'sm']} implementation="js">
          {this.descriptionMoreOrLessTablet(labels, prodDesc)}
        </Hidden>
      </>
    );
  };

  render() {
    const { isBot, labels } = this.props;
    const productSpecification = this.props.product?.productAttributes ?? [];
    return (
      <div>
        {this.renderWarrantyModal()}
        <div className={styles.productSpecificationWrapper} ref={this.specificationCall}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              md={12}
              lg={7}
              xl={7}
              className={this.props.shouldShowLoadingSkeleton ? styles.contentDivider : ''}
            >
              {this.props.shouldShowLoadingSkeleton ? (
                <ProductSpecificationSkeleton />
              ) : (
                <div
                  className={styles.productSpecificationPart}
                  id="product-specifications-container"
                >
                  <Hidden only={['xl', 'lg', 'md']} implementation="js">
                    <ExpansionPanel
                      title={labels.label_CategoryPage_body_ProductSpecifications}
                      summary={
                        <h2
                          className={cx(
                            azCommonStyles['az-title-5-medium'],
                            styles.productSpecificationTitle
                          )}
                          data-testid="product-specification-heading-sm"
                        >
                          {labels.label_CategoryPage_body_ProductSpecifications}
                        </h2>
                      }
                      content={this.renderProdSpec(this.state.seeMore, productSpecification.length)}
                      classes={{
                        summary: productStyles.expansionPanelSummary,
                        detailsRoot: productStyles.expansionPanelDetails,
                      }}
                      defaultExpandedProp={isBot}
                      overrideExpanded={this.props.shouldOpenSpecsPanel || isBot}
                      onChange={this.props.setShouldOpenSpecsPanel}
                      expandIcon={<ExpandIcon />}
                    />
                  </Hidden>
                  <Hidden only={['sm']} implementation="js">
                    {this.renderProdSpec(this.state.seeMore, productSpecification.length)}
                  </Hidden>
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={12} lg={5} xl={5}>
              {this.props.shouldShowLoadingSkeleton ? (
                <ProductDescriptionSkeleton />
              ) : (
                this.renderSpecifications()
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const WrappedProductDetailSpecification = (props: ProductDetailSpecificationProps) => {
  const { data: productDetails, status: productsStatus } = useProductDetails();
  const skuDetailsResult = useProductSkuDetails({
    skuIds:
      productsStatus === 'success' && productDetails.product?.itemId
        ? [productDetails.product.itemId]
        : null,
  });
  const skuDetails = skuDetailsResult.isSuccess ? skuDetailsResult.data[0] : null;
  const labels = useLabels(labelMap);
  const { data: headerData } = useHeaderData();
  const hasVehicle = headerData?.vehicleMap?.vehicleId !== '';
  const isBot = useDeviceType() === 'bot';
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <ProductDetailSpecification
      {...props}
      isBot={isBot}
      labels={labels}
      hasVehicle={hasVehicle}
      product={productDetails?.product}
      skuDetails={skuDetails}
      isMobile={isMobile}
    />
  );
};

export default WrappedProductDetailSpecification;
