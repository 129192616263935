/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import NextImage from '@/components/NextImage';
import { configurableSkuIcon } from '@/constants/images/configurableSkuIcon';
import { useLabels } from '@/hooks/useLabels';
import styles from './styles.module.scss';
import { useMediaQuery } from '@/hooks/useMediaQuery';

const labelMap = {
  customize: 'label.configurableSku.customizeYourFloorMat',
};

const ConfigurableSkuBadge = () => {
  const labels = useLabels(labelMap);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const width = isMobile ? configurableSkuIcon.width.mobile : configurableSkuIcon.width.desktop;
  const height = isMobile ? configurableSkuIcon.height.mobile : configurableSkuIcon.height.desktop;
  return (
    <div className={styles.configurableSkuBadge}>
      <NextImage
        src={configurableSkuIcon.src}
        alt={configurableSkuIcon.alt}
        width={width}
        height={height}
      />
      <span className={styles.text}>{labels.customize}</span>
    </div>
  );
};

export default ConfigurableSkuBadge;
