/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import cx from 'classnames';
import azCommonStyles from '../../theme/globals.module.scss';
import styles from './styles.module.scss';
type Props = {
  className?: string;
  'data-testid'?: string;
  label: React.ReactNode | string;
  value: string;
};

const ProductMetaLabel = ({ className, 'data-testid': dataTestId, label, value }: Props) => (
  <div
    className={cx(
      azCommonStyles['az-body-2-regular'],
      className ? `${styles.labelContainer} ${className}` : styles.labelContainer
    )}
    data-testid={dataTestId}
  >
    <span className={`${styles.label}`}>{label}</span>
    <span className={`${styles.value}`}>{` ${value}`}</span>
  </div>
);

export { ProductMetaLabel };
