/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import cx from 'classnames';
import styles from './TableCaption.module.scss';

export function TableCaption({ dataTestId, captionside, children }: Props) {
  return (
    <caption
      className={cx(styles.root, {
        [styles.topCaption]: captionside === 'top',
      })}
      data-testid={dataTestId}
    >
      {children}{' '}
    </caption>
  );
}

type Props = {
  children?: React.ReactNode;
  captionside?: string;
  dataTestId?: string;
};
