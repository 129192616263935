/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import NextImage from '@/components/NextImage';
import { Skeleton, View } from '@az/starc-ui';
import cx from 'classnames';
import styles from './styles.module.scss';

const HorizontalPartCard = () => (
  <View className={styles.partCard} borderRadius="small">
    <View direction="row" align="center">
      <NextImage src="/images/certona-skeleton-square.svg" width={65} height={65} />
    </View>
    <View className={styles.textSkeletonSection}>
      <View>
        <Skeleton width="100%" height="20px" className={styles.textSkeleton} />
      </View>
      <View className={styles.innerRow}>
        <View className={styles.descriptionColumn}>
          <Skeleton width="100%" height="18px" className={styles.textSkeleton} />
          <Skeleton width="70%" height="18px" className={styles.textSkeleton} />
        </View>
        <View className={styles.buttonColumn}>
          <Skeleton
            width="77px"
            height="33px"
            borderRadius="small"
            className={styles.buttonSkeleton}
            noAnimation
          />
        </View>
      </View>
    </View>
  </View>
);

type HorizontalScrolledPartCardSkeletonProps = {
  className?: string;
};

export const HorizontalScrolledPartCardSkeleton = ({
  className,
}: HorizontalScrolledPartCardSkeletonProps) => {
  return (
    <View direction="row" className={cx(styles.skeletonContainer, className)} align="center">
      <View.Item>
        <View className={styles.partsContainer}>
          {Array.from({ length: 10 }).map((_, i) => (
            <View.Item key={i}>
              <HorizontalPartCard />
            </View.Item>
          ))}
        </View>
      </View.Item>
      <View.Item className={styles.skeletonArrows}>
        <NextImage
          className={styles.chevronImg}
          src="/images/arrow_right_initial.svg"
          alt="scroll carousel right"
          width={56}
          height={62}
        />
      </View.Item>
    </View>
  );
};
