/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { clickTrack } from '@/utils/analytics/clickTrack';

export const trackDealBundleDeliveryMethod = (
  dealId: string,
  dealName: string,
  dealsFulfillmentMethods: string[]
) => {
  const { pageName = '', pageType = '', siteSection = '' } = window.utag_data ?? {};
  clickTrack({
    eventType: 'dealBundleDeliveryMethod',
    dealId,
    dealName,
    pageName,
    pageType,
    siteSection,
    customLinkName: `${pageType}:Deal Delivery Method`,
    dealDeliveryMethodAvailabilityList: [dealsFulfillmentMethods.join(':')],
  });
};
