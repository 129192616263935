/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useMonetateDecisionFlag } from '@/features/kibo';
import {
  AffinityRelatedProductsBlockV2,
  useAddAffinityItemsToCartMutation,
  useAffinityRelatedProducts,
  AffinityRelatedProductsBlock,
} from '@/features/pdpAffinity';
import { useProductDetails } from '@/features/product/api/getProductDetails';
import { useMediaQuery } from '@/hooks/useMediaQuery';

export type CMSCompleteTheJobProps = {
  shippingType: string;
  fulfillmentId: number;
  refetchSkuDetails: () => void;
  itemsToDisplay?: number;
};

export const CMSCompleteTheJob = ({
  shippingType,
  fulfillmentId,
  refetchSkuDetails,
  itemsToDisplay,
}: CMSCompleteTheJobProps) => {
  const { data: affinityRelatedProducts } = useAffinityRelatedProducts({ itemsToDisplay });
  const { data: productDetailsData, isSuccess: isProductDetailsSuccess } = useProductDetails();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isMobileAndTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const shouldShowDesktopAffinityV2 = useMonetateDecisionFlag('desktopAffinityV2Enabled');
  const shouldShowMobileAndTabletAffinityV2 = useMonetateDecisionFlag(
    'mobileAndTabletAffinityV2Enabled'
  );
  const productDetails = productDetailsData?.product;
  isProductDetailsSuccess && productDetails?.itemId ? [productDetails.itemId] : null;

  const addAffinityItemsToCartMutation = useAddAffinityItemsToCartMutation();

  if (!affinityRelatedProducts?.length) {
    return null;
  }

  return (isMobileAndTablet && shouldShowMobileAndTabletAffinityV2) ||
    (isDesktop && shouldShowDesktopAffinityV2) ? (
    <AffinityRelatedProductsBlockV2
      shippingType={shippingType}
      fulfillmentId={fulfillmentId}
      refetchSkuDetails={refetchSkuDetails}
      itemsToDisplay={itemsToDisplay}
    />
  ) : (
    <AffinityRelatedProductsBlock
      affinityRelatedProducts={affinityRelatedProducts}
      shippingType={shippingType}
      fulfillmentId={fulfillmentId}
      addAffinityItemsToCartMutation={addAffinityItemsToCartMutation}
    />
  );
};
