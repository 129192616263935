/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect } from 'react';
import { useLabels } from '@/hooks/useLabels';
import { BundleDealOption } from '../BundleDealOption';
import { ParentSkuCard } from '../ParentSkuCard';
import { NavBar } from '@/components/NavBar';
import type { SkuDetails, DealHash, Deal } from '../../../interface';
import styles from './styles.module.scss';
type Props = {
  parentSkuDetails: SkuDetails;
  skuPrice?: string | number | string[] | number[];
  skuRetailPrice: string | number | string[] | number[];
  skuQuantity: number | string;
  deals: DealHash;
  trackOnDealsAvailableLink: (dealDrawerInfo: {
    dealIdListString: string;
    dealNameListString: string;
  }) => void;
  onSelect: (deal: Deal) => void;
  onBackClick: () => void;
  onClose: () => void;
};
const labelMap = {
  label_deal_bundle_parent_sku_section_title: 'label_deal_bundle_parent_sku_section_title',
  label_deal_bundle_list_title: 'label_deal_bundle_list_title',
  label_deal_bundle_list_description: 'label_deal_bundle_list_description',
};

export const BundleDealOptionList = ({
  parentSkuDetails,
  skuPrice,
  skuRetailPrice,
  skuQuantity,
  deals,
  trackOnDealsAvailableLink,
  onSelect,
  onBackClick,
  onClose,
}: Props) => {
  const labels = useLabels(labelMap);
  useEffect(() => {
    if (deals) {
      const dealIdList: string[] = [];
      const dealNameList: Array<string | null> = [];

      Object.values(deals).map((deal: Deal) => {
        dealIdList.push(deal.id);
        dealNameList.push(deal.name);
      });
      const dealIdListString = dealIdList.join(':');
      const dealNameListString = dealNameList.join(':');
      const dealDrawerInfo = {
        dealIdListString,
        dealNameListString,
      };

      trackOnDealsAvailableLink(dealDrawerInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deals]);

  return (
    <div className={styles.bundleDealOptionList}>
      <NavBar
        showBackButton={false}
        showCloseButton
        handleBack={onBackClick}
        handleClose={onClose}
      />
      <div className={styles.bundleDealListScroller}>
        <div className={styles.parentSkuSection} data-testid={'parent-sku-details'}>
          <h2 className={styles.parentSkuSectionTitle}>
            {labels.label_deal_bundle_parent_sku_section_title}
          </h2>
          <ParentSkuCard
            skuDetails={parentSkuDetails}
            skuQuantity={skuQuantity}
            skuPrice={skuPrice}
            skuRetailPrice={skuRetailPrice}
          />
        </div>
        <div className={styles.bundleDealOptionListMainBody}>
          <h2 className={styles.bundleDealOptionListTitle}>
            {labels.label_deal_bundle_list_title}
          </h2>
          <div className={styles.bundleDealOptionListDescription}>
            {labels.label_deal_bundle_list_description}
          </div>
          <div className={styles.separator} />
          <div className={styles.dealsContainer}>
            {Object.entries(deals).map(([dealKey, deal]) => {
              return <BundleDealOption key={dealKey} deal={deal} onSelect={onSelect} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
