/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { StoreDetails } from '@/types';
import dayjs from 'dayjs';
import { useLabels } from '@/hooks/useLabels';

type Props = {
  storeDetails: StoreDetails | undefined;
};

const labelMap = {
  lable_shipping_option_tomorrow: 'label_shipping_Tomorrow',
  label_shipping_option_today: 'label_checkout_bopus_today',
} as const;

export default function StorePickupTime({ storeDetails }: Props) {
  const labels = useLabels(labelMap);
  const today = new Date();
  const getWeekDay = today.toLocaleString('default', { weekday: 'long' }) as
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday'
    | 'Sunday';
  const storeCloseHour = storeDetails?.storeFullHours[getWeekDay];

  const yextClosingTimeFormat = '0:00 pm';

  const is24HourStore =
    storeCloseHour?.close === 'Midnight' || storeCloseHour?.close === yextClosingTimeFormat; // 24 hour store for ATG or Yext

  const twoHourBefore = new Date().getTime() - 3600000 * 2;

  const now = dayjs();

  const twoHoursFromNow = dayjs().add(2, 'hour');

  const midNightIsInTwoHours = !twoHoursFromNow.isSame(now, 'day');

  const shouldShowTodayFor24HourStore = is24HourStore && !midNightIsInTwoHours;

  const twoHourBeforeTime = new Date(twoHourBefore)?.toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
  const getHourAMorPM = today.toLocaleString('default', { hour12: true });
  const closeTime = dayjs(
    new Date(
      `${twoHourBeforeTime} ${
        storeCloseHour?.close === 'Midnight' ? '11:59 PM' : storeCloseHour?.close || ''
      }`
    )
  ).diff(today, 'm');

  return shouldShowTodayFor24HourStore ? (
    <p>
      <strong>{labels.label_shipping_option_today}</strong>
    </p>
  ) : getHourAMorPM?.includes('PM') ? (
    String(closeTime).includes('-') ? (
      <p>{labels.lable_shipping_option_tomorrow}</p>
    ) : closeTime <= 120 ? (
      <p>{labels.lable_shipping_option_tomorrow}</p>
    ) : (
      <p>{labels.label_shipping_option_today}</p>
    )
  ) : (
    <p>{labels.label_shipping_option_today}</p>
  );
}
