/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import styles from './flyout.module.scss';
import PriceComponent from '@/components/AZCustomComponent/Price';
import Image from '@/components/NextImage';
import { getCurrencySymbol, getStaticFormattedPrice } from '@/utils/priceHelpers';
import ClearanceBadgeWrapper from '@/components/AZCustomComponent/ClearanceBadge';
import QuantityExceedInfo from './QuantityExceedInfo';
import { useCallback, useEffect, useState } from 'react';
import CartCommonCounterPart from '@/components/Cart/CommonCounterPart';
import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import { useFeatureFlag } from '@/features/globalConfig';
import { getAllAvailableQuantitiesForSku } from '@/utils/getFulfillmentOption';
import { Label } from '@/features/i18n';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { formatPrice } from '@/utils/validator/formatPrice';
import { countryCodes } from '@/constants/locale';
import { usePriceHiding } from '@/hooks/usePriceHiding';

type Props = {
  filterproduct?: any;
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  shippingType?: string;
  miniumCount: number;
  sisterStoreAvailMaxQty: number;
  labelMap: Record<string, string>;
  dealId?: string;
};

function SisterStoreFlyoutBody({
  filterproduct,
  count,
  setCount,
  shippingType,
  miniumCount,
  sisterStoreAvailMaxQty,
  labelMap,
  dealId,
}: Props) {
  const labels = useLabels(labelMap);
  const locale = useLocale();
  const isBR = locale === countryCodes.ptBr;
  const currencySymbol = getCurrencySymbol(locale);
  const staticPrice = getStaticFormattedPrice(
    filterproduct?.skuPricingAndAvailability?.configurableSku,
    filterproduct?.skuPricingAndAvailability
  );
  const counterData = {
    itemQuantity: count,
    orderType: shippingType,
    eligibleForNextDay: filterproduct?.aZBadgesFlagVO?.eligibleForNextDay,
    productInfo: {
      skuId: filterproduct?.skuNumber,
    },
    unitAmount: Number(staticPrice),
    lineItemPriceInfo: {
      unitPrice: Number(staticPrice),
    },
  };

  const isCorePriceAvaible = Boolean(filterproduct?.skuPricingAndAvailability?.corePrice);
  const corePricing = filterproduct?.skuPricingAndAvailability?.corePrice * count;
  const retailPrice = formatPrice(filterproduct?.price * count);
  const productTotalPrice = `${filterproduct?.price}`;
  const otherPagesDiscountedPrice = filterproduct?.skuPricingAndAvailability?.discountedPrice;
  const cartDiscountedPrice = filterproduct?.skuPricingAndAvailability?.retailPrice;
  const isProductDiscountedCartEnabled = filterproduct?.skuPricingAndAvailability?.discounted;
  const strikeThroughPricingEnabled = useFeatureFlag('SHOW_STRIKE_THROUGH_PRICING') === 'true';
  const hidePricing = usePriceHiding();

  const discountedPrice =
    strikeThroughPricingEnabled && !otherPagesDiscountedPrice
      ? cartDiscountedPrice
      : otherPagesDiscountedPrice;

  let showStrikeThroughPricing = false;
  if (strikeThroughPricingEnabled) {
    if (
      (otherPagesDiscountedPrice || (cartDiscountedPrice && isProductDiscountedCartEnabled)) &&
      !dealId
    ) {
      showStrikeThroughPricing = true;
    }
  }

  const totalPriceParts = showStrikeThroughPricing
    ? formatPrice(discountedPrice * count)
        ?.toString()
        ?.split('.')
    : retailPrice?.toString()?.split('.');

  const [quantityCountIncreased, setQuantityCountIncreased] = useState(false);

  const handleQuantityChange = useCallback(
    (quantity: number) => {
      const newQuantity = quantity ? Math.abs(quantity) : 1;
      setCount(newQuantity);
      newQuantity > 1 ? setQuantityCountIncreased(true) : setQuantityCountIncreased(false);
    },
    [setCount]
  );

  const sisterStoreAvailable =
    filterproduct?.fulfillmentOptions?.[0]?.sisterStoreAvailable ?? false;
  const skuAvailableQuantities = getAllAvailableQuantitiesForSku(filterproduct?.fulfillmentOptions);
  const availabilityInfoVO = {
    ...skuAvailableQuantities,
    bopusQuantity: sisterStoreAvailMaxQty,
    sthQuantity: sisterStoreAvailMaxQty,
  };
  const productImage = filterproduct?.productImageUrl ?? filterproduct?.imageUrl;
  const productTitle = filterproduct?.name ?? filterproduct?.description;
  const productSKU = filterproduct?.skuNumber ?? filterproduct?.skuPricingAndAvailability?.skuId;
  const productPartNumber = filterproduct?.partNumber;

  useEffect(() => {
    if (count > 1) {
      handleQuantityChange(count);
    }
  }, [count, handleQuantityChange]);

  const priceContent = hidePricing ? null : (
    <div
      className={
        showStrikeThroughPricing
          ? styles.strikeThroughPricingContainer
          : isCorePriceAvaible
          ? styles.corePriceAvailablePriceContainer
          : styles.priceContainer
      }
    >
      <div>
        {showStrikeThroughPricing ? (
          <div
            className={
              quantityCountIncreased
                ? styles.extraMarginStrikeThroughPriceContainer
                : styles.strikeThroughPriceContainer
            }
          >
            <PriceComponent
              priceType="strikethrough"
              tabIndex={0}
              dealPrice={totalPriceParts ?? ['0', '00']}
              className={styles.price}
              enableStrikeThru={true}
            />{' '}
            <s
              className={styles.strikeThroughPrice}
              tabIndex={0}
              aria-label={`crossed-out regular price was ${currencySymbol}${retailPrice}`}
            >
              {currencySymbol}
              {retailPrice}
            </s>
          </div>
        ) : isCorePriceAvaible ? (
          <div className={styles.corePriceAvailable}>
            <PriceComponent
              priceType="strikethrough"
              tabIndex={0}
              dealPrice={totalPriceParts ?? ['0', '00']}
              className={styles.corePriceAvailable}
              enableStrikeThru={true}
            />
            <div className="core-price">
              <div className={styles.corePriceLabel}>
                {`+ ${currencySymbol}${formatPrice(corePricing)} ${
                  labels.lblRefundableCoreDepositFlyout
                }`}
              </div>
            </div>
          </div>
        ) : (
          <PriceComponent
            tabIndex={0}
            priceType="strikethrough"
            dealPrice={totalPriceParts ?? ['0', '00']}
            enableStrikeThru={showStrikeThroughPricing}
          />
        )}

        {filterproduct?.skuPricingAndAvailability?.skuAvailabilityInfo?.clearanceItem ? (
          <div className={styles.flyOutClearenceWrapper}>
            <ClearanceBadgeWrapper />
          </div>
        ) : null}
      </div>
      {!isBR && (
        <div className={styles.counterComp}>
          <CartCommonCounterPart
            data={counterData}
            hasDealId={!!dealId}
            productTotalPrice={productTotalPrice}
            corePrice={filterproduct?.skuPricingAndAvailability?.corePrice}
            availabilityCheck={availabilityInfoVO}
            handleQuantityChange={handleQuantityChange}
            sisterStoreAvailable={sisterStoreAvailable}
            isMini
            disabled={false}
            disableRemoveButton
            fulfillmentId={FULFILLMENT_METHODS.STOREORDER}
            isNewMessagingShown={false}
            isSisterStoreFlyout
            filterproduct={filterproduct}
          />
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.flyoutBodyMain}>
      <div
        data-testid="sister-store-flyout-product-container"
        className={
          strikeThroughPricingEnabled ? styles.strikeThroughContainer : styles.productContainer
        }
      >
        <div>
          <Image src={productImage} alt="filter" height={64} width={64} />
        </div>
        <div className={styles.productName}>
          {productTitle}
          <div className={styles.subHeading}>
            <Label label="label_DetailsPage_body_PartNo" /> #{productPartNumber} |{' '}
            <Label label="Label_Sku_Uppercase" /> #{productSKU}
          </div>
        </div>
      </div>
      {priceContent}
      {miniumCount < count ? (
        <div data-testid="sister-store-quantity-exceed-notice" className={styles.quantityExceed}>
          <QuantityExceedInfo />
        </div>
      ) : null}
    </div>
  );
}

export default SisterStoreFlyoutBody;
