/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState, useCallback, useEffect, useContext, createRef } from 'react';
import cx from 'classnames';
import azCommonStyles from '../../theme/globals.module.scss';
import CustomRadioInput from '../AZCustomComponent/FormGroup/RadioButton';
import { FULFILLMENT_METHODS } from '../../constants/fulfillmentConstants';
import { cartConstants } from '../../constants/cart';
import styles from './styles.module.scss';
import { SubGroupOptions } from './SubGroupOptions';
import { useFeatureFlag } from '@/features/globalConfig';
import type { AvailabilityInfoVOFromAPI } from '@/types/availability';
import type { FulfillmentGroup } from '@/types/reduxStore/skuDetails';
import { SisterStoreContext } from '@/features/fulfillment/context/SisterStoreContext';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';
import {
  getAllAvailableQuantitiesForSku,
  getFulfillmentIDforCartItem,
} from '@/utils/getFulfillmentOption';
import Skeleton from '@/components/Skeleton/Skeleton';
import { useSelector } from 'react-redux';
import { ReduxState } from '@/types';

type Props = {
  itemData?: any;
  fulfillmentOptionsGroupList: Array<FulfillmentGroup>;
  changeShippingType: (
    shippingType: 'STOREORDER' | 'ONLINEORDER',
    groupId: typeof FULFILLMENT_METHODS.STOREORDER | typeof FULFILLMENT_METHODS.ONLINEORDER,
    maxAvailableQty: number,
    sisterStoreAvailable: boolean,
    a?: number
  ) => void;
  shippingType: typeof cartConstants.STOREORDER | typeof cartConstants.ONLINEORDER;
  skuId: string | undefined;
  productName: string;
  sisterStoreAvailable?: boolean;
  handleInstockAnalytics?: () => any;
  availabilityInfoVO?: AvailabilityInfoVOFromAPI;
  isDealDrawer?: boolean;
  itemPresentInCart?: boolean;
  quantityInCart: number;
  dealId?: string;
  setFulfillmentId?: Function;
};

export type ItemData = {
  shipToHome: boolean;
  itemQuantity: number;
};

export default function FulfillmentOptionsV2({
  changeShippingType,
  fulfillmentOptionsGroupList,
  productName,
  shippingType,
  skuId,
  itemData,
  handleInstockAnalytics,
  availabilityInfoVO,
  isDealDrawer,
  dealId,
  itemPresentInCart,
  quantityInCart,
  setFulfillmentId,
}: Props) {
  const inline = false;
  const isBopusNewMsgApplicable = useFeatureFlag('IS_BOPUS_MSG_AVAILABLE_US') === 'true';
  const router = useRouter();
  const { isFetchingLocation } = useSelector(({ appData }: ReduxState) => appData);

  const { openSisterStoreFlyout, isSisterStoreFlyoutOpen } = useContext(SisterStoreContext);

  const openSisterStore = () => {
    openSisterStoreFlyout({
      skuId: skuId ?? '',
      shippingType,
      quantityInCart,
      productDetails: itemData,
      dealId,
    });
  };

  useEffect(() => {
    if (isDealDrawer) {
      const availableQuantities = getAllAvailableQuantitiesForSku(fulfillmentOptionsGroupList);
      const onlineOrderFulfillmentID = availableQuantities
        ? getFulfillmentIDforCartItem(availableQuantities, quantityInCart)
        : FULFILLMENT_METHODS.ONLINEORDER;
      const fulfillmentID =
        shippingType === cartConstants.STOREORDER
          ? FULFILLMENT_METHODS.STOREORDER
          : onlineOrderFulfillmentID;
      setFulfillmentId?.(fulfillmentID);
    }
  }, [shippingType, fulfillmentOptionsGroupList, isDealDrawer, quantityInCart, setFulfillmentId]);
  const sisterStoreAvailable = (item: FulfillmentGroup) =>
    item.groupFulfillmentOptions.find((group) => group.groupId === FULFILLMENT_METHODS.STOREORDER)
      ?.sisterStoreAvailable ?? false;

  const handleChange = (item: FulfillmentGroup) => {
    if (item.available || sisterStoreAvailable(item)) {
      const availableQuantities = getAllAvailableQuantitiesForSku(fulfillmentOptionsGroupList);
      const onlineOrderFulfillmentID = availableQuantities
        ? getFulfillmentIDforCartItem(availableQuantities, quantityInCart)
        : FULFILLMENT_METHODS.ONLINEORDER;
      const fulfillmentID =
        item.groupId === FULFILLMENT_METHODS.STOREORDER
          ? FULFILLMENT_METHODS.STOREORDER
          : onlineOrderFulfillmentID;
      setFulfillmentId?.(fulfillmentID);
      !isSisterStoreFlyoutOpen &&
        changeShippingType(
          item.groupId === FULFILLMENT_METHODS.STOREORDER
            ? (cartConstants.STOREORDER as 'STOREORDER')
            : (cartConstants.ONLINEORDER as 'ONLINEORDER'),
          item.groupId,
          item.maxAvailableQty,
          sisterStoreAvailable(item),
          fulfillmentID
        );
      if (
        item.groupId === FULFILLMENT_METHODS.STOREORDER &&
        router.pathname === routePaths.cart &&
        sisterStoreAvailable(item)
      ) {
        openSisterStore();
      }
    }
  };

  const subGroupRefs = fulfillmentOptionsGroupList?.map(() => ({ ref: createRef<any>() }));
  const [ariaLabels, setAriaLabels] = useState<Array<string>>([]);

  const determineAriaLabel = useCallback(() => {
    const ariaLabel: string[] = [];
    const inStockLabel =
      subGroupRefs[0]?.ref.current?.querySelector('[data-testid^="availability-"]')?.innerHTML ||
      '';
    const storeLabel = subGroupRefs[0]?.ref.current?.querySelector('button')?.innerHTML || '';

    const nextDayDelivery =
      subGroupRefs[1]?.ref.current?.children[0]?.querySelector('[data-testid="next-day"]')
        ?.innerHTML || '';
    const sameDayDelivery =
      subGroupRefs[1]?.ref.current?.children[1]?.querySelector('[data-testid="same-day"]')
        ?.innerHTML || '';
    const standardDelivery =
      subGroupRefs[1]?.ref.current?.querySelector('[data-testid="standard-delivery-item"]')
        ?.innerHTML || '';
    let sameDayDeliveryText = '';
    let nextDayDeliveryText = '';
    let standardDeliveryText = '';
    if (nextDayDelivery || sameDayDelivery) {
      const orderWithin =
        subGroupRefs[1]?.ref.current?.children[0]?.querySelector('[data-testid="orderby"]')
          ?.innerHTML || '';
      const nextDaycountdownHours =
        subGroupRefs[1]?.ref.current?.children[0]?.querySelector(
          'span[data-testid="countdown-hours"]'
        )?.innerHTML || '';
      const nextDayestimatedDelivery =
        subGroupRefs[1]?.ref.current?.children[0]?.querySelector(
          'span[data-testid="estimated-delivery-date-label"]'
        )?.innerHTML || '';
      const nextDayestimatedDeliveryDate =
        subGroupRefs[1]?.ref.current?.children[0]?.querySelector(
          'span[data-testid="estimated-delivery-date"]'
        )?.innerHTML || '';
      nextDayDeliveryText = `${nextDayDelivery} ${orderWithin} ${nextDaycountdownHours} ${nextDayestimatedDelivery} ${nextDayestimatedDeliveryDate} `;

      if (sameDayDelivery) {
        const sameDayDelivery =
          subGroupRefs[1]?.ref.current?.children[1]?.querySelector('[data-testid="same-day"]')
            ?.innerHTML || '';
        const orderBy =
          subGroupRefs[1]?.ref.current?.children[1]?.querySelector('[data-testid="orderby"]')
            ?.innerHTML || '';
        const sameDaycountdownHours =
          subGroupRefs[1]?.ref.current?.children[1]?.querySelector(
            'span[data-testid="countdown-ampm"]'
          )?.innerHTML || '';
        const sameDayestimatedDelivery =
          subGroupRefs[1]?.ref.current?.children[1]?.querySelector(
            'span[data-testid="estimated-delivery-date-label"]'
          )?.innerHTML || '';
        const sameDayestimatedDeliveryDate =
          subGroupRefs[1]?.ref.current?.children[1]?.querySelector(
            'span[data-testid="estimated-delivery-date"]'
          )?.innerHTML || '';
        sameDayDeliveryText = `${sameDayDelivery} ${orderBy} ${sameDaycountdownHours} ${sameDayestimatedDelivery} ${sameDayestimatedDeliveryDate} `;
      }
    }
    if (!sameDayDelivery) {
      const sameDayDelivery =
        subGroupRefs[1]?.ref.current?.children[0]?.querySelector('[data-testid="same-day"]')
          ?.innerHTML || '';
      const orderBy =
        subGroupRefs[1]?.ref.current?.children[0]?.querySelector('[data-testid="orderby"]')
          ?.innerHTML || '';
      const sameDaycountdownHours =
        subGroupRefs[1]?.ref.current?.children[0]?.querySelector(
          'span[data-testid="countdown-ampm"]'
        )?.innerHTML || '';
      const sameDayestimatedDelivery =
        subGroupRefs[1]?.ref.current?.children[0]?.querySelector(
          'span[data-testid="estimated-delivery-date-label"]'
        )?.innerHTML || '';
      const sameDayestimatedDeliveryDate =
        subGroupRefs[1]?.ref.current?.children[0]?.querySelector(
          'span[data-testid="estimated-delivery-date"]'
        )?.innerHTML || '';
      sameDayDeliveryText = `${sameDayDelivery} ${orderBy} ${sameDaycountdownHours} ${sameDayestimatedDelivery} ${sameDayestimatedDeliveryDate} `;
    }

    if (standardDelivery) {
      const estimatedDelivery =
        subGroupRefs[1]?.ref.current?.querySelector('[data-testid="estimated-delivery-standard"]')
          ?.innerHTML || '';
      const estimatedDeliveryDate =
        subGroupRefs[1]?.ref.current?.querySelector(
          '[data-testid="estimated-delivery-date-standard"]'
        )?.innerHTML || '';
      standardDeliveryText = ' ' + standardDelivery + estimatedDelivery + estimatedDeliveryDate;
    }
    const fullfillOption1 = `${fulfillmentOptionsGroupList[0]?.groupLabel} ${inStockLabel} ${storeLabel}`;

    const fullfillOption2 = `${fulfillmentOptionsGroupList[1]?.groupLabel} ${nextDayDeliveryText} ${sameDayDeliveryText} ${standardDeliveryText}`;
    ariaLabel.push(fullfillOption1);
    ariaLabel.push(fullfillOption2);
    setAriaLabels([...ariaLabel]);
  }, [fulfillmentOptionsGroupList, subGroupRefs]);

  useEffect(() => {
    if (fulfillmentOptionsGroupList) {
      determineAriaLabel();
    }
    // if adding determinAriaLabel as dependecy causes stack overflow
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillmentOptionsGroupList]);
  return (
    <div className={styles.toggleContainer}>
      {fulfillmentOptionsGroupList
        ? fulfillmentOptionsGroupList.map((item, buttonIndex) => {
            const optionChecked =
              item.groupId ===
              (shippingType === cartConstants.STOREORDER
                ? FULFILLMENT_METHODS.STOREORDER
                : FULFILLMENT_METHODS.ONLINEORDER);
            const hideRadioButton =
              item.groupId === FULFILLMENT_METHODS.ONLINEORDER
                ? !item.available
                : Array.isArray(item.groupFulfillmentOptions) &&
                  item.groupFulfillmentOptions[0]?.sisterStoreAvailable
                ? false
                : !item.available;
            // SWITCH to previous version of code  (Previosly !item.available is used for hide button)
            let hideRdButton = isBopusNewMsgApplicable ? hideRadioButton : !item.available;

            return isFetchingLocation ? (
              <Skeleton height={73} animation={'wave'} className={styles.skeletonLoading} />
            ) : (
              <div
                key={item.groupId}
                className={styles.segmentedToggleContainer}
                data-testid={`toggle-container-${buttonIndex}`}
              >
                <div
                  className={`${inline ? styles.inlineToggle : styles.segmentedToggle}`}
                  key={item.groupId}
                >
                  <CustomRadioInput
                    inputDataTestId={`optionButton${buttonIndex}`}
                    name={`fullfilment-radio${buttonIndex}`}
                    value={item.groupId}
                    disabled={
                      item.groupId === FULFILLMENT_METHODS.ONLINEORDER
                        ? !item.available
                        : Array.isArray(item.groupFulfillmentOptions) &&
                          item?.groupFulfillmentOptions[0]?.sisterStoreAvailable
                        ? false
                        : !item.available
                    }
                    checked={optionChecked}
                    onClick={(e) => {
                      hideRdButton && e.preventDefault();
                    }}
                    onChange={() => handleChange(item)}
                    label={
                      <div
                        className={cx(
                          azCommonStyles['az-caption'],
                          azCommonStyles['az-align-left'],
                          styles.deliveryGroupLabel
                        )}
                        data-testid="delivery-option-label"
                        aria-hidden="true"
                      >
                        {item.groupLabel}
                      </div>
                    }
                    ariaLabel={ariaLabels[buttonIndex]}
                    subLabel={
                      item.groupFulfillmentOptions ? (
                        <SubGroupOptions
                          groupFulfillmentOptions={item.groupFulfillmentOptions}
                          itemData={itemData}
                          isDealDrawer={isDealDrawer}
                          skuId={skuId}
                          productName={productName}
                          groupId={item.groupId}
                          handleInstockAnalytics={handleInstockAnalytics}
                          availabilityInfoVO={availabilityInfoVO}
                          ref={subGroupRefs[buttonIndex].ref}
                          itemPresentInCart={itemPresentInCart}
                          toggleSisterStoreFlyout={openSisterStore}
                        />
                      ) : null
                    }
                    hideRadioButton={hideRdButton}
                  />
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
}
