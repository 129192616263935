/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { isAxiosError } from './isAxiosError';

export function getErrorDetails<T>(error: unknown): T | undefined {
  if (isAxiosError(error)) {
    return error.response?.data.errorDetails;
  }
}
