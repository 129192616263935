/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useLabels } from '@/hooks/useLabels';
import { BuyersGuideBanner } from './BuyersGuideBanner';
import styles from './styles.module.scss';
type Props = {
  children: React.ReactNode;
  openBuyersGuide: () => void;
};
const labelMap = {
  labelThisPart: 'label_thisPart',
  labelFitsTheseVehicles: 'label_fitsTheseVehicles',
};

export function BuyersGuideWrapper(props: Props) {
  const { openBuyersGuide } = props;
  const labels = useLabels(labelMap);
  return (
    <>
      <div data-testid="bannerContainer" className={styles.bannerContainer}>
        <BuyersGuideBanner
          openBuyersGuide={openBuyersGuide}
          lblFitmentOne={labels.labelThisPart}
          lblFitmentTwo={labels.labelFitsTheseVehicles}
        />
      </div>
      {props.children}
    </>
  );
}
