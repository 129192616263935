/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useFeatureFlag } from '@/features/globalConfig';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { getAxios } from '@/lib/axios';
import { AxiosInstance } from 'axios';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { Get360InventoryResponse, Get360InventoryData } from '../interface';
import { format360Url } from '../utils/format360Url';

type Inventory360Options = {
  locale: Locale;
};

const get360Inventory = async (
  { queryKey }: QueryFunctionContext<ReturnType<typeof get360InventoryKey>>,
  axiosInstance?: AxiosInstance
) => {
  const [, options] = queryKey;
  const { locale } = options;

  const response = await getAxios(axiosInstance).get<Get360InventoryResponse>(
    format360Url('/inventory.json', locale),
    {
      headers: {
        Accept: 'application/json, text/plain, */*',
      },
    }
  );

  return selector(response.data);
};

const selector = (data: Get360InventoryResponse): Get360InventoryData => data;

const get360InventoryKey = (options: Inventory360Options) => ['360inventory', options] as const;

export const use360Inventory = () => {
  const locale = useLocale();
  const threeSixtyImagesEnabled = useFeatureFlag('THREE_SIXTY_ENABLED') === 'true';

  const options = {
    locale,
  };

  return useQuery({
    queryKey: get360InventoryKey(options),
    queryFn: get360Inventory,
    enabled: threeSixtyImagesEnabled,
  });
};
