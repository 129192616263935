/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { Typography } from '../Typography';
import { pascalCase } from '../../utils/pascalCase';
import { useFormControl } from '../FormControl/FormControlContext';
import styles from './FormControlLabel.module.scss';

type Props = React.HTMLAttributes<HTMLLabelElement> & {
  /**
   * If `true`, the component appears selected.
   */
  checked?: boolean;
  /**
   * @ignore
   */
  className?: string;
  /**
   * A control element. For instance, it can be a `Radio`, a `Switch` or a `Checkbox`.
   */
  control: React.ReactElement<any>;
  /**
   * If `true`, the control will be disabled.
   */
  disabled?: boolean;
  /**
   * Pass a ref to the `input` element.
   */
  inputRef?: React.Ref<any>;
  /**
   * The text to be used in an enclosing label element.
   */
  label?: React.ReactNode;
  /**
   * a11y text needed for screen readers.
   */
  ariaLabel?: string;
  /**
   * The position of the label.
   */
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  /**
   * @ignore
   */
  name?: string;
  /**
   * Callback fired when the state is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new checked state by accessing `event.target.checked` (boolean).
   */
  onChange?: (event: React.ChangeEvent, checked: boolean) => unknown;
  /**
   * The value of the component.
   */
  value?: any;
  htmlFor?: string;
};

/**
 * Drop in replacement of the `Radio`, `Switch` and `Checkbox` component.
 * Use this component if you want to display an extra label.
 */
export const FormControlLabel = React.forwardRef<HTMLLabelElement, Props>(function FormControlLabel(
  props,
  ref
) {
  const {
    checked,
    className,
    control,
    disabled: disabledProp,
    inputRef,
    label,
    ariaLabel,
    labelPlacement = 'end',
    name,
    onChange,
    value,
    ...other
  } = props;
  const muiFormControl = useFormControl();
  let disabled = disabledProp;

  if (typeof disabled === 'undefined' && typeof control.props.disabled !== 'undefined') {
    disabled = control.props.disabled;
  }

  if (typeof disabled === 'undefined' && muiFormControl) {
    disabled = muiFormControl.disabled;
  }

  const controlProps = {};
  Object.keys({
    checked,
    name,
    onChange,
    value,
    inputRef,
  }).forEach((key) => {
    if (
      typeof control.props[key] === 'undefined' &&
      // @ts-expect-error refine type
      typeof props[key] !== 'undefined'
    ) {
      // @ts-expect-error refine type
      controlProps[key] = props[key];
    }
  });
  return (
    // eslint-disable-next-line jsx-a11y/label-has-for -- TODO investigate
    <label
      className={cx(
        styles.root,
        {
          [styles[`labelPlacement${pascalCase(labelPlacement)}`]]: labelPlacement !== 'end',
          [styles.disabled]: disabled,
        },
        className
      )}
      aria-label={ariaLabel}
      ref={ref}
      {...other}
    >
      {React.cloneElement(control, {
        disabled,
        ...controlProps,
      })}
      <Typography
        component="span"
        className={cx(styles.label, {
          [styles.disabled]: disabled,
        })}
      >
        {label}
      </Typography>
    </label>
  );
});
