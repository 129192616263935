/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
// Dependencies
import cx from 'classnames';
// STARC Components
import { View, Text, Skeleton, Notification } from '@az/starc-ui';
// Features
import {
  CertonaBlock,
  HorizontalScrolledPartCardSkeleton,
  type CertonaRecommendation,
  type AddCertonaItemObjectType,
} from '@/features/certona';
import { useLabel, useLabels } from '@/hooks/useLabels';
import { useProductDetails } from '@/features/product/api/getProductDetails';
import { useGetProductSkuPrice } from '@/features/product/api/getProductSkuPrice';
import { useProductAvailability } from '@/features/product/api/getProductAvailability';
import { useLocale } from '@/hooks/useLocale';
import type { CertonaItem } from '@/types';
import { useHeaderData } from '@/features/header/api/getHeader';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { CMSYmme } from '@/features/contentstack/components/CMSYmme/CMSYmme';
import { useFeatureFlag } from '@/features/globalConfig';
// Hooks
import { useMediaQuery } from '@/hooks/useMediaQuery';
// Utils
import { SmartLink } from '@/utils/smartLink';
// Constants
import { countryCodes } from '@/constants/locale';
import { routePaths } from '@/constants/routePaths';
// Styles
import styles from './styles.module.scss';
import root from 'window-or-global';
import { useEffect, useState } from 'react';

type CertonaContentForOos = {
  '@type': string;
  containerID: string;
  desktop: boolean;
  mobile: boolean;
  name: string;
  tablet: boolean;
  themeCode: string;
};

type NewCertonaForOosProps = {
  isOos: boolean;
  doesNotFit: boolean;
  logAnalytics: () => void;
  certonaRecommendations?: CertonaRecommendation[];
  setCertonaRecommendationAddedToCart: React.Dispatch<React.SetStateAction<boolean>>;
  setAddedCertonaItemObject: React.Dispatch<React.SetStateAction<AddCertonaItemObjectType | null>>;
};

const certonaContentForOos: CertonaContentForOos = {
  '@type': 'PDPPageBody_ProductDetail',
  containerID: 'pencilproduct1_rr',
  desktop: true,
  mobile: true,
  name: 'Certona Content Block',
  tablet: true,
  themeCode: 'grey',
};

const labelsMap = {
  ADD_YOUR_VEHCILE: 'label_First_add_your_vehicle',
  GET_AN_EXACT_FIT_FOR_YOUR_VEHICLE: 'hyperlink_YMME_Body_GetAnExactFitForYourVehicle',
  THIS_PART_DOES: 'label_ProductDetailsPage_YMME_this_part_does',
  NOT: 'label_ProductDetailsPage_YMME_not',
  FIT_YOUR_CURRENT_VEHICLE: 'label_ProductDetailsPage_YMME_fit_your_current_vehicle',
  SEE_PARTS_THAT_FIT: 'label_ProductDetailsPage_YMME_see_parts_that_fit',
};

type ComponentDisplayOption = 'skeleton' | 'container' | 'warning' | 'ymme';

export const NewCertonaForOos = ({
  isOos,
  doesNotFit,
  logAnalytics,
  certonaRecommendations,
  setCertonaRecommendationAddedToCart,
  setAddedCertonaItemObject,
}: NewCertonaForOosProps) => {
  const labels = useLabels(labelsMap);
  const appPdpOosThisPartIsOutOfStock = useLabel(
    'label_ProductDetailsPage_OOS_This_Product_Is_Out_Of_Stock'
  );
  const appPdpOosShopSimilarItemsBelow = useLabel(
    'label_ProductDetailsPage_OOS_Shop_similar_items_below'
  );
  const appPdpDoesNotFitThesePartsFit = useLabel(
    'label_ProductDetailsPage_Does_Not_Fit_These_Parts_Fit'
  );
  const appPdpDoesNotFitYour = useLabel('label_MyAccountMyProfile_landing_RewardYour');
  const labelShopAll = useLabel('label_Homepage_content_ShopAll');

  const isCertonaEnabledForOosOrDoesNotFit =
    useFeatureFlag('IS_OOS_OR_DOES_NOT_FIT_CONTAINER_ENABLED') === 'true';
  const isPdpCertonaAddToCartEnabled =
    useFeatureFlag('IS_PDP_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';

  const [availableProductsForPencilLength, setAvailableProductsForPencilLength] = useState(0);

  const { data: headerData } = useHeaderData();
  const hasVehicle = headerData?.vehicleMap?.vehicleId !== '';
  const storeNumber = headerData?.storeNumber;

  const { data: productDetailsData } = useProductDetails();
  const { vehicleFitFlag, breadCrumbs, recordType } = productDetailsData?.product ?? {};
  const shelfSeoUrl =
    breadCrumbs?.find((item) => item.pageType === 'ProductShelf')?.seoUrl || routePaths.blankURL;

  const locale = useLocale();
  const preferredVehicle = usePreferredVehicle();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.only('lg'));

  const [displayOption, setDisplayOption] = useState<ComponentDisplayOption>('skeleton');

  const vehicleTextForDoesNotFit = `${preferredVehicle?.year} ${preferredVehicle?.make} ${preferredVehicle?.model}.`;

  const index = certonaRecommendations?.findIndex((item) =>
    item.scheme.includes(certonaContentForOos.containerID || '')
  );
  const items = certonaRecommendations?.[index ?? -1]?.items;
  const skuNumbers = items?.map((part) => part.id);
  const {
    data: productAvailability,
    isFetching: productAvailabilityIsFetching,
    isSuccess: productAvailabilityIsSuccess,
  } = useProductAvailability({
    enabled: !!skuNumbers?.length,
    skuIds: skuNumbers ?? null,
  });

  const shownItemsAmount = isPdpCertonaAddToCartEnabled
    ? availableProductsForPencilLength
    : items?.length;

  const { data: pricingInfo } = useGetProductSkuPrice({
    skuNumbers,
    storeNumber,
    enabled: !!storeNumber && !!skuNumbers?.length,
    staleTime: 5 * 60000, // 5 minutes
  });

  const updateAfterAddtoCartClick = () => {
    setCertonaRecommendationAddedToCart(true);
  };

  useEffect(() => {
    if (
      productAvailabilityIsFetching ||
      root.certonaNotCalled === true ||
      root.certonaNotCalled === undefined
    ) {
      setDisplayOption('skeleton');
    }

    const skuDetailsDataOos = productAvailabilityIsSuccess ? productAvailability : null;
    const availableProductsForPencil =
      items?.reduce<CertonaItem[]>((acc, item) => {
        const isAvailable = skuDetailsDataOos?.some((skudata) => {
          const productAvailibility =
            skudata.availabilityInfoVO.bopusQuantity > 0 ||
            skudata.availabilityInfoVO.sthQuantity > 0;
          const pricingAvailability = !!pricingInfo?.[item.id]?.configurableSku
            ? skudata.skuId === item.id && productAvailibility
            : !!pricingInfo?.[item.id]?.totalPrice &&
              productAvailibility &&
              skudata.skuId === item.id;
          if (pricingAvailability) {
            return skudata;
          }
        });

        if (isAvailable) {
          acc = [...acc, item];
        }
        return acc;
      }, []) ?? [];

    setAvailableProductsForPencilLength(availableProductsForPencil.length);

    const shouldShowFitmentMessage = locale === countryCodes.enUS && hasVehicle && doesNotFit;

    if (
      certonaRecommendations?.length &&
      availableProductsForPencil.length &&
      !productAvailabilityIsFetching &&
      root.certonaNotCalled === false &&
      root.certonaNotCalled !== undefined
    ) {
      setDisplayOption('container');
    } else if (
      !productAvailabilityIsFetching &&
      root.certonaNotCalled === false &&
      root.certonaNotCalled !== undefined
    ) {
      if (
        isOos &&
        ((!hasVehicle && !vehicleFitFlag && recordType !== 'app') ||
          (hasVehicle && (!vehicleFitFlag || (vehicleFitFlag && recordType === 'app'))))
      ) {
        setDisplayOption('ymme');
      } else if (shouldShowFitmentMessage) {
        setDisplayOption('warning');
      }
    }
  }, [
    items,
    pricingInfo,
    productAvailability,
    productAvailabilityIsSuccess,
    certonaRecommendations?.length,
    doesNotFit,
    hasVehicle,
    isOos,
    recordType,
    locale,
    vehicleFitFlag,
    productAvailabilityIsFetching,
  ]);

  const truncateText = (text: string, maxCharacters?: number) => {
    if (maxCharacters === undefined) {
      return text;
    } else if (maxCharacters < 1) {
      return;
    } else {
      let truncatedText = text.slice(0, maxCharacters - 1);
      if (text.length > maxCharacters) {
        truncatedText = truncatedText.trimEnd() + '\u2026';
      }
      return truncatedText;
    }
  };

  const title = Boolean(availableProductsForPencilLength) ? (
    <View
      direction="row"
      justify={{
        s: 'space-between',
        m: availableProductsForPencilLength >= 2 ? 'space-between' : 'start',
        l: availableProductsForPencilLength >= 2 ? 'space-between' : 'start',
      }}
      align={isMobile ? 'start' : 'center'}
      padding={{ s: 0, m: [3, 0, 0, 14 / 4], l: [3, 0, 0, 14 / 4] }}
      className={styles.titlesContainer}
    >
      <View.Item>
        {isOos ? (
          <Text className={styles.pdpOosText}>
            <span className={styles.pdpOosBoldText}>{`${appPdpOosThisPartIsOutOfStock}`}.</span>{' '}
            <span
              className={availableProductsForPencilLength === 1 ? styles.shopAllMobileSpace : ''}
            >
              {`${appPdpOosShopSimilarItemsBelow}`}.
            </span>
          </Text>
        ) : (
          doesNotFit && (
            <Text className={styles.pdpOosText}>
              <span
                className={styles.pdpOosBoldText}
              >{`${appPdpDoesNotFitThesePartsFit} ${appPdpDoesNotFitYour} `}</span>{' '}
              <span>
                {truncateText(
                  vehicleTextForDoesNotFit,
                  isMobile
                    ? 31
                    : isTablet
                    ? 45
                    : isDesktop && shownItemsAmount === 2
                    ? 46
                    : undefined
                )}
              </span>
            </Text>
          )
        )}
      </View.Item>
      <View.Item>
        <SmartLink to={shelfSeoUrl!} onClick={logAnalytics}>
          <Text
            decoration="underline"
            className={cx(styles.shopAllStyles, {
              [styles.threeOrMoreItemsAvailable]: shownItemsAmount && shownItemsAmount >= 3,
              [styles.twoItemsAvailable]: shownItemsAmount && shownItemsAmount === 2,
            })}
          >
            {labelShopAll}
          </Text>
        </SmartLink>
      </View.Item>
    </View>
  ) : (
    ''
  );

  if (displayOption === 'ymme') {
    return (
      <View padding={[0, 0, 5, 0]}>
        <View
          className={cx({
            [styles.sharedOOSWrapper]: isCertonaEnabledForOosOrDoesNotFit && (isOos || doesNotFit),
          })}
          justify={
            isCertonaEnabledForOosOrDoesNotFit && (isOos || doesNotFit) ? 'center' : undefined
          }
        >
          <CMSYmme
            content={{
              layout: 'Thin',
              text: labels.ADD_YOUR_VEHCILE,
              sub_text: labels.GET_AN_EXACT_FIT_FOR_YOUR_VEHICLE,
            }}
            classes={{
              titleContainerCustomStyle: styles.titleContainer,
              ymmeTitleLabel: styles.title,
            }}
            removeMargins
          />
        </View>
      </View>
    );
  }

  if (displayOption === 'warning') {
    return (
      <View
        padding={{
          m: [0, 0, 5, 0],
          s: [0, 0, 5, 0],
        }}
      >
        <View
          className={cx({
            [styles.sharedOOSWrapper]: isCertonaEnabledForOosOrDoesNotFit && (isOos || doesNotFit),
          })}
        >
          <Notification
            type="warning"
            text={
              <Text as="p" className={styles.text}>
                {labels.THIS_PART_DOES}{' '}
                <Text weight="bold" as="strong">
                  {labels.NOT}
                </Text>{' '}
                {labels.FIT_YOUR_CURRENT_VEHICLE}{' '}
                <SmartLink
                  to={
                    breadCrumbs?.find((item) => item.pageType === 'ProductShelf')?.seoUrl ??
                    routePaths.blankURL
                  }
                  onClick={logAnalytics}
                >
                  <Text weight="bold" as="strong" decoration="underline">
                    {labels.SEE_PARTS_THAT_FIT}
                  </Text>
                </SmartLink>
              </Text>
            }
          />
        </View>
      </View>
    );
  }
  if (displayOption === 'container') {
    return (
      <View>
        <CertonaBlock
          className={
            Boolean(availableProductsForPencilLength)
              ? isOos || doesNotFit
                ? styles.oosAndDoesNotFitCertonaContainer
                : ''
              : ''
          }
          title={title}
          contentItem={certonaContentForOos}
          certonaRecommendations={certonaRecommendations}
          onCertonaAddToCartClick={() => {
            updateAfterAddtoCartClick();
          }}
          setAddedCertonaItemObject={setAddedCertonaItemObject}
          containerType="horizontal_scrolled"
          isOos={isOos}
          doesNotFit={doesNotFit}
          shelfSeoUrl={shelfSeoUrl}
          updateAfterAddtoCartClick={updateAfterAddtoCartClick}
          showInitialSkeleton
          inDrawer={true}
          onlyShowAvailableProductRecommendations={isPdpCertonaAddToCartEnabled}
        />
      </View>
    );
  }

  return <SkeletonWithTitle />;
};

const SkeletonWithTitle = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <View direction="column" className={styles.horizontalSkeletonWrapper}>
      <View.Item>
        <View direction="row" justify="space-between">
          <View.Item>
            <Skeleton width={{ m: '403px', s: '215px' }} height={{ m: '28px', s: '50px' }} />
          </View.Item>
          <View.Item
            gapBefore={isMobile ? 12 : 0}
            attributes={{ style: { paddingRight: isMobile ? '16px' : '20px' } }}
          >
            <Skeleton width="80px" height="28px" />
          </View.Item>
        </View>
      </View.Item>
      <HorizontalScrolledPartCardSkeleton className={styles.horizontalSkeleton} />
    </View>
  );
};
