/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export function appendVehicleIdFilters(currFilters: string[], vehicleId: string) {
  if (vehicleId !== '0') {
    return [currFilters, ['vehicleid:universal', `vehicleid:${vehicleId}`]];
  }
  return [currFilters];
}
