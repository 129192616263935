/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect } from 'react';
import cx from 'classnames';
import Hidden from '../Hidden';
import { AZButton } from '../AZButton';
import GridObject from './GridObject';
import MobileGridContent from '../ContentBlocks/MobileGridContent';
import styles from './styles.module.scss';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { useAnalytics } from '@/utils/analytics/useAnalytics';
import { usePageViewCall } from '@/utils/analytics/usePageViewCall';
import { getPageName } from '@/utils/analytics/legacyUtils/getPageName';
import { getSeoPageType } from '@/utils/analytics/legacyUtils/getSeoPageType';
import { getAnalyticsRelativeComplementOf } from '@/utils/analytics/getAnalyticsRelativeComplementOf';
import { pageTypes } from '@/constants/page';
import { siteSections } from '@/utils/siteSections';

const { siteSectionPageUnavailable } = siteSections;

const labelMap = {
  heading: 'label_not_found_sorry',
  textTwoMobTablet:
    'label_not_found_You_can_head_back_to_the_AutoZone_homepage_or_choose_one_of_the_categories_below',
  textOne: 'label_not_found_text_one',
  textTwoDesk: 'label_not_found_text_two',
  textThree: 'label_not_found_text_three',
};

interface ErrorPage {
  isWithAnalytics?: boolean;
}

const ErrorPage = ({ isWithAnalytics = true }: ErrorPage) => {
  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const pageViewCall = usePageViewCall();
  const locale = useLocale();
  const labels = useLabels(labelMap);
  const { heading, textTwoMobTablet, textOne, textTwoDesk, textThree } = labels;
  const isLocaleUSA = locale === countryCodes.us;
  const isBrSite = locale === countryCodes.ptBr;

  const hasSetupAnalytics = useAnalytics();
  useEffect(() => {
    if (hasSetupAnalytics && isWithAnalytics) {
      const pageSpecificAnalyticsData = {
        pageName: getPageName(siteSectionPageUnavailable, 'not-found'),
        siteSection: siteSectionPageUnavailable,
        pageType: pageTypes.notFound,
        seoPageType: getSeoPageType('', ''),
        pageHierarchy: '',
        contentBlockNames: [],
        contentBlockTypes: [],
      };
      pageViewCall(
        pageSpecificAnalyticsData,
        getAnalyticsRelativeComplementOf(pageSpecificAnalyticsData, undefined)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSetupAnalytics, isWithAnalytics]);

  const handleOnPress = () => {
    // @ts-expect-error fix use of window.location
    window.location = '/';
  };

  return (
    <div className={styles.mainContainer}>
      <div
        className={cx(styles.errorPageContainer, {
          [styles.marginBottom30]: isLocaleUSA,
        })}
      >
        {matchesMobile ? (
          <>
            <div className={styles.errorPageHeadingContainer}>
              <h1 tabIndex={0} className={styles.errorPageMobileHeading}>
                {isBrSite ? 'OPA!' : heading}
              </h1>
              <p tabIndex={0} className={styles.errorPageSubText}>
                {isBrSite
                  ? 'Parece que algo deu errado do nosso lado, mas estamos trabalhando nisso!'
                  : textOne}
              </p>
            </div>
            <div role="img" aria-label="Page not found image" className={styles.errorPageImg}></div>
            <div className={styles.errorPageBtnContainer}>
              <Hidden only={['lg']}>
                <p tabIndex={0} className={styles.errorPageSubText}>
                  {isBrSite
                    ? 'Clique no botão para voltar à nossa página inicial.'
                    : textTwoMobTablet}
                </p>
              </Hidden>
              <div className={styles.errorPageBtn}>
                <AZButton
                  variant="primary"
                  title={isBrSite ? 'VOLTAR' : textThree}
                  className={cx(styles.errorPageFlexStart, {
                    [styles.positionAbsolute]: !isLocaleUSA,
                  })}
                  onPress={handleOnPress}
                  buttonState="enabled"
                />
              </div>
            </div>
          </>
        ) : (
          <div className={styles.errorPageContainerLarge}>
            <div className={styles.errorPageElements}>
              <h1 tabIndex={0} className={styles.errorPageHeading}>
                {isBrSite ? 'OPA!' : heading}
              </h1>
              <div className={styles.paragraphContainer}>
                <p className={styles.errorPageParagraph} tabIndex={0}>
                  {isBrSite
                    ? 'Parece que algo deu errado do nosso lado, mas estamos trabalhando nisso!'
                    : textOne}
                </p>
                {isDesktop ? (
                  <p className={styles.errorPageParagraph} tabIndex={0}>
                    {isBrSite ? 'Clique no botão para voltar à nossa página inicial.' : textTwoDesk}
                  </p>
                ) : (
                  <p className={cx(styles.errorPageParagraph, styles.marginTopZero)} tabIndex={0}>
                    {isBrSite
                      ? 'Clique no botão para voltar à nossa página inicial.'
                      : textTwoMobTablet}
                  </p>
                )}
              </div>
              <div className={styles.errorPageBtn}>
                <AZButton
                  variant="primary"
                  title={isBrSite ? 'VOLTAR' : textThree}
                  onPress={handleOnPress}
                  className={cx({ [styles.positionAbsolute]: !isLocaleUSA })}
                  buttonState="enabled"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {isLocaleUSA && <MobileGridContent contentBlock={GridObject} />}
    </div>
  );
};

export default ErrorPage;
