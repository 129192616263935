/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const shelfPageType = {
  productShelf: 'ProductShelf',
  shopByMake: 'ShopByMake',
  shopByModel: 'ShopByModel',
};
