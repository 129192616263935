/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useLabels } from '@/hooks/useLabels';
import { BundleDealConstraintCard } from '../BundleDealConstraintCard';
import type { DealConstraintSkuDetails, DealConstraint } from '../../../interface';
import styles from './styles.module.scss';
import ConstraintSwapButton from '../BundleDeal/ConstraintSwapButton';
import ConstraintItemHeader from './ConstraintItemHeader';
import VehicleFitCard from './VehicleFitCard';
import { labelMap } from './helpers/labelMap';

type Props = {
  index?: number;
  constraint: DealConstraint;
  skuDetails: DealConstraintSkuDetails;
  onSwapClick?: () => void;
  showItemHeading?: boolean;
};

const BundleDealConstraint = ({
  index,
  constraint,
  skuDetails,
  onSwapClick,
  showItemHeading = true,
}: Props) => {
  const { skus } = constraint;
  const labels = useLabels(labelMap);

  return (
    <div className={styles.constraintItem}>
      <div className={styles.constraintItemWrapper}>
        {index !== undefined && showItemHeading && (
          <ConstraintItemHeader label={labels.label_deal_bundle_constraint_item} index={index} />
        )}
        {skus.length > 1 && index !== undefined && (
          <ConstraintSwapButton onSwapClick={onSwapClick} />
        )}
      </div>

      {skuDetails.vehicleSpecfic && !skuDetails.vehicleFit && (
        <VehicleFitCard
          lblDoesNotFit={labels.label_deal_bundle_current_vehicle_does_not_fit_title}
          lblFitDesc={labels.label_deal_bundle_current_vehicle_does_not_fit_description}
        />
      )}
      <div className={styles.constraintItemCardSection}>
        <BundleDealConstraintCard constraint={constraint} skuDetails={skuDetails} />
      </div>
    </div>
  );
};

export default BundleDealConstraint;
