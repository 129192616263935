/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type {
  DealConstraint,
  DealConstraintHash,
  DealConstraintSkuDetails,
  SkuDetails,
  ConstraintSelectionHash,
} from '../../../../interface';

export const getConstraintData = (
  constraints: DealConstraintHash,
  constraintSelections: ConstraintSelectionHash,
  parentSkuDetails: SkuDetails
) => {
  const secondaryConstraints: DealConstraint[] = [];
  let parentConstraint: DealConstraint | undefined;
  let parentConstraintSkuDetails: DealConstraintSkuDetails | undefined;

  Object.values(constraints).forEach((constraintObj) => {
    const constraint: DealConstraint = constraintObj;
    const { id: constraintId } = constraint;

    const parentSkuFound = constraint.skus.find((s) => s === parentSkuDetails.id);

    if (parentSkuFound) {
      parentConstraint = constraint;
      parentConstraintSkuDetails = constraintSelections[constraintId];
    } else {
      secondaryConstraints.push(constraint);
    }
  });
  return { parentConstraint, parentConstraintSkuDetails, secondaryConstraints };
};
