/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import styles from './styles.module.scss';
import { Warning, Icon } from '@az/starc-ui';
import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import NotificationBlock from '../NotificationBlock';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import usePrevious from '@/utils/usePrevious';
import { useLabels } from '@/hooks/useLabels';

export type Props = {
  selectedFulfillmentID: number;
  availableQuantity: number | undefined;
  selectedQuantity: number;
  nddQuantity: number | undefined;
  sddQuantity: number | undefined;
  messageIsNotification?: boolean;
};
const labelMap = {
  label_max_quantity_sdd: 'label_max_quantity_sdd',
  label_max_quantity_ndd: 'label_max_quantity_ndd',
  label_max_quantity_hd: 'label_max_quantity_hd',
  label_quantity_exceeded_ndd: 'label_quantity_exceeded_ndd',
  label_max_quantity_bopus: 'label_max_quantity_bopus',
  label_deliverymethod_unavailable: 'label_deliverymethod_unavailable',
};

export function MaxQuantityNotificationBlock(props: Props) {
  const labels = useLabels(labelMap);
  const { data: storeDetails } = useStoreDetailsData();
  const previousStoreNumber = usePrevious(storeDetails?.storeNumber);
  const {
    selectedFulfillmentID,
    nddQuantity = 0,
    sddQuantity = 0,
    selectedQuantity,
    availableQuantity = 0,
    messageIsNotification = false,
  } = props;

  const checkAvailability = () => {
    if (availableQuantity > selectedQuantity) {
      return true;
    }
  };
  let message = '';
  if (selectedFulfillmentID == FULFILLMENT_METHODS.SAMEDAY) {
    storeDetails?.storeNumber !== previousStoreNumber && sddQuantity != null && sddQuantity <= 0
      ? (message = labels.label_deliverymethod_unavailable)
      : !checkAvailability()
      ? (message = labels.label_max_quantity_sdd)
      : null;
  } else if (selectedFulfillmentID == FULFILLMENT_METHODS.NEXTDAY) {
    if (availableQuantity <= selectedQuantity) {
      message = labels.label_max_quantity_ndd;
    }
  } else if (selectedFulfillmentID == FULFILLMENT_METHODS.ONLINEORDER) {
    if (
      nddQuantity &&
      nddQuantity > 0 &&
      nddQuantity < selectedQuantity &&
      availableQuantity > selectedQuantity
    ) {
      message = labels.label_quantity_exceeded_ndd;
    } else if (availableQuantity <= selectedQuantity) {
      message = labels.label_max_quantity_hd;
    }
  } else if (selectedFulfillmentID == FULFILLMENT_METHODS.STOREORDER) {
    if (!checkAvailability()) {
      message = labels.label_max_quantity_bopus;
    }
  }

  if (messageIsNotification && message) {
    return (
      <NotificationBlock
        customClasses={styles.warningTextCart}
        type="warn"
        message={message}
        itemLevel
      />
    );
  } else if (message) {
    return (
      <div className={styles.warningMessage}>
        <Icon svg={Warning} color="warning" size={5.5} />
        <p className={styles.warningTextStyles}>{message}</p>
      </div>
    );
  }
  return null;
}
