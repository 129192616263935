/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { BreadcrumbModel } from '@/api/types/browse-search-types';
import type { Url } from '../../../../next-types';

export type IItem = {
  label?: string;
  url?: Url | string;
  pageType?: string;
  displayName?: string;
};

export const isBreadcrumbModel = (
  breadcrumb: IItem | BreadcrumbModel
): breadcrumb is BreadcrumbModel => {
  return Boolean(breadcrumb && 'canonicalPath' in breadcrumb);
};
