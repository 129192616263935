/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { QueryClient, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { ProductDealsData, ProductDealsResponse } from '../interface';
import { cache } from '@/utils/serverQueryCache';
import { useHeaderData } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { AxiosInstance } from 'axios';

type Options = {
  partTypeId: number;
  catalogRefId: string;
  productId: string;
  currentPage?: number;
  // These are not used in the API call, but the result varies depending on them
  // So we need to use them in the key for the cache
  vehicleId: string | undefined;
  storeNumber: string | undefined;
};

const URL = `${requestBaseURL}/ecomm/b2c/v1/browse/page/productDetailsToDeals`;
const QUERY_NAME = 'productDeals';

const selector = (data: ProductDealsResponse): ProductDealsData => data;

const getProductDeals = async (
  { queryKey }: QueryFunctionContext<ReturnType<typeof getProductDealsKey>>,
  axiosInstance?: AxiosInstance
) => {
  const [, { catalogRefId, partTypeId, productId, currentPage = 1 }] = queryKey;

  const response = await getAxios(axiosInstance).get<ProductDealsResponse>(URL, {
    params: {
      productId,
      catalogRefId,
      partTypeId,
      currentPage,
    },
  });

  return selector(response.data);
};

const getProductDealsKey = (options: Options) => [QUERY_NAME, options] as const;

const getProductDealsCacheKey = ({
  catalogRefId,
  partTypeId,
  productId,
  currentPage = 1,
}: Options) =>
  [QUERY_NAME, catalogRefId, partTypeId, productId, currentPage].map((it) => JSON.stringify(it));

export const useProductDeals = (options: Omit<Options, 'vehicleId' | 'storeNumber'>) => {
  const headerResult = useHeaderData();
  const storeNumber = headerResult.data?.storeNumber;
  const vehicleId = getPreferredVehicle(headerResult.data)?.catalogVehicleId;

  return useQuery({
    enabled: headerResult.isSuccess,
    queryKey: getProductDealsKey({
      ...options,
      vehicleId,
      storeNumber,
    }),
    queryFn: getProductDeals,
    // This is needed for pagination
    keepPreviousData: true,
  });
};

export const prefetchProductDeals = async (
  queryClient: QueryClient,
  options: Options,
  axiosInstance: AxiosInstance
) => {
  const queryKey = getProductDealsKey(options);
  const cacheKey = getProductDealsCacheKey(options);
  let data = cache.getItem(cacheKey);
  if (data) {
    queryClient.setQueryData(queryKey, data);
    return;
  }
  data = await queryClient.fetchQuery({
    queryKey,
    queryFn: (context: QueryFunctionContext<ReturnType<typeof getProductDealsKey>>) =>
      getProductDeals(context, axiosInstance),
  });
  data && cache.setItem(cacheKey, data);
};
