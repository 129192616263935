/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useRef, useEffect } from 'react';

export const useAddToCartErrorScroll = (addToCartError: string | null | undefined) => {
  const scrollerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!addToCartError) {
      return;
    }

    if (scrollerRef.current) {
      scrollerRef.current.scrollTop = 0;
    }
  }, [addToCartError]);

  return { scrollerRef };
};
