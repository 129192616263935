/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Button, View, Text } from '@az/starc-ui';
import { ParentSkuCard, ParentSkuCardProps } from '../../ParentSkuCard';
import { useLabels } from '@/hooks/useLabels';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { useMemo } from 'react';
import styles from './VehicleNotFit.module.scss';

type Props = {
  fittingPartsURL: string;
  parentSkuCardProps: ParentSkuCardProps;
  onVehicleDoesNotFitCtaOnClick: () => void;
};

const labelMap = {
  label_deal_doesnt_fit_vehicle: 'label_deal_doesnt_fit_vehicle',
  label_deal_doesnt_fit_vehicle_cta: 'label_deal_doesnt_fit_vehicle_cta',
};

export function VehicleNotFit({
  fittingPartsURL,
  parentSkuCardProps,
  onVehicleDoesNotFitCtaOnClick,
}: Props) {
  const preferredVehicle = usePreferredVehicle();
  const preferredVehicleDisplayName = useMemo(
    () => (preferredVehicle ? preferredVehicle.vehicleName : ''),
    [preferredVehicle]
  );

  const labels = useLabels(labelMap);
  return (
    <View backgroundColor="100" className={styles.vehicleErrorContainer} gap={4}>
      <View.Item>
        <Text variant="small-body">
          {labels.label_deal_doesnt_fit_vehicle}{' '}
          <Text fontFamily="body" weight="bold" size="087" as="strong">
            {preferredVehicleDisplayName}
          </Text>
        </Text>{' '}
      </View.Item>
      <View.Item>
        <ParentSkuCard
          skuDetails={parentSkuCardProps.skuDetails}
          skuQuantity={parentSkuCardProps.skuQuantity}
          skuPrice={parentSkuCardProps.skuPrice}
          skuRetailPrice={parentSkuCardProps.skuRetailPrice}
        />
      </View.Item>
      <View.Item grow={false} className={styles.ctaContainer}>
        <Button
          variant="primary"
          fullWidth={false}
          className={styles.ctaButton}
          href={fittingPartsURL}
          onClick={onVehicleDoesNotFitCtaOnClick}
        >
          {labels.label_deal_doesnt_fit_vehicle_cta}
        </Button>
      </View.Item>
    </View>
  );
}
