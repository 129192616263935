/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import Image from '@/components/NextImage';
import { Label } from '@/features/i18n';
import type { DealInfo } from '@/types/availability';
import azCommonStyles from '@/theme/globals.module.scss';
import { SmartLink as Link } from '@/utils/smartLink';
import cx from 'classnames';
import * as React from 'react';
import styles from './styles.module.scss';

type Props = {
  badgeDetails: DealInfo;
  withShortDescription?: boolean;
};

const substringShortDescWithPrice = (
  rebateShortDescFromState?: string,
  withShortDescription?: boolean
) => {
  const indexOfDollarSign = rebateShortDescFromState?.indexOf('$') ?? -1;
  let newRebateShortDesc = '';

  if (indexOfDollarSign !== -1 && withShortDescription) {
    newRebateShortDesc = `: ${
      rebateShortDescFromState ? rebateShortDescFromState.substring(indexOfDollarSign) : ''
    }`;
  }

  return newRebateShortDesc;
};

export const RebateOnlyBadge = ({ badgeDetails, withShortDescription = false }: Props) => {
  const additionalRebateShortDesc = substringShortDescWithPrice(
    badgeDetails.rebateShortDesc,
    withShortDescription
  );
  const lblRebateAvailable = <Label label="label_ShelfPage_DealsBundle_RebateAvailable" />;
  const seperateTab = '_blank';
  const rebateIconSrc = '/images/az-rebate.svg';
  return badgeDetails.rebatesAvailable ? (
    <div className={cx(styles.rebateWrapper, styles.newFulfillmentRebate)}>
      <Link
        to={badgeDetails.rebateUrl ?? '/deals/rebates'}
        target={seperateTab}
        className={cx(styles.rebates, azCommonStyles['az-caption'])}
      >
        <div className={styles.rebateIcon}>
          <Image src={rebateIconSrc} alt="" width={22} height={22} />
        </div>
        {lblRebateAvailable}
        {additionalRebateShortDesc}
      </Link>
    </div>
  ) : null;
};
