/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { MyStore } from '../MyStore/MyStore';
import ModalWrapper from './ModalWrapper';
import type { ModalWrapperProps } from './ModalWrapper';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';

export type Props = {
  closeAllModals?: () => void;
  ariaLabelledBy?: string;
} & ModalWrapperProps;

export const MyStoreModal = ({ closeStoreModal, closeModalOnBack, closeAllModals }: Props) => {
  const { data: storeDetails } = useStoreDetailsData();

  return (
    <ModalWrapper
      closeStoreModal={closeStoreModal}
      closeModalOnBack={closeModalOnBack}
      ariaLabelledBy="my-store-header"
    >
      {({ backBtnHandler }) => (
        <MyStore
          closeStoreModal={closeAllModals || closeStoreModal}
          backBtnHandler={backBtnHandler}
          storeDetails={storeDetails}
        />
      )}
    </ModalWrapper>
  );
};
