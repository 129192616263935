/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import dayjs from 'dayjs';
import { useLabels } from '@/hooks/useLabels';
import { SmartLink as Link } from '@/utils/smartLink';
import styles from './styles.module.scss';
import cx from 'classnames';
import CountDownV2 from './CountDown';
import EstDeliveryDate from '../FulfillmentOptionsV2/EstDeliveryDate';
import isToday from 'dayjs/plugin/isToday';
import isTommorow from 'dayjs/plugin/isTomorrow';
import { useMonetateDecisionFlag } from '@/features/kibo';
dayjs.extend(isToday);
dayjs.extend(isTommorow);

type Props = {
  estDeliveryDateString?: string;
  cutOffDateString: string;
  arrivesByLabel: string;
  displayAmPmFormat: boolean;
  shippingArriveBy?: string;
  // hrs mins if false or null
  styleOverride?: any;
  isCheckoutPage?: boolean;
  isCartPage?: boolean;
  orderByLabel?: string;
  isNdd: boolean;
};
const labels = {
  labels_item_free_delivey: 'label_fulfillment_options_free_delivery',
  label_today: 'label_fulfilment_options_today',
  label_tomorrow: 'label_fulfilment_options_tomorrow',
  label_to_receive_by: 'label_fulfilment_options_to_receive_by',
  label_by: 'label_fulfilment_options_by',
  label_to_receive: 'label_fulfilment_options_to_receive',
  label_order: 'label_fulfilment_options_order',
  label_ndd: 'label_shipping_next_day_delivery',
  label_free_for_orders: 'label_fulfillment_options_free_orders_over_35',
  label_order_within: 'labels_order_within',
  label_learn_more: 'label_learn_more',
};

export function OrderWithinHrsMinArrivesMessage({
  estDeliveryDateString,
  cutOffDateString,
  displayAmPmFormat,
  styleOverride,
  isCheckoutPage,
  isCartPage,
  isNdd,
}: Props) {
  const labelMap = useLabels(labels);
  const cutOffDateTime = dayjs(cutOffDateString);
  const estDeliveryDateTime = dayjs(estDeliveryDateString);
  const showNewFfmText = useMonetateDecisionFlag('displayNewFulfillmentText');
  if (!cutOffDateTime.isValid()) {
    return null;
  }
  const getOrderByNddLabel = () => {
    return (
      <span>
        <span className={styles.subtext}>{labelMap.label_order_within} </span>
        <strong>
          <CountDownV2
            toDate={cutOffDateTime.toDate()}
            amPm={displayAmPmFormat}
            isCheckoutPage={isCheckoutPage}
          />
        </strong>{' '}
        {!!estDeliveryDateString && (
          <span>
            <span data-testid="estimated-delivery-date-label" className={styles.subtext}>
              {labelMap.label_to_receive_by}{' '}
            </span>
            <span
              data-testid="estimated-delivery-date"
              className={cx(showNewFfmText ? styles.subTextBold : styles.deliveryMessage, {
                [styles.deliveryMessageCapitalized]:
                  !estDeliveryDateTime.isToday() && !estDeliveryDateTime.isTomorrow(),
                [styles.lowercase]:
                  !isCheckoutPage &&
                  (estDeliveryDateTime.isToday() || estDeliveryDateTime.isTomorrow()),
              })}
            >
              <EstDeliveryDate estimatedDeliveryDate={estDeliveryDateString} />.
            </span>
          </span>
        )}
        {!showNewFfmText && (
          <span>
            {' '}
            <Link target="_blank" to="/lp/next-day-delivery" className={styles.subTextBold}>
              {labelMap.label_learn_more}
            </Link>
          </span>
        )}
      </span>
    );
  };
  const getOrderBySddLabel = () => {
    return (
      <span>
        <span className={styles.subtext}>{labelMap.label_order}</span>{' '}
        <span className={styles.subTextBold}>{labelMap.label_by}</span>
        <CountDownV2
          toDate={cutOffDateTime.toDate()}
          amPm={displayAmPmFormat}
          isCheckoutPage={isCheckoutPage}
        />{' '}
        <span data-testid="estimated-delivery-date-label" className={styles.subtext}>
          {labelMap.label_to_receive}{' '}
        </span>
        <span
          data-testid="estimated-delivery-date"
          className={showNewFfmText ? styles.subTextBold : styles.deliveryMessage}
        >
          {estDeliveryDateTime.isToday() && labelMap.label_today}
          {estDeliveryDateTime.isTomorrow() && labelMap.label_tomorrow}
          {'. '}
        </span>
        {(!isCartPage || showNewFfmText) && (
          <span>
            <Link target="_blank" to="/lp/same-day-delivery" className={styles.desktopBoldText}>
              {labelMap.label_learn_more}
            </Link>
            {'.'}
          </span>
        )}
      </span>
    );
  };

  return (
    <span
      data-testid="orderWithin"
      className={styles.shippingOptionDetailV3}
      style={styleOverride ? styleOverride : null}
    >
      {isNdd && getOrderByNddLabel()}
      {!isNdd && getOrderBySddLabel()}
    </span>
  );
}
