/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const getDealPrice = (
  lowestPrice: number | undefined,
  strikeThroughPricingEnabled: boolean,
  discountedPrice: string | null,
  configurableSku: boolean,
  retailPrice: string[]
): string[] => {
  const [lowestPriceDollars, lowestPriceCents] = lowestPrice?.toString().split('.') ?? [];
  const [retailPriceDollars, retailPriceCents] = retailPrice;

  if (strikeThroughPricingEnabled && discountedPrice) {
    let priceArray = discountedPrice.toString().split('.');
    return [priceArray[0], priceArray[1] ? priceArray[1].padEnd(2, '0') : '00'];
  } else {
    if (configurableSku) {
      return [lowestPriceDollars, lowestPriceCents ? lowestPriceCents.padEnd(2, '00') : '00'];
    } else {
      return [retailPriceDollars, retailPriceCents ? retailPriceCents.padEnd(2, '00') : '00'];
    }
  }
};
