/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import cx from 'classnames';
import { Button } from '@/components/Button';
import Image from '@/components/NextImage';
import { storeModalType } from '../../constants/storeModal';
import { Label } from '@/features/i18n';
import { useLabels } from '@/hooks/useLabels';
import { fulfillmentLabels } from '../../constants/fulfillmentConstants';
import styles from './styles.module.scss';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { SearchStoreModal } from '../Store/StoreModal/SearchStoreModal';
import { MyStoreModal } from '../Store/StoreModal/MyStoreModal';
import logger from '@/utils/logger';

type ParentProps = {
  available: boolean;
  renderType:
    | typeof storeModalType.myStore
    | typeof storeModalType.searchStore
    | typeof storeModalType.nearByStore;
  skuId?: string;
  productName?: string;
  handleInstockAnalytics?: () => any;
};
type ChildProps = {} & ParentProps;
const labelMap = {
  lblSelectStore: fulfillmentLabels.label_shipping_SelectStore,
  lblCheckNearbyStores: fulfillmentLabels.label_shipping_CheckNearbyStores,
  lblInStockSisterStores: fulfillmentLabels.label_inStock_sisterStores,
  lblAtNearByStore: fulfillmentLabels.label_shipping_AtNearByStores,
};

export default function FulfillmentControlStoreModalV2({
  available,
  renderType,
  handleInstockAnalytics,
}: ChildProps) {
  const [storeModalOpen, setStoreModalOpen] = React.useState(false);

  const toggleStoreModal = () => {
    if (renderType === storeModalType.nearByStore) {
      logger.error({
        message: 'NearbyStoreModal was removed',
        meta: {
          source: 'OldFulfillmentOptionsV2',
        },
      });
    } else {
      setStoreModalOpen((state) => !state);
    }
  };

  const { data: storeData } = useStoreDetailsData();
  const storeAddress = storeData?.address1;
  const labels = useLabels(labelMap);
  const storeAddressorModalType =
    renderType === storeModalType.myStore
      ? storeAddress
      : renderType === storeModalType.searchStore
      ? labels.lblSelectStore
      : labels.lblAtNearByStore;
  const availableAtNearbyStores = !available && renderType === storeModalType.nearByStore;
  return (
    <div>
      <div className={styles.searchStoreButton} data-testid="fulfillment-store-button-container">
        {availableAtNearbyStores && (
          <div className={styles.inStockSisterStoreContainer}>
            <Image
              src={'/images/green-tickmark.svg'}
              alt={''}
              width={14}
              height={11}
              layout="fixed"
            />
            <span className={styles.inStockSisterStoreLabel} data-testid="sister-store-label">
              <Label label={labels.lblInStockSisterStores} />
            </span>
          </div>
        )}
        <Button
          variant="link"
          customClass={cx(styles.storeDetails, {
            [styles.bold]: availableAtNearbyStores,
          })}
          data-testid="search-store-button"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            e.nativeEvent.stopImmediatePropagation();
            toggleStoreModal();

            if (handleInstockAnalytics && typeof handleInstockAnalytics == 'function') {
              handleInstockAnalytics();
            }
          }}
          aria-label={storeAddressorModalType as any}
        >
          {storeAddressorModalType as any}
        </Button>
        {storeModalOpen ? (
          <>
            {renderType === storeModalType.searchStore ? (
              <SearchStoreModal closeStoreModal={toggleStoreModal} />
            ) : null}
            {renderType === storeModalType.myStore ? (
              <MyStoreModal closeStoreModal={toggleStoreModal} />
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
}
