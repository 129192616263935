/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxios } from '@/lib/axios';
import { type QueryClient } from '@tanstack/react-query';
import { requestBaseURL } from '@/config/serviceAPI';
import { createQuery } from '@/utils/createReactQuery';
import { type AxiosInstance } from 'axios';

type ConfigFlagsResponse = {
  IS_SPNSRD_ADVRTSMT_FL: string;
};
type Options = {
  partNumber?: string;
};
const getConfigFlags = async ({ partNumber }: Options, axiosInstance?: AxiosInstance) => {
  const URL = `${requestBaseURL}/ecomm/b2c/v1/products/${partNumber}/config-flags`;
  const response = await getAxios(axiosInstance).get<ConfigFlagsResponse>(URL, {});
  return selector(response.data);
};

const selector = (data: ConfigFlagsResponse): ConfigFlagsResponse => data;

const { useData, prefetch, query } = createQuery('config-flags', getConfigFlags);

export const useConfigFlags = <T = ConfigFlagsResponse>(
  options: Options & {
    enabled?: boolean;
    keepPreviousData?: boolean;
    select?: (data: ConfigFlagsResponse) => T;
  }
) => {
  return useData({
    ...options,
    partNumber: options.partNumber,
    enabled: Boolean(options.partNumber && options.enabled),
  });
};

export const prefetchConfigFlags = (
  options: Options,
  queryClient: QueryClient,
  axiosInstance: AxiosInstance
) => {
  return prefetch(queryClient, options, axiosInstance);
};

export const getConfigFlagsFromCache = (options: Options, queryClient: QueryClient) => {
  return queryClient.getQueryData<ConfigFlagsResponse>(query.getFullKey(options));
};
