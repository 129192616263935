/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { requestBaseURL } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { AxiosInstance } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import actionConstant from '@/types/action';
import { useDispatch } from '@/hooks/redux/useDispatch';
import fetchMiniCartData from '@/actions/miniCart';
import type { AddToCartResponse, AddToCartData } from '@/types/reduxStore/cartUpdate';
import { getErrorCode, getErrorStatus, isAxiosError } from '@/utils/errorsHandling';
import { type AvailabilityInfoData } from '../interface';
import { getAvailabilityInfoKey } from './getAvailabilityInfo';

const URL = `${requestBaseURL}/ecomm/b2c/order/v4/item`;

type PatchAddToCartPayload = {
  skuId: string;
  productId: string | undefined;
  quantity: string;
  vehicleId: string | undefined;
  storeNumber: string | undefined;
  fulfillmentTypeId: number | undefined | null;
  commerceItemId?: string | undefined;
};

type Options = PatchAddToCartPayload;
// TODO: get isLoading from here
const patchOrderItem = async (options: Options, axiosInstance?: AxiosInstance) => {
  const { skuId, productId, quantity, fulfillmentTypeId, vehicleId, storeNumber, commerceItemId } =
    options;

  const response = await getAxios(axiosInstance).patch<AddToCartResponse>(URL, {
    skuId,
    productId,
    quantity,
    fulfillmentTypeId,
    vehicleId,
    storeNumber,
    commerceItemId,
  });
  return selector(response.data);
};

const selector = (data: AddToCartResponse): AddToCartData => data;

export const usePatchOrderItem = ({
  onAddToCartSuccess,
  onErrorCode,
}: {
  onAddToCartSuccess?: (addToCartPayload: PatchAddToCartPayload) => void;
  onErrorCode?: (errorCode: number | undefined, errorStatus: string | undefined) => void;
} = {}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      skuId,
      productId,
      quantity,
      fulfillmentTypeId,
      vehicleId,
      storeNumber,
      commerceItemId,
    }: PatchAddToCartPayload) =>
      patchOrderItem({
        skuId,
        productId,
        quantity,
        fulfillmentTypeId,
        vehicleId,
        storeNumber,
        commerceItemId,
      }),
    onMutate: ({ skuId }) => {
      dispatch({ type: actionConstant.UPDATE_ORDERITEM_REQUESTING });
      dispatch({ type: actionConstant.SET_LOADING_FOR_PRODUCT, skuId });
    },
    onSuccess: (data, payload) => {
      const { skuId, quantity } = payload;
      onAddToCartSuccess?.(payload);
      dispatch({ type: actionConstant.UPDATE_ITEM_QUANTITY_SUCCESS, data: { skuId, quantity } });
      dispatch(fetchMiniCartData(queryClient));
      queryClient.setQueriesData(
        getAvailabilityInfoKey(),
        (currentAvailabilityInfoData: AvailabilityInfoData | undefined) => {
          return currentAvailabilityInfoData
            ? {
                ...currentAvailabilityInfoData,
                oneFulfillment: data?.oneFulfillment,
              }
            : undefined;
        }
      );
    },
    onSettled: () => {
      dispatch({ type: actionConstant.SET_LOADING_FOR_PRODUCT });
    },
    onError: (err: unknown) => {
      if (isAxiosError(err)) {
        const httpStatusCode = getErrorStatus(err);
        const errorCode = getErrorCode(err);
        onErrorCode?.(httpStatusCode, errorCode);
      }
    },
  });
};
