/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { trackGetDirectionInteraction } from '@/utils/analytics/track/trackGetDirectionInteraction';
import { useLabels } from '@/hooks/useLabels';
import azCommonStyles from '@/theme/globals.module.scss';
import { getMapUrl } from '@/utils/urlHelpers';
import cx from 'classnames';
import { type SisterStoreDetail } from '../../api/postSisterStores';
import styles from './flyout.module.scss';

type GetDirectionsBlockProps = {
  sisterStoreDetail: SisterStoreDetail | undefined;
};

const labelMap = {
  getDirections: 'label_Footer_modal_GetDirections',
  getDirectionsToSisterStore: 'label_get_directions_to_sister_store',
};

export const GetDirectionsBlock = ({ sisterStoreDetail }: GetDirectionsBlockProps) => {
  const labels = useLabels(labelMap);
  return (
    <div className={azCommonStyles['az-margin-bottom-xs']}>
      <a
        href={getMapUrl(`${sisterStoreDetail?.latitude},${sisterStoreDetail?.longitude}`)}
        className={cx(styles.getDirectionLink, azCommonStyles['az-margin-right-s'])}
        data-testid="sister_store_get_directions"
        target="_blank"
        rel="noopener noreferrer"
        onClick={trackGetDirectionInteraction}
        aria-label={`${labels.getDirectionsToSisterStore} ${sisterStoreDetail?.storeNumber} ${sisterStoreDetail?.address1} ${sisterStoreDetail?.city} ${sisterStoreDetail?.state} ${sisterStoreDetail?.zip}`}
      >
        {labels.getDirections}
      </a>
    </div>
  );
};
