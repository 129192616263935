/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type IItem } from '@/components/AZCustomComponent/BreadcrumbSTARC/utils/isBreadcrumbModel';

const formatBreadcrumbsForSTARC = (breadcrumbs?: IItem[] | null, shouldModify = false): IItem[] => {
  let defaultBreadcrumbs = breadcrumbs || [];

  if (shouldModify) {
    defaultBreadcrumbs = defaultBreadcrumbs.slice(1);
    defaultBreadcrumbs.pop();
  }

  return defaultBreadcrumbs.map((breadcrumb) => ({
    ...breadcrumb,
    label: breadcrumb.displayName,
  }));
};

export { formatBreadcrumbsForSTARC };
