/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import { cartConstants } from '@/constants/cart';

export function convertOrderTypeToFulfillmentType(orderType: string): number {
  const fulfillmentTypeId =
    orderType && orderType === cartConstants.ONLINEORDER
      ? FULFILLMENT_METHODS.ONLINEORDER
      : FULFILLMENT_METHODS.STOREORDER;
  return fulfillmentTypeId;
}
