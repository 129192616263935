/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useFeatureFlag } from '@/features/globalConfig';
import { useHeaderData } from '@/features/header/api/getHeader';

export const usePriceHiding = () => {
  const isAllPriceHidden = useFeatureFlag('HIDE_PRICE_ALL') === 'true';
  const storesList = useFeatureFlag('HIDE_PRICE_FOR_STORES') || '';
  const storesNumbers = storesList.split(',').map((store) => store.trim());
  const { data: selectedStore } = useHeaderData({
    select: (headerData) => headerData.storeNumber,
  });

  if (isAllPriceHidden || (selectedStore && storesNumbers.includes(selectedStore))) {
    return true;
  }

  return false;
};
