/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { cloneElement, Fragment, useEffect, useRef, useState } from 'react';
import root from 'window-or-global';
import cx from 'classnames';
import { stringifyUrl, parseUrl } from '../../../utils/urlHelpers';
import { useRouter } from 'next/router';
import azCommonStyles from '../../../theme/globals.module.scss';
import { Label } from '@/features/i18n';
import { isMobile } from '../../../utils/common';
import { rewardsConstants } from '../../../constants/rewards';
import { productInfoConstants } from '../../../constants/productInfoConstants';
import { dealConstants } from '../../../constants/deals';
import type { RenderButtonProps } from '../../FlatPagination/FlatPagination';
import styles from './styles.module.scss';
import { clickTrack } from '@/utils/analytics/clickTrack';
import LeftPaginationArrow from '@/public/images/arrow-left.svg';
import LeftPaginationArrowInactive from '@/public/images/arrow-left-grey-14.svg';
import RightPaginationArrow from '@/public/images/arrow-right.svg';
import RightPaginationArrowInactive from '@/public/images/arrow-right-grey-14.svg';
import FlatPagination from '../../FlatPagination/FlatPagination';

const trackPaginationOrderHistory = (pageNumber: number) => {
  clickTrack({
    pageNumberSelected: pageNumber,
    eventType: 'paginationOrderHistoryInteraction',
  });
};

type Props = {
  offset: number;
  updateOffset?: (offset: number) => void;
  limit: number;
  total: number;
  performFetch?: (offset: number, limit: number, pageNumber?: number) => void;
  pageName?: string;
  disableScroll?: boolean;
  reduced?: boolean;
};

const PaginationComp = (props: Props) => {
  const maxPageNumber =
    Math.floor(props.total / props.limit) + (props.total % props.limit === 0 ? 0 : 1);
  const currentPage = Math.floor(props.offset / props.limit) + 1;
  const [pagination, setPagination] = useState<React.ReactNode>(null);
  const scrolled = useRef(false);
  const router = useRouter();
  const { asPath } = router;
  const { url, query } = parseUrl(asPath);
  const isSearchResults = props.pageName === productInfoConstants.SEARCH_RESULTS;

  const loadMUFlatPaginationOnScroll = (): void => {
    if (!scrolled.current) {
      scrolled.current = true;

      setPagination(
        <FlatPagination
          id="paginationButton"
          className={styles.listStyle}
          component="ul"
          offset={props.offset}
          limit={props.limit}
          total={props.total}
          reduced={typeof props.reduced === 'undefined' ? isMobile() : props.reduced}
          nextPageLabel={
            <Fragment>
              <span
                className={cx(azCommonStyles['az-button-text'], styles.next)}
                id="nextBtnLabel"
                data-testid="next-pagination-button"
              >
                <Label label="label_MyAccountMyProfile_serviceHistory_Next" />
              </span>
              {currentPage === maxPageNumber ? (
                <RightPaginationArrowInactive aria-hidden />
              ) : (
                <RightPaginationArrow aria-hidden />
              )}
            </Fragment>
          }
          previousPageLabel={
            <Fragment>
              {currentPage === 1 ? (
                <LeftPaginationArrowInactive aria-hidden />
              ) : (
                <LeftPaginationArrow aria-hidden />
              )}
              <span
                className={cx(azCommonStyles['az-button-text'], styles.prev)}
                id="previousBtnLabel"
                data-testid="previous-pagination-button"
              >
                <Label label="label_MyAccountMyProfile_orderhistory_Prev" />
              </span>
            </Fragment>
          }
          renderButton={({ page, children, offset }: RenderButtonProps) => {
            const searchParam = isSearchResults
              ? {
                  currentPage: page,
                }
              : {
                  pageNumber: page,
                };
            return (
              <li className={styles.liStyle}>
                {cloneElement(children, {
                  onClick: (e: any) => {
                    e.preventDefault();
                    handleClick(offset, page);
                  },
                  href: stringifyUrl({
                    url,
                    query: { ...query, ...searchParam },
                  }),
                })}
              </li>
            );
          }}
        />
      );
      root.removeEventListener('scroll', loadMUFlatPaginationOnScroll);
    }
  };

  useEffect(() => {
    root.addEventListener('scroll', loadMUFlatPaginationOnScroll);
    return () => {
      scrolled.current = false;
      root.removeEventListener('scroll', loadMUFlatPaginationOnScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);
  useEffect(() => {
    const buttonList = document.getElementById('paginationButton')?.querySelectorAll('button');
    const buttonListArray = buttonList ? [...buttonList] : [];

    if (Array.isArray(buttonListArray)) {
      buttonListArray.forEach((currentButton) => {
        if (Number(currentButton.textContent) === currentPage && !currentButton.ariaCurrent) {
          currentButton.ariaCurrent = 'page';

          currentButton.ariaLabel = `page ${currentPage}`;
          const links = document.createElement('a');
          links.innerHTML = currentButton.innerHTML;

          for (let i = 0; i < currentButton.attributes.length; i++) {
            const attribute = currentButton.attributes.item(i);

            if (attribute?.nodeName !== 'type') {
              // @ts-expect-error fix type
              links.setAttributeNode(attribute.cloneNode(true));
            }
          }

          const parent = currentButton.parentNode;

          if (parent) {
            parent.replaceChild(links, currentButton);
          }
        }
      });
    }
  }, [currentPage, maxPageNumber]);

  function handleClick(offset: number, newPageNumber: number) {
    const { updateOffset, performFetch } = props;
    updateOffset ? updateOffset(offset) : null;
    const pageNumber = offset / props.limit + 1;

    /* Analytics Start */
    if (props.pageName === dealConstants.DEALS) {
      performFetch?.(offset, newPageNumber);
    } else if (props.pageName === 'DEALS_MODAL') {
      performFetch?.(offset, newPageNumber);
    } else if (props.pageName === rewardsConstants.ORDERHISTORY) {
      trackPaginationOrderHistory(pageNumber);
      performFetch?.(offset, pageNumber);
    } else if (props.pageName === productInfoConstants.productShelfName) {
      performFetch?.(offset, newPageNumber);
    } else if (props.pageName === productInfoConstants.SEARCH_RESULTS) {
      performFetch?.(offset, newPageNumber);
    } else if (root.utag_data?.pageType === productInfoConstants.catalogSearchResults) {
      root.utag?.view(root.utag_data);
    } else {
      performFetch?.(offset, props.limit, newPageNumber);
    }
  }

  return (
    <nav aria-label="pagination" data-testid="pagination-container" className={styles.pagination}>
      {pagination}
      {isSearchResults && <div id="qualtrics-embedded-feedback" />}
    </nav>
  );
};

export default PaginationComp;
