/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect, useRef, useState } from 'react';
import root from 'window-or-global';
import { useLabels } from '@/hooks/useLabels';
import { fetchOrderDetails } from '@/actions/details';
import type { MappedOrderLineItem } from '@/utils/itemHelpers/types';
import type {
  ConstraintSelectionHash,
  Deal,
  DealConstraint,
  DealConstraintSkuDetails,
  SkuDetails,
} from '../../../interface';
import { useDealConstraintSkuDetails } from '../../../api/getDealConstraintSkuDetails';
import { useDeal } from '../../../api/getDeal';
import { BundleDealDetails } from './Details';
import { BundleDealConstraintPicker } from './ConstraintPicker';
import { clickTrack } from '@/utils/analytics/clickTrack';
import type { CustomLinkName } from '@/types/analytics';
import { useDispatch } from '@/hooks/redux/useDispatch';
import { useHeaderData } from '@/features/header/api/getHeader';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { useAvailabilityInfoData } from '@/features/orders/api/getAvailabilityInfo';
import { usePostDealToCart } from '../../../api/postDealToCart';
import { getErrorCodeDescription } from '@/utils/errorsHandling';
import { useQueryClient } from '@tanstack/react-query';
import { useExecuteOnce } from '@/hooks/useExecuteOnce';

const formatToOriginalPartTypeIdList = (originalPartType = '0') => {
  const originalPartInt = Number(originalPartType);
  const charArray = [...originalPartType];
  return `${originalPartInt}-${charArray[0] === '0' ? '0' : '10'}`;
};

const trackAddToCartDealBundle = (
  dealInfo: { skuId: string; dealId: string; description: string },
  itemsToAdd: Array<{
    skuId: string;
    constraintId: string;
    productId: string;
    originalPartType: string;
    vehicleId: string | undefined;
    storeNumber: string | undefined;
    fulfillmentTypeId: string | number | null | undefined;
    quantity: number;
    price: number;
    brandName: string;
  }>,
  cartItems: MappedOrderLineItem[],
  analyticsPageType: string
) => {
  let productFulfillmentTypeList: string[] = [];
  let productIdList: string[] = [];
  let originalPartTypeIdList: string[] = [];
  let productRepositoryIdList: string[] = [];
  let productBrandList: string[] = [];
  let productPriceList: string[] = [];
  itemsToAdd.map((item) => {
    const matchingCartItem = cartItems.find((cartItem) => cartItem.skuId === item.skuId);

    if (matchingCartItem && matchingCartItem.itemQuantity === item.quantity.toString()) {
      return;
    }

    if (item.fulfillmentTypeId) {
      productFulfillmentTypeList = [
        ...productFulfillmentTypeList,
        item.fulfillmentTypeId.toString(),
      ];
    }
    productIdList = [...productIdList, item.skuId];
    productRepositoryIdList = [...productRepositoryIdList, item.productId];
    originalPartTypeIdList = [
      ...originalPartTypeIdList,
      formatToOriginalPartTypeIdList(item.originalPartType),
    ];
    productBrandList = [...productBrandList, item.brandName];
    productPriceList = [...productPriceList, new Intl.NumberFormat('en-US').format(item.price)];
  });
  const { pageName = '', pageType = '', siteSection = '' } = root.utag_data || {};
  const cartAddToCartDealBundle: CustomLinkName = 'Add to Cart - Deal Bundle';
  const data = {
    eventType: 'addToCart',
    productFulfillmentTypeList,
    productIdList,
    dealId: dealInfo.dealId,
    dealName: dealInfo.description,
    originalPartTypeIdList,
    productRepositoryIdList,
    productBrandList,
    productPriceList,
    pageName,
    pageType,
    siteSection,
    customLinkName: `${analyticsPageType ?? pageType}: ${cartAddToCartDealBundle}`,
  };
  clickTrack(data);
};

type ConstraintForSwap = {
  constraint: DealConstraint;
  constraintSkuDetails: DealConstraintSkuDetails;
};

const labelMap = {
  label_deal_bundle_unexpected_error: 'label_deal_bundle_unexpected_error',
};

type Props = {
  analyticsPageType: string;
  parentSkuDetails: SkuDetails;
  skuFulfillmentTypeId: number | string | null | undefined;
  skuIsVehicleSpecific: boolean | undefined;
  deal: Deal;
  onAddedToCart: () => void;
  onBackClick: () => void;
  onClose: () => void;
  cartItems: MappedOrderLineItem[];
  trackOnDealSelection: (dealInfo: { dealId: string; name: string | null }) => void;
  handleErrorAnalytics: (a: string, b: string) => void;
  dealItemSkuSet: Set<string>;
  setminiCartDealData: (value: React.SetStateAction<Set<string>>) => void;
};

export const BundleDeal = ({
  analyticsPageType,
  parentSkuDetails,
  skuFulfillmentTypeId,
  skuIsVehicleSpecific,
  deal,
  onAddedToCart,
  onBackClick,
  onClose,
  cartItems,
  trackOnDealSelection,
  handleErrorAnalytics,
  dealItemSkuSet,
  setminiCartDealData,
}: Props) => {
  const queryClient = useQueryClient();
  const labels = useLabels(labelMap);
  const { id: skuId } = parentSkuDetails;
  const { id: dealId } = deal;

  const dispatch = useDispatch();
  const [addToCartError, setAddToCartError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const isFirst = useRef(true);
  const [constraintSelections, setConstraintSelections] = useState<ConstraintSelectionHash | null>(
    null
  );
  const [constraintForSwap, setConstraintForSwap] = useState<ConstraintForSwap | null>(null);
  const { data: headerData, refetch: refetchHeaderData } = useHeaderData();
  const { refetch: refetchAvailabilityInfo } = useAvailabilityInfoData();
  const storeNumber = headerData?.storeNumber;
  const preferredVehicleId = usePreferredVehicle()?.catalogVehicleId;
  const { data: dealDetailsData, isError: isDealError } = useDeal({
    dealId,
    skuId,
    fulfillmentOption: String(skuFulfillmentTypeId),
    onError: (err) =>
      handleErrorAnalytics(err.response?.statusText ?? '', err.response?.status.toString() ?? ''),
  });

  const { mutateAsync: postDealAddToCart } = usePostDealToCart();

  let selectedSkus: string[] = [];

  if (isFirst.current) {
    if (dealDetailsData?.constraints) {
      let parentSkuFound = false;

      selectedSkus = Object.values(dealDetailsData.constraints).map((constraintObj) => {
        const constraint: DealConstraint = constraintObj;

        if (parentSkuFound) {
          return constraint.skus[0];
        } else {
          parentSkuFound = !!constraint.skus.find((s) => s === skuId);

          if (parentSkuFound) {
            return skuId;
          } else {
            return constraint.skus[0];
          }
        }
      });
    }
  } else {
    if (constraintSelections) {
      selectedSkus = Object.values(constraintSelections).map((constraintSkuDetailsObj) => {
        const constraintSkuDetails: DealConstraintSkuDetails = constraintSkuDetailsObj;
        return constraintSkuDetails.id;
      });
    }
  }

  const { data: dealConstraintSkuDetails, isError: isDealConstraintError } =
    useDealConstraintSkuDetails({
      dealId,
      parentSku: skuId,
      fulfillmentOption: String(skuFulfillmentTypeId),
      constraintSkusList: selectedSkus,
      onError: (err) =>
        handleErrorAnalytics(err.response?.statusText ?? '', err.response?.status.toString() ?? ''),
    });

  if (isFirst.current && dealConstraintSkuDetails && dealDetailsData) {
    const skuArray: DealConstraintSkuDetails[] = Object.values(dealConstraintSkuDetails.skus);
    const initialConstraintSelections: Record<string, DealConstraintSkuDetails> = {};

    Object.values(dealDetailsData.constraints).forEach((constraint, index) => {
      const defaultSkuId = selectedSkus[index];
      const sku = skuArray.find((sku) => sku.id === defaultSkuId);
      if (sku) {
        initialConstraintSelections[constraint.id] = sku;
      }
    });
    isFirst.current = false;
    setConstraintSelections(initialConstraintSelections);
  }

  useEffect(() => {
    if (constraintSelections && dealItemSkuSet.size < Object.entries(constraintSelections).length) {
      Object.entries(constraintSelections).forEach((x) => {
        dealItemSkuSet.add(x[1].id);
      });
      setminiCartDealData(dealItemSkuSet);
    }
  }, [constraintSelections, dealItemSkuSet, setminiCartDealData]);

  useExecuteOnce({
    enabled: Boolean(deal.id && deal.name),
    fn: () => {
      trackOnDealSelection({
        dealId: deal.id,
        name: deal.name,
      });
    },
  });

  const addDealToCart = async () => {
    try {
      if (isSaving || !dealDetailsData || !constraintSelections) {
        return;
      }

      setAddToCartError(null);
      const { constraints, deals } = dealDetailsData;
      const dealInfo = {
        skuId,
        dealId,
        description: deals[dealId].description,
      };
      const itemsToAdd = Object.entries(constraintSelections).map(
        ([constraintId, constraintSelectionObj]) => {
          const constraintSelection: DealConstraintSkuDetails = constraintSelectionObj;
          const { minQty } = constraints[constraintId];
          const {
            id: skuId,
            productId,
            productBrandName: brandName,
            productTotalPrice: price,
            originalPartType,
          } = constraintSelection;

          return {
            skuId,
            constraintId,
            productId,
            originalPartType,
            vehicleId: preferredVehicleId,
            storeNumber,
            fulfillmentTypeId: skuFulfillmentTypeId,
            quantity: minQty,
            price,
            brandName,
          };
        }
      );
      setIsSaving(true);

      await postDealAddToCart({ skuId, dealId, selectedConstraints: itemsToAdd });

      await Promise.all([
        dispatch(fetchOrderDetails(undefined, queryClient)),
        refetchHeaderData(),
        refetchAvailabilityInfo(),
      ]);

      onAddedToCart();
      trackAddToCartDealBundle(dealInfo, itemsToAdd, cartItems, analyticsPageType);
    } catch (e) {
      setAddToCartError(getErrorCodeDescription(e) || labels.label_deal_bundle_unexpected_error);
    } finally {
      setIsSaving(false);
    }
  };

  const swapClickHandler = (
    constraint: DealConstraint,
    constraintSkuDetails: DealConstraintSkuDetails
  ) => {
    setConstraintForSwap({
      constraint,
      constraintSkuDetails,
    });
  };

  const updateSelectedItem = (constraintSkuDetails: DealConstraintSkuDetails) => {
    if (!constraintForSwap) {
      return;
    }

    const { constraint } = constraintForSwap;
    setConstraintForSwap(null);
    isFirst.current = false;
    dealItemSkuSet.clear();
    setConstraintSelections({
      ...constraintSelections,
      [constraint.id]: constraintSkuDetails,
    });
  };

  const onConstraintPickerBack = () => {
    setConstraintForSwap(null);
  };

  if (constraintForSwap) {
    return (
      <BundleDealConstraintPicker
        parentSkuDetails={parentSkuDetails}
        deal={deal}
        constraint={constraintForSwap.constraint}
        initialSelection={constraintForSwap.constraintSkuDetails}
        onUpdate={updateSelectedItem}
        onBackClick={onConstraintPickerBack}
        onClose={onClose}
        fulfillmentOption={String(skuFulfillmentTypeId)}
      />
    );
  }

  return (
    <BundleDealDetails
      parentSkuDetails={parentSkuDetails}
      skuIsVehicleSpecific={skuIsVehicleSpecific}
      deal={deal}
      dealDetails={dealDetailsData}
      constraintSelections={constraintSelections}
      isSaving={isSaving}
      onCartAddClick={addDealToCart}
      onConstraintSwapClick={swapClickHandler}
      onBackClick={onBackClick}
      onClose={onClose}
      loadError={
        isDealError || isDealConstraintError ? labels.label_deal_bundle_unexpected_error : null
      }
      addToCartError={addToCartError}
    />
  );
};
