/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { isSafari } from '../common';

const nativeSmoothScrollTo = (position: number) => {
  window.scroll({
    behavior: 'smooth',
    left: 0,
    top: position,
  });
}; // polyfilled smooth scrolling for IE, Edge & Safari

const smoothScrollTo = (position: number, duration: number) => {
  const element = document.scrollingElement || document.documentElement,
    startDate = +new Date();

  const easeInOutQuad = (t: number, b = 0, c: number, d: number) => {
    t /= d / 2;
    if (t < 1) {
      return (c / 2) * t * t + b;
    }
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const animateScroll = () => {
    const currentDate = +new Date();
    const currentTime = currentDate - startDate;

    element.scrollTop = easeInOutQuad(currentTime, 0, position, duration);

    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      element.scrollTop = position;
    }
  };

  animateScroll();
};

// detect support for the behavior property in ScrollOptions
const supportsNativeSmoothScroll = () => {
  if (typeof window !== 'undefined') {
    return 'scrollBehavior' in document.documentElement.style;
  }
};

// smooth scrolling stub
const scrollToElem = (position: number) => {
  if (position) {
    if (supportsNativeSmoothScroll() && !isSafari()) {
      nativeSmoothScrollTo(position);
    } else {
      smoothScrollTo(position, 600);
    }
  }
};

export default scrollToElem;
