/**
 * Copyright 2019-2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { Grid } from '../../Grid';
import styles from './styles.module.scss';

type Props = {
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
};

export default function ProductAvailabilityInfoSkeleton({ leftContent, rightContent }: Props) {
  return (
    <div className={styles.container}>
      <Grid container className={styles.wrapper}>
        <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
          {leftContent}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
          {rightContent}
        </Grid>
      </Grid>
    </div>
  );
}
