/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { AxiosInstance } from 'axios';
import { createQuery } from '@/utils/createReactQuery';
import { getAxios } from '@/lib/axios';
import logger from '@/utils/logger';
import {
  getErrorCode,
  getErrorCodeDescription,
  getErrorTitle,
  isAxiosError,
} from '@/utils/errorsHandling';
import type { FulfillmentSelectionData, FulfillmentSelectionResponse } from '../interface';

const URL = `${requestBaseURL}/ecomm/b2c/browse/v3/skus`;

type Options = {
  storeNumber: string | undefined;
  selectedVehicle: string | undefined;
  skuId: string | undefined;
  dealId: string | undefined;
};

const getDealDrawerFulfillmentSelection = async (
  options: Options,
  axiosInstance?: AxiosInstance
) => {
  const { selectedVehicle, storeNumber, skuId, dealId } = options;

  if (!skuId) {
    throw new Error('skuId is required');
  }

  if (!dealId) {
    throw new Error('dealId is required');
  }

  const vehicleParam = selectedVehicle ? `/vehicles/${selectedVehicle}` : '';
  const storeParam = storeNumber ? `/stores/${storeNumber}` : '';

  const response = await getAxios(axiosInstance).get<FulfillmentSelectionResponse>(
    `${URL}/${skuId}/deals/${dealId}/fulfillment-options${vehicleParam}${storeParam}`
  );

  return selector(response.data);
};

const selector = (data: FulfillmentSelectionResponse): FulfillmentSelectionData => data;

const { useData } = createQuery<FulfillmentSelectionData, Options>(
  'dealDrawerFulfillmentSelection',
  getDealDrawerFulfillmentSelection
);

export const useDealDrawerFulfillmentSelection = ({
  enabled = true,
  ...rest
}: Options & { enabled?: boolean }) => {
  return useData({
    enabled: Boolean(rest.skuId) && Boolean(rest.dealId) && enabled,
    ...rest,

    onError: (err) => {
      if (isAxiosError(err)) {
        const errorCode = getErrorCode(err) ?? err.code;
        const errorCodeDescription = getErrorCodeDescription(err) ?? getErrorTitle(err);

        logger.error({
          message:
            errorCode && errorCodeDescription
              ? `${errorCode}: ${errorCodeDescription}`
              : 'Unknown error',
        });
      }
    },
  });
};
