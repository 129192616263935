/**
 * Copyright 2021-2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getCartridgeType } from '@/utils/getCartridgeType';
import type { ProductFinder, SortingOption, ShelfBodyParts } from '@/types/legacy';

type SpecifiedShelfPageBodyItem = ResultsList | SortingOptionsContainer | Slot;

type UnspecifiedShelfPageBodyItem = Omit<SpecifiedShelfPageBodyItem, 'contentType'> & {
  contentType: string;
};

export type ResultsList = {
  contentType: 'ResultsList';
  '@type': string;
} & ShelfBodyParts['resultsList'];

export type SortingOptionsContainer = {
  contentType: 'SortingOptionsContainer';
  '@type': string;
  sortingOptions: SortingOption[];
  quickFilter: boolean;
};

type Slot = {
  contentType: 'Slot';
  '@type': string;
  contents?: Array<
    | ProductFinder
    | {
        refinementList: Array<{ label: string }>;
        mobile: boolean;
        tablet: boolean;
        desktop: boolean;
        '@type': string;
        contents: [
          {
            '@type': string;
            refinementList: Array<{ label: string }>;
          }
        ];
      }
  >;
};

const isShelfPageBodyItem = (item: { contentType: string }): item is SpecifiedShelfPageBodyItem => {
  return ['ResultsList', 'SortingOptionsContainer', 'Slot'].includes(item.contentType);
};

export const getShelfBodyParts = (
  shelfPageBody: Array<Omit<UnspecifiedShelfPageBodyItem, 'contentType'>>
): ShelfBodyParts => {
  const shelfPageBodyArray = shelfPageBody.map((item) => ({
    ...item,
    contentType: getCartridgeType(item['@type']),
    '@type': item['@type'],
  }));

  return shelfPageBodyArray.reduce<ShelfBodyParts>(
    (result, bodyCartridge, index) => {
      if (!isShelfPageBodyItem(bodyCartridge)) {
        return result;
      }

      const previousCartridge = index > 0 ? shelfPageBodyArray[index - 1] : undefined;

      switch (bodyCartridge.contentType) {
        case 'ResultsList':
          result.resultsList = bodyCartridge;
          break;

        case 'SortingOptionsContainer':
          result.sortingOptions = {
            options: bodyCartridge.sortingOptions || [],
            quickFilter: bodyCartridge.quickFilter,
          };
          break;

        case 'Slot':
          const cartridgeData =
            'contents' in bodyCartridge ? bodyCartridge.contents?.[0] : undefined;

          if (
            cartridgeData &&
            cartridgeData['@type'] === 'ShelfPageBody_ProductFinder' &&
            'ProductFinder' in cartridgeData &&
            cartridgeData.ProductFinder.length
          ) {
            result.productFinderData = {
              ...(cartridgeData || {}),
              isAfterYmme: Boolean(
                previousCartridge && previousCartridge['@type'] === 'ShelfPageBody_YMME'
              ),
            };
          } else if (
            cartridgeData &&
            cartridgeData['@type'].includes('ProductFilter') &&
            'refinementList' in cartridgeData &&
            cartridgeData.refinementList.length
          ) {
            result.productFilterData = cartridgeData;
          }
      }

      return result;
    },
    {
      sortingOptions: {
        options: [],
        quickFilter: false,
      },
      productFinderData: null,
      productFilterData: null,
    }
  );
};
