/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useFeatureFlag } from '@/features/globalConfig';
import { useLabel } from '@/hooks/useLabels';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { View, Text, Tooltip } from '@az/starc-ui';
import { useRouter } from 'next/router';
import styles from './ProductCrossReference.module.scss';
import { useEffect, useRef } from 'react';

export const ProductCrossReference = ({
  oemPartNumber,
  oemBrandName,
}: {
  oemBrandName: string | undefined;
  oemPartNumber: string;
}) => {
  const oemBrandNameLabel = useLabel('label_oem_brand_name');
  const crossReferenceLabel = useLabel('label_cross_reference_num');
  const crossReferenceTooltipBodyLabel = useLabel('label_cross_reference_tooltip_body');
  const whatIsItLabel = useLabel('label_cart_lineItem_WhatIsIt');
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const router = useRouter();
  const isPDP = router.pathname === '/products/[...slug]';
  const crossReferenceContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentContainer = crossReferenceContainerRef.current;

    const handleClick = (e: MouseEvent) => {
      /* eslint-disable */
      // @ts-expect-error this is due to DOM types missing from the project
      e?.target?.parentElement?.querySelector('[data-testid="st-tooltip-button"]')?.click();
      /* eslint-enable */
    };

    currentContainer?.addEventListener('click', handleClick);

    return () => {
      currentContainer?.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    const currentContainer = crossReferenceContainerRef.current;

    const handleClick = (e: MouseEvent) => {
      /* eslint-disable */
      // @ts-expect-error this is due to DOM types missing from the project
      e?.target?.parentElement?.querySelector('[data-testid="st-tooltip-button"]')?.click();
      /* eslint-enable */
    };

    currentContainer?.addEventListener('click', handleClick);

    return () => {
      currentContainer?.removeEventListener('click', handleClick);
    };
  }, []);

  const showOemBrandName = useFeatureFlag('IS_OEM_BRAND_NAME_ENABLED') === 'true';
  return (
    <View direction="column" gap={0} divided={isPDP}>
      <View
        direction="row"
        className={styles.crossReferenceContainer}
        justify="start"
        align="center"
        as="div"
        attributes={{
          ref: crossReferenceContainerRef,
        }}
      >
        <Text
          lineHeight="125"
          color="500"
          size={isPDP ? (isMobile ? '075' : '087') : '075'}
          className={styles.xLargeOverride}
        >
          {crossReferenceLabel}
        </Text>
        <Text
          lineHeight="125"
          size={isPDP ? (isMobile ? '075' : '087') : '075'}
          className={styles.xLargeOverride}
        >
          {oemPartNumber}
        </Text>
        <View.Item className={styles.tooltipContainer}>
          <Tooltip
            svgSize={4.5}
            headerText={whatIsItLabel}
            bodyText={crossReferenceTooltipBodyLabel}
          />
        </View.Item>
      </View>
      {Boolean(showOemBrandName && oemBrandName) && (
        <View direction="row" gap={1}>
          <Text
            lineHeight="125"
            color="500"
            size={isPDP ? (isMobile ? '075' : '087') : '075'}
            className={styles.xLargeOverride}
          >
            {oemBrandNameLabel}
          </Text>
          <Text
            lineHeight="125"
            size={isPDP ? (isMobile ? '075' : '087') : '075'}
            className={styles.xLargeOverride}
          >
            {oemBrandName}
          </Text>
        </View>
      )}
    </View>
  );
};
