/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { useLabel, useLabels } from '@/hooks/useLabels';
import ContentContainer from '@/components/AZCustomComponent/ContentContainer';
import styles from '../styles.module.scss';
import { getErrorDetails, getErrorMessage } from '@/utils/errorsHandling';

type Props = {
  error?: unknown;
  onClick: () => void;
};

const labelMap = {
  label_items: 'label_ProductDisplayPage_body_Items',
};

export function AffinityErrorNotification({ error, onClick }: Props) {
  const labels = useLabels(labelMap);

  const amountOfUnavailableItems =
    getErrorDetails<Array<Record<string, string>>>(error)?.length ?? 0;
  return (
    <div className={styles.notificationContainer}>
      <ContentContainer type="shadowed" withPadding withMargin className={styles.errorShadow}>
        <div className={styles.notificationMessage}>
          <div className={styles.iconAndText}>
            <span className={styles.errorIcon} />
            <div>
              <div className={styles.notificationTitle}>{`${amountOfUnavailableItems} ${
                labels.label_items
              } ${useLabel(getErrorMessage(error) ?? '')}`}</div>
              <div className={styles.notificationSubtitle}>
                {useLabel(
                  getErrorDetails<Array<Record<string, string>>>(error)?.at(0)?.errorMessageKey ??
                    ''
                )}
              </div>
            </div>
          </div>
          <button className={styles.closeNotificationButton} onClick={onClick}>
            x
          </button>
        </div>
      </ContentContainer>
    </div>
  );
}
