/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { PageName } from '@/types/analytics';
import { clickTrack } from '@/utils/analytics/clickTrack';

export const trackReadMoreLinkPdp = (readmoreLinkName: PageName) => {
  const data = {
    infoLinkName: readmoreLinkName,
    eventType: 'readMoreInteractionPDP',
  };
  clickTrack(data);
};
