/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { PageBodyItem, PageBodyItemWithPriority } from '../interface';

export const prioritizeLCP = (
  data: PageBodyItem[] | undefined
): PageBodyItemWithPriority[] | undefined => {
  let priorityBannerAssigned = false;
  let hasProductDetails = false;

  return data?.map((block) => {
    if (block?.product_details && !hasProductDetails) {
      hasProductDetails = true;
    }
    if (block.banner?.banner?.[0]?.asset_size && !priorityBannerAssigned && !hasProductDetails) {
      priorityBannerAssigned = true;

      return {
        ...block,
        banner: {
          ...block.banner,
          banner: [
            {
              ...block.banner.banner[0],
              image: {
                ...block?.banner?.banner?.[0]?.image,
                priority: true,
              },
            },
          ],
        },
      };
    }
    return block;
  });
};
