/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { clickTrack } from '@/utils/analytics/clickTrack';

export const trackInStockNearYouLinkClick = () => {
  const { pageName = '', pageType = '', siteSection = '' } = window.utag_data ?? {};
  clickTrack({
    eventType: `inStockNearYouFlyoutCTA-${pageType}`,
    pageName,
    pageType,
    siteSection,
  });
};
