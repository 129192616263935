/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useAvailabilityInfoData } from '@/features/orders/api/getAvailabilityInfo';
import { GlobalConfig, useFeatureFlag } from '@/features/globalConfig';
import { useIsStoreSelected } from '@/features/header/hooks/useIsStoreSelected';

export const useShowDealsDrawerButton = ({
  skuId,
  flag,
  fulfillmentID,
  discounted,
}: {
  skuId: string | null;
  fulfillmentID: number;
  flag: keyof GlobalConfig;
  discounted: boolean;
}) => {
  const isStoreSelected = useIsStoreSelected();
  const { data: availabilityInfo } = useAvailabilityInfoData();
  const itemAvailability = availabilityInfo?.itemAvailability;
  const availabilityInfoItem = itemAvailability?.find((e) => e.skuId === skuId);
  const selectedFulfillmentGroup = availabilityInfoItem?.fulfillmentGroups.find((g) => g.selected);
  const selectedGroupFulfillmentOption = selectedFulfillmentGroup?.groupFulfillmentOptions.find(
    (o) => o.selected
  );

  const dealDrawerCartEnabled = useFeatureFlag(flag) === 'true';
  const strikeThroughPricingFlag = useFeatureFlag('SHOW_STRIKE_THROUGH_PRICING') === 'true';
  const showStrikeThroughPricing = strikeThroughPricingFlag && discounted;
  const shouldShowDealsButton: boolean =
    !!availabilityInfoItem &&
    !!selectedFulfillmentGroup &&
    !!selectedGroupFulfillmentOption &&
    !!fulfillmentID &&
    selectedGroupFulfillmentOption.fulfillmentTypeId === fulfillmentID &&
    selectedGroupFulfillmentOption.availableQty > 0 &&
    selectedGroupFulfillmentOption.dealsAvailableQty > 0 &&
    dealDrawerCartEnabled &&
    isStoreSelected &&
    !showStrikeThroughPricing;

  return { shouldShowDealsButton };
};
