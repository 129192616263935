/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import Image from '@/components/NextImage';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { Button } from '@az/starc-ui';
import Price from '@/components/AZCustomComponent/Price';
import { getFormattedDate } from '@/utils/date/formatDate';
import { extractPriceFromDescription } from '../../utils/extractPriceFromDescription';
import type { Deal } from '../../../interface';
import styles from './styles.module.scss';
import { translateMonthToSpanish } from '@/components/AZCustomComponent/Store/helpers';

type Props = {
  deal: Deal;
  onSelect: (deal: Deal) => void;
};

const labelMap = {
  label_deal_bundle_expires: 'label_deal_bundle_expires',
  label_deal_bundle_get_deal: 'label_deal_bundle_get_deal',
};

export const BundleDealOption = ({ deal, onSelect }: Props) => {
  const labels = useLabels(labelMap);
  const { dealImageUrl, name, description, expirationDate, id } = deal;
  const price = extractPriceFromDescription(description);
  const locale = useLocale();
  const spanishMonth = translateMonthToSpanish(getFormattedDate(expirationDate, 'MMMM'));
  const usMonth = getFormattedDate(expirationDate, 'MMMM');
  const date =
    locale == 'es-MX'
      ? getFormattedDate(expirationDate, 'DD MMMM, YYYY').replace(usMonth, spanishMonth)
      : getFormattedDate(expirationDate, 'MMMM DD, YYYY');

  return (
    <div className={styles.bundleDealOption}>
      <div className={styles.mainDetails}>
        <div className={styles.dealImage}>
          <Image src={dealImageUrl ?? ''} alt="" width={90} height={90} />
        </div>

        <div className={styles.priceAndName}>
          {Boolean(price) && (
            <div className={styles.price}>
              <Price
                priceType="secondary"
                dealPrice={price}
                wrapperClassName={styles.dealPriceWrapper}
              />
            </div>
          )}
          <div className={styles.name}>{name}</div>
        </div>
      </div>
      <div className={styles.description}>{description}</div>
      <div className={styles.expirationDate}>
        {labels.label_deal_bundle_expires} {date}
      </div>
      <Button onClick={() => onSelect(deal)} data-testid={`get-deal-button-${id}`}>
        {labels.label_deal_bundle_get_deal}
      </Button>
    </div>
  );
};
