/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useRef } from 'react';
import root from 'window-or-global';
import { useRouter } from 'next/router';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

export const useScrollIntoViewDOM = (dependencies: Array<unknown>, isCSPage = true) => {
  const router = useRouter();
  const hashValue = router.asPath.split('=')[1] || router.asPath.split('#')[1];
  const imageCount = useRef(0);
  const loadedImageCount = useRef(0);
  const headerHeight = 100;

  const scrollToHash = () => {
    const hashElement = document.getElementById(hashValue);

    if (hashElement) {
      const scrollLocation = hashElement?.offsetTop;
      setTimeout(() => {
        root.scrollTo(0, scrollLocation - headerHeight);
      }, 100);
    }
  };

  const checkAllImagesLoaded = () => {
    const newLoadedImageCount = loadedImageCount.current + 1;

    if (newLoadedImageCount === imageCount.current) {
      scrollToHash();
    } else {
      loadedImageCount.current = newLoadedImageCount;
    }
  };

  const waitForCSImages = () => {
    const hashElement = document.getElementById(hashValue);
    const allImgContainersBeforeHash = hashElement?.parentElement?.querySelectorAll(
      `div:has(picture img):has( ~ #${hashValue})`
    );
    const allImgContainersBeforeHashArray = Array.from(allImgContainersBeforeHash ?? []);
    const closestImgContainer = allImgContainersBeforeHashArray.at(-1);
    const closestImg = closestImgContainer?.querySelector('picture img');
    closestImg?.addEventListener('load', scrollToHash);
  };

  const waitForImages = () => {
    if (!imageCount.current) {
      const images = document.querySelectorAll<HTMLImageElement>('.htmlContentBlock img');
      const imageArray = Array.from(images);
      imageCount.current = imageArray.length;
      imageArray.forEach((img) => (img.onload = checkAllImagesLoaded));
    }
  };

  useIsomorphicLayoutEffect(() => {
    if (typeof root !== 'undefined' && typeof document !== 'undefined') {
      if (isCSPage) {
        waitForCSImages();
      } else {
        waitForImages();
      }

      scrollToHash();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
