/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { FulfillmentOption } from '@/types/reduxStore/skuDetails';
import styles from './styles.module.scss';
import cx from 'classnames';
import NextImage from '@/components/NextImage';
import { useLabel } from '@/hooks/useLabels';
import { useMonetateDecisionFlag } from '@/features/kibo';

const stdIcon = {
  src: '/images/stdDeliveryIcon-16-16.svg',
  alt: '',
};

export function StandardOption({
  option,
  nddAvailable,
}: {
  option: FulfillmentOption;
  nddAvailable: boolean | undefined;
}) {
  const estDeliveryStrings: string[] = option.estimatedDeliveryDate?.split(':');
  const inStockLabel = useLabel('label_cart_lineItem_InStock');
  const showNewFfmText = useMonetateDecisionFlag('displayNewFulfillmentText');
  if (option.fulfillmentTypeLabel || option.estimatedDeliveryDate) {
    return showNewFfmText ? (
      <>
        {!nddAvailable ? (
          <div className={cx(styles.listItemContainer)} data-testid="standard-delivery">
            <div className={styles.inStockLabel}>
              <div className={styles.inStockLabelContainer}>
                <div className={styles.tickmarkContainer}>
                  <img src="/images/green-tickmark.svg" alt="" />{' '}
                </div>
                <span>
                  <span className={styles.successBoldedMessage}>{inStockLabel}</span>
                  <span> | </span>
                  {estDeliveryStrings && (
                    <span data-testid="availability" className={styles.shippingOptionDetailV3}>
                      <span
                        className={styles.estDeliveryText}
                        data-testid="estimated-delivery-standard"
                      >
                        {estDeliveryStrings[0]}
                      </span>{' '}
                      <span
                        data-testid="estimated-delivery-date-standard"
                        className={cx(styles.subTextBold, styles.deliveryMessageCapitalized)}
                      >
                        {estDeliveryStrings[1].replace(/\b0/g, '')}
                      </span>
                    </span>
                  )}
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </>
    ) : (
      <div
        className={cx(styles.listItemContainer, styles.unsetHeight, {
          [styles.applyMargins]: nddAvailable,
        })}
        data-testid="standard-delivery"
      >
        <div className={styles.listItemIcon}>
          <NextImage
            role="presentation"
            src={stdIcon.src}
            alt={stdIcon.alt}
            height="16"
            width="16"
          />
        </div>
        <div className={styles.listItemDetails}>
          <div className={styles.listItemHeader}>
            <span data-testid="standard-delivery-item">{option.fulfillmentTypeLabel}</span>
          </div>
          {estDeliveryStrings && (
            <div data-testid="availability">
              <span className={styles.estDeliveryText} data-testid="estimated-delivery-standard">
                {estDeliveryStrings[0]}
              </span>{' '}
              <span
                data-testid="estimated-delivery-date-standard"
                className={cx(styles.deliveryMessage, styles.deliveryMessageCapitalized)}
              >
                {estDeliveryStrings[1].replace(/\b0/g, '')}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }

  return null;
}
