/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { parseUrl } from '@/utils/urlHelpers';
import { getCSPLPResultList } from './getCSPLPResultList';

type GetRecsPerPageParams = {
  reqUrl: string | null | undefined;
  is24ProductViewEnabled: boolean;
  deviceType: string;
  csResultList: ReturnType<typeof getCSPLPResultList>;
  isCSProductListingPageEnabled: boolean;
};

export const getRecsPerPage = ({
  reqUrl,
  is24ProductViewEnabled,
  deviceType,
  csResultList,
  isCSProductListingPageEnabled,
}: GetRecsPerPageParams) => {
  const parsedUrl = parseUrl(reqUrl ?? '');
  const allowedValues = [24, 36, 48];

  let recordsPerPage = undefined;

  if (parsedUrl.query.recsPerPage && allowedValues.includes(Number(parsedUrl.query.recsPerPage))) {
    recordsPerPage = Number(parsedUrl.query.recsPerPage);
  } else if (is24ProductViewEnabled || deviceType === 'bot') {
    recordsPerPage = 24;
  } else if (isCSProductListingPageEnabled) {
    const csRecordsPerPage = csResultList?.records_per_page ?? undefined;
    recordsPerPage = csRecordsPerPage === 12 ? undefined : csRecordsPerPage;
  }
  return recordsPerPage;
};
