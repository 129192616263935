/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { VideoResponseModel } from '@/api/types/products-types';
import { requestBaseURL } from '@/config/serviceAPI';
import { useHeaderData } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { getAxios } from '@/lib/axios';
import { createQuery } from '@/utils/createReactQuery';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { QueryClient } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

type Options = {
  productId: string;
  vehicleId?: string;
  videoLocation?: string;
  vehicleYear?: number;
  vehicleModel?: string;
  vehicleMake?: string;
  locale: Locale;
};

const selector = (data: VideoResponseModel[]) => data[0] ?? null;

export const getSylvaniaVideos = async (options: Options, axiosInstance?: AxiosInstance) => {
  const { productId, videoLocation, vehicleId, vehicleYear, vehicleModel, vehicleMake, locale } =
    options;
  const country = getCountryFromLocale(locale);
  const salesChannel = 'ECOMM';
  const customerType = 'B2C';

  const URL = `${requestBaseURL}/external/product-discovery/product/v1/products/${productId}/sylvania-videos`;

  const params = {
    country,
    salesChannel,
    customerType,
    vehicleId,
    videoLocation,
    vehicleYear,
    vehicleModel,
    vehicleMake,
  };

  const response = await getAxios(axiosInstance).get<VideoResponseModel[]>(URL, { params });

  return selector(response.data);
};

const {
  useData: useSylvaniaVideosData,
  prefetch: prefetchSylvaniaVideosFunc,
  query: getSylvaniaVideosQuery,
} = createQuery<VideoResponseModel, Options>('getSylvaniaVideos', getSylvaniaVideos);

export const useSylvaniaVideos = ({
  productId,
  videoLocation,
  vehicleYear,
  vehicleModel,
  vehicleMake,
  enabled = true,
}: {
  productId: string;
  videoLocation?: string;
  vehicleYear?: number;
  vehicleModel?: string;
  vehicleMake?: string;
  enabled?: boolean;
}) => {
  const headerResult = useHeaderData();
  const vehicleId = getPreferredVehicle(headerResult.data)?.catalogVehicleId;
  const locale = useLocale();

  return useSylvaniaVideosData({
    enabled: headerResult.isSuccess && enabled && Boolean(vehicleId) && Boolean(productId),
    productId,
    vehicleId,
    videoLocation,
    vehicleYear,
    vehicleModel,
    vehicleMake,
    locale,
    cacheTime: 0,
  });
};

export const getSylvaniaVideosKey = ({
  productId,
  locale,
  vehicleId,
  videoLocation,
  vehicleMake,
  vehicleModel,
  vehicleYear,
}: Options) =>
  getSylvaniaVideosQuery.getFullKey({
    productId,
    vehicleId,
    videoLocation,
    vehicleYear,
    vehicleModel,
    vehicleMake,
    locale,
  });

export const prefetchSylvaniaVideos = async (
  axiosInstance: AxiosInstance,
  queryClient: QueryClient,
  options: Options
) => {
  return prefetchSylvaniaVideosFunc(queryClient, options, axiosInstance);
};
