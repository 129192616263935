/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Button, Link, View } from '@az/starc-ui';
import { useLabels } from '@/hooks/useLabels';
import { NavBar } from '@/components/NavBar';
import { routePaths } from '@/constants/routePaths';
import { useRouter } from 'next/router';
import Title from '@/components/AZCustomComponent/Title';
import styles from './styles.module.scss';

type Props = {
  onBackClick: () => void;
  onClose: () => void;
  onContinueShopping: () => void;
};

const labelMap = {
  label_deal_drawer_button_continue_to_cart: 'label_deal_drawer_button_continue_to_cart',
  label_deal_drawer_added_to_cart: 'label_deal_drawer_added_to_cart',
  label_deal_drawer_confirmation_message: 'label_deal_drawer_confirmation_message',
  label_deal_drawer_button_continue_shopping: 'label_deal_drawer_button_continue_shopping',
};

export const DealDrawerConfirmation = ({ onBackClick, onClose, onContinueShopping }: Props) => {
  const labels = useLabels(labelMap);
  const router = useRouter();

  return (
    <div className={styles.dealDrawerConfirmation}>
      <NavBar
        showBackButton={false}
        showCloseButton
        handleBack={onBackClick}
        handleClose={onClose}
      />
      <div className={styles.dealDrawerConfirmationScroller}>
        <div className={styles.dealDrawerConfirmationHeader}>
          <div className={styles.centered}>
            <img src="/images/greenTickRoundedMA.png" alt="green tick" />
          </div>
        </div>
        <div className={styles.dealDrawerConfirmationBody}>
          <div className={`${styles.section} ${styles.centered}`}>
            <Title
              firstText={labels.label_deal_drawer_added_to_cart}
              variant="xs"
              headerType="div"
            />
            <div className={styles.sectionDescription}>
              {labels.label_deal_drawer_confirmation_message}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.dealDrawerConfirmtionFooter}>
        <div className={styles.dealDrawerGoToCartButton}>
          <Button
            variant="super"
            onClick={() => {
              void router.push(routePaths.cart);
            }}
            data-testid="deals-drawer-cart-action-button"
          >
            {labels.label_deal_drawer_button_continue_to_cart}
          </Button>
        </div>
        <View align="center" padding={4}>
          <Link
            type="button"
            size="small"
            onClick={onContinueShopping}
            data-testid="deals-drawer-continue-shopping"
          >
            {labels.label_deal_drawer_button_continue_shopping}
          </Link>
        </View>
      </div>
    </div>
  );
};
