/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import Price from '@/components/AZCustomComponent/Price';
import Image from '@/components/NextImage';
import { useLabels } from '@/hooks/useLabels';
import { getPriceArray } from '../../utils/getPriceArray';
import type { DealConstraintSkuDetails, DealConstraint } from '../../../interface';
import CheckIcon from '@/public/images/check.svg';
import iconStyles from '@/theme/iconStyles.module.scss';
import styles from './styles.module.scss';
import { eventConstants } from '@/constants/event';

type Props = {
  constraint: DealConstraint;
  skuDetails: DealConstraintSkuDetails;
  selected?: boolean;
  onClick?: () => void;
  className?: string;
};
const labelMap = {
  label_deal_bundle_contraint_item_quantity: 'label_deal_bundle_contraint_item_quantity',
  label_deals_sku_location: 'label_deals_sku_location',
};

export const BundleDealConstraintCard = ({
  constraint,
  skuDetails,
  selected,
  onClick,
  className,
}: Props) => {
  const { minQty } = constraint;
  const {
    description,
    productImageUrl,
    productTotalPrice,
    discountPrice,
    locationFilter,
    id: skuId,
  } = skuDetails;
  const labels = useLabels(labelMap);
  const hasDealPrice = discountPrice && discountPrice !== productTotalPrice;
  const isButton = onClick ? true : false;
  const divRole = isButton ? 'button' : 'presentation';
  const divTabIndex = isButton ? 0 : -1;

  const onKeyUpHandler = (e: React.KeyboardEvent) => {
    if (e.key === eventConstants.enterKeyType || e.key === eventConstants.spaceKeyType) {
      onClick?.();
    }
  };

  return (
    <div
      tabIndex={divTabIndex}
      role={divRole}
      className={cx(styles.constraintItemCard, className, {
        [styles.selected]: selected,
      })}
      data-testid={skuId}
      onClick={onClick}
      onKeyUp={onKeyUpHandler}
    >
      <div className={styles.constraintItemImage}>
        <Image src={productImageUrl} alt="" width={27} height={23} />
      </div>
      <div className={styles.constraintItemDescriptionSection}>
        <div className={styles.constraintItemDescription}>{description}</div>
        {Boolean(locationFilter) && (
          <div className={styles.locationSection}>
            <span className={styles.locationLabel}>{labels.label_deals_sku_location}</span>
            <span className={styles.locationValue}>{locationFilter}</span>
          </div>
        )}
      </div>
      <div className={styles.constraintItemPriceDetails}>
        <div className={styles.constraintItemQuantity}>
          <span className={styles.constraintItemQuantityLabel}>
            {labels.label_deal_bundle_contraint_item_quantity}
          </span>
          <span className={styles.constraintItemQuantityValue}>{minQty}</span>
        </div>
        <div className={styles.constraintItemPrice}>
          <>
            {hasDealPrice ? (
              <Price
                priceType="small"
                dealPrice={getPriceArray(discountPrice)}
                wasDealPrice={getPriceArray(productTotalPrice)}
                each={minQty > 1}
              />
            ) : (
              <Price
                priceType="small"
                dealPrice={getPriceArray(productTotalPrice)}
                each={minQty > 1}
              />
            )}
          </>
        </div>
      </div>
      {selected ? (
        <CheckIcon className={cx(iconStyles.defaultIconStyle, styles.selectedIcon)} />
      ) : null}
    </div>
  );
};
