/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { getPriceArray } from './getPriceArray';

const PRICE_REGEX = /^\$\d+(\.\d+)?\b/;

export const extractPriceFromDescription = (description: string): string[] | null => {
  const matches = description.match(PRICE_REGEX);

  if (!matches) {
    return null;
  }

  const price = matches[0];
  return getPriceArray(price);
};
