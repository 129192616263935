/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { isEmptyObject } from '@/utils/common';
import type { ShelfPart } from '@/types/legacy';
import { type CitrusCarouselResponse } from '@/features/citrus';

export const generateNewProductRecordsForCitrus = (
  productRecords: ShelfPart[],
  sponsoredData: CitrusCarouselResponse | undefined
) => {
  const copyOfProductRecords = [...productRecords];

  if (sponsoredData?.ads.length) {
    const sponsoredAds = sponsoredData.ads.slice(0, 3);
    const regEx = /\/[^\/]*[\/]?$/;
    const filteredPromotions = sponsoredAds.filter(
      (sponsoredProductData) => !isEmptyObject(sponsoredProductData)
    );
    const sponsoredItems = filteredPromotions.map((sponsoredProductData) => {
      const sponsoredProductSku = sponsoredProductData.gtin
        ? Number(sponsoredProductData.gtin)
        : null;

      const fixedSponsoredImgLink = sponsoredProductData.customPayload.product_image.replace(
        regEx,
        '/2/'
      );

      const shapedSponsoredProductRecord: ShelfPart = {
        citrusAdId: sponsoredProductData.id,
        description: '',
        imageUrl: fixedSponsoredImgLink,
        isSponsoredCard: true,
        name: sponsoredProductData.customPayload.product_name,
        productImageUrl: fixedSponsoredImgLink,
        productReviewsEnabled: true,
        recordType: sponsoredProductData.customPayload.product_record_type,
        seoUrl: sponsoredProductData.customPayload.product_link.includes('://')
          ? new URL(sponsoredProductData.customPayload.product_link).pathname
          : sponsoredProductData.customPayload.product_link,
        skuNumber: sponsoredProductSku ?? 0,
        vehicleFit: true,
        currencyCode: 'USD',
        pageImpressionId: sponsoredProductData.id,
        dssId: sponsoredProductData.id,
        productType: sponsoredProductData.customPayload.product_partnum,
        partNumber: sponsoredProductData.customPayload.product_partnum,
        brand: sponsoredProductData.customPayload.product_name,
        originalPartTypeId: 'N/A',
      };

      const indexOfDuplicatedProduct = copyOfProductRecords.findIndex((record) => {
        return record.skuNumber === sponsoredProductSku;
      });

      if (indexOfDuplicatedProduct > -1) {
        copyOfProductRecords.splice(indexOfDuplicatedProduct, 1);
      }
      return shapedSponsoredProductRecord;
    });
    if (sponsoredItems.length) {
      copyOfProductRecords.unshift(...sponsoredItems);
    }
  }

  return copyOfProductRecords;
};
