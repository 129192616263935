/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useMemo } from 'react';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import Skeleton from '../../Skeleton/Skeleton';
import styles from './ProductDescriptionSkeleton.module.scss';
const baseSkeletonProps: {
  animation: 'pulse' | 'wave' | false;
  variant: 'text' | 'rect';
} = {
  animation: 'wave',
  variant: 'rect',
};

const mobileConfig = [
  {
    width: '80%',
    height: 25,
  },
  {
    width: '100%',
    height: 16,
  },
  {
    width: '90%',
    height: 16,
  },
  {
    width: '40%',
    height: 16,
  },
];
const tabletConfig = [
  {
    width: '30%',
    height: 25,
  },
  {
    width: '50%',
    height: 20,
  },
  {
    width: '50%',
    height: 20,
  },
  {
    width: '25%',
    height: 20,
  },
];
const desktopConfig = [
  {
    width: '55%',
    height: 25,
  },
  {
    width: '100%',
    height: 20,
  },
  {
    width: '100%',
    height: 20,
  },
  {
    width: '40%',
    height: 20,
  },
];

const getConfig = (isMobile: boolean, isTablet: boolean) => {
  if (isMobile) {
    return mobileConfig;
  } else if (isTablet) {
    return tabletConfig;
  }

  return desktopConfig;
};

export default function ProductDescriptionSkeleton() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const config = useMemo(() => getConfig(isMobile, isTablet), [isMobile, isTablet]);

  return (
    <>
      <div className={styles.horizontalDivider} />
      <div className={styles.container}>
        {config.map((data, index) => (
          <Skeleton
            key={index}
            className={index === 0 ? styles.title : styles.description}
            width={data.width}
            height={data.height}
            {...baseSkeletonProps}
          />
        ))}
      </div>
    </>
  );
}
