/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button } from '@az/starc-ui';
import styles from './styles.module.scss';

type Props = {
  label: string;
  onCartAddClick: () => void;
  isSaving: boolean;
  dealId: string;
};
const AddDealToCartBtn = ({ label, onCartAddClick, isSaving, dealId }: Props) => (
  <div className={styles.bundleDetailsFooter} data-testid="add-to-cart">
    <Button
      onClick={onCartAddClick}
      loading={isSaving}
      data-testid={`deals-drawer-cart-action-button-${dealId}`}
    >
      {label}
    </Button>
  </div>
);

export default AddDealToCartBtn;
