/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
const GridObject = {
  tablet: true,
  layoutCode: 'GRID4',
  desktop: true,
  overallHeadLine: '<p>FEATURED CATEGORIES</p>\n',
  altText: null,
  childContentBlockList: [
    {
      altText: 'Batteries',
      imageURL:
        'https://www.autozone.com/cdn/images/B2C/US/media/FY20/Test/Grid4/fy20p0506-grid4-battery.jpg',
      CTALink: '/ignition-tune-up-and-routine-maintenance/battery',
      text: 'Batteries',
    },
    {
      altText: 'Alternators',
      imageURL:
        'https://www.autozone.com/cdn/images/B2C/US/media/FY20/P11/MobileFirst/FY20P11-Grid4-Alternators.jpg',
      CTALink: '/batteries-starting-and-charging/alternator',
      text: 'Alternators',
    },
    {
      altText: 'Brake Pads',
      imageURL:
        'https://www.autozone.com/cdn/images/B2C/US/media/FY20/Test/Grid4/fy20p0506-grid4-brakepads.jpg',
      CTALink: '/brakes-and-traction-control/brake-pads',
      text: 'Brake Pads',
    },
    {
      altText: 'Brake Rotors',
      imageURL:
        'https://www.autozone.com/cdn/images/B2C/US/media/FY20/Test/Grid4/fy20p0506-grid4-rotors.jpg',
      CTALink: '/brakes-and-traction-control/brake-rotor',
      text: 'Brake Rotors',
    },
    {
      altText: 'Engine Oil',
      imageURL:
        'https://www.autozone.com/cdn/images/B2C/US/media/FY20/Test/Grid4/fy20p0506-grid4-engineoil.jpg',
      CTALink: '/motor-oil-and-transmission-fluid/engine-oil',
      text: 'Engine Oil',
    },
    {
      altText: 'Oil Filter',
      imageURL:
        'https://www.autozone.com/cdn/images/B2C/US/media/FY20/Test/Grid4/fy20p0506-grid4-oilfilters.jpg',
      CTALink: '/external-engine/oil-filter',
      text: 'Oil Filter',
    },
    {
      altText: 'Headlights',
      imageURL:
        'https://www.autozone.com/cdn/images/B2C/US/media/FY20/Test/Grid4/fy20p0506-grid4-headlights.jpg',
      CTALink: '/collision-body-parts-and-hardware/headlight',
      text: 'Lighting',
    },
    {
      altText: 'Wiper Blade Windhield',
      imageURL:
        'https://www.autozone.com/cdn/images/B2C/US/media/FY20/P09/MobilFirst/fy20p09-grid4-wipers.jpg',
      CTALink: '/ignition-tune-up-and-routine-maintenance/wiper-blade-windshield',
      text: 'Wipers',
    },
    {
      altText: 'Shocks & Struts',
      imageURL:
        'https://www.autozone.com/cdn/images/B2C/US/media/FY20/Test/Grid4/fy20p0506-grid4-suspension.jpg',
      CTALink: '/suspension-steering-tire-and-wheel/shock-strut-front',
      text: 'Shocks & Struts',
    },
    {
      altText: 'AC Compressors',
      imageURL:
        'https://www.autozone.com/cdn/images/B2C/US/media/FY20/P11/MobileFirst/FY20P11-Grid4-ACCompressor.jpg',
      CTALink: '/cooling-heating-and-climate-control/a-c-compressor',
      text: 'AC Compressors',
    },
    {
      altText: 'Trailer Hitches',
      imageURL:
        'https://www.autozone.com/cdn/images/B2C/US/media/FY20/Test/Grid4/fy20p0506-grid4-customhitches.jpg',
      CTALink: '/hitches-balls-mounts-and-hitch-accessories/trailer-hitch',
      text: 'Custom Hitches',
    },
    {
      altText: 'Tools And Equipment',
      imageURL:
        ' https://www.autozone.com/cdn/images/B2C/US/media/FY20/P11/MobileFirst/FY20P11-Grid4-Tools.jpg',
      CTALink: '/tools-and-equipment',
      text: 'Tools',
    },
  ],
  themeCode: 'White',
  mobile: true,
  CTALink: null,
  CTAName: null,
  theme: 'White',
};

export default GridObject;
