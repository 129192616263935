/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { SisterStoreContext } from '@/features/fulfillment/context/SisterStoreContext';
import { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import actionTypes from '@/types/action';
import { ReduxState } from '@/types';

export const useAutoCloseOnOrderReady = () => {
  const orderDetailsReadyStatus = useSelector(
    (state: ReduxState) => state.orders.details.readyStatus
  );

  const {
    closeSisterStoreFlyout,
    isSisterStoreFlyoutOpen,
    updateSettingNewStore,
    isSettingNewStore,
  } = useContext(SisterStoreContext);

  /*  Autoclose relies on the trigger UPDATE_ORDERITEM_FAILURE or UPDATE_ORDERITEM_SUCCESS
    if something happens and they are never dispatched  this will prevent a perpetual load
    on sister store flyout by closing the drawer after 20s after the loading store  */
  useEffect(() => {
    let timeOutId: ReturnType<typeof setTimeout> | null = null;

    if (isSettingNewStore) {
      timeOutId = setTimeout(() => {
        closeSisterStoreFlyout();
      }, 10 * 1000);
    }

    if ((!isSisterStoreFlyoutOpen || !isSettingNewStore) && timeOutId) {
      clearTimeout(timeOutId);
    }

    return () => {
      if (timeOutId) {
        clearTimeout(timeOutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSettingNewStore, isSisterStoreFlyoutOpen]);

  useEffect(() => {
    if (orderDetailsReadyStatus === actionTypes.UPDATE_ORDERITEM_FAILURE) {
      updateSettingNewStore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetailsReadyStatus]);

  useEffect(() => {
    if (
      (orderDetailsReadyStatus === actionTypes.UPDATE_ORDERITEM_SUCCESS ||
        orderDetailsReadyStatus === actionTypes.UPDATE_ORDERITEM_FAILURE) &&
      isSisterStoreFlyoutOpen
    ) {
      closeSisterStoreFlyout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetailsReadyStatus]);
};
