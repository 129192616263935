/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import NextImage from '@/components/NextImage';
import { rightArrowActive } from '@/constants/images/rightArrowActive';
import { useLabels } from '@/hooks/useLabels';
import { View, Text } from '@az/starc-ui';
import { SmartLink as Link } from '@/utils/smartLink';
import styles from './styles.module.scss';

type Props = {
  reviewsRef: React.Ref<HTMLDivElement>;
  skuId: string;
  productReviewsURL: string | undefined;
  scriptReady: boolean;
};

const labelMap = {
  seeMoreReviews: 'label_reviews_seeMoreReviews',
};

const ReviewsContent = ({ reviewsRef, skuId, productReviewsURL, scriptReady }: Props) => {
  const labels = useLabels(labelMap);

  return (
    <div id="tab-reviews" ref={reviewsRef} className={styles.reviewsContent}>
      {scriptReady && <div data-bv-show="reviews" data-bv-product-id={skuId} />}
      {productReviewsURL && (
        <View className={styles.reviewsLinkContainer}>
          <Link to={productReviewsURL} className={styles.reviewsLink}>
            <Text
              variant="inline-link"
              attributes={{ style: { '--st-text-line-height': 'unset' } }}
              decoration="underline"
            >
              {labels.seeMoreReviews}
            </Text>
            <NextImage src={rightArrowActive.src} width={14} height={8} />
          </Link>
        </View>
      )}
    </div>
  );
};

export { ReviewsContent };
