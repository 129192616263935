/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useMemo } from 'react';
import { getCartridgeType } from '@/utils/getCartridgeType';
import type { ShelfPageBodyComplete, ShelfPageBodyItemResultsList } from '@/types/legacy';
import { isShelfPageBodyItemResultsList } from '../utils/isShelfPageBodyItemResultsList';

export const useGetShelfPageRecords = (
  shelfPageBody: ShelfPageBodyComplete | undefined
): ShelfPageBodyItemResultsList | undefined => {
  const shelfPageRecords = useMemo(
    () =>
      shelfPageBody?.filter(
        (contentItem) => getCartridgeType(contentItem['@type']) === 'ResultsList'
      )?.[0],
    [shelfPageBody]
  );

  if (!isShelfPageBodyItemResultsList(shelfPageRecords)) {
    return undefined;
  }

  return shelfPageRecords;
};
