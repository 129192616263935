/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { fromApiSkuDetails } from '@/adaptors';
import { type IItem } from '@/components/AZCustomComponent/BreadcrumbSTARC/utils/isBreadcrumbModel';
import { pageTypes } from '@/constants/page';
import { type BrandData } from '@/features/brands';
import { useBrand } from '@/features/brands/api/getBrand';
import { useBrandProductData } from '@/features/brands/api/getProductBrandData';
import type { CategoryData } from '@/features/category';
import { useProductShelfResults } from '@/features/category/api/getProductShelfResults';
import { useMappedCategoryData } from '@/features/category/api/mapCategoryData';
import { useFeatureFlag } from '@/features/globalConfig';
import { type ProductSkuDetails } from '@/features/product';
import { useProductSkuDeals } from '@/features/product/api/getProductDeals';
import { useProductSkuDetails } from '@/features/product/api/getProductSkuDetails';
import type { SkuDealsFromAPI } from '@/types/availability';
import { usePageType } from '@/features/category/api/getPageType';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { shelfPageType } from '../constants';
import type {
  ProductFinder,
  ShelfHead,
  ShelfLeftNav,
  ShelfPageBodyComplete,
  ShelfPageBodyItemGeneric,
  ShelfPart,
  ShelfResultsBottomItemTwo,
  SortingOption,
} from '@/types/legacy';
import type { MappedBrandShelfResults } from '@/features/brands';
import { generateNewProductRecordsForCitrus } from '../utils/generateNewProductRecords';
import { getShelfBodyParts } from '../utils/getShelfBodyparts';
import { getSkuIdsForPage } from '../utils/getSkuIdsForPage';
import { normalizeShelfResponse } from '../utils/normalizeShelfResponse';
import { shopByPageTypeInfo } from '../utils/shopByPageTypeInfo';
import { FACETED_URL, FACETED_URL_V2 } from '@/utils/validatorRegex';
import { type MappedSearchProductData } from '@/features/search';
import { useSearchProductData } from '@/features/search/api/getSearchProductData';
import { useIs24ProductViewEnabled } from '@/hooks/useIs24ProductViewEnabled';
import { useCitrusShelfCarouselData } from '@/features/citrus/api/getCitrusShelfCarouselData';
import { useDeviceType } from '@/utils/useDeviceType';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { countryCodes } from '@/constants/locale';
import { useConfigFlags } from './getConfigFlags';
import { useHeaderData } from '@/features/header/api/getHeader';
import { isBrandShelfPage } from '@/utils/isBrandShelfPage';
import type { UseProductListDataResult } from '../interface';

type TransformedPageData = {
  brandName: string | undefined;
  makeModelYearPageType: 'ShopByMake' | 'ShopByModel' | 'ShopByYear' | undefined | null;
  catLabel: string | undefined;
  productId: string | undefined | null;
  shelfHead: ShelfHead[];
  shelfLeftNav: ShelfLeftNav | undefined;
  shelfPageBody: ShelfPageBodyComplete;
  shelfResultHeader: ShelfPageBodyItemGeneric[] | [];
  shelfResultBottom:
    | [ShelfPageBodyItemGeneric, ShelfResultsBottomItemTwo]
    | Array<{ '@type': string }>;
  sortingOptions: {
    options: SortingOption[];
    quickFilter: boolean;
  } | null;
  productFinderData: ProductFinder | null;
  productFilterData:
    | null
    | undefined
    | {
        mobile: boolean;
        tablet: boolean;
        desktop: boolean;
        '@type': string;
        contents: [
          {
            '@type': string;
            refinementList: Array<{ label: string }>;
          }
        ];
      };
  productRecords: ShelfPart[];
  isBrandPremiumPage?: boolean;
};

type BatchedDetailsSelector = {
  updatedShelfParts: ShelfPart[] | undefined;
  shelfBreadcrumb: IItem[];
};

const pageDataSelector = ({
  pageData,
  pathname,
}: {
  pageData: CategoryData | BrandData | undefined;
  pathname: string;
}): TransformedPageData => {
  const {
    brandName,
    catLabel,
    productId,
    shelfLeftNav,
    shelfPageBody,
    shelfResultBottom,
    shelfResultHeader,
    shelfHead,
  } = normalizeShelfResponse({
    data: pageData,
    pathname: pathname,
  });
  const { resultsList, sortingOptions, productFinderData, productFilterData } =
    getShelfBodyParts(shelfPageBody);
  const productRecords = resultsList?.records ?? [];

  const makeModelYearPageType =
    pageData && 'makeModelYearPageType' in pageData ? pageData.makeModelYearPageType : null;

  return {
    brandName,
    makeModelYearPageType,
    catLabel,
    productId,
    shelfHead,
    shelfLeftNav,
    shelfPageBody,
    shelfResultHeader,
    shelfResultBottom,
    sortingOptions,
    productFinderData,
    productFilterData,
    productRecords,
  };
};

const skuDetailsSelector = ({
  batchedProductSkuDetails,
  newProductRecords,
  pageData,
  query,
  shouldIncludeSponsoredProducts,
}: {
  batchedProductSkuDetails: ProductSkuDetails[] | undefined;
  newProductRecords: ShelfPart[] | undefined;
  pageData: CategoryData | BrandData | undefined;
  query: {
    brandName?: string;
    params?: { minPrice: string; maxPrice: string } | undefined;
    seoUrlPath?: string[];
  };
  shouldIncludeSponsoredProducts: boolean;
}): BatchedDetailsSelector => {
  const updatedShelfPartsWithUndefineds = (batchedProductSkuDetails ?? []).map((skuDetail) => {
    const matchingRecord = newProductRecords?.find(
      (record) =>
        parseInt(record.skuNumber.toString()) ===
        parseInt(skuDetail.skuPricingAndAvailability.skuId)
    );

    if (matchingRecord) {
      const productSkuDetails = fromApiSkuDetails(skuDetail);
      return { ...productSkuDetails, ...matchingRecord };
    }
  });

  const updatedShelfParts = updatedShelfPartsWithUndefineds.filter(
    (el) =>
      el !== null &&
      el !== undefined &&
      (shouldIncludeSponsoredProducts || (!el.isSponsoredCard && !el.sponsoredProductFlag))
  ) as ShelfPart[];

  // TODO backend service should change "displayName" to "label" to align with STARC
  const shelfBreadcrumb =
    pageData != null && 'breadCrumbsList' in pageData
      ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        pageData.breadCrumbsList?.slice(0).map((crumb) => ({
          ...crumb,
          label: crumb.displayName,
        }))
      : [];

  if (query.brandName) {
    shelfBreadcrumb.push({ label: 'Shop All Brands', url: '/brands' });
  }

  return { updatedShelfParts, shelfBreadcrumb };
};

type DefaultSortingOptionsObject = { quickFilter: boolean; options: SortingOption[] };

const defaultSortingOptions: DefaultSortingOptionsObject = {
  quickFilter: true,
  options: [
    {
      tablet: true,
      desktop: true,
      '@type': 'ShelfPageBody_SortingOption',
      parameter: 'price-asc',
      name: 'Price (low to high)',
      mobile: true,
      enabled: true,
    },
    {
      tablet: true,
      desktop: true,
      '@type': 'ShelfPageBody_SortingOption',
      parameter: 'price-desc',
      name: 'Price (high to low)',
      mobile: true,
      enabled: true,
    },
    {
      tablet: true,
      desktop: true,
      '@type': 'ShelfPageBody_SortingOption',
      parameter: 'recently-added-desc',
      name: 'Recently Added',
      mobile: true,
      enabled: true,
    },
    {
      tablet: true,
      desktop: true,
      '@type': 'ShelfPageBody_SortingOption',
      parameter: 'customer-rating-desc',
      name: 'Customer Rating',
      mobile: true,
      enabled: true,
    },
    {
      tablet: true,
      desktop: true,
      '@type': 'ShelfPageBody_SortingOption',
      parameter: 'best-seller-desc',
      name: 'Best Sellers',
      mobile: true,
      enabled: true,
    },
  ],
};

const defaultSortingOptionsForMX: DefaultSortingOptionsObject = {
  options: [
    {
      tablet: true,
      desktop: true,
      '@type': 'ShelfPageBody_SortingOption',
      parameter: 'price-asc',
      name: 'Precio (de más bajo a más alto)',
      mobile: true,
      enabled: true,
    },
    {
      tablet: true,
      desktop: true,
      '@type': 'ShelfPageBody_SortingOption',
      parameter: 'price-desc',
      name: 'Precio (de más alto a más bajo)',
      mobile: true,
      enabled: true,
    },
    {
      tablet: true,
      desktop: true,
      '@type': 'ShelfPageBody_SortingOption',
      parameter: 'recently-added-desc',
      name: 'Añadido Recientemente',
      mobile: true,
      enabled: true,
    },
    {
      tablet: true,
      desktop: true,
      '@type': 'ShelfPageBody_SortingOption',
      parameter: 'best-seller-asc',
      name: 'Más Vendido',
      mobile: true,
      enabled: true,
    },
  ],
  quickFilter: true,
};

const defaultSortingOptionsForBR: DefaultSortingOptionsObject = {
  options: [
    {
      tablet: true,
      desktop: true,
      '@type': 'ShelfPageBody_SortingOption',
      parameter: 'price-asc',
      name: 'Menor Preço',
      mobile: true,
      enabled: true,
    },
    {
      tablet: true,
      desktop: true,
      '@type': 'ShelfPageBody_SortingOption',
      parameter: 'price-desc',
      name: 'Maior Preço',
      mobile: true,
      enabled: true,
    },
    {
      tablet: true,
      desktop: true,
      '@type': 'ShelfPageBody_SortingOption',
      parameter: 'best-seller-asc',
      name: 'Os máis vendidos',
      mobile: true,
      enabled: true,
    },
  ],
  quickFilter: true,
};

const defaultSortingOptionsMap = {
  [countryCodes.enUS]: defaultSortingOptions,
  [countryCodes.mx]: defaultSortingOptionsForMX,
  [countryCodes.ptBr]: defaultSortingOptionsForBR,
};

const shelfDataSelector = ({
  locale,
  pageData,
  transformedPageData,
  shelfParts,
  shelfBreadcrumb,
  shelfResultHasAppItems,
}: {
  locale: Locale;
  pageData: CategoryData | BrandData | undefined;
  transformedPageData: TransformedPageData;
  shelfParts: ShelfPart[];
  shelfBreadcrumb: IItem[];
  shelfResultHasAppItems: boolean;
  isSuccess?: boolean;
}) => {
  const {
    brandName,
    makeModelYearPageType,
    productFilterData,
    productFinderData,
    catLabel,
    productId,
    shelfHead,
    shelfLeftNav,
    shelfPageBody,
    shelfResultHeader,
    shelfResultBottom,
    sortingOptions,
  } = transformedPageData;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const contents = pageData?.contents?.contents[0];

  const topContentSeoActive =
    pageData && 'topContentSeoActive' in pageData ? pageData.topContentSeoActive : undefined;

  const shopByUrlSuffix =
    pageData && 'shopByUrlSuffix' in pageData ? pageData.shopByUrlSuffix : undefined;

  const seoTopCopy =
    pageData && 'seoTopCopy' in pageData && topContentSeoActive ? pageData.seoTopCopy : null;

  const seoBottomCopy = pageData && 'seoBottomCopy' in pageData ? pageData.seoBottomCopy : '';

  const seoCopyBlock =
    pageData && 'seoCopyBlock' in pageData && seoTopCopy === null
      ? pageData.seoCopyBlock
      : seoBottomCopy;

  const shelfSeoCopyBlock = topContentSeoActive ? seoBottomCopy : seoCopyBlock;

  const shortDescription =
    pageData && 'shortDescription' in pageData ? pageData.shortDescription : null;

  const shelfProductListData = {
    brandName,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    isBrandPremiumPage: pageData?.contents?.contents[0]?.isBrandPremiumPage,
    description:
      pageData && 'description' in pageData && pageData.description ? pageData.description : '',
    shelfResultHasAppItems,
    hierarchyLocation:
      pageData && 'hierarchyLocation' in pageData && pageData.hierarchyLocation
        ? pageData.hierarchyLocation
        : '',
    makeModelYearPageType,
    productFilterData: !makeModelYearPageType ? productFilterData : null,
    productFinderData: !makeModelYearPageType ? productFinderData : null,
    productId,
    seoCopyBlock: shelfSeoCopyBlock,
    shelfBreadcrumb,
    shelfCatLabel: catLabel,
    shelfHead,
    shelfHeading: catLabel,
    shelfLeftNav,
    shelfPageBody,
    shelfParts,
    shelfResultHeader,
    shelfResultBottom,
    shelfSeoMetaData: pageData && 'seoMetaData' in pageData ? pageData.seoMetaData : null,
    shelfTippyTop:
      contents && 'shelfTippyTop' in contents && contents.shelfTippyTop
        ? contents.shelfTippyTop
        : [],
    shopByUrlMake:
      pageData && 'shopByUrlMake' in pageData && pageData.shopByUrlMake
        ? pageData.shopByUrlMake
        : undefined,
    shopByUrlYear:
      pageData && 'shopByUrlYear' in pageData && pageData.shopByUrlYear
        ? pageData.shopByUrlYear
        : undefined,
    shopByUrlModel:
      pageData && 'shopByUrlModel' in pageData && pageData.shopByUrlModel
        ? pageData.shopByUrlModel
        : undefined,
    seoBottomCopy: shelfSeoCopyBlock,
    shortDescription: shortDescription,
    yearRange: pageData && 'yearRange' in pageData ? pageData.yearRange : undefined,
    makeNames: pageData && 'makeNames' in pageData ? pageData.makeNames : undefined,
    categoryMake: pageData && 'categoryMake' in pageData ? pageData.categoryMake : undefined,
    categoryModel: pageData && 'categoryModel' in pageData ? pageData.categoryModel : undefined,
    makeorModelName:
      pageData && 'makeorModelName' in pageData ? pageData.makeorModelName : undefined,
    shopByUrlSuffix,
    seoTopCopy,

    modelNames: pageData && 'modelNames' in pageData ? pageData.modelNames : undefined,
    catLabel: pageData && 'catLabel' in pageData ? pageData.catLabel : undefined,
    sortingOptions: sortingOptions?.options.length
      ? sortingOptions
      : defaultSortingOptionsMap[locale],
    summary: pageData && 'summary' in pageData && pageData.summary ? pageData.summary : '',
  };

  return shelfProductListData;
};

const searchDataSelector = (
  partTypeData: MappedSearchProductData,
  locale: Locale
): TransformedPageData => ({
  brandName: undefined,
  makeModelYearPageType: undefined,
  catLabel: '',
  productId: undefined,
  shelfHead: [],
  shelfLeftNav: undefined,
  shelfPageBody: [],
  shelfResultHeader: [],
  shelfResultBottom: [],
  productFinderData: null,
  productFilterData: null,
  sortingOptions: defaultSortingOptionsMap[locale],
  productRecords: partTypeData.records ?? [],
});

const productDataBrandSelector = (
  data: MappedBrandShelfResults,
  brandData: BrandData | undefined
) => ({
  brandName: data.brandName ?? brandData?.brandName,
  isBrandPremiumPage: brandData?.contents.contents[0]?.isBrandPremiumPage ?? false,
  catLabel: data?.brandName ?? '',
  productRecords: data.records ?? [],
  makeModelYearPageType: undefined,
  productId: undefined,
  shelfLeftNav:
    brandData?.contents.contents[0]?.brandShelfLeftNav ??
    ([
      {
        '@type': 'string',
        dynamicNavigation: {},
        name: 'Brand Navigation',
        staticNavigation: data.staticNavigation,
      },
      {},
      {},
    ] as ShelfLeftNav),
  shelfResultBottom: [],
  shelfResultHeader: brandData?.contents.contents[0]?.brandShelfHeader ?? [],
  shelfPageBody: brandData?.contents.contents[0]?.brandShelfPageBody ?? [],
  shelfHead: brandData?.contents.contents[0]?.brandShelfHead ?? [],
  sortingOptions: defaultSortingOptions,
  productFinderData: null,
  productFilterData: null,
});

export const useProductListData = ({
  shouldFetchSecondaryProductSkus = false,
}): UseProductListDataResult => {
  const enable24ProductView = useIs24ProductViewEnabled();
  const isNewDealsApiEnabled = useFeatureFlag('IS_NEW_DEALS_API_ENABLED') === 'true';
  const citrusEnabled = useFeatureFlag('IS_CITRUS_ENABLED') === 'true';
  const citrusShelfSponsoredCardsEnabled =
    useFeatureFlag('IS_CITRUS_SHELF_SPONSORED_CARDS_ENABLED') === 'true';

  const engineOilFilterName = 'Engine Oil';

  const deviceType = useDeviceType();

  const router = useRouter();
  const { query, asPath } = router;
  const isSearchPage = router.pathname.includes('/searchresult');
  const { filters, facet } = query;
  const { data: pageTypeData } = usePageType();

  const hasFilters =
    FACETED_URL.test(asPath.split('?')[0]) ||
    FACETED_URL_V2.test(asPath.split('?')[0]) ||
    !!filters ||
    !!facet;
  const locale = useLocale();
  const isBrandPage = isBrandShelfPage(router.asPath);

  const raEngineOilFilterOnlyCheck = () => {
    if (
      FACETED_URL_V2.test(asPath.split('?')[0]) ||
      facet?.includes('+') ||
      !facet?.includes(engineOilFilterName)
    ) {
      return false;
    }

    return true;
  };

  const hasEngineOilFilterSelectedOnly = raEngineOilFilterOnlyCheck();

  const hasCustomPrice = query.maxPrice || query.minPrice;

  const {
    data: brandData,
    status: brandQueryStatus,
    isFetching: brandIsFetching,
  } = useBrand({ enabled: isBrandPage });

  const {
    data: brandProductData,
    status: brandProductStatus,
    isFetching: brandProductIsFetching,
  } = useBrandProductData({
    enabled: isBrandPage,
  });

  const {
    data: searchProductData,
    status: searchProductQueryStatus,
    isFetching: searchProductIsFetching,
  } = useSearchProductData({
    enabled: pageTypeData?.pageType === pageTypes.search && query.partTypesView !== 'true',
  });

  const { data: categoryData, status: categoryQueryStatus } = useMappedCategoryData();

  const searchProductSkus =
    searchProductData && !searchProductData.redirectLocation
      ? searchProductData.records?.map((record) => record.skuNumber.toString()) ?? []
      : isBrandPage
      ? brandProductData?.records?.map((record) => record.skuNumber.toString()) ?? []
      : [];

  const isShelf = pageTypeData?.pageType === shelfPageType.productShelf;
  const { data: productShelfData, isSuccess: productShelfSuccess } = useProductShelfResults();
  /* Analytics start Shelf-page SeoPage */
  useEffect(() => {
    if (isShelf) {
      shopByPageTypeInfo(pageTypeData.makeModelYearPath ?? '', []);
    }
  }, [pageTypeData?.pageType, pageTypeData?.makeModelYearPath, isShelf]);
  /* Analytics end */

  const pageData = isBrandPage ? brandData : categoryData;
  const { data: headerData, isFetching: headerIsFetching } = useHeaderData();
  const { data: configFlagsData, isFetching: confligFlagsDataIsFetching } = useConfigFlags({
    partNumber:
      !!productShelfData?.productShelfResults &&
      'productId' in productShelfData?.productShelfResults &&
      productShelfData?.productShelfResults.productId !== null
        ? productShelfData?.productShelfResults.productId
        : '',
    enabled:
      categoryData !== undefined &&
      'productId' in pageData! &&
      !!pageData.productId &&
      locale === countryCodes.us,
  });

  const defaultCitrusChecks =
    deviceType !== 'bot' &&
    citrusEnabled &&
    citrusShelfSponsoredCardsEnabled &&
    (!hasFilters || (hasFilters && hasEngineOilFilterSelectedOnly)) &&
    !hasCustomPrice &&
    pageTypeData?.pageType === pageTypes.ProductShelf;

  const frontEndShouldCallCitrus =
    configFlagsData?.IS_SPNSRD_ADVRTSMT_FL === 'false' ||
    (configFlagsData?.IS_SPNSRD_ADVRTSMT_FL === 'true' && !headerData?.vehicleMap.catalogVehicleId);
  const shouldFetchCitrusProducts = defaultCitrusChecks && frontEndShouldCallCitrus;
  const { data: citrusData, isSuccess: citrusSuccess } = useCitrusShelfCarouselData({
    enabled:
      shouldFetchCitrusProducts &&
      confligFlagsDataIsFetching === false &&
      headerIsFetching === false &&
      productShelfSuccess,
  });

  const skuIds =
    isSearchPage || isBrandPage
      ? searchProductSkus
      : getSkuIdsForPage(
          pageData as CategoryData | BrandData | undefined,
          router.asPath,
          citrusData
        );
  const [primarySkus, secondarySkus] = enable24ProductView
    ? [skuIds.slice(0, 12), skuIds.slice(12)]
    : [skuIds, []];
  const {
    data: productSkuDetails,
    status: productSkuStatus,
    isFetching: productSkuIsFetching,
    isLoading: productSkuIsLoading,
  } = useProductSkuDetails({
    enabled: Boolean(
      !!primarySkus.length &&
        ((!brandIsFetching && brandQueryStatus === 'success') ||
          (!brandProductIsFetching && brandProductStatus === 'success') ||
          (!searchProductIsFetching && searchProductQueryStatus === 'success') ||
          categoryQueryStatus === 'success')
    ),
    skuIds: primarySkus,
  });
  const {
    data: secondaryProductSkuDetails,
    status: secondaryProductSkuStatus,
    isFetching: secondaryProductSkuIsFetching,
  } = useProductSkuDetails({
    enabled: Boolean(
      shouldFetchSecondaryProductSkus &&
        !!secondarySkus.length &&
        ((!brandIsFetching && brandQueryStatus === 'success') ||
          (!brandProductIsFetching && brandProductStatus === 'success') ||
          (!searchProductIsFetching && searchProductQueryStatus === 'success') ||
          categoryQueryStatus === 'success')
    ),
    skuIds: secondarySkus,
  });

  const { data: productSkuDeals } = useProductSkuDeals({
    skuIds: primarySkus,
    enabled: Boolean(
      !!primarySkus.length &&
        isNewDealsApiEnabled &&
        ((!brandIsFetching && brandQueryStatus === 'success') ||
          (!brandProductIsFetching && brandProductStatus === 'success') ||
          (!searchProductIsFetching && searchProductQueryStatus === 'success') ||
          categoryQueryStatus === 'success')
    ),
  });
  const { data: secondaryProductSkuDeals } = useProductSkuDeals({
    skuIds: secondarySkus,
    enabled: Boolean(
      shouldFetchSecondaryProductSkus &&
        isNewDealsApiEnabled &&
        !!secondarySkus.length &&
        ((!brandIsFetching && brandQueryStatus === 'success') ||
          (!brandProductIsFetching && brandProductStatus === 'success') ||
          (!searchProductIsFetching && searchProductQueryStatus === 'success') ||
          categoryQueryStatus === 'success')
    ),
  });

  let status: UseProductListDataResult['status'];
  const isFetching = brandIsFetching || searchProductIsFetching || brandProductIsFetching;

  if (
    categoryQueryStatus === 'success' ||
    brandQueryStatus === 'success' ||
    brandProductStatus === 'success' ||
    searchProductQueryStatus === 'success'
  ) {
    status = 'success';
  } else if (
    categoryQueryStatus === 'loading' ||
    brandQueryStatus === 'loading' ||
    searchProductQueryStatus === 'loading' ||
    brandProductStatus === 'loading'
  ) {
    status = 'loading';
  } else if (
    categoryQueryStatus === 'error' ||
    brandQueryStatus === 'error' ||
    productSkuStatus === 'error' ||
    secondaryProductSkuStatus === 'error' ||
    searchProductQueryStatus === 'error' ||
    brandProductStatus === 'error'
  ) {
    status = 'error';
  } else {
    status = 'idle';
  }

  const shelfData = useMemo(() => {
    const transformedPageData =
      !pageData && searchProductData && !searchProductData.redirectLocation
        ? searchDataSelector(searchProductData, locale)
        : brandProductData
        ? productDataBrandSelector(brandProductData, brandData)
        : pageDataSelector({
            pageData: pageData as CategoryData | BrandData | undefined,
            pathname: router.asPath,
          });

    if (brandProductData) {
      const data = productDataBrandSelector(brandProductData, brandData);
      transformedPageData.productRecords = data.productRecords;
      transformedPageData.brandName = data.brandName;
      transformedPageData.isBrandPremiumPage = data.isBrandPremiumPage;
      transformedPageData.catLabel = data.catLabel;
    }
    const newProductRecords =
      citrusEnabled &&
      citrusShelfSponsoredCardsEnabled &&
      shouldFetchCitrusProducts &&
      citrusSuccess
        ? generateNewProductRecordsForCitrus(transformedPageData.productRecords, citrusData)
        : (transformedPageData.productRecords as ShelfPart[]);
    const shelfResultHasAppItems = newProductRecords.some(
      (product) => product.originalSystemCode === 0
    );

    const skuDetailsData = productSkuDetails
      ? [...productSkuDetails, ...(secondaryProductSkuDetails ?? [])]
      : productSkuDetails;

    const batchedProductSkuDeals = productSkuDeals
      ? [...productSkuDeals, ...(secondaryProductSkuDeals ?? [])]
      : productSkuDeals;

    const mappedProductSkuDetailsandDeals = (skuDetailsData ?? []).map(
      (skuDetail: ProductSkuDetails): ProductSkuDetails => {
        const matchingRecord = batchedProductSkuDeals?.find((record: SkuDealsFromAPI) => {
          return parseInt(record.skuId) === parseInt(skuDetail.skuPricingAndAvailability.skuId);
        });

        if (matchingRecord) {
          skuDetail.skuPricingAndAvailability['comboDeal'] =
            matchingRecord?.skuDealsInfo?.comboDeal;
          skuDetail.skuPricingAndAvailability['dealAvailable'] =
            matchingRecord?.skuDealsInfo?.dealAvailable;
          skuDetail.skuPricingAndAvailability['dealConfigurableLabel'] =
            matchingRecord?.skuDealsInfo?.dealConfigurableLabel;
          skuDetail.skuPricingAndAvailability['dealUrl'] = matchingRecord?.skuDealsInfo?.dealUrl;
          skuDetail.skuPricingAndAvailability['dealsAvailableQty'] =
            matchingRecord?.skuDealsInfo?.dealsAvailableQty;
          skuDetail.skuPricingAndAvailability['discountedPrice'] =
            matchingRecord?.skuDealsInfo?.discountedPrice;
          skuDetail.skuPricingAndAvailability['inStoreDealsAvailableQty'] =
            matchingRecord?.skuDealsInfo?.inStoreDealsAvailableQty;
          skuDetail.skuPricingAndAvailability['onlineDealsAvailableQty'] =
            matchingRecord?.skuDealsInfo?.onlineDealsAvailableQty;
          skuDetail.skuPricingAndAvailability['singleDeal'] =
            matchingRecord?.skuDealsInfo?.singleDeal;
          skuDetail.skuPricingAndAvailability['rebateUrl'] =
            matchingRecord?.skuRebatesInfo?.rebateUrl;
          skuDetail.skuPricingAndAvailability['rebateShortDesc'] =
            matchingRecord?.skuRebatesInfo?.rebateShortDesc;
          skuDetail.skuPricingAndAvailability['rebatesAvailable'] =
            matchingRecord?.skuRebatesInfo?.rebatesAvailable;
        }
        return skuDetail;
      }
    );

    const batchedProductSkuDetails = isNewDealsApiEnabled
      ? mappedProductSkuDetailsandDeals
      : skuDetailsData;

    const shouldIncludeSponsoredProducts =
      !frontEndShouldCallCitrus ||
      ((!hasFilters || (hasFilters && hasEngineOilFilterSelectedOnly)) && !hasCustomPrice);
    const { updatedShelfParts, shelfBreadcrumb } = skuDetailsSelector({
      batchedProductSkuDetails,
      newProductRecords,
      pageData: pageData as CategoryData | BrandData | undefined,
      query,
      shouldIncludeSponsoredProducts,
    });
    const shelfParts =
      updatedShelfParts && updatedShelfParts.length > 0 ? updatedShelfParts : newProductRecords;

    if (!frontEndShouldCallCitrus && !citrusSuccess && locale === countryCodes.us) {
      shelfParts.forEach((part) => {
        part.isSponsoredCard = Boolean(part.sponsoredProductFlag);
      });
    }

    return shelfDataSelector({
      locale,
      pageData: pageData as CategoryData | BrandData | undefined,
      transformedPageData,
      shelfParts,
      shelfBreadcrumb,
      shelfResultHasAppItems,
    });
  }, [
    pageData,
    searchProductData,
    brandProductData,
    brandData,
    router.asPath,
    citrusEnabled,
    citrusShelfSponsoredCardsEnabled,
    citrusData,
    frontEndShouldCallCitrus,
    citrusSuccess,
    productSkuDetails,
    secondaryProductSkuDetails,
    query,
    hasCustomPrice,
    hasEngineOilFilterSelectedOnly,
    hasFilters,
    locale,
    productSkuDeals,
    shouldFetchCitrusProducts,
    isNewDealsApiEnabled,
    secondaryProductSkuDeals,
  ]);

  const data = status === 'success' ? shelfData : undefined;
  // This is later passed down in context, so we should use `useMemo` to
  // avoid re-rendering context consumers if this data didn't change but a parent re-rendered
  return useMemo(
    () => ({
      data,
      status,
      isSuccess: status === 'success',
      isLoading: status === 'loading',
      isError: status === 'error',
      isIdle: status === 'idle',
      isFetching,
      productSkuIsFetching,
      productSkuIsLoading,
      secondaryProductSkuIsFetching,
    }),
    [
      data,
      isFetching,
      productSkuIsFetching,
      secondaryProductSkuIsFetching,
      status,
      productSkuIsLoading,
    ]
  );
};
