/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect, useState } from 'react';
import root from 'window-or-global';
import styles from './styles.module.scss';

const SeoContent = ({
  productBrand,
  catalogRefIdNew,
}: {
  productBrand: string;
  catalogRefIdNew: string;
}) => {
  const [href, setHref] = useState('');

  useEffect(() => {
    setHref(root.location?.href);
  }, []);

  return (
    <div className={styles.seoContentStyles}>
      {productBrand}
      <span>{catalogRefIdNew}</span>
      {href}
    </div>
  );
};

export default SeoContent;
