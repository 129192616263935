/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

const CLEAN_PRICE_REGEX = /[^0-9.-]+/g;

export const getPriceArray = (price: string | number | string[] | number[]): string[] => {
  if (Array.isArray(price)) {
    return price.map((i) => i.toString());
  }

  if (typeof price === 'string') {
    const parsedNumber = +price.replace(CLEAN_PRICE_REGEX, '');
    return parsedNumber.toFixed(2).split('.');
  }

  if (typeof price === 'number') {
    return price.toFixed(2).toString().split('.');
  }

  throw new Error('Unexpected value provided');
};
