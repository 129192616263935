/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const accessoriesOptions = [
  'interior accessories',
  'exterior accessories',
  'truck and towing',
  'tools and equipment',
  'fluids and chemicals',
  'performance',
];
