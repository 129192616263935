/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type IItem } from '@/components/AZCustomComponent/BreadcrumbSTARC/utils/isBreadcrumbModel';

export const analyticsDynamicPageName = (
  breadCrumbData: IItem[] | undefined,
  pageType: string
): string => {
  const displayNameData = breadCrumbData?.map((bredCrumb) => {
    const { displayName } = bredCrumb || {};

    if (displayName && displayName !== '') {
      return displayName.toLowerCase().replace(/\s/g, '-');
    }
  });

  if (displayNameData && displayNameData.length > 1) {
    displayNameData.shift();
  }

  if (!displayNameData) {
    return `:${pageType}`;
  }

  return `${displayNameData.join(':')}:${pageType}`;
};
