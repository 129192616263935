/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { AxiosInstance } from 'axios';
import { requestBaseURL } from '@/config/serviceAPI';
import type {
  FacetModel,
  ProductSearchResponseModel,
  SearchRequestModel,
} from '@/api/types/browse-search-types';
import { getAxios } from '@/lib/axios';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { createQuery } from '@/utils/createReactQuery';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { NextRouter, useRouter } from 'next/router';
import { getApiOptions } from '../utils/getSearchApiOptions';
import { getMapFacet, useMapFacet } from '@/hooks/useMapFacet';
import { QueryClient } from '@tanstack/react-query';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
import { useIs24ProductViewEnabled } from '@/hooks/useIs24ProductViewEnabled';
import type { MappedSearchProductData } from '../interface';
import { usePageType } from '@/hooks/usePageType';
import { useGlobalState } from '@/hooks/useGlobalState';

const PART_TYPES_URL = `${requestBaseURL}/external/product-discovery/browse-search/v1/products/search`;

const sortingOptionsArray = [
  'featured',
  'price-asc',
  'price-desc',
  'recently-added-desc',
  'customer-rating-desc',
  'best-seller-asc',
  'best-seller-desc',
];

type GetSearchProductDataOptions = {
  mapFacet: ReturnType<typeof useMapFacet>;
  locale: Locale;
  facet: string | undefined;
  pageNumber: number | undefined;
  recordsPerPage: number | undefined;
  searchText: string | undefined;
  sort:
    | {
        sortFieldName: string | undefined;
        sortOrder: string | undefined;
      }
    | undefined;
  storeId: string | undefined;
  userSegment: string | undefined;
  vehicleId: string | undefined;
  preview: boolean;
  skipPdpRedirect: boolean;
  userProfileId?: string;
};

const searchProductDataSelector = (
  {
    searchResults,
    redirectUrl,
    oemBrandName,
    oemPartNumber,
    lwTestExperience,
    xFusionQueryId,
  }: ProductSearchResponseModel,
  mapFacet: ReturnType<typeof useMapFacet>
): MappedSearchProductData => {
  return {
    redirectLocation: redirectUrl,
    oemBrandName,
    oemPartNumber,
    lwTestExperience,
    xFusionQueryId,
    isUnitOfMeasureMatch: searchResults?.isUnitOfMeasureMatch,
    interchangeSearchFlag: searchResults?.interchangeSearchFlag,
    correctedTerm: searchResults?.correctedTerm,
    recordsPerPage: searchResults?.recordsPerPage,
    total: searchResults?.totalNumberOfRecords,
    quickFilters: searchResults?.quickFilters,
    firstRecNum: searchResults?.firstRecordNumber,
    lastRecNum: searchResults?.lastRecordNumber,
    partTypeRecords: searchResults?.partTypeRecords,
    staticNavigation: searchResults?.facets?.map(
      mapFacet ?? ((facet: FacetModel) => getMapFacet(facet, 'Clearance'))
    ),
    searchedKeyword: searchResults?.searchedKeyword,
    records: searchResults?.skuRecords?.map?.((record) => ({
      active: record.activeFlag,
      alternatePartNumber: record.alternatePartNumber,
      application: record.applicationQuestions?.join?.(', ') ?? '',
      brandName: record.brandName,
      seoUrl: record.productDetailsPageUrl,
      imageUrl: record.itemImageUrl ?? '',
      productImageUrl: record.itemImageUrl ?? '',
      description: record.itemDescription,
      skuNumber: Number(record.itemId),
      lineCode: record.lineCode,
      systemCode: Number(record.originalSystemCode),
      partNumber: record.partNumber,
      productRepositoryId: record.eCommerceProductId,
      productId: record.eCommerceProductId,
      quickNote: record.quickNote,
      techNote: record.technicalNote,
      recordType: record.recordType,
      skuReviewEnabled: record.reviewEnabledFlag,
      vehicleFitmentLabel: record.vehicleFitmentLabel,
      vehicleSpecific: record.vehicleSpecificFlag,
      warrantyMonths: record.warrantyMonths,
      locationFilter: record.itemLocation,
      brand: record.brandName ?? '',
      name: record.itemDescription,
      productReviewsEnabled: record.reviewEnabledFlag,
      vehicleFit: record.vehicleFitmentFlag ?? false,
      originalPartTypeId: record.originalPartTypeId ?? '',
      productCanonicalUrl: record.productCanonicalUrl ?? '',
      productSeoUrl: record.productSeoUrl ?? '',
      interchangeSearchFlag: record.interchangeSearchFlag ?? false,
      oemBrandName: record.oemBrandName,
      oemPartNumber: record.oemPartNumber,
      warrantyText: record.warrantyText,
    })),
  };
};

const getSearchProductData = async (
  options: GetSearchProductDataOptions,
  axiosInstance?: AxiosInstance
) => {
  const { mapFacet, locale, searchText, userProfileId, ...rest } = options;
  const country = getCountryFromLocale(locale);
  const customerType = 'B2C';
  const salesChannel = 'ECOMM';

  if (!searchText) {
    throw new Error('searchText is required');
  }
  const reqData: SearchRequestModel = {
    ...rest,
    country,
    customerType,
    salesChannel,
    searchText,
    userProfileId,
    ...(!!options.facet &&
      options.facet.includes('vehicle_fitment') && {
        ignoreVehicleSpecificProductsCheck: true,
      }),
  };

  const response = await getAxios(axiosInstance).post<ProductSearchResponseModel>(
    PART_TYPES_URL,
    reqData
  );

  return searchProductDataSelector(response.data, mapFacet);
};

const {
  useData: useSearchParts,
  prefetch: prefetchParts,
  query: productDataQuery,
} = createQuery<MappedSearchProductData, GetSearchProductDataOptions>(
  'searchProductData',
  getSearchProductData
);

const handleSortingOptionError = async (router: NextRouter) => {
  try {
    // eslint-disable-next-line no-console
    console.error(
      `An error occurred while requesting search results. Invalid sorting option was input`
    );
    await router.replace('/errorPage');
    return;
  } catch (error) {
    throw new Error(`There was a problem redirecting to /errorPage`);
  }
};

export const useSearchProductData = (
  { enabled = true }: { enabled: boolean } = {
    enabled: true,
  }
) => {
  const enable24ProductView = useIs24ProductViewEnabled();
  const locale = useLocale();
  const router = useRouter();
  const mapFacet = useMapFacet();
  const preferredVehicle = usePreferredVehicle();
  const vehicleId = preferredVehicle?.catalogVehicleId;
  const { data: storeDetailsData } = useStoreDetailsData();
  const storeNumber = storeDetailsData?.storeNumber;
  const [searchState] = useGlobalState('search');
  const { data: headerData } = useHeaderData();
  const { pageType } = usePageType();
  const userProfileId = headerData?.myAccountMap?.profileId;
  const options = getApiOptions({
    enabled: enabled,
    router,
    locale,
    vehicleId,
    storeNumber,
    mapFacet,
    enable24ProductView,
    preview: showXMPreviewDate(),
    vehicleIdChanged: searchState?.preferredVehicleChanged,
    ...(userProfileId && { userProfileId }),
  });
  const isValidSortingOption = sortingOptionsArray.includes(
    !options.sort?.sortOrder && options.sort?.sortFieldName
      ? `${options.sort?.sortFieldName}`
      : `${options.sort?.sortFieldName}-${options.sort?.sortOrder}`
  );

  if (options.sort && !isValidSortingOption && pageType === 'search') {
    handleSortingOptionError(router);
  }

  return useSearchParts({
    ...options,
    onError: async (error) => {
      try {
        // eslint-disable-next-line no-console
        console.log(`An error occurred while requesting search results: `, error);
        await router.replace('/errorPage');
        return;
      } catch (error) {
        throw new Error(`There was a problem redirecting to /errorPage`);
      }
    },
  });
};

export const getSearchProductDataFromCache = (
  queryClient: QueryClient,
  options: GetSearchProductDataOptions
) => {
  return queryClient.getQueryData<MappedSearchProductData>(productDataQuery.getFullKey(options));
};

export const prefetchSearchProductData = async (
  queryClient: QueryClient,
  options: GetSearchProductDataOptions,
  axiosInstance: AxiosInstance
) => {
  await prefetchParts(queryClient, options, axiosInstance);
};
