/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { CustomLinkName } from '@/types/analytics';
import { clickTrack } from '@/utils/analytics/clickTrack';

export const trackImageInteractionPDP = (
  customLinkName: CustomLinkName | `PDP|${number}`,
  imageRank?: string
) => {
  const utag = window.utag_data;
  const pagetype = utag?.pageType ?? '';
  const pagename = utag?.pageName ?? '';
  const sitesection = utag?.siteSection ?? '';

  clickTrack({
    eventType: 'imageInteractionPDP',
    ...(imageRank
      ? {
          imageNumber: imageRank,
        }
      : {}),
    customLinkName,
    pageName: pagename,
    pageType: pagetype,
    siteSection: sitesection,
  });
};
