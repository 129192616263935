/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import styles from './flyout.module.scss';
import { useLabel } from '@/hooks/useLabels';
import { Toggle } from '@az/starc-ui';

type Props = {
  count: number;
  filterChecked: boolean;
  setFilterChecked: (value: boolean) => void;
};

function OneOrMoreStores({ count, filterChecked, setFilterChecked }: Props) {
  const show_me_stores_with_fewer_than = useLabel(
    'sister_store_flyout_show_me_stores_with_fewer_than'
  );
  const in_stock = useLabel('sister_store_flyout_in_stock');
  return (
    <div className={styles.moreStoresContainer}>
      <Toggle
        name="checkbox"
        className={styles.toggle}
        onChange={(event) => {
          const target = event.target;
          const value = target.checked;
          setFilterChecked(value);
        }}
        checked={filterChecked}
      />
      <div className={styles.countInfoMsg}>
        <div
          className={styles.countInfoMsg}
        >{`${show_me_stores_with_fewer_than} ${count} ${in_stock}`}</div>
      </div>
    </div>
  );
}

export default OneOrMoreStores;
