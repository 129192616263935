/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import styles from './flyout.module.scss';
import { Button } from '@/components/Button';
import { useLabels } from '@/hooks/useLabels';

interface ShowMore {
  labelMap: Record<string, string>;
  numberOfSisterStores: number;
  limitCount: number;
  setLimitCount: (value: number) => void;
  setIndexToFocus: (indexToFocus: number) => void;
}

function ShowMore({
  labelMap,
  numberOfSisterStores,
  limitCount,
  setLimitCount,
  setIndexToFocus,
}: ShowMore) {
  const labels = useLabels(labelMap);
  const onShowMoreClick = () => {
    setLimitCount(limitCount + 10);
    setIndexToFocus(limitCount);
  };
  return (
    <div className={styles.showMoreButtonContainer}>
      {numberOfSisterStores > limitCount ? (
        <Button
          size="small"
          customClass={styles.showMoreButton}
          ariaLabel={labels.lblShowMoreNearbyStores}
          onClick={onShowMoreClick}
          disabled={false}
        >
          {labels.show_more}
        </Button>
      ) : null}
    </div>
  );
}

export default ShowMore;
