/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { clickTrack } from '@/utils/analytics/clickTrack';

export const trackNearbyStoreUpdate = (
  eventType: 'Update_Quantity' | 'In-Stock Near You: Set Store and Add to Cart',
  customLinkName:
    | 'In-Stock Near You: Set Store and Add to Cart: Quantity Change'
    | 'In-Stock Near You: Set Store and Add to Cart',
  analyticData: {
    productRank?: number | undefined;
    [key: string]: number | string | string[] | undefined;
  }
) => {
  const { pageName = '', pageType = '', siteSection = '' } = window.utag_data ?? {};

  const data = {
    eventType: `${pageType}: ${eventType}`,
    customLinkName: `${pageType}: ${customLinkName}`,
    pageName,
    pageType,
    siteSection,
    ...analyticData,
  };
  if (analyticData.productRank) {
    data.productRank = analyticData.productRank + 1;
  }
  clickTrack(data);
};
