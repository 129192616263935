/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import styles from './styles.module.scss';
import cx from 'classnames';
import azCommonStyles from '@/theme/globals.module.scss';
import ClearanceBadgeWrapper from '@/components/AZCustomComponent/ClearanceBadge';
import StrikeThroughPriceWrapper from '@/components/AZCustomComponent/StrikethroughPricing';
import { useLocale } from '@/hooks/useLocale';
import { Grid } from '@/components/Grid';
import { countryCodes } from '@/constants/locale';
import { useFeatureFlag } from '@/features/globalConfig';
import type { SkuPricingAndAvailabilityFromAPI } from '@/types/availability';

type CounterData = {
  itemQuantity: number;
  orderType: string;
  eligibleForNextDay: boolean | undefined;
  productInfo: {
    skuId: string;
  };
  unitAmount: number;
  lineItemPriceInfo: {
    unitPrice: number;
  };
  clearanceItem: boolean | undefined;
  strikethroughPrice: string | number | null | undefined;
  fulfillmentId: number;
};

type PdpPriceProps = {
  dealAvailable: boolean;
  dealDrawerPDPEnabled: boolean;
  priceLabel: string;
  data: CounterData;
  count: number;
  corePriceInfoPopover: React.ReactNode;
  priceInfoPopover: React.ReactNode;
  saveNowOrRebateBadges: React.ReactNode;
  skuPricingAndAvailability: SkuPricingAndAvailabilityFromAPI | undefined;
  discountedPrice: string | null;
  updatedDiscountedPrice: number;
  retailPriceInt: number;
};

const PdpPrice = ({
  dealAvailable,
  dealDrawerPDPEnabled,
  priceLabel,
  data,
  corePriceInfoPopover,
  priceInfoPopover,
  saveNowOrRebateBadges,
  skuPricingAndAvailability,
  count,
  discountedPrice,
  updatedDiscountedPrice,
  retailPriceInt,
}: PdpPriceProps) => {
  const locale = useLocale();
  const strikeThroughPricingEnabled = useFeatureFlag('SHOW_STRIKE_THROUGH_PRICING') === 'true';

  const totalCorePrice = (skuPricingAndAvailability?.corePrice ?? 0) * count;

  const showCartDiscountCaption = () => {
    if (locale === countryCodes.mx) {
      return dealAvailable && !strikeThroughPricingEnabled;
    } else {
      return dealAvailable;
    }
  };

  return (
    <div>
      {totalCorePrice ? (
        <>
          {dealAvailable && (
            <div
              className={cx(azCommonStyles['az-caption'], styles.discountCaption)}
              id="cartDiscountCaption"
            >
              {!dealDrawerPDPEnabled && priceLabel}
            </div>
          )}
          <div className={styles.coreInfoWrapper}>
            {data.clearanceItem && (
              <div className={data.strikethroughPrice ? '' : styles.marginBtm}>
                <ClearanceBadgeWrapper />
              </div>
            )}
            {strikeThroughPricingEnabled && discountedPrice && (
              <StrikeThroughPriceWrapper
                alignRight
                price={updatedDiscountedPrice}
                wasPrice={retailPriceInt}
              />
            )}
            {corePriceInfoPopover}
          </div>
        </>
      ) : (
        <>
          <Grid container spacing={1}>
            {showCartDiscountCaption() && (
              <Grid xs={12}>
                <div className={cx(azCommonStyles['az-caption'])} id="cartDiscountCaption">
                  {!dealDrawerPDPEnabled && priceLabel}
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              {priceInfoPopover}
            </Grid>
            <Grid className={styles.clearanceWrapper}>
              {data.clearanceItem && <ClearanceBadgeWrapper />}
            </Grid>
          </Grid>
          {saveNowOrRebateBadges !== null && data.clearanceItem && (
            <div className={styles.horizantalStrikeLine} />
          )}
        </>
      )}
      {saveNowOrRebateBadges}
    </div>
  );
};

export default PdpPrice;
