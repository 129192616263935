/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState, useEffect, useRef } from 'react';
import { Label } from '@/features/i18n';
import { useLabels } from '@/hooks/useLabels';
import { infoIcon } from '@/constants/images';
import Hidden from '@/components/Hidden';
import { Headline } from '@/components/Headline/Headline';
import NotificationBlock from '@/components/AZCustomComponent/NotificationBlock';
import type { AffinityRelatedProductWithPricing } from '@/types';
import NextImage from '@/components/NextImage';
import styles from './styles.module.scss';
import { RelatedPartsPictureList } from './components/RelatedPartsPictureList';
import { RelatedPartsCheckboxNameList } from './components/RelatedPartsCheckboxNameList';
import { AddToCartButtonSection } from './components/AddToCartButtonSection';
import { AffinityErrorNotification } from './components/AffinityErrorNotification';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useProductDetails } from '@/features/product/api/getProductDetails';
import { useProductSkuDetails } from '@/features/product/api/getProductSkuDetails';
import { useAddAffinityItemsToCartMutation } from '../../api/postAddAffinityItemsToCart';
import { getErrorDetails, getErrorMessage } from '@/utils/errorsHandling';
import type { MappedAffinityProduct } from '../../interface';
import { View } from '@az/starc-ui';

type Props = {
  affinityRelatedProducts: AffinityRelatedProductWithPricing[];
  addAffinityItemsToCartMutation: ReturnType<typeof useAddAffinityItemsToCartMutation>;
  shippingType: string;
  fulfillmentId: number;
};

export type AddToCartItem = {
  skuId: string;
  productId: string;
  quantity: string;
  fulfillmentTypeId: string;
  vehicleId: string;
  storeNumber: string | undefined;
};

const labelMap = {
  lblCompleteTheJob: 'label_ProductDisplayPage_body_CompleteTheJob',
  lblProductQuantityText: 'label_ProductDisplayPage_body_ProductQuantityText',
  lblFeatureNotAvailable: 'label_FeatureNotAvailable',
};

export const AffinityRelatedProductsBlock = ({
  affinityRelatedProducts,
  shippingType,
  addAffinityItemsToCartMutation,
  fulfillmentId,
}: Props) => {
  const labels = useLabels(labelMap);
  const {
    mutate: addToCartMutation,
    status: addAffinityToCartStatus,
    error: addAffinityToCartError,
  } = addAffinityItemsToCartMutation;
  const productDetailsQuery = useProductDetails();
  const productDetails = productDetailsQuery.isSuccess ? productDetailsQuery.data : null;
  const skuDetailsResult = useProductSkuDetails({
    skuIds:
      productDetailsQuery.isSuccess && productDetails?.product?.itemId
        ? [productDetails.product.itemId]
        : null,
  });
  const skuDetails = skuDetailsResult.isSuccess ? skuDetailsResult.data[0] : null;
  const { data: headerData } = useHeaderData();
  const storeNumber = headerData?.storeNumber;
  const catalogVehicleId = headerData?.vehicleMap.catalogVehicleId;
  const [parts, setParts] = useState<MappedAffinityProduct[]>([]);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const mappedAffinityRelatedProducts: MappedAffinityProduct[] =
      productDetails && skuDetails
        ? [
            // Current PDP item:
            {
              availableFulfillmentIds: skuDetails.fulfillmentOptions.map(
                (fulfillmentOption) => fulfillmentOption.groupId
              ),
              totalPrice: skuDetails.skuPricingAndAvailability.retailPrice ?? null,
              isPartSelected: !skuDetails.itemPresentInCart,
              isAvailable:
                skuDetails.fulfillmentOptions.some((option) => option.available) &&
                // Check that price is not null or 0
                Boolean(skuDetails.skuPricingAndAvailability.retailPrice),
              productImageUrl: productDetails.product?.itemImageUrl ?? '',
              productName: productDetails.product?.itemDescription ?? '',
              skuId: productDetails.product?.itemId ?? '',
              productId: productDetails.product?.ecommProductId ?? '',
              isMainItem: true,
            },
            // Affinity items:
            ...affinityRelatedProducts.map(
              ({
                availableFulfillmentIds,
                totalPrice,
                productImageUrl,
                productName,
                skuId,
                seoUrl,
                productId,
              }) => ({
                availableFulfillmentIds,
                totalPrice,
                productImageUrl,
                productName,
                skuId,
                seoUrl,
                productId,
                isAvailable: totalPrice !== null,
                isPartSelected: true,
                isMainItem: false,
              })
            ),
          ]
        : [];
    setParts(mappedAffinityRelatedProducts);
  }, [skuDetails, productDetails, affinityRelatedProducts]);

  useEffect(() => {
    if (addAffinityToCartStatus === 'error') {
      setHasError(true);
      const unavailableSkus = getErrorDetails<Array<Record<string, string>>>(
        addAffinityToCartError
      )?.map((part) => part.errorCodeDescription);
      setParts((prevParts) =>
        prevParts.map((part) => {
          if (part.skuId && unavailableSkus?.includes(part.skuId)) {
            part.isPartSelected = false;
            part.isAvailable = false;
          }

          return part;
        })
      );
    } else {
      setHasError(false);
    }
  }, [addAffinityToCartStatus, addAffinityToCartError]);

  const isPDPItemAlreadyInCart = useRef(false);

  useEffect(() => {
    // skuDetails will return itemPresentInCart just for the main pdp item
    if (!isPDPItemAlreadyInCart.current && skuDetails?.itemPresentInCart) {
      setParts((prevParts) =>
        prevParts.map((part) => {
          if (part.isMainItem) {
            part.isPartSelected = false;
            isPDPItemAlreadyInCart.current = true;
          }

          return part;
        })
      );
    }
  }, [parts, skuDetails?.itemPresentInCart]);

  const { amountOfSelectedItems, itemsTotalPrice } = parts.reduce(
    (acc, { isPartSelected, isAvailable, totalPrice }) => ({
      itemsTotalPrice:
        acc.itemsTotalPrice +
        (isAvailable && isPartSelected && totalPrice !== null ? totalPrice : 0),
      amountOfSelectedItems: acc.amountOfSelectedItems + (isAvailable && isPartSelected ? 1 : 0),
    }),
    {
      itemsTotalPrice: 0,
      amountOfSelectedItems: 0,
    }
  );

  const handleSelect = (skuId: string | null) => {
    setParts((prevParts) =>
      prevParts.map((part) => {
        if (part.skuId === skuId && part.isAvailable) {
          return { ...part, isPartSelected: !part.isPartSelected };
        }

        return part;
      })
    );
  };

  const handleAddToCart = () => {
    const selectedAndAvailableParts = parts.filter(
      ({ isPartSelected, isAvailable }) => isAvailable && isPartSelected
    );

    addToCartMutation({
      selectedAndAvailableParts,
      allPartsAmount: parts.length,
      storeNumber,
      catalogVehicleId,
      shippingType,
      fulfillmentId,
    });
  };

  const handleCloseErrorNotification = () => {
    setHasError(false);
  };

  return parts.length ? (
    <div className={styles.relatedProductsContainer}>
      <div className={styles.headerContainer}>
        <View padding={{ s: [0, 0, 6, 0] }}>
          <Headline className={styles.header} size="175">
            {labels.lblCompleteTheJob}
          </Headline>
        </View>
      </div>
      {hasError ? (
        getErrorDetails<Array<Record<string, string>>>(addAffinityToCartError)?.length &&
        getErrorMessage(addAffinityToCartError) ? (
          <AffinityErrorNotification
            error={addAffinityToCartError}
            onClick={handleCloseErrorNotification}
          />
        ) : (
          <NotificationBlock
            type="error"
            message={
              <Label
                label={getErrorMessage(addAffinityToCartError) ?? labels.lblFeatureNotAvailable}
              />
            }
          />
        )
      ) : null}
      <div className={styles.relatedPartsBody}>
        <div className={styles.relatedPartsPictureListContainer}>
          <RelatedPartsPictureList parts={parts} />
          <Hidden mdDown>
            <AddToCartButtonSection
              addAffinityToCartStatus={addAffinityToCartStatus}
              amountOfSelectedItems={amountOfSelectedItems}
              itemsTotalPrice={itemsTotalPrice}
              onClick={handleAddToCart}
            />
          </Hidden>
        </div>
        <div className={styles.productQuantityInfo}>
          <div className={styles.productQuantityInfoImageWrapper}>
            <NextImage src={infoIcon.src} alt={infoIcon.alt} height={20} width={20} />
          </div>
          <span className={styles.productQuantityInfoLabel}>{labels.lblProductQuantityText}</span>
        </div>
        <RelatedPartsCheckboxNameList parts={parts} handleSelect={handleSelect} />
        <Hidden lgUp>
          <AddToCartButtonSection
            addAffinityToCartStatus={addAffinityToCartStatus}
            amountOfSelectedItems={amountOfSelectedItems}
            itemsTotalPrice={itemsTotalPrice}
            onClick={handleAddToCart}
          />
        </Hidden>
      </div>
    </div>
  ) : null;
};
