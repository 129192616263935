/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type ContentStackProductListingPage } from '../interface';

export const getCSPLPResultList = (data?: ContentStackProductListingPage) => {
  const resultsList = data?.shelfpagebody?.find((item) => item.results_list);
  return resultsList?.results_list;
};
