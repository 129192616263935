/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import dayjs from 'dayjs';
import { useLabel } from '@/hooks/useLabels';
import styles from './styles.module.scss';
import CountDownV2 from './CountDownV2';
import EstDeliveryDate from './EstDeliveryDate';
type Props = {
  estDeliveryDateString?: string;
  cutOffDateString: string;
  orderByLabel: string;
  arrivesByLabel: string;
  displayAmPmFormat: boolean;
  // hrs mins if false or null
  styleOverride?: any;
};

export function OrderWithinHrsMinArrivesMessage({
  estDeliveryDateString,
  cutOffDateString,
  orderByLabel,
  arrivesByLabel,
  displayAmPmFormat,
  styleOverride,
}: Props) {
  const labelOrderBy = useLabel(orderByLabel);
  const labelArrives = useLabel(arrivesByLabel);
  const cutOffDateTime = dayjs(cutOffDateString);

  if (!cutOffDateTime.isValid()) {
    return null;
  }

  return (
    <div
      data-testid="orderWithin"
      className={styles.shippingOptionDetail}
      style={styleOverride ? styleOverride : null}
    >
      <span data-testid="orderby">{labelOrderBy} </span>
      <strong>
        <CountDownV2 toDate={cutOffDateTime.toDate()} amPm={displayAmPmFormat} />
      </strong>{' '}
      {!!estDeliveryDateString && (
        <>
          <span data-testid="estimated-delivery-date-label">{labelArrives} </span>
          <span data-testid="estimated-delivery-date" className={styles.inStock}>
            <EstDeliveryDate estimatedDeliveryDate={estDeliveryDateString} />
          </span>
        </>
      )}
    </div>
  );
}
