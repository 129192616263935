/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { storeModalType } from '../../constants/storeModal';
import styles from './styles.module.scss';
import FulfillmentControlStoreModalV2 from './FulfillmentControlStoreModalV2';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { defaultStoreUS } from '@/constants/locale';
import type { FulfillmentOption } from '@/types/reduxStore/skuDetails';
import NextImage from '@/components/NextImage';

export function StorePickupOption({
  option,
  skuId,
  productName,
  handleInstockAnalytics,
}: {
  option: FulfillmentOption;
  skuId: string | undefined;
  productName: string;
  handleInstockAnalytics?: () => any;
}) {
  const { data: storeData } = useStoreDetailsData();
  const isStoreSet = storeData?.storeNumber !== defaultStoreUS;
  const storeAddress = storeData?.address1;

  if (!isStoreSet) {
    return (
      <FulfillmentControlStoreModalV2
        available={option.available}
        renderType={storeModalType.searchStore}
      />
    );
  }

  const availabilityClass = !option.available ? styles.notAvailable : styles.inStock;
  const availableLabel = option.available
    ? option.availableLabel
    : `${option.availableLabel} ${storeAddress}`;
  return (
    <>
      <div className={availabilityClass}>
        {option.available && (
          <>
            <NextImage src="/images/green-tickmark.svg" alt="" width={14} height={11} />{' '}
          </>
        )}
        <span data-testid={`availability-${skuId}`} className={styles.storePickupAvailability}>
          {availableLabel}
        </span>
        <span className={styles.storeLink}>
          {option.available && (
            <>
              <span className={styles.divider}>|</span>
              <FulfillmentControlStoreModalV2
                available={option.available}
                renderType={storeModalType.myStore}
                handleInstockAnalytics={handleInstockAnalytics}
              />
            </>
          )}
        </span>
      </div>
      {option.sisterStoreAvailable && (
        <FulfillmentControlStoreModalV2
          available={option.available}
          renderType={storeModalType.nearByStore}
          skuId={skuId}
          productName={productName}
        />
      )}
    </>
  );
}
