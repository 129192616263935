/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export function invalidDataCheck(data: string | undefined | null | boolean): string | boolean {
  if (
    data === undefined ||
    data === null ||
    data === '' ||
    (Array.isArray(data) && data.length < 1)
  ) {
    return '';
  }

  return data;
}
