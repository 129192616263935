/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import ReportProblemOutlinedIcon from '@/public/images/expand-more.svg';
import iconStyles from '@/theme/iconStyles.module.scss';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  lblDoesNotFit: string;
  lblFitDesc: string;
};
const VehicleFitCard = ({ lblDoesNotFit, lblFitDesc }: Props) => (
  <div className={styles.vehicleFitSection}>
    <div className={styles.vehicleFitSectionHeader}>
      <ReportProblemOutlinedIcon
        className={cx(iconStyles.defaultIconStyle, styles.vehicleDoesNotFitIcon)}
      />
      <div className={styles.vehicleDoesNotFitTitle}>{lblDoesNotFit}</div>
    </div>
    <div className={styles.vehicleDoesNotFitDescription}>{lblFitDesc}</div>
  </div>
);

export default VehicleFitCard;
