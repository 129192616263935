/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect } from 'react';
import cx from 'classnames';
import azCommonStyles from '@/theme/globals.module.scss';
import { useLabel } from '@/hooks/useLabels';
import LocalOfferIcon from '@/public/images/local-offer.svg';
import iconStyles from '@/theme/iconStyles.module.scss';
import styles from './styles.module.scss';
import { ButtonBase } from '@/components/ButtonBase/ButtonBase';
import type { DealData } from '@/types';
import { useShowDealsDrawerButton } from '../../hooks/useShowDealsDrawerButton';

type Props = {
  handleClick: (data: DealData) => void;
  skuId: string;
  dataTestId?: string;
  seeDealsButtonRef: React.RefObject<HTMLButtonElement>;
  dealData: DealData;
  dealConfigurableLabel?: string | null;
  customClass: string;
  fulfillmentID: number;
  discounted: boolean;
};

export const DealsDrawerButtonCart = ({
  handleClick,
  skuId,
  dataTestId,
  seeDealsButtonRef,
  dealData,
  dealConfigurableLabel,
  customClass,
  fulfillmentID,
  discounted,
}: Props) => {
  const { shouldShowDealsButton } = useShowDealsDrawerButton({
    skuId,
    fulfillmentID,
    flag: 'DEAL_DRAWER_CART_ENABLED',
    discounted,
  });

  if (!shouldShowDealsButton) {
    return null;
  }

  return (
    <DealsDrawerButton
      handleClick={() => handleClick(dealData)}
      dataTestId={dataTestId}
      seeDealsButtonRef={seeDealsButtonRef}
      dealConfigurableLabel={dealConfigurableLabel}
      customClass={customClass}
    />
  );
};
type ButtonProps = {
  handleClick: (ref: React.RefObject<HTMLButtonElement>) => void;
  dataTestId?: string;
  seeDealsButtonRef: React.RefObject<HTMLButtonElement>;
  autoFocus?: boolean;
  dealConfigurableLabel?: string | null;
  customClass: string;
};

export const DealsDrawerButton = ({
  handleClick,
  dataTestId,
  seeDealsButtonRef,
  autoFocus,
  dealConfigurableLabel,
  customClass,
}: ButtonProps) => {
  useEffect(() => {
    if (autoFocus) {
      seeDealsButtonRef.current?.focus();
    }
  }, [autoFocus, seeDealsButtonRef]);
  const defaultDealLabel = useLabel('label_ShelfPage_DealsBundle_SeeAvailableDeals');
  const badgeLabel = dealConfigurableLabel ? dealConfigurableLabel : defaultDealLabel;
  return (
    <ButtonBase
      className={cx(
        azCommonStyles['az-caption'],
        styles.comboDealsButtonContainer,
        styles.dealBundleButton,
        customClass
      )}
      onClick={() => handleClick(seeDealsButtonRef)}
      data-testid={dataTestId ?? 'cart-item-promodeals-container'}
      ref={seeDealsButtonRef}
      autoFocus={autoFocus}
    >
      <div className={cx(styles.comboDealsButtonInnerContainer)}>
        <div className={cx(styles.comboDealsIcon)}>
          <LocalOfferIcon className={cx(iconStyles.defaultIconStyle, iconStyles.fontSizeSmall)} />
        </div>
        {badgeLabel}
      </div>
    </ButtonBase>
  );
};
