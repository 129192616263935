/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURLGCPExtOrInt } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { AxiosInstance } from 'axios';
import { QueryClient, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { GcpVideo } from '../interface';

const GCP_URL = `${requestBaseURLGCPExtOrInt}/sls/b2c/product-discovery-product-data/v1/product-videos`;

const getGcpProductVideos = async (
  { queryKey }: QueryFunctionContext<ReturnType<typeof gcpProductVideosKeys>>,
  axiosInstance?: AxiosInstance
) => {
  const [, { skuId }] = queryKey;

  if (skuId == null) {
    throw new Error("An 'skuId' wasn't passed");
  }

  const response = await getAxios(axiosInstance).get<GcpVideo[]>(
    `${GCP_URL}?country=USA&skuId=${skuId}`,
    { withCredentials: false }
  );

  return response.data;
};

const gcpProductVideosKeys = ({ skuId }: { skuId: string }) =>
  ['gcpProductVideos', { skuId }] as const;

export const useGcpProductVideos = ({
  skuId,
  enabled = false,
}: {
  skuId: string;
  enabled?: boolean;
}) => {
  return useQuery({
    enabled: enabled,
    queryKey: gcpProductVideosKeys({ skuId }),
    queryFn: getGcpProductVideos,
  });
};

export const prefetchGcpProductVideos = async ({
  queryClient,
  skuId,
  axiosInstance,
}: {
  queryClient: QueryClient;
  skuId: string;
  axiosInstance: AxiosInstance;
}) => {
  return queryClient.prefetchQuery({
    queryKey: gcpProductVideosKeys({ skuId }),
    queryFn: (context: QueryFunctionContext<ReturnType<typeof gcpProductVideosKeys>>) =>
      getGcpProductVideos(context, axiosInstance),
  });
};
