/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Button } from '@/components/Button';
import { storeModalType } from '../../constants/storeModal';
import { useLabels } from '@/hooks/useLabels';
import styles from './styles.module.scss';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useState } from 'react';
import { SearchStoreModal } from '../Store/StoreModal/SearchStoreModal';
import { MyStoreModal } from '../Store/StoreModal/MyStoreModal';
import cx from 'classnames';
import logger from '@/utils/logger';

type ParentProps = {
  available: boolean;
  renderType:
    | typeof storeModalType.myStore
    | typeof storeModalType.searchStore
    | typeof storeModalType.nearByStore;
  skuId?: string;
  productName?: string;
  handleInstockAnalytics?: () => any;
  numberOfSisterStoreAvailable?: number;
};
type ChildProps = {
  isDealDrawer?: boolean;
  itemPresentInCart?: boolean;
  toggleSisterStoreFlyout?: () => void;
} & ParentProps;
const labelMap = {
  lblSelectStore: 'label_DetailsPage_body_SelectStore',
  lblAtNearByStores: 'Label_AtNearByStores',
  lblAtNearByStore: 'Label_AtNearByStore',
  label_AvailableNearYou_SisterStores: 'label_AvailableNearYou_SisterStores',
  label_InStockAt_SisterStores: 'label_fulfilment_options_instock_sister_stores',
  label_PickUpAt_SisterStores: 'label.common.storepickup.instock',
};

export default function FulfillmentControlStoreModalV2({
  available,
  renderType,
  handleInstockAnalytics,
  numberOfSisterStoreAvailable,
  toggleSisterStoreFlyout,
}: ChildProps) {
  const [storeModalOpen, setStoreModalOpen] = useState(false);
  const toggleStoreModal = () => {
    if (renderType === storeModalType.nearByStore) {
      logger.error({
        message: 'NearbyStoreModal was removed',
        meta: {
          source: 'FulfillmentOptionButtonsV2',
        },
      });
    } else {
      setStoreModalOpen((state) => !state);
    }
  };

  const { data: storeData } = useStoreDetailsData();
  const storeAddress = storeData?.address1;
  const labels = useLabels(labelMap);

  const sisterStores = numberOfSisterStoreAvailable ?? 0;
  const atNearByStoreLabel = sisterStores > 1 ? labels.lblAtNearByStores : labels.lblAtNearByStore;
  const isMyStoreModal = renderType === storeModalType.myStore;
  const isSearchStoreModal = renderType === storeModalType.searchStore;
  const myOrSearchStoreLabel = isMyStoreModal ? storeAddress : labels.lblSelectStore;
  const storeAddressorModalType =
    isMyStoreModal || isSearchStoreModal ? myOrSearchStoreLabel : atNearByStoreLabel;

  const availableAtNearbyStores = !available && renderType === storeModalType.nearByStore;
  const label = storeAddressorModalType?.includes('At')
    ? storeAddressorModalType?.replace('At', `${sisterStores}`)
    : storeAddressorModalType;
  return (
    <div>
      <div className={styles.searchStoreButton} data-testid="fulfillment-store-button-container">
        {availableAtNearbyStores && (
          <div data-testid="sister-store-label" className={styles.inStockSisterStoreContainer}>
            <span className={styles.greenText}>
              {labels.label_PickUpAt_SisterStores.replace(/\sat\s*/gi, '')}
            </span>
            {` ${labels.label_PickUpAt_SisterStores.match(/at/gi)}`}
          </div>
        )}
        {availableAtNearbyStores && ' '}
        <Button
          variant="link"
          customClass={cx(
            styles.capitalize,
            availableAtNearbyStores
              ? `${styles.sisterStoreDetails} ${styles.lowercase}`
              : styles.storeDetails
          )}
          data-testid="search-store-button"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            e.nativeEvent.stopImmediatePropagation();

            if (availableAtNearbyStores) {
              toggleSisterStoreFlyout?.();
            } else {
              toggleStoreModal();
            }

            if (handleInstockAnalytics && typeof handleInstockAnalytics == 'function') {
              handleInstockAnalytics();
            }
          }}
          aria-label={storeAddressorModalType as any}
        >
          {label?.toLowerCase()}
        </Button>

        {storeModalOpen ? (
          <>
            {renderType === storeModalType.searchStore ? (
              <SearchStoreModal closeStoreModal={toggleStoreModal} />
            ) : null}
            {renderType === storeModalType.myStore ? (
              <MyStoreModal closeStoreModal={toggleStoreModal} />
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
}
