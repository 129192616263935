/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const orangeReadLessArrow = {
  src: '/images/readLessArrow.svg',
  alt: 'top Arrow',
};

export const orangeReadMoreArrow = {
  src: '/images/readMoreArrow.svg',
  alt: 'down Arrow',
};
