/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/** Ported from material-ui-flat-pagination@4.1.1 */

import * as React from 'react';
import cx from 'classnames';
import { ButtonBase } from '../ButtonBase/ButtonBase';
import styles from './FlatPagination.module.scss';
import buttonStyles from '../AZCustomComponent/Pagination/styles.module.scss';
import { useLabel } from '@/hooks/useLabels';

type BaseButtonProps = {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;

  /**
   * If `true`, the base button will be disabled.
   */
  disabled?: boolean;

  /**
   * Callback fired when the component is focused with a keyboard.
   * We trigger a `onFocus` callback too.
   */
  component?: string | React.ComponentType<any>;
  tabIndex?: number | null;
  className?: string;
  onBlur?: (event: Event) => unknown;
  onClick?: (event: React.MouseEvent<HTMLElement>, offset: number, page: number) => void;
  href?: string;
  id?: string;
  style?: {};
  'data-testid'?: string;
  'aria-label'?: string;
  'aria-disabled'?: string;
  'aria-checked'?: boolean;
  'data-theme'?: 'light' | 'dark';
  type?: string;
  role?: string;
  title?: string;
  autoFocus?: boolean;
  value?: string; // TODO: not sure what's "value" for. Investigate
};

type PagePosition = {
  page: number;
  position: Position;
};

export type RenderButtonProps = {
  offset: number;
  page: number;
  children: React.ReactElement;
};

type Size = 'small' | 'medium' | 'large';

export type Props = {
  id?: string;
  limit: number;
  offset: number;
  total: number;
  className?: string;
  component?: string;
  disabled?: boolean;
  innerButtonCount?: number;
  nextPageLabel?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>, offset: number, page: number) => void;
  renderButton?: (props: RenderButtonProps) => React.ReactElement;
  outerButtonCount?: number;
  previousPageLabel?: React.ReactNode;
  reduced?: boolean;
  size?: Size;
};

type PageButtonProps = {
  limit: number;
  page: number;
  total: number;
  pageVariant: PageVariant;
  size?: Size;
  onClick?: (event: React.MouseEvent<HTMLElement>, offset: number, page: number) => void;
  renderButton?: (props: RenderButtonProps) => React.ReactElement;
} & BaseButtonProps;

type Position = 'Current' | 'LowEllipsis' | 'HighEllipsis' | 'LowEnd' | 'HighEnd' | 'Standard';
type PageVariant = 'current' | 'ellipsis' | 'end' | 'standard';

const createPagePosition = (position: Position, page = 0): PagePosition => {
  return {
    page,
    position,
  };
};

const getOffset = (page: number, limit: number): number => {
  const offset = (page - 1) * limit;
  return offset < 0 ? 0 : offset;
};

const computePages = (
  limitProp: number,
  offsetProp: number,
  totalProp: number,
  innerButtonCountProp: number,
  outerButtonCountProp: number
): PagePosition[] => {
  const limit = limitProp >= 1 ? limitProp : 1;
  const offset = offsetProp >= 0 ? offsetProp : 0;
  const total = totalProp >= 0 ? totalProp : 0;
  const innerButtonCount = innerButtonCountProp >= 0 ? innerButtonCountProp : 0;
  const outerButtonCount = outerButtonCountProp >= 1 ? outerButtonCountProp : 1;
  const minPage = 1;
  const maxPage = Math.floor(total / limit) + (total % limit === 0 ? 0 : 1);
  const currentPage = Math.floor(offset / limit) + 1;
  const previousPage = currentPage <= minPage ? 0 : currentPage - 1;
  const nextPage = currentPage >= maxPage ? 0 : currentPage + 1;
  const pages: PagePosition[] = []; // previous

  pages.push(createPagePosition('LowEnd', previousPage)); // low

  const lowInnerReservedButtonCount = Math.max(innerButtonCount + currentPage - maxPage, 0);
  const lowInnerEllipsisPage = currentPage - innerButtonCount - lowInnerReservedButtonCount - 1;
  const lowOuterEllipsisPage = minPage + outerButtonCount;

  for (let i = minPage; i < currentPage; i++) {
    if (i < lowOuterEllipsisPage) {
      pages.push(createPagePosition('Standard', i));
    } else {
      pages.push(
        i === lowOuterEllipsisPage && i < lowInnerEllipsisPage
          ? createPagePosition('LowEllipsis')
          : createPagePosition('Standard', i)
      );

      for (let j = Math.max(i, lowInnerEllipsisPage) + 1; j < currentPage; j++) {
        pages.push(createPagePosition('Standard', j));
      }

      break;
    }
  }

  // current
  pages.push(createPagePosition('Current', currentPage)); // high

  const highInnerReservedButtonCount = Math.max(innerButtonCount - currentPage + minPage, 0);
  const highInnerEllipsisPage = currentPage + innerButtonCount + highInnerReservedButtonCount + 1;
  const highOuterEllipsisPage = maxPage - outerButtonCount;

  for (let i = currentPage + 1; i <= maxPage; i++) {
    if (i < highInnerEllipsisPage) {
      pages.push(createPagePosition('Standard', i));
    } else {
      pages.push(
        i === highInnerEllipsisPage && i < highOuterEllipsisPage
          ? createPagePosition('HighEllipsis')
          : createPagePosition('Standard', i)
      );

      for (let j = Math.max(i, highOuterEllipsisPage) + 1; j <= maxPage; j++) {
        pages.push(createPagePosition('Standard', j));
      }

      break;
    }
  }

  // next
  pages.push(createPagePosition('HighEnd', nextPage));
  return pages;
};

export default function FlatPagination({
  limit = 1,
  offset = 0,
  total = 0,
  className,
  component: Component = 'div',
  disabled = false,
  innerButtonCount: innerButtonCountProp = 2,
  nextPageLabel = '>',
  onClick,
  outerButtonCount: outerButtonCountProp = 2,
  previousPageLabel = '<',
  reduced = false,
  renderButton,
  size = 'medium',
  ...other
}: Props) {
  const innerButtonCount = reduced ? 1 : innerButtonCountProp;
  const outerButtonCount = reduced ? 1 : outerButtonCountProp;
  const goToNextPageLabel = useLabel('label_ShelfPage_Pagination_go_to_next_page');
  const goToPreviousPageLabel = useLabel('label_ShelfPage_Pagination_go_to_previous_page');
  const currentPageLabel = useLabel('label_ShelfPage_Pagination_current_page');
  const pageLabel = useLabel('label_ShelfPage_Pagination_page');

  return (
    // @ts-expect-error fix type
    <Component className={className} {...other}>
      {computePages(limit, offset, total, innerButtonCount, outerButtonCount).map(
        (pp: PagePosition) => {
          let key;
          let children: React.ReactNode;
          let pageVariant: PageVariant;
          let dataTestID: string;
          let ariaLabel: string = `${pageLabel} ${pp.page}`;
          let ariaDisabled: string = 'false';
          let ariaRole: string | undefined = undefined;

          switch (pp.position) {
            case 'Current':
              key = pp.position;
              children = pp.page;
              pageVariant = 'current';
              dataTestID = `page-button-current-${pp.page}`;
              ariaLabel = `${currentPageLabel} ${pp.page}`;
              ariaDisabled = 'true';
              ariaRole = 'link';
              break;

            case 'LowEllipsis':
            case 'HighEllipsis':
              key = pp.position;
              children = '...';
              pageVariant = 'ellipsis';
              dataTestID = 'page-button-ellipsis';
              break;

            case 'LowEnd':
            case 'HighEnd':
              key = pp.position;
              children = pp.position === 'LowEnd' ? previousPageLabel : nextPageLabel;
              pageVariant = 'end';
              dataTestID = pp.position === 'LowEnd' ? 'page-button-previous' : 'page-button-next';
              ariaLabel = pp.position === 'LowEnd' ? goToPreviousPageLabel : goToNextPageLabel;
              break;

            default:
              key = pp.page;
              children = pp.page;
              pageVariant = 'standard';
              dataTestID = `page-button-${pp.page}`;
              ariaLabel;
              ariaDisabled;
              break;
          }

          return (
            <PageButton
              limit={limit}
              page={pp.page}
              total={total}
              disabled={disabled}
              key={key}
              onClick={onClick}
              renderButton={renderButton}
              pageVariant={pageVariant}
              size={size}
              data-testid={dataTestID}
              aria-label={ariaLabel}
              aria-disabled={ariaDisabled}
              role={ariaRole}
            >
              {children}
            </PageButton>
          );
        }
      )}
    </Component>
  );
}

const handleClick =
  (
    page: number,
    limit: number,
    onClick: (event: React.MouseEvent<HTMLElement>, offset: number, page: number) => void
  ) =>
  (event: React.MouseEvent<HTMLElement>): void => {
    onClick(event, getOffset(page, limit), page);
  };

function PageButton({
  limit = 1,
  page = 0,
  total = 0,
  pageVariant = 'standard',
  disabled: disabledProp = false,
  onClick: onClickProp,
  renderButton,
  size,
  ...other
}: PageButtonProps) {
  const isCurrent: boolean = pageVariant === 'current';
  const isEllipsis: boolean = pageVariant === 'ellipsis';
  const isEnd: boolean = pageVariant === 'end';
  const isStandard: boolean = pageVariant === 'standard';
  const isSmall = size === 'small';
  const disabled = disabledProp || isEllipsis || page <= 0 || total <= 0;
  const isClickable = !disabled && (isEnd || isStandard);
  let onClick: (event: React.MouseEvent<HTMLElement>) => unknown;

  if (isClickable && onClickProp) {
    onClick = handleClick(page, limit, onClickProp);
  }

  const button = (
    // @ts-expect-error fix type
    <ButtonBase
      className={cx(styles.root, {
        [styles.disabled]: disabled,
        [styles.rootCurrent]: isCurrent,
        [styles.rootEllipsis]: isEllipsis,
        [styles.rootEnd]: isEnd,
        [styles.rootStandard]: isStandard,
        [styles.sizeSmallCurrent]: isCurrent && isSmall,
        [styles.sizeSmallEllipsis]: isEllipsis && isSmall,
        [styles.sizeSmallEnd]: isEnd && isSmall,
        [styles.sizeSmallStandard]: isStandard && isSmall,
      })}
      disabled={disabled}
      // @ts-expect-error make sure that values are always declared
      onClick={onClick}
      {...other}
    />
  );

  if (renderButton && isClickable) {
    return renderButton({
      offset: getOffset(page, limit),
      page,
      children: button,
    });
  }

  return <li className={buttonStyles.liStyle}>{button}</li>;
}
