/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const labelMap = {
  label_deal_bundle_constraint_item: 'label_deal_bundle_constraint_item',
  label_deal_bundle_current_vehicle_does_not_fit_title:
    'label_deal_bundle_current_vehicle_does_not_fit_title',
  label_deal_bundle_current_vehicle_does_not_fit_description:
    'label_deal_bundle_current_vehicle_does_not_fit_description',
};
