/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useLabels } from '@/hooks/useLabels';
import { sessionStorage } from '@/utils/sessionStorage';
import { SmartLink as Link } from '@/utils/smartLink';
import type { MappedAffinityProduct } from '../../../../interface';
import styles from './RelatedPartCheckboxName.module.scss';
import { Checkbox, Text } from '@az/starc-ui';

type Props = {
  part: MappedAffinityProduct;
  isCurrentPDPPart: boolean;
  handleSelect: (skuId: string | null) => void;
};

const labelMap = {
  currentlyViewing: 'label_ProductDisplayPage_body_CurrentlyViewing',
  priceNotAvailable: 'label_shelf_resultsList_price_not_available',
};

export function RelatedPartCheckboxName({
  part: { skuId, productName, isPartSelected, totalPrice, isAvailable, seoUrl = '' },
  isCurrentPDPPart,
  handleSelect,
}: Props) {
  const { currentlyViewing, priceNotAvailable } = useLabels(labelMap);

  const handleChange = () => {
    if (isAvailable) {
      handleSelect(skuId);
    }
  };

  const handleUrlClick = () => {
    sessionStorage.setItem('pdpUtagViewAffinity', 'true');
  };

  const price = totalPrice === null ? priceNotAvailable : `$${totalPrice.toFixed(2)}`;

  return (
    <Checkbox
      checked={isAvailable && isPartSelected}
      className={styles.container}
      onChange={handleChange}
      name={skuId}
      value={skuId}
      label={
        <Text as="p" className={styles.label}>
          <Text as="span" className={styles.productName}>
            {isCurrentPDPPart ? (
              <>
                <Text as="strong" weight="bold">{`${currentlyViewing}: `}</Text>
                {productName}
              </>
            ) : (
              <Link to={seoUrl} onClick={handleUrlClick} className={styles.link}>
                {productName}
              </Link>
            )}
          </Text>
          <Text as="strong" weight="bold">
            {price}
          </Text>
        </Text>
      }
    />
  );
}
