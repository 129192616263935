/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import cx from 'classnames';
import styles from './styles.module.scss';
import ConstraintSwapButton from './ConstraintSwapButton';
import BundleDealConstraint from '../BundleDealConstraint';
import type { DealConstraint, DealConstraintSkuDetails } from '../../../interface';

type Props = {
  onConstraintSwapClick: (
    constraint: DealConstraint,
    constraintSkuDetails: DealConstraintSkuDetails
  ) => void;
  parentConstraint: DealConstraint;
  parentConstraintSkuDetails: DealConstraintSkuDetails;
  label: string;
};

const ParentConstraint = ({
  onConstraintSwapClick,
  parentConstraint,
  parentConstraintSkuDetails,
  label,
}: Props) => {
  return (
    <div className={styles.section}>
      <div className={styles.parentSkuHeader}>
        <h2 className={cx(styles.sectionTitle, styles.parentSkuHeaderSectionTile)}>{label}</h2>
        {parentConstraint.skus.length > 1 && (
          <ConstraintSwapButton
            onSwapClick={() => {
              onConstraintSwapClick(parentConstraint, parentConstraintSkuDetails);
            }}
          />
        )}
      </div>

      <div className={styles.separator} />
      <BundleDealConstraint
        showItemHeading={false}
        constraint={parentConstraint}
        skuDetails={parentConstraintSkuDetails}
      />
    </div>
  );
};

export default ParentConstraint;
