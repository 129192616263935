/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type MouseEventHandler, useState } from 'react';
import dynamic from 'next/dynamic';
import type { CarouselItem } from '@/components/Carousel/ThumbnailComponent/types';
import { type ReactZoomPanPinchRef, type ReactZoomPanPinchProps } from 'react-zoom-pan-pinch';
import { PinchZoomImage } from '../PinchZoomImage';

const LazyReactImageMagnify = dynamic(
  () => import('../ReactImageMagnify').then((mod) => mod.ReactImageMagnify),
  {
    ssr: false,
  }
);

type CarouselImageProps = {
  useMagnify?: boolean;
  item: CarouselItem;
  handleClick?: MouseEventHandler<HTMLDivElement>;
  pinchZoomProps?: ReactZoomPanPinchProps;
  overLayText?: string;
  priority?: boolean;
  showMediumImageBeforeInteraction: boolean;
  itemIndex: number;
};

export const CarouselImage = ({
  useMagnify,
  item,
  handleClick,
  pinchZoomProps,
  overLayText,
  priority,
  showMediumImageBeforeInteraction,
  itemIndex,
}: CarouselImageProps) => {
  const [showMediumImage, setShowMediumImage] = useState(
    () => !!priority && showMediumImageBeforeInteraction
  );
  const dynamicImage = showMediumImage ? item.mediumImageURL : item.enlargedMediaUrl;
  const onZoomStart = (ref: ReactZoomPanPinchRef, event: TouchEvent | MouseEvent) => {
    pinchZoomProps?.onZoomStart?.(ref, event);

    setShowMediumImage(false);
  };

  if (useMagnify) {
    return (
      <LazyReactImageMagnify
        smallImage={{
          alt: item.alt,
          isFluidWidth: true,
          src: dynamicImage ?? '',
        }}
        largeImage={{
          src: item.enlargedMediaUrl ?? '',
          width: 1000,
          height: 1000,
        }}
        enlargedImagePosition="over"
      />
    );
  }

  return (
    <PinchZoomImage
      item={item}
      handleClick={handleClick}
      showMediumImageBeforeInteraction={showMediumImageBeforeInteraction}
      pinchZoomProps={{ ...pinchZoomProps, onZoomStart }}
      overLayText={overLayText}
      priority={priority}
      itemIndex={itemIndex}
      imageSrc={dynamicImage ?? ''}
    />
  );
};
