/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const acceptableBrandQueryParams = {
  filters: true,
  maxPrice: true,
  minPrice: true,
  pageNumber: true,
  rrec: true,
  sort: true,
  vehicleId: true,
  intcmp: true,
  cmpid: true,
  previewDate: true,
  workspaceId: true,
} as const;
