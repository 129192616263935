/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState } from 'react';
import Modal from '@/components/Modal';
import { globalConstant } from '../../../constants/global';
import { SearchStoreModal } from './SearchStoreModal';

export type ModalWrapperProps = {
  closeStoreModal: (handle?: boolean) => void;
  closeModalOnBack?: boolean;
  ariaLabelledBy?: string;
};
type Props = {
  children: (a: { backBtnHandler: () => void }) => React.ReactNode;
} & ModalWrapperProps;

function ModalWrapper({ children, closeStoreModal, closeModalOnBack, ariaLabelledBy }: Props) {
  const [showSearchStore, setShowSearchStore] = useState(false);

  const backBtnHandler = () => setShowSearchStore(true);

  return showSearchStore ? (
    <SearchStoreModal ariaLabelledBy={'modal-title'} closeStoreModal={closeStoreModal} />
  ) : (
    <Modal
      isOpen
      desktopSize={globalConstant.large}
      tabletSize={globalConstant.fullscreen}
      handleClose={closeStoreModal}
      showCloseButton
      showBackButton
      handleBack={closeModalOnBack ? closeStoreModal : backBtnHandler}
      body={children({
        backBtnHandler,
      })}
      inset
      noGap
      role="dialog"
      ariaModal={true}
      ariaLabelledBy={ariaLabelledBy}
    />
  );
}

export default ModalWrapper;
