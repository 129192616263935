/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export default function getPosition(elm: HTMLElement): {
  x: number;
  y: number;
} {
  let xPos = 0,
    yPos = 0;

  while (elm) {
    xPos += elm.offsetLeft - elm.scrollLeft + elm.clientLeft;
    yPos += elm.offsetTop - elm.scrollTop + elm.clientTop;

    // @ts-expect-error fix type
    elm = elm.offsetParent;
  }

  return {
    x: xPos,
    y: yPos,
  };
}
