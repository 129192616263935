/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import NextImage from '@/components/NextImage';
import { Link, Text, View } from '@az/starc-ui';
import styles from './YMMESpecificInstallVideoBanner.module.scss';
import { VideoResponseModel } from '@/api/types/products-types';

const images = {
  bannerIcon: {
    src: 'https://www.autozone.com/cdn/images/B2C/US/media/icons/D_Banner_Icon.svg',
  },
  bannerImage: {
    src: 'https://www.autozone.com/cdn/images/B2C/US/media/icons/D_Banner_Image.jpg',
  },
};

type Props = {
  data?: VideoResponseModel;
  onClick: () => void;
};

export function YMMESpecificInstallVideoBanner({ data, onClick }: Props) {
  const location = data?.locations?.[data?.sylvaniaVideos[0].location] ?? '';

  const vehicle = `${data?.vehicleYear} ${data?.vehicleMake} ${data?.vehicleModel}`;

  return (
    <Link variant="plain" onClick={onClick}>
      <div className={styles.container}>
        <View className={styles.leftImageContainer}>
          <NextImage src={images.bannerIcon.src} fill className={styles.image} />
        </View>
        <View direction="column" gap={2} className={styles.copyContainer}>
          <Text weight="bold" className={styles.installCopy}>
            Install {location.toLowerCase()} on your {vehicle}
          </Text>
          <Text className={styles.seeAllCopy}>
            See full video series covering all vehicle locations
          </Text>
        </View>
        <View className={styles.rightImageContainer}>
          <NextImage src={images.bannerImage.src} fill className={styles.image} />
        </View>
      </div>
    </Link>
  );
}
