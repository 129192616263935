/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { createQuery } from '@/utils/createReactQuery';
import type { MappedCategoryResultsResponse } from '../interface';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { requestBaseURL } from '@/config/serviceAPI';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { AxiosInstance } from 'axios';
import type {
  GetCategoryResultsUsingGetParamsModel,
  CategoryResponseModel,
} from '@/api/types/browse-search-types';
import { getAxios } from '@/lib/axios';
import { pageTypes } from '@/constants/page';
import { QueryClient } from '@tanstack/react-query';
import { usePageType } from './getPageType';
import { useAppState } from '@/stores/AppState';
import { useHeaderData } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { useRouter } from 'next/router';
import type { Store } from '@/types';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';

const CATEGORY_DATA_URL = `${requestBaseURL}/external/product-discovery/browse-search/v1/categories`;

type Options = {
  locale?: Locale;
  vehicleId: string | undefined;
  taxonomyPath?: string;
  canonicalPath?: string;
  pageType?: string;
  recsPerPage?: string | undefined;
  makeModelYearPath?: string | undefined;
  storeId?: string;
  preview: boolean;
};

const {
  useData: useCategoryResultsData,
  prefetch: prefetchCategoryResultsData,
  query: categoryResultsQuery,
} = createQuery<MappedCategoryResultsResponse, Options>(
  'category-results',
  async (options, axiosInstance) => getCategoryResultsData(options, axiosInstance)
);

const getCategoryResultsData = async (options: Options, axiosInstance?: AxiosInstance) => {
  const { locale, taxonomyPath, vehicleId, canonicalPath, pageType, makeModelYearPath, preview } =
    options;

  const country = getCountryFromLocale(locale ?? 'en-US');

  const customerType = 'B2C';
  const salesChannel = 'ECOMM';

  if (!canonicalPath) {
    throw new Error('canonicalPath must be provided in getCategoryResults');
  }

  if (pageType === pageTypes.ProductShelf) {
    throw new Error('No category data on product shelf');
  }

  const params: GetCategoryResultsUsingGetParamsModel = {
    country,
    customerType,
    salesChannel,
    taxonomyPath,
    vehicleId,
    canonicalPath,
    makeModelYearPath,
    preview,
  };
  const response = await getAxios(axiosInstance).get<CategoryResponseModel>(CATEGORY_DATA_URL, {
    params,
  });

  return selector(response.data);
};

const selector = ({
  categoryResults,
  redirectUrl,
}: CategoryResponseModel): MappedCategoryResultsResponse => {
  return {
    redirectLocation: redirectUrl,
    shopByCategory: categoryResults?.categories?.map(
      ({ taxonomyId, displayName, canonicalPath, ...rest }) => ({
        seoUrl: canonicalPath,
        id: taxonomyId,
        name: displayName,
        ...rest,
      })
    ),
    mostPopularParts: categoryResults?.popularParts?.map(
      ({ pageType, displayName, imageUrl, canonicalPath, ...rest }) => ({
        navigationState: canonicalPath,
        link: canonicalPath,
        label: displayName,
        properties: {
          seoUrl: canonicalPath,
          refPageType: pageType,
          imageUrl: imageUrl ?? '',
          ...rest,
        },
      })
    ),
    ...categoryResults,
    taxonomyId: categoryResults?.taxonomyId ?? '',
    displayName: categoryResults?.displayName ?? '',
    pageType: categoryResults?.pageType ?? '',
  };
};

export const useCategoryResults = ({
  enabled = true,
  ...rest
}: { enabled?: boolean; pageType?: string; taxonomyPath?: string } = {}) => {
  const router = useRouter();
  const headerResult = useHeaderData();
  const preferredVehicle = getPreferredVehicle(headerResult.data);
  const locale = useLocale();

  const appState = useAppState();

  // TODO: replace with router.asPath when Next bug is fixed
  // https://github.com/vercel/next.js/issues/46876
  const reqUrl = __IS_SERVER__ ? appState.state.reqUrl : router.asPath;

  if (!reqUrl) {
    throw new Error(`A 'path' option is required`);
  }

  const { data: pageTypeData } = usePageType();

  return useCategoryResultsData({
    enabled:
      enabled && pageTypeData?.pageType !== 'ProductShelf' && router.route === '/[...seoUrlPath]',
    locale,
    vehicleId: preferredVehicle?.catalogVehicleId,
    canonicalPath: pageTypeData?.canonicalPath || undefined,
    taxonomyPath: pageTypeData?.taxonomyPath || undefined,
    pageType: pageTypeData?.pageType || undefined,
    makeModelYearPath: pageTypeData?.makeModelYearPath || undefined,
    storeId: headerResult.data?.storeNumber ?? undefined,
    preview: showXMPreviewDate(),
    staleTime: 30 * 60000,
    ...rest,
  });
};

export const prefetchCategoryResults = async (
  axiosInstance: AxiosInstance,
  queryClient: QueryClient,
  options: Options
) => {
  return prefetchCategoryResultsData(queryClient, options, axiosInstance);
};

export const getCategoryResultsFromCache = (
  queryClient: QueryClient,
  store: Store,
  options: Options
) => {
  const { appData } = store.getState();
  return queryClient.getQueryData<MappedCategoryResultsResponse>(
    categoryResultsQuery.getFullKey({
      recsPerPage: appData.deviceType === 'bot' ? '24' : undefined,
      ...options,
      locale: 'en-US',
    })
  );
};
