/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type {
  Deal,
  DealConstraint,
  DealConstraintSkuDetails,
  SkuDetails,
} from '../../../interface';
import { useDealConstraintSkuDetails } from '../../../api/getDealConstraintSkuDetails';
import { useLabels } from '@/hooks/useLabels';
import cx from 'classnames';
import { useState } from 'react';
import { Button } from '@az/starc-ui';
import { LoadingIndicator } from '@/components/AZCustomComponent/LoadingIndicator';
import NotificationBlock from '@/components/AZCustomComponent/NotificationBlock';
import PaginationComp from '@/components/AZCustomComponent/Pagination/Pagination';
import { BundleDealConstraintCard } from '../BundleDealConstraintCard';
import { NavBar } from '@/components/NavBar';
import styles from './styles.module.scss';
import { getErrorCodeDescription } from '@/utils/errorsHandling';

const chunk = (array: string[], chunk_size = 1) =>
  Array(Math.ceil(array.length / chunk_size))
    .fill(0)
    .map((_, index) => index * chunk_size)
    .map((begin) => array.slice(begin, begin + chunk_size));

type Props = {
  parentSkuDetails: SkuDetails;
  deal: Deal;
  constraint: DealConstraint;
  initialSelection: DealConstraintSkuDetails;
  onUpdate: (skuDetails: DealConstraintSkuDetails) => void;
  onBackClick: () => void;
  onClose: () => void;
  fulfillmentOption: string;
};

const labelMap = {
  label_deal_bundle_constraint_picker_title: 'label_deal_bundle_constraint_picker_title',
  label_deal_bundle_constraint_picker_description:
    'label_deal_bundle_constraint_picker_description',
  label_deal_bundle_constraint_picker_update_item:
    'label_deal_bundle_constraint_picker_update_item',
  label_deal_bundle_constraint_picker_pagination_label:
    'label_deal_bundle_constraint_picker_pagination_label',
  label_deal_bundle_unexpected_error: 'label_deal_bundle_unexpected_error',
};
const PAGE_SIZE = 5;

export const BundleDealConstraintPicker = ({
  parentSkuDetails,
  deal,
  constraint,
  initialSelection,
  onUpdate,
  onBackClick,
  onClose,
  fulfillmentOption,
}: Props) => {
  const { id: skuId } = parentSkuDetails;
  const { id: dealId } = deal;
  const labels = useLabels(labelMap);

  const [currentSelection, setCurrentSelection] =
    useState<DealConstraintSkuDetails>(initialSelection);
  const [paginationOffset, setPaginationOffset] = useState(0);

  const [skuPages] = useState(() => chunk(constraint.skus, PAGE_SIZE));
  const currentPage = Math.floor(paginationOffset / PAGE_SIZE);
  const constraintSkusList = skuPages[currentPage] ?? [];
  const constraintSkuDetailsResult = useDealConstraintSkuDetails({
    dealId,
    parentSku: skuId,
    constraintSkusList,
    fulfillmentOption,
  });

  const skusHash = constraintSkuDetailsResult.data?.skus;
  const skuDetailsOptions = skusHash ? constraintSkusList.map((skuId) => skusHash[skuId]) : null;

  const onSelectionChange = (constraintSkuDetails: DealConstraintSkuDetails) => {
    setCurrentSelection(constraintSkuDetails);
  };

  const onOffsetChange = (newOffset: number) => {
    setPaginationOffset(newOffset);
  };

  let content;

  if (constraintSkuDetailsResult.isError) {
    content = (
      <div className={styles.bundleDetailsBody}>
        <div className={styles.errorContainer}>
          <NotificationBlock
            type="error"
            message={
              getErrorCodeDescription(constraintSkuDetailsResult.error) ??
              labels.label_deal_bundle_unexpected_error
            }
          />
        </div>
      </div>
    );
  } else if (constraintSkuDetailsResult.isLoading) {
    content = (
      <div className={styles.loadingContainer}>
        <LoadingIndicator />
      </div>
    );
  } else {
    content = (
      <>
        {skuDetailsOptions?.map((constraintSkuDetails) => {
          const { id: skuId } = constraintSkuDetails;
          const selected = currentSelection.id === skuId;
          return (
            <BundleDealConstraintCard
              className={styles.constraintPickerCard}
              key={skuId}
              constraint={constraint}
              skuDetails={constraintSkuDetails}
              selected={selected}
              onClick={() => onSelectionChange(constraintSkuDetails)}
            />
          );
        })}
      </>
    );
  }

  return (
    <div className={styles.bundleConstraintPicker}>
      <NavBar showBackButton showCloseButton handleBack={onBackClick} handleClose={onClose} />
      <div className={styles.bundleConstraintBody}>
        <div className={styles.section}>
          <h2 className={styles.sectionTitle}>
            {labels.label_deal_bundle_constraint_picker_title}
          </h2>
          <div className={styles.sectionDescription}>
            {labels.label_deal_bundle_constraint_picker_description}
          </div>
          <div className={cx(styles.separator, styles.noMargin)} />
          {content}
        </div>
      </div>

      {!constraintSkuDetailsResult.isLoading && (
        <>
          {skuPages && skuPages.length > 1 && (
            <div className={styles.constraintPickerPagination}>
              <PaginationComp
                offset={paginationOffset}
                limit={PAGE_SIZE}
                total={constraint.skus.length}
                updateOffset={onOffsetChange}
                reduced={true}
              />
            </div>
          )}
        </>
      )}

      {constraintSkuDetailsResult.isSuccess && (
        <div className={styles.bundleDetailsFooter}>
          <Button onClick={() => onUpdate(currentSelection)}>
            {labels.label_deal_bundle_constraint_picker_update_item}
          </Button>
        </div>
      )}
    </div>
  );
};
