/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import PaginationComp from '@/components/AZCustomComponent/Pagination/Pagination';
import { Grid } from '@/components/Grid';
import Hidden from '@/components/Hidden';
import Modal from '@/components/Modal';
import { productInfoConstants } from '@/constants/productInfoConstants';
import { useLabels } from '@/hooks/useLabels';
import azCommonStyles from '@/theme/globals.module.scss';
import { isMobile } from '@/utils/common';
import React from 'react';
import { useProductDeals } from '../../api/getProductDeals';
import { PDPDeals } from '../PDPDeals';
import styles from './styles.module.scss';

type Props = {
  openDialog: boolean;
  productDetails: {
    skuId: string | null;
    productId: string | undefined;
    skuDescription: string;
  } | null;
  mainImage: string;
  handleCloseModal: () => void;
  catalogRefId: string | number;
  productId: string | undefined;
};

const labelMap = {
  dealMakerPackage: 'label_Deals_Dealmaker_AsPartOfOneOfOurDealmakerPackagesBelow',
  lblSpecialDealIncluding: 'label_Deals_Dealmaker_SpecialDealsIncluding',
  lblSpecialDeal: 'label_Deals_Dealmaker_SpecialDealsFor',
  lblPurchaseFor: 'label_Deals_Dealmaker_PurchaseYour',
  lblExpires: 'label_Deals_DealsPage_Expires',
};

const PAGINATION_LIMIT = 9;
const PART_TYPE_ID = 3056;

export const DealsModal = ({ productDetails, mainImage, handleCloseModal, openDialog }: Props) => {
  const [offset, setOffset] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState<number | undefined>(undefined);

  const labels = useLabels(labelMap);
  const { data: pdpDeals } = useProductDeals({
    partTypeId: PART_TYPE_ID,
    catalogRefId: productDetails?.skuId ?? '0',
    productId: productDetails?.productId ?? '0',
    currentPage,
  });

  const updatePagination = (newOffset: number, newPageNumber: number) => {
    setCurrentPage(newPageNumber);
    setOffset(newOffset);
  };

  const renderComponent = () => {
    const skuDescription = productDetails?.skuDescription ?? '';
    const mainTitleSplitText = skuDescription.split(/ (.*)/);
    const isMobileSide = isMobile();
    let isMobileSideLabel;

    if (isMobileSide) {
      isMobileSideLabel = labels.lblSpecialDealIncluding;
    } else {
      isMobileSideLabel = labels.lblSpecialDeal;
    }

    return (
      <div>
        <div className={styles.dealsModalContent}>
          <Grid container className={styles.headerSection}>
            <Grid item xl={12} xs={12} md={12} lg={12} sm={12}>
              <span className={azCommonStyles['az-subtitle-3-regular']}>{isMobileSideLabel}</span>
              <div className={azCommonStyles['az-subtitle-2-heavy']}>
                <span className={azCommonStyles['az-title-4-regular']}>
                  {mainTitleSplitText[0] ?? ''}
                </span>{' '}
                {mainTitleSplitText[1] ?? ''}
              </div>
            </Grid>
          </Grid>
          <Grid container className={styles.mainProduct}>
            <img
              src={mainImage}
              alt={productInfoConstants.productSelected}
              className={styles.productTagImg}
            />
            <Hidden only={['sm']}>
              <div className={styles.verticalBar} />
            </Hidden>
            <div className={styles.prodDescPopup}>
              {labels.lblPurchaseFor} {skuDescription} {labels.dealMakerPackage}{' '}
            </div>
          </Grid>
        </div>
        <Grid container className={styles.gridView}>
          {pdpDeals?.potentialDeals.map((item) => (
            <PDPDeals
              key={item.dealImageUrl}
              itemVal={item}
              staticText={labels.lblExpires}
              systemVal={pdpDeals.systemCode}
            />
          ))}
        </Grid>
        {pdpDeals && pdpDeals.dealsCount > PAGINATION_LIMIT ? (
          <PaginationComp
            offset={offset}
            limit={PAGINATION_LIMIT}
            total={pdpDeals.dealsCount}
            performFetch={updatePagination}
            pageName={productInfoConstants.dealsModal}
          />
        ) : null}
      </div>
    );
  };

  return (
    <Modal
      isOpen={openDialog}
      disableEscapeKeyDown={false}
      desktopSize="large"
      tabletSize="fullscreen"
      handleClose={handleCloseModal}
      showCloseButton
      heading=""
      body={renderComponent()}
    />
  );
};
