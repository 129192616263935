/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { PageTypeData } from '@/types';

export const getShopByPageName = (
  pageTypeData: PageTypeData | undefined | null
): 'ShopByMake' | 'ShopByModel' | 'ShopByYear' | null | undefined => {
  switch (true) {
    case pageTypeData?.make && !pageTypeData?.model && !pageTypeData?.year:
      return 'ShopByMake';
    case pageTypeData?.make && pageTypeData?.model && !pageTypeData?.year:
      return 'ShopByModel';
    case !!pageTypeData?.make && !!pageTypeData?.model && !!pageTypeData?.year:
      return 'ShopByYear';
    default:
      return null;
  }
};
