/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import environmentConfig from '@/config/environment';
import type { Locale } from '@/types/i18n';

export const format360Url = (uri: string, locale: Locale, imageWidth?: number): string => {
  const domainUrl = environmentConfig.THREE_SIXTY_IMAGE_BASE_URL || 'https://images.autozone.com';
  const baseUrl = `${domainUrl}/360/${locale.replace('-', '/')}`;
  return imageWidth ? `${baseUrl}${uri}?imwidth=${imageWidth}` : `${baseUrl}${uri}`;
};
