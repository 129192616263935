/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect, useState } from 'react';

export const useExecuteOnce = ({ enabled = true, fn }: { enabled?: boolean; fn: () => void }) => {
  const [hasRun, setHasRun] = useState(false);

  useEffect(() => {
    if (!hasRun && enabled) {
      fn();
      setHasRun(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);
};
