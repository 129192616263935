/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { MAX_VISIBLE_THUMBNAILS } from '@/components/Carousel/constants';
import ThumbnailComponent from '@/components/Carousel/ThumbnailComponent';
import type {
  CarouselItem,
  CarouselItemTypes,
} from '@/components/Carousel/ThumbnailComponent/types';
import { eventConstants } from '@/constants/event';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import React, { useRef, useState, Suspense, useCallback } from 'react';
import { use360Inventory } from '../../api/get360Inventory';
import { useProductVideos } from '../../api/getProductVideos';
import { useGcpProductVideos } from '../../api/getGcpProductVideos';
import { CarouselComponent } from './CarouselComponent';
import styles from './styles.module.scss';
import { map360CarouselItem } from './utils/map360CarouselItem';
import { type ProductModel } from '@/api/types/products-types';
import ProgressModal from '@/components/AZCustomComponent/ProgressModal';
import { useFeatureFlag } from '@/features/globalConfig';
import dynamic from 'next/dynamic';
import { YMMESpecificInstallVideoBanner } from '@/components/ProductDetail/ProductAvailabilityInfo/YMMESpecificInstallVideoBanner/YMMESpecificInstallVideoBanner';
import { useSylvaniaVideos } from '@/features/product/api/getSylvaniaVideos';
import { trackImageInteractionPDP } from '../../utils/analytics/trackImageInteractionPDP';
import CustomizableSkuBadge from '@/features/configurableSku';
import { useMonetateDecisionFlag } from '@/features/kibo';

const LazyProductImageModal = dynamic(
  () => import('./ProductImageModal').then((mod) => mod.ProductImageModal),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

const LazyEnlargedMediaComponent = dynamic(() =>
  import('./EnlargedMediaComponent').then((mod) => mod.EnlargedMediaComponent)
);

type Props = {
  data: ProductModel;
  labels: Record<string, string>;
};

const labelMap = {
  label_CategoryPage_body_ImageNotAvailable: 'label_CategoryPage_body_ImageNotAvailable',
  hoverToZoomLabel: 'label_hover_over_image_to_zoom',
  tapToZoomLabel: 'Label_tap_image_to_zoom',
  tapImage360Label: 'label_tap_image_to_view_360',
  dragImageLabel: 'label_drag_image_to_view_all_slides',
};

export const ProductCarousel = (props: Props) => {
  const { itemDescription, itemMediaUrl, itemId } = props.data;
  const isMobileDevice = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const labels = useLabels(labelMap);
  const locale = useLocale();
  const initializedImages = useRef(0);
  const [modalTab, setModalTab] = useState('images');
  const [pinchToZoomImagesInitialized, setPinchToZoomImagesInitialized] = useState(false);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const { hoverToZoomLabel, tapToZoomLabel, tapImage360Label, dragImageLabel } = labels;
  const [selectedModalThumbnailIndex, setSelectedModalThumbnailIndex] = useState(0);
  const [selectedPDPThumbnailIndex, setSelectedPDPThumbnailIndex] = useState(0);
  const [aprimoVideoTag, setAprimoVideoTag] = useState<HTMLVideoElement | null>(null);
  const enlargedImages = itemMediaUrl?.enlargedItemImageUrls || [];
  const imageThumbnails = itemMediaUrl?.thumbnailItemImageUrls || [];
  const imageData = enlargedImages.map(mapToImageObject);
  const { data: threeSixtyInventory } = use360Inventory();
  const { skus } = threeSixtyInventory || {};
  const threeSixtySkuItem = itemId !== null ? skus?.[itemId] : undefined;
  const threeSixtyImageThumbnails = map360CarouselItem(locale, threeSixtySkuItem, itemDescription);

  const installVideosEnabled = useFeatureFlag('IS_YMME_INSTALL_VIDEOS_ENABLED') === 'true';
  const { data: sylvaniaData } = useSylvaniaVideos({
    enabled: installVideosEnabled,
    productId: props.data.itemId,
  });

  const installationGuideEnabled = Boolean(installVideosEnabled && !!sylvaniaData);

  const isGcpUrlEnabled = useMonetateDecisionFlag('pdGcpUrlEnabled');
  const gcpProductsVideosResult = useGcpProductVideos({ skuId: itemId, enabled: isGcpUrlEnabled });

  // TODO: remove old product videos api and logic once GCP flag isn't needed
  const videosResult = useProductVideos(itemId, !isGcpUrlEnabled);
  const aprimoPDPVideosEnabled = useFeatureFlag('APRIMO_PDP_VIDEO_ENABLED') === 'true';

  const videoThumbnails: CarouselItem[] =
    (isGcpUrlEnabled
      ? gcpProductsVideosResult.data?.map(({ thumbnailURL, videoURL, title }) => ({
          thumbnail: thumbnailURL,
          enlargedMediaUrl: videoURL,
          title,
          type: 'video' as const,
        }))
      : videosResult.data?.map(({ asset: { thumbnailUrl }, id, title }) => ({
          thumbnail: thumbnailUrl || '',
          enlargedMediaUrl: aprimoPDPVideosEnabled
            ? id
            : `//www.autozone.com/videos/tvpembed/${id}?autoplay=0`,
          title,
          type: 'video' as const,
        }))) ?? [];

  const videoThumbnailsExist = !!videoThumbnails.length;
  const allThumbnails = [...imageData, ...videoThumbnails, ...threeSixtyImageThumbnails];

  function mapToImageObject(enlargedMediaUrl: string, index: number): CarouselItem {
    return {
      alt: itemDescription,
      enlargedMediaUrl,
      mediumImageURL: index === 0 ? getMediumImageURL(enlargedMediaUrl) : undefined,
      thumbnail: imageThumbnails[index],
      type: 'image',
    };
  }

  function getMediumImageURL(imageURL: string | undefined) {
    if (
      imageURL &&
      /^https:\/\/contentinfo\.autozone\.com\/znetcs\/product-info\/([^/]+\/){4}image\/\d+\/$/.test(
        imageURL
      )
    ) {
      return imageURL.replace(/\/\d+\/$/, '/8/');
    }

    return imageURL;
  }

  const onPinchToZoomImageInit = () => {
    initializedImages.current = initializedImages.current + 1;
    if (initializedImages.current === enlargedImages.length) {
      //avoid jumpy centering on mount of pinch to zoom images
      setTimeout(() => {
        setPinchToZoomImagesInitialized(true);
      }, 100);
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [enlargedMediaData, setEnlargedMediaData] = useState(
    allThumbnails[0] ? allThumbnails[0] : null
  );
  const enlargedMedia = enlargedMediaData ?? (allThumbnails[0] ? allThumbnails[0] : undefined);
  const enlargedMediaIsImage = enlargedMedia?.type === 'image';
  const enlargedMediaIs360 = enlargedMedia?.type === 'spin360';
  const imageLabel = isDesktop ? hoverToZoomLabel : tapToZoomLabel;
  const image360Label = !isMobileDevice ? dragImageLabel : tapImage360Label;
  const imageCaption = enlargedMediaIsImage ? imageLabel : enlargedMediaIs360 ? image360Label : '';

  const additionalThumbnails = [
    ...(videoThumbnailsExist ? [videoThumbnails[0]] : []),
    ...threeSixtyImageThumbnails,
  ];
  const imageCutOff = MAX_VISIBLE_THUMBNAILS - additionalThumbnails.length - 1; //one thumbnail for see more button + any video or threesixty images that need to show before see more button

  const imageThumbnailLength = imageThumbnails.length;
  const additionalThumbnailLength = additionalThumbnails.length;
  const swapLastPDPImageWithVideo =
    !isMobileDevice && imageThumbnailLength > imageCutOff && additionalThumbnailLength; //if the number of images + videos or 360 images will cause the see more button to show up, replace the last image with the first video

  const pdpThumbnails = swapLastPDPImageWithVideo
    ? [
        ...allThumbnails.slice(0, imageCutOff),
        ...additionalThumbnails,
        ...allThumbnails.slice(imageCutOff, MAX_VISIBLE_THUMBNAILS - 1),
      ]
    : allThumbnails;

  const findThumbnailIndex = (thumbnails: CarouselItem[], thumbnailSrc?: string) => {
    return thumbnails.findIndex(
      (thumbnailObject: CarouselItem) => thumbnailObject.thumbnail === thumbnailSrc
    );
  };

  const handleModalTabChange = (tab: string) => {
    setModalTab(tab);
    let selectedCarouselItem;
    if (tab === '360 Image') {
      selectedCarouselItem = threeSixtyImageThumbnails[0];
    } else if (tab === 'Videos') {
      selectedCarouselItem = videoThumbnails[0];
    } else {
      selectedCarouselItem = imageData[0];
    }
    showExpandedImageModal(0, selectedCarouselItem);
  };

  const selectModalTab = (type?: CarouselItemTypes) => {
    if (type === 'spin360') {
      setModalTab('360 Image');
      return;
    } else if (type === 'video') {
      setModalTab('Videos');
      return;
    }
    setModalTab('Images');
  };

  const showExpandedImageModal = (index: number, carouselItem: CarouselItem) => {
    const { thumbnail } = carouselItem;
    const pdpIndex: number = findThumbnailIndex(pdpThumbnails, thumbnail);
    setSelectedModalThumbnailIndex(index);
    setSelectedPDPThumbnailIndex(pdpIndex);
    setEnlargedMediaData(carouselItem);
  };

  const showExpandedImagePDP = (index: number, carouselItem: CarouselItem) => {
    const { type, thumbnail } = carouselItem;
    const dataSet = type === 'image' ? imageData : videoThumbnails;
    const modalIndex: number = findThumbnailIndex(dataSet, thumbnail);
    selectModalTab(carouselItem.type);
    setSelectedPDPThumbnailIndex(index);
    setSelectedModalThumbnailIndex(modalIndex);
    setEnlargedMediaData(carouselItem);
  };

  const handleClose = () => {
    setPinchToZoomImagesInitialized(false);
    initializedImages.current = 0;
    setModalOpen(false);
  };

  const handleOnKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (
      event.keyCode === eventConstants.enterKeyCode &&
      event.key === eventConstants.enterKeyType
    ) {
      return setModalOpen(true);
    }
  }, []);

  const handleOpenCarouselModal = useCallback(() => {
    setModalOpen(true);

    if (aprimoVideoTag) {
      aprimoVideoTag.pause();
    }
  }, [aprimoVideoTag]);

  const handleThumbnailClick = (item: CarouselItem) => {
    const index = findThumbnailIndex(pdpThumbnails, item.thumbnail);

    trackImageInteractionPDP(`PDP|${index + 1}`, `${index + 1}`);
    //open modal on desktop or for 360 thumbnails on tablet
    const callback = isDesktop
      ? handleOpenCarouselModal
      : (thumbnail: CarouselItem) => {
          thumbnail.type === 'spin360' && handleOpenCarouselModal();
        };
    callback(item);
  };

  const handleSeeMoreClick = () => {
    handleOpenCarouselModal();
    trackImageInteractionPDP('See More Images-PDP');
  };

  if (allThumbnails.length > 0) {
    return (
      <div className={styles.productCarouselContainer}>
        {modalOpen ? (
          <Suspense fallback={<ProgressModal noScroll />}>
            <LazyProductImageModal
              open={modalOpen}
              imageCaption={imageCaption}
              imageData={imageData}
              videoThumbnails={videoThumbnails}
              threeSixtyImageThumbnails={threeSixtyImageThumbnails}
              selectedModalThumbnailIndex={selectedModalThumbnailIndex}
              showExpandedImageModal={showExpandedImageModal}
              data={props.data}
              handleModalTabChange={handleModalTabChange}
              modalTab={modalTab}
              onClose={handleClose}
              pinchToZoomImagesInitialized={pinchToZoomImagesInitialized}
              onPinchToZoomImageInit={onPinchToZoomImageInit}
              threeSixtySkuItem={threeSixtySkuItem}
              setSelectedPDPThumbnailIndex={setSelectedPDPThumbnailIndex}
              videoThumbnailsExist={videoThumbnailsExist}
              installationGuideExist={installationGuideEnabled}
            />
          </Suspense>
        ) : null}
        {Boolean(props.data.vehicleFitFlag) &&
          props.data.lineCode === 'SYL' &&
          installationGuideEnabled && (
            <YMMESpecificInstallVideoBanner
              data={sylvaniaData}
              onClick={() => {
                setModalTab('Installation Guide');
                handleOpenCarouselModal();
              }}
            />
          )}
        <div className={styles.desktopCarousel}>
          <ThumbnailComponent
            classes={{
              thumbnailList: styles.pdpThumbnailContainer,
            }}
            imgTitle={itemDescription}
            onImageChange={showExpandedImagePDP}
            selectedThumbnailIndex={selectedPDPThumbnailIndex}
            handleSeeMoreClick={handleSeeMoreClick}
            handleThumbnailClick={handleThumbnailClick}
            loading={isGcpUrlEnabled ? gcpProductsVideosResult.isFetching : videosResult.isFetching}
            items={pdpThumbnails}
            verticalLayout
            hoverEnabled
          />
          {!isMobileDevice ? (
            <div className={styles.desktopMedia}>
              <div className={styles.configurableSkuBadgeContainer}>
                {props.data.configurableSkuFlag && <CustomizableSkuBadge />}
              </div>
              <LazyEnlargedMediaComponent
                enlargedMedia={enlargedMedia}
                handleOnKeyDown={handleOnKeyDown}
                onClick={handleOpenCarouselModal}
                caption={imageCaption}
                setAprimoVideoTag={setAprimoVideoTag}
              />
            </div>
          ) : null}
        </div>
        <div className={styles.mobileCarousel}>
          {props.data.configurableSkuFlag && <CustomizableSkuBadge />}
          <CarouselComponent
            imgTitle={itemDescription}
            items={pdpThumbnails}
            showMediumImageBeforeInteraction
            carouselCaption={imageCaption}
            onImageChange={showExpandedImagePDP}
            handleclick={handleOpenCarouselModal}
            selectedThumbnailIndex={selectedPDPThumbnailIndex}
            controlled
            useMagnify={false}
            classes={{ customCarouselContainer: styles.customCarouselContainer }}
          />
        </div>
      </div>
    );
  }

  return <div className={styles.noProdMsg}>{labels.label_CategoryPage_body_ImageNotAvailable}</div>;
};
