/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { analyticsDataLayerVariable } from '@/utils/analytics/analyticsDataLayerVariable';
import { invalidDataCheck } from '@/utils/analytics/invalidDataCheck';

export function analyticsPDPReviewRating(rating: string, reviewCount: string) {
  const data = {
    productStarRating: invalidDataCheck(rating),
    productReviewCount: reviewCount ? reviewCount.replace(/[()]/g, '').toString() : '0',
  };
  analyticsDataLayerVariable(data);
}
