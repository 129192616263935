/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import styles from '../styles.module.scss';
import azCommonStyles from '../../../../theme/globals.module.scss';

const BreadcrumbSeparator = () => {
  return (
    <span className={cx(azCommonStyles['az-caption'], styles['breadcrumb-separator'])}>
      {'\u2215'}
    </span>
  );
};

export default BreadcrumbSeparator;
