/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { ReduxState } from '@/types';
import { useSelector } from 'react-redux';
import Skeleton from '@/components/Skeleton/Skeleton';
import Price from '@/components/AZCustomComponent/Price';
import cx from 'classnames';
import azCommonStyles from '@/theme/globals.module.scss';
import StrikeThroughPriceWrapper from '@/components/AZCustomComponent/StrikethroughPricing';
import { useFeatureFlag } from '@/features/globalConfig';
import styles from './styles.module.scss';
import { Label } from '@/features/i18n';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { countryCodes } from '@/constants/locale';
import { useShowSkeletonOrNoPrice } from './useShowSkeletonOrNoPrice';

type Props = {
  discountedPrice: string | null;
  updatedDiscountedPrice: number;
  configurableSku: boolean;
  retailPrice: string[];
  retailPriceInt: number;
  itemQuantity: number;
};

const PriceInfoPopover = ({
  discountedPrice,
  updatedDiscountedPrice,
  configurableSku,
  retailPrice,
  retailPriceInt,
  itemQuantity,
}: Props) => {
  const { isFetchingLocation } = useSelector(({ appData }: ReduxState) => appData);
  const strikeThroughPricingEnabled = useFeatureFlag('SHOW_STRIKE_THROUGH_PRICING') === 'true';
  const locale = useLocale();
  const { isLoading, isNoPrice } = useShowSkeletonOrNoPrice({
    isFetchingLocation,
    retailPriceInt,
    updatedDiscountedPrice,
    configurableSku,
  });
  const isStrikethrough = discountedPrice && parseFloat(discountedPrice) !== retailPriceInt;

  if (isNoPrice) {
    return <NoPrice />;
  }

  return isLoading ? (
    getSkeletonOrNoPrice(locale)
  ) : (
    <>
      {strikeThroughPricingEnabled && isStrikethrough ? (
        <div className={styles.strikethroughPriceWrapper}>
          <div className={cx(azCommonStyles['az-caption'])}>
            <Price
              priceType="strikethrough"
              dealPrice={updatedDiscountedPrice?.toFixed(2).split('.')}
            />
          </div>
          <div className={styles.marginLeft}>
            <StrikeThroughPriceWrapper
              price={updatedDiscountedPrice}
              wasPrice={retailPriceInt}
              quantity={itemQuantity}
            />
          </div>
        </div>
      ) : (
        <div className={cx(azCommonStyles['az-caption'])}>
          <div>
            <Price
              priceType="strikethrough"
              isConfigurableSku={configurableSku}
              dealPrice={retailPrice}
            />
          </div>
        </div>
      )}
    </>
  );
};

const getSkeletonOrNoPrice = (locale: Locale) => {
  if (locale === countryCodes.mx) {
    return <NoPrice />;
  } else {
    return <Skeleton className={styles.priceSkeleton} width={70} height={38} animation={'wave'} />;
  }
};

const NoPrice = () => (
  <div data-testid="price-fragment">
    <span className={cx(azCommonStyles['az-title-5-heavy'])}>
      <Label label="label_shelf_resultsList_price_not_available" />
    </span>
  </div>
);

export default PriceInfoPopover;
