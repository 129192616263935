/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { BrandData } from '@/features/brands';
import type { CategoryData } from '@/features/category';
import { getShelfBodyParts } from './getShelfBodyparts';
import { normalizeShelfResponse } from './normalizeShelfResponse';
import { type CitrusCarouselResponse } from '@/features/citrus';

export const getSkuIdsForPage = (
  pageData: CategoryData | BrandData | undefined,
  pathname: string,
  citrusData: CitrusCarouselResponse | undefined
): string[] => {
  const { shelfPageBody } = normalizeShelfResponse({
    data: pageData,
    pathname: pathname,
  });
  const citrusSponsoredSkus = citrusData?.ads
    .slice(0, 3)
    .map((ad) => ad.gtin)
    .filter(Boolean);

  const shelfBodyParts = getShelfBodyParts(shelfPageBody);
  const skuIds =
    shelfBodyParts.resultsList?.records?.map((record) => record.skuNumber.toString()) ?? [];

  if (citrusSponsoredSkus?.length) {
    skuIds.unshift(...citrusSponsoredSkus);
  }

  // Remove duplicate SKU IDs
  return skuIds.filter((item, index, shelf) => shelf.indexOf(item) == index);
};
