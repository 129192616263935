/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { countryCodes } from '@/constants/locale';
import type { Locale } from '@/types/i18n';
import type { useMapFacet } from '@/hooks/useMapFacet';
import type { NextRouter } from 'next/router';

const STALE_TIME_5_MINUTES = 60000 * 5;

export const getApiOptions = ({
  enabled,
  router,
  locale,
  vehicleId,
  storeNumber,
  mapFacet,
  preview,
  viewingPartTypes,
  isCurrentPage = false,
  enable24ProductView = false,
  vehicleIdChanged,
  userProfileId,
}: {
  enabled: boolean;
  router: NextRouter;
  locale: Locale;
  vehicleId: string | undefined;
  storeNumber: string | undefined;
  mapFacet: ReturnType<typeof useMapFacet>;
  preview: boolean;
  enable24ProductView?: boolean;
  isCurrentPage?: boolean;
  viewingPartTypes?: boolean;
  vehicleIdChanged?: boolean;
  userProfileId?: string;
}) => {
  const {
    searchText,
    currentPage,
    sort = [],
    recsPerPage,
    pageNumber,
    facet,
    minPrice,
    maxPrice,
  } = router.query;
  const sortArr = typeof sort === 'string' ? sort?.split('-') : [];
  const sortOrder = sortArr.length > 1 ? sortArr[sortArr.length - 1] : '';
  const sortFieldName = sortArr.length > 1 ? sortArr.slice(0, sortArr.length - 1).join('-') : sort;
  const newFacet =
    minPrice || maxPrice
      ? `${facet ? `${facet}++` : ''}price:${minPrice ?? '*'} to ${maxPrice ?? '*'}`
      : facet;
  return {
    mapFacet,
    locale,
    searchText: typeof searchText === 'string' ? searchText : undefined,
    pageNumber: isCurrentPage
      ? typeof currentPage === 'string'
        ? Number(currentPage)
        : 1
      : typeof pageNumber === 'string'
      ? Number(pageNumber)
      : 1,
    vehicleId: vehicleId ?? '0',
    userProfileId: userProfileId,
    skipPdpRedirect: vehicleIdChanged,
    recordsPerPage: enable24ProductView
      ? 24
      : typeof recsPerPage === 'string'
      ? Number(recsPerPage)
      : undefined,
    facet: typeof newFacet === 'string' ? newFacet : undefined,
    sort: typeof sort === 'string' ? { sortFieldName, sortOrder } : undefined,
    storeId: storeNumber,
    userSegment: undefined,
    keywordSearchVisual: viewingPartTypes || undefined,
    enabled: Boolean(
      enabled &&
        searchText &&
        typeof searchText === 'string' &&
        searchText !== '' &&
        (storeNumber || locale === countryCodes.mx || locale === countryCodes.ptBr)
    ),
    preview,
    staleTime: STALE_TIME_5_MINUTES,
    ...(!!router.asPath &&
      router.asPath.includes('ignoreVehicleSpecificProductsCheck') && {
        ignoreVehicleSpecificProductsCheck: true,
      }),
  };
};
