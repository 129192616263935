/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useMemo } from 'react';

type Props = {
  isFetchingLocation: boolean;
  retailPriceInt: number;
  updatedDiscountedPrice: number;
  configurableSku: boolean;
};

export const useShowSkeletonOrNoPrice = ({
  isFetchingLocation,
  retailPriceInt,
  updatedDiscountedPrice,
  configurableSku,
}: Props) => {
  const { isLoading, isNoPrice } = useMemo(() => {
    if (isFetchingLocation || retailPriceInt > 1 || updatedDiscountedPrice > 1) {
      return {
        isLoading: false,
        isNoPrice: false,
      };
    } else if (
      !isFetchingLocation &&
      ((!configurableSku && !retailPriceInt) || (configurableSku && !updatedDiscountedPrice))
    ) {
      return {
        isLoading: false,
        isNoPrice: true,
      };
    }

    return {
      isLoading: true,
      isNoPrice: false,
    };
  }, [retailPriceInt, updatedDiscountedPrice, isFetchingLocation, configurableSku]);

  return { isLoading, isNoPrice };
};
