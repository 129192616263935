/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import Breadcrumbs from '@/components/AZCustomComponent/BreadcrumbSTARC';
import { useProductDetails } from '@/features/product/api/getProductDetails';
import { formatBreadcrumbsForSTARC } from '@/utils/STARC/STARC';
import styles from './CMSBreadcrumbs.module.scss';

export function CMSBreadcrumbs() {
  const { data: productDetails } = useProductDetails();

  if (!productDetails?.product) {
    return null;
  }

  return (
    <Breadcrumbs
      customClass={styles.breadcrumbs}
      data={formatBreadcrumbsForSTARC(
        productDetails.product.breadCrumbs?.map((breadcrumb) => ({
          ...breadcrumb,
          url: breadcrumb.seoUrl,
        }))
      )}
    />
  );
}
