/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Link } from '@az/starc-ui';
import styles from './styles.module.scss';
import { useLabel } from '@/hooks/useLabels';

type Props = {
  onSwapClick?: () => void;
};
const ConstraintSwapButton = ({ onSwapClick }: Props) => {
  const lblSwap = useLabel('label_deal_bundle_constraint_item_swap');

  return (
    <div className={styles.constraintItemSwap}>
      <Link type="button" onClick={onSwapClick}>
        {lblSwap}
      </Link>
    </div>
  );
};

export default ConstraintSwapButton;
