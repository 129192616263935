/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type {
  ShelfPageBodyItemOne,
  ShelfPageBodyItemGeneric,
  GenericShelfItem,
  ShelfPageBodyItemFour,
  ShelfPageBodyItemTen,
  ShelfPageBodyItemResultsList,
} from '@/types/legacy';

export function isShelfPageBodyItemResultsList(
  pageContents:
    | ShelfPageBodyItemOne
    | ShelfPageBodyItemGeneric
    | GenericShelfItem
    | ShelfPageBodyItemFour
    | ShelfPageBodyItemTen
    | ShelfPageBodyItemResultsList
    | undefined
): pageContents is ShelfPageBodyItemResultsList {
  return (
    pageContents != null &&
    'disableRecordsPerPage' in pageContents &&
    pageContents.disableRecordsPerPage != null
  );
}
