/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { View, Actionable, Text, Icon, ChevronDown } from '@az/starc-ui';
import styles from './styles.module.scss';
import { useLabels } from '@/hooks/useLabels';
import cx from 'classnames';

const labelMap = {
  lblSeeAll: 'label_topnav_seeall',
  lblSeeLess: 'hyperlink_label_shelf_See_Less',
  lblParts: 'label_ContactUs_header_Parts',
};

export const ShowAllItemsBtn = ({
  expanded,
  onClick,
  numParts,
}: {
  expanded: boolean;
  onClick: () => void;
  numParts: number;
}) => {
  const labels = useLabels(labelMap);
  return (
    <View padding={4}>
      <Actionable fullWidth onClick={onClick} className={styles.showAllBtn}>
        <View padding={[5, 0]} align="center" justify="center" direction="row" gap={5}>
          <Text size="075" variant="subtitle-bold" textCase="uppercase">
            {expanded ? labels.lblSeeLess : `${labels.lblSeeAll} ${numParts} ${labels.lblParts}`}
          </Text>
          <Icon
            svg={ChevronDown}
            className={cx(styles.showAllChevron, { [styles.expanded]: expanded })}
          />
        </View>
      </Actionable>
    </View>
  );
};
