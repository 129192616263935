/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import { RelatedPartCheckboxName } from './RelatedPartCheckboxName';
import type { MappedAffinityProduct } from '../../../../interface';
import styles from './RelatedPartsCheckboxNameList.module.scss';

type Props = {
  parts: MappedAffinityProduct[];
  handleSelect: (skuId: string | null) => void;
};

const RelatedPartsCheckboxNameList = ({ parts, handleSelect }: Props) => {
  return (
    <div className={cx(styles.relatedPartsCheckboxNameList, styles.desktopListWrap)}>
      {parts.map((part, index) => (
        <RelatedPartCheckboxName
          key={`${part.skuId ?? ''}_${index}`}
          part={part}
          handleSelect={handleSelect}
          isCurrentPDPPart={index === 0}
        />
      ))}
    </div>
  );
};

export { RelatedPartsCheckboxNameList };
