/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import { Grid } from '../../Grid';
import { useLabels } from '@/hooks/useLabels';
import azCommonStyles from '../../../theme/globals.module.scss';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import styles from './styles.module.scss';
import { useYmmeDefaultData } from '@/features/ymme/utils/useYmmeDefaultData';
import { Button } from '@/components/Button';
import Image from '@/components/NextImage';
const vehicleIconSrc = '/images/vehicle-new.svg';
const warningIconSrc = '/images/icon-fitment-warning-orange.svg';
const errorIconSrc = '/images/icon-fitment-warning-red.svg';
const successIconSrc = '/images/icon-fitment-success-check.svg';

type Props = {
  fitsVehicle?: boolean;
  hasVehicle?: boolean;
  vehicleDisplayName?: string;
  toggleShowYmme: () => void;
  isList?: boolean;
  customClass?: string;
  productName?: string;
  onClickSeeFittingParts?: () => void;
  makeOrModelName?: string | null | undefined;
  displayVehicleInformation?: boolean;
  customizedVehicleName?: string | undefined;
  isConfigurableSku?: boolean;
};

const labelMap = {
  label_change_vehicle: 'hyperlink_YMME_Body_ChangeVehicle',
  label_CategoryPage_body_Check_VechicleSpecific: 'label_CategoryPage_body_Check_VechicleSpecific',
  label_fits_your_vehicle: 'label_fits_your_vehicle',
  label_doesnt_fit_vehicle: 'label_doesnt_fit_vehicle',
  label_fits_your: 'label_ymm_fitsYour_prefix',
  label_see_parts: 'label_fitment_button_see_parts',
  label_fitment_button_if_this_fits: 'label_fitment_button_if_this_fits',
  label_fitment_button_check: 'label_fitment_button_check',
  label_ymm_fitment_prefix: 'label_ymm_fitment_prefix',
  label_doesnt_fit_your: 'label_doesnt_fit_your',
  label_customized_for_your: 'label_customized_for_your',
};

const FitmentButton = ({
  fitsVehicle,
  hasVehicle,
  vehicleDisplayName,
  toggleShowYmme,
  isList = true,
  customClass,
  productName,
  makeOrModelName,
  onClickSeeFittingParts,
  displayVehicleInformation,
  customizedVehicleName,
  isConfigurableSku,
}: Props) => {
  const { data: ymmeData } = useYmmeDefaultData();
  const preferredVehicle = usePreferredVehicle();
  const labels = useLabels(labelMap);
  const defaultEngine = ymmeData?.defaultEngine;
  const listModifier = isList ? styles['fitmentButton--list'] : styles['fitmentButton--grid'];
  let buttonClassName = cx(
    azCommonStyles['az-body-2-regular'],
    styles.vehicleFit,
    styles.fitmentButton,
    listModifier
  );
  const fitsYourVehicle = labels.label_fits_your_vehicle;
  const [checkWordValue, ifWordValue] =
    labels?.label_CategoryPage_body_Check_VechicleSpecific.split(' ');
  const checkIfAutoPartFitsYourVehicle = `${checkWordValue} ${ifWordValue} ${productName} ${fitsYourVehicle}`;

  let buttonImage = null;
  let buttonText = null;
  let testId = null;
  let ariaLabel = '';

  if (makeOrModelName && !defaultEngine) {
    buttonImage = (
      <Image src={warningIconSrc} alt="" width={10} height={10} className={styles.fitmentIcon} />
    );
    buttonText = (
      <>
        <span className={styles.underlined}>{labels.label_fitment_button_check}</span>{' '}
        {labels.label_ymm_fitment_prefix}
        <strong>{` ${makeOrModelName}`}</strong>
      </>
    );
    testId = 'fitment-button-no-vehicle-selected';
  } else if (!hasVehicle) {
    buttonImage = (
      <Image src={warningIconSrc} alt="" width={10} height={10} className={styles.fitmentIcon} />
    );
    ariaLabel = checkIfAutoPartFitsYourVehicle;
    buttonText = (
      <>
        <span className={styles.underlined}>{labels.label_fitment_button_check}</span>{' '}
        {labels.label_fitment_button_if_this_fits}
      </>
    );
    testId = 'fitment-button-no-vehicle';
  } else if (fitsVehicle && !isConfigurableSku) {
    buttonImage = <Image src={successIconSrc} alt="" fill className={styles.fitmentIcon} />;
    buttonClassName += ` ${styles.fitsVehicle}`;
    const selectedVehicle = vehicleDisplayName
      ? vehicleDisplayName
      : `${preferredVehicle?.year} ${preferredVehicle?.make} ${preferredVehicle?.model}`;
    buttonText = hasVehicle ? (
      <>
        <span className={styles.successText}>{labels.label_fits_your}</span>{' '}
        <span className={styles.underlined}>{selectedVehicle}</span>
      </>
    ) : (
      <span className={styles.successText}>{labels.label_fits_your_vehicle}</span>
    );
    testId = 'fitment-button-fits-vehicle';
  } else if (isConfigurableSku) {
    buttonImage = <Image src={successIconSrc} alt="" fill className={styles.fitmentIcon} />;
    buttonClassName += ` ${styles.fitsVehicle}`;
    buttonText = (
      <>
        <span className={styles.successText}>{labels.label_customized_for_your}</span>{' '}
        <span className={styles.underlined}>{customizedVehicleName}</span>
      </>
    );
    testId = 'fitment-button-custom-sku-vehicle';
  } else {
    buttonImage = <Image src={errorIconSrc} fill alt="" className={styles.fitmentIcon} />;
    buttonClassName += ` ${styles.fitsVehicleError}`;
    buttonText = (
      <div className={styles.errorBtn}>
        {!displayVehicleInformation && (
          <div className={styles.errorText}>{labels.label_doesnt_fit_vehicle}</div>
        )}
        <div>
          {displayVehicleInformation ? (
            <>
              <span className={styles.errorText}>{labels.label_doesnt_fit_your}</span>{' '}
              <span role="button" className={cx(styles.changeVehicle, styles.underlined)}>
                {vehicleDisplayName}
              </span>
            </>
          ) : (
            <>
              <span role="button" className={cx(styles.changeVehicle, styles.underlined)}>
                {labels.label_change_vehicle}
              </span>
            </>
          )}
          {onClickSeeFittingParts && (
            <>
              <span className={styles.errorPipe}>|</span>
              <span
                role="button"
                className={cx(styles.seeFittingPartsBtn, styles.underlined)}
                onClick={(e) => {
                  e.stopPropagation();
                  onClickSeeFittingParts();
                }}
              >
                {labels.label_see_parts}
              </span>
            </>
          )}
        </div>
      </div>
    );
    testId = 'fitment-button-doesnt-fit-vehicle';
  }

  return (
    <Grid item className={customClass}>
      <Button
        variant="ghost"
        className={buttonClassName}
        onClick={toggleShowYmme}
        data-testid={testId}
        aria-label={buttonText ? '' : ariaLabel ? ariaLabel : ''}
        disabled={isConfigurableSku}
      >
        <div className={styles.imgContainer}>
          <Image src={vehicleIconSrc} alt="" width={20} height={14} />
          <div className={styles.imgSmall}>{buttonImage}</div>
        </div>
        <div className={styles.descContainer}>
          <span>{buttonText}</span>
        </div>
      </Button>
    </Grid>
  );
};

export default FitmentButton;
