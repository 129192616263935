/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const searchAnalyticsKeys = [
  'prodView',
  'productBrandList',
  'productCorePriceList',
  'productDealMessage',
  'productFilterSelection',
  'productFindingMethod',
  'productFitmentBanner',
  'productFitmentCheckMessage',
  'productGuide',
  'productIdList',
  'productNotAvailableList',
  'productPart',
  'productPartList',
  'productPartNumberList',
  'productPrice',
  'productRecommendationTypeList',
  'productRepositoryIdList',
  'productStrikeThroughPricing',
  'productToastNotification',
];
