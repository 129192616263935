/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
/* eslint-disable react-hooks/rules-of-hooks */

import React, { useRef, useState, useEffect } from 'react';
import styles from './styles.module.scss';

export const getTimeDiff = (formattedCutOff: string): number => {
  const current = new Date();
  const final = new Date(formattedCutOff);
  const diff = final.getTime() - current.getTime();
  return diff;
};

const CountDown = (props: any) => {
  const { shippingAvailability, storeStockLabel, labels } = props;

  if (shippingAvailability == null) {
    return <span className={styles.inStock}>{storeStockLabel}</span>;
  }

  const { calculatedDate, fulfillmentCutOffTime, orderByMessage } = props.shippingAvailability;
  const [current, setCurrent] = useState(getTimeDiff(fulfillmentCutOffTime));
  const savedCallback = useRef<() => void>();

  function callback() {
    setCurrent(getTimeDiff(fulfillmentCutOffTime));
  }

  useEffect(() => {
    savedCallback.current = callback;
  });
  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }

    const id = setInterval(tick, 10000);
    return () => clearInterval(id);
  }, []);

  if (current > 0) {
    const hours = current / 3600000;
    const minutes = (current % 3600000) / 60000;
    const formattedLabel = `${labels.labels_order_receive} ${calculatedDate}`;
    return (
      <p data-testid="orderWithin">
        {labels.labels_order_within}{' '}
        <strong>
          {Math.floor(hours)}
          {labels.labels_order_hrs} {labels.labels_order_and} {Math.ceil(minutes)}
          {labels.labels_order_min}{' '}
        </strong>
        {labels.labels_order_to} <span className={styles.inStock}>{formattedLabel}</span>
      </p>
    );
  }

  return <span className={styles.inStock}>{orderByMessage}</span>;
};

export default CountDown;
