/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { FulfillmentOption } from '@/types/reduxStore/skuDetails';
import NextImage from '@/components/NextImage';
import styles from './styles.module.scss';
const stdIcon = {
  src: '/images/stdDeliveryIcon-16-16.svg',
  alt: '',
};

export function StandardOption({ option }: { option: FulfillmentOption }) {
  const estDeliveryStrings: string[] = option.estimatedDeliveryDate?.split(':');

  if (option.fulfillmentTypeLabel || option.estimatedDeliveryDate) {
    return (
      <>
        <div className={styles.listItemContainer} data-testid="standard-delivery">
          <div className={styles.listItemIcon}>
            <NextImage
              role="presentation"
              src={stdIcon.src}
              alt={stdIcon.alt}
              height="16"
              width="16"
            />
          </div>
          <div className={styles.listItemDetails}>
            <div className={styles.listItemHeader}>
              <span data-testid="standard-delivery-item">{option.fulfillmentTypeLabel}</span>
            </div>
            {estDeliveryStrings && (
              <div data-testid="availability">
                <span data-testid="estimated-delivery-standard">{estDeliveryStrings[0]}</span>{' '}
                <span
                  data-testid="estimated-delivery-date-standard"
                  className={styles.deliveryMessage}
                >
                  {estDeliveryStrings[1]}
                </span>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  return null;
}
