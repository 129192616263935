/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { TableCell } from '@/components/TableCell/TableCell';
import { TableRow } from '@/components/TableRow/TableRow';
import azCommonStyles from '@/theme/globals.module.scss';
import cx from 'classnames';
import { forwardRef, type ReactNode } from 'react';
import styles from './styles.module.scss';

export type ProductSpecificationRowProps = {
  label: string;
  value: ReactNode;
  labelClassName?: string;
  valueClassName?: string;
  id?: string;
  labelTestId?: string;
  valueTestId?: string;
  compact?: boolean;
};

export const ProductSpecificationRow = forwardRef<HTMLElement, ProductSpecificationRowProps>(
  function ProductSpecificationRowComp(
    { label, value, labelClassName, valueClassName, id, labelTestId, valueTestId, compact = false },
    ref
  ) {
    return (
      <TableRow className={styles.specInnerRow} id={id} ref={ref}>
        <TableCell
          component="th"
          scope="row"
          className={cx(
            azCommonStyles['az-body-1-regular'],
            styles.specsCell,
            styles.specLabel,
            labelClassName,
            { [styles.compactCell]: compact }
          )}
          data-testid={labelTestId}
        >
          {label}
        </TableCell>
        <TableCell
          className={cx(
            azCommonStyles['az-body-1-heavy'],
            styles.specsCell,
            styles.specsValue,
            valueClassName,
            {
              [styles.compactCell]: compact,
            }
          )}
          data-testid={valueTestId}
        >
          <span>{value}</span>
        </TableCell>
      </TableRow>
    );
  }
);
