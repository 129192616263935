/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState, useEffect } from 'react';
import duration from 'dayjs/plugin/duration';
import dayjs from 'dayjs';
import { fulfillmentLabels } from '../../constants/fulfillmentConstants';
import { dayjsFormats } from '../../constants/date';
import { useLabel } from '@/hooks/useLabels';

dayjs.extend(duration);

type Props = {
  toDate: Date;
  amPm?: boolean;
};

const generateRemainingTime = (toDate: Date) => {
  return dayjs.duration(dayjs(toDate).diff(dayjs()));
};

const CountDownV2 = ({ toDate, amPm }: Props) => {
  const labelHrs = useLabel(fulfillmentLabels.label_shipping_hrs);
  const labelMinutes = useLabel(fulfillmentLabels.label_shipping_min);
  const labelAnd = useLabel(fulfillmentLabels.label_shipping_and);
  const [timeRemaining, setTimeRemaining] = useState(() => generateRemainingTime(toDate));

  useEffect(() => {
    const id = setInterval(() => setTimeRemaining(generateRemainingTime(toDate)), 10000);
    return () => clearInterval(id);
  }, [toDate]);

  const formattedAmPmShort = dayjs(toDate).format(dayjsFormats.timeOnly);
  const days = timeRemaining.days();
  const hours = timeRemaining.hours();
  const minutes = timeRemaining.minutes();
  const sumHours = days > 0 ? Math.floor(days * 24) + (hours ?? 0) : hours;
  const hoursRemainingMessage = sumHours > 0 ? `${sumHours}${labelHrs}` : ``;
  const minutesRemainingMessage = minutes > 0 ? `${minutes}${labelMinutes}` : `1${labelMinutes}`;
  const hoursMinutesRemainingMessage =
    hours > 0
      ? `${hoursRemainingMessage} ${labelAnd} ${minutesRemainingMessage}`
      : minutesRemainingMessage;
  const defaultMessage =
    minutes === 0
      ? hoursRemainingMessage
      : dayjs().isAfter(dayjs(toDate))
      ? `0${labelMinutes}`
      : hoursMinutesRemainingMessage;

  return !amPm ? (
    <span data-testid="countdown-hours">{defaultMessage}</span>
  ) : (
    <span data-testid="countdown-ampm">{formattedAmPmShort.toLowerCase()}</span>
  );
};

export default CountDownV2;
