/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { SkuPricingAndAvailabilityFromAPI } from '@/types/availability';

export const getProductStrikethroughAnalyticValue = (
  skuPricingAndAvailability: SkuPricingAndAvailabilityFromAPI | undefined,
  discountedPrice: string | null | undefined,
  strikeThroughPricingFlag: boolean
) => {
  let productStrikeThroughPricing = ['0'];
  const retailPrice = skuPricingAndAvailability?.retailPrice;
  const wasPrice = skuPricingAndAvailability?.wasRetailPrice
    ? parseInt(`${skuPricingAndAvailability?.wasRetailPrice}`)
    : undefined;
  const showStrikeThroughPricing = strikeThroughPricingFlag && !!discountedPrice;
  const isStrikethrough = wasPrice && retailPrice && wasPrice > retailPrice;
  const isClearance = skuPricingAndAvailability?.skuAvailabilityInfo.clearanceItem;
  if ((showStrikeThroughPricing && isClearance) || (!discountedPrice && isStrikethrough)) {
    productStrikeThroughPricing = ['1'];
  }

  if (!!discountedPrice) {
    productStrikeThroughPricing = ['1'];
  }

  return productStrikeThroughPricing;
};
