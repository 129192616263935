/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useLabels } from '@/hooks/useLabels';
import CartButton, { CartButtonRef } from '@/components/AZCustomComponent/CartButton';
import { useAddAffinityItemsToCartMutation } from '../../../../api/postAddAffinityItemsToCart';
import styles from './AddToCartButtonSection.module.scss';

type Props = {
  amountOfSelectedItems: number;
  itemsTotalPrice: number;
  onClick: () => void;
  addAffinityToCartStatus: ReturnType<typeof useAddAffinityItemsToCartMutation>['status'];
};

const labelMap = {
  lblAssortmentTotal: 'label_ProductDisplayPage_body_AssortmentTotal',
  lblItems: 'label_checkout_shipping_Items',
  lblItem: 'label_checkout_shipping_Item',
  lblAdd: 'label_ProductDisplayPage_body_Add',
  lblToCart: 'label_ProductDisplayPage_body_ToCart',
  lblGoToCart: 'label_CategoryPage_body_GoToCart',
};

export function AddToCartButtonSection({
  amountOfSelectedItems,
  itemsTotalPrice,
  onClick,
  addAffinityToCartStatus,
}: Props) {
  const labels = useLabels(labelMap);
  const router = useRouter();
  const cartButtonRef = useRef<CartButtonRef>(null);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [addToCartButtonClicked, setAddToCartButtonClicked] = useState(false);
  const [isAddToCartSuccess, setIsAddToCartSuccess] = useState(false);

  useEffect(() => {
    if (addAffinityToCartStatus === 'loading') {
      setAddToCartButtonClicked(true);
      setIsAddToCartSuccess(false);
      setIsLoadingState(true);
    } else if (addAffinityToCartStatus === 'success') {
      setIsLoadingState(false);
      setAddToCartButtonClicked(false);
      cartButtonRef.current?.onSuccessfulAdd();
      setIsAddToCartSuccess(true);
    } else {
      setAddToCartButtonClicked(false);
      setIsLoadingState(false);
      setIsAddToCartSuccess(false);
    }
  }, [addAffinityToCartStatus]);

  const addToCartButtonText = `${labels.lblAdd} (${amountOfSelectedItems} ${
    amountOfSelectedItems > 1 ? labels.lblItems : labels.lblItem
  }) ${labels.lblToCart}`;

  const navigateToCart = () => {
    void router.push('/cart');
  };
  const onAddToCartClick = () => {
    if (!addToCartButtonClicked) {
      setAddToCartButtonClicked(true);
      onClick();
    }
  };
  return (
    <div className={styles.addToCartButtonSection}>
      <div className={styles.assortmentTotalText}>
        <span>{`${labels.lblAssortmentTotal}:`}</span>
        <strong className={styles.itemsTotalPrice}>{`$${itemsTotalPrice.toFixed(2)}`}</strong>
        <span>{`(${amountOfSelectedItems} ${
          amountOfSelectedItems > 1 ? labels.lblItems : labels.lblItem
        })`}</span>
      </div>
      <CartButton
        ref={cartButtonRef}
        variant="contained"
        className={styles.addToCartButton}
        emphasis="low"
        fullWidth
        data-testid="cart-action-pdp-affinity"
        disabled={amountOfSelectedItems === 0}
        onClick={isAddToCartSuccess ? navigateToCart : onAddToCartClick}
        loading={isLoadingState}
      >
        {isAddToCartSuccess ? labels.lblGoToCart : addToCartButtonText}
      </CartButton>
    </div>
  );
}
