/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type ProductModel } from '@/api/types/products-types';
import { type ProductSkuDetails } from '@/features/product';
import { clickTrack } from '@/utils/analytics/clickTrack';

export const trackCustomizeSkuStart = (
  productDetails: ProductModel,
  skuDetailsData: ProductSkuDetails,
  fulfillmentID: string,
  productAvailableQuantityList: string[] | string
) => {
  const { pageName = '', pageType = '', siteSection = '' } = window.utag_data ?? {};

  clickTrack({
    customLinkName: 'Customize Your Item',
    eventType: 'Configurable SKU Start',
    originalPartTypeIdList: [productDetails.originalPartTypeId],
    pageName,
    pageType,
    siteSection,
    productAvailableQuantityList: Array.isArray(productAvailableQuantityList)
      ? productAvailableQuantityList
      : [productAvailableQuantityList],
    productBrandList: [productDetails.brandName ?? ''],
    productFulfillmentTypeList: [`${fulfillmentID}`],
    productIdList: [skuDetailsData.skuPricingAndAvailability.skuId],
    productPartNumberList: [productDetails.partNumber],
    productPriceList: [`${skuDetailsData.skuPricingAndAvailability.lowestPrice}`],
    productRecommendationTypeList: ['AutoZone'],
    productRepositoryIdList: [productDetails.ecommProductId],
    productRequestedQuantityList: ['1'],
  });
};
