/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import cx from 'classnames';
import { SmartLink as Link } from '@/utils/smartLink';
import azCommonStyles from '@/theme/globals.module.scss';
import { Label } from '@/features/i18n';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import styles from './styles.module.scss';
import type { DealInfo } from '@/types/availability';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';

type Props = {
  badgeDetails: DealInfo;
  dealsCheck?: () => void;
};

export const SaveNowBadge = ({ badgeDetails, dealsCheck }: Props) => {
  const locale = useLocale();
  const lblSavenow = <Label label="label_CategoryPage_body_SaveNow" />;
  const lblMailInRebate = <Label label="label_mail-in_rebate" />;
  const lblInstantSavings = <Label label="label_instant_saving" />;
  const lblWith = <Label label="label_CategoryPage_body_With_lower" />;
  const seperateTab = '_blank';
  const { data: storeData } = useStoreDetailsData();
  const isBopusEnabled = storeData?.bopusEnabled ?? false;
  const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;
  return badgeDetails.dealAvailable || badgeDetails.rebatesAvailable ? (
    <div className={cx(azCommonStyles['az-margin-top-4xs'], styles.saveBadgeWrapper)}>
      <div className={styles.badgeWrapper}>
        <div data-testid="save-now-badge" className={styles.orangeBanner}>
          {'$ '}
          {lblSavenow}
        </div>
        <div className={styles.corner} />
      </div>

      <div className={cx(azCommonStyles['az-caption'], styles.badgeOfferContent)}>
        {/* TODO: localization */}
        {locale !== countryCodes.mx && <span>{lblWith}</span>}
        {badgeDetails.dealAvailable && !isBopusMexicoStoreEnabled ? (
          <button
            className={styles.instantSaving}
            onClick={dealsCheck}
            data-testid="instant-savings-button"
          >
            {lblInstantSavings}
          </button>
        ) : null}
        {badgeDetails.dealAvailable && badgeDetails.rebatesAvailable && <span>{' | '}</span>}
        {badgeDetails.rebatesAvailable ? (
          <Link
            to={badgeDetails.rebateUrl || '/deals/rebates'}
            target={seperateTab}
            className={styles.rebates}
          >
            {lblMailInRebate}
          </Link>
        ) : null}
      </div>
    </div>
  ) : null;
};
