/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import { orderTypes, type OrderType } from '@/constants/orderTypes';

export function convertFulfillmentTypeToOrderType(fulfillmentType: number): OrderType {
  const isOnlineOrder =
    fulfillmentType === FULFILLMENT_METHODS.ONLINEORDER ||
    fulfillmentType === FULFILLMENT_METHODS.NEXTDAY ||
    fulfillmentType === FULFILLMENT_METHODS.SAMEDAY;

  const orderTypeId =
    fulfillmentType && isOnlineOrder ? orderTypes.onlineOrder : orderTypes.storeOrder;

  return orderTypeId;
}
