/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { fulfillmentLabels } from '../../constants/fulfillmentConstants';
import styles from './styles.module.scss';
import { OrderWithinHrsMinArrivesMessage } from './FulfillmentCutoff';
import type { FulfillmentOption } from '@/types/reduxStore/skuDetails';
import NextImage from '@/components/NextImage';

const sddIcon = {
  src: '/images/sddIcon-19-17.svg',
  alt: '',
};

export default function SameDayOption({
  option,
}: {
  option: FulfillmentOption;
  skuId: string | undefined;
  productName: string;
}) {
  return (
    <>
      <div className={styles.listItemContainer} data-testid="same-day-delivery">
        <div className={styles.listItemIcon}>
          <NextImage
            role="presentation"
            src={sddIcon.src}
            alt={sddIcon.alt}
            width={19}
            height={17}
          />
        </div>
        <div className={styles.listItemDetails}>
          <div className={styles.listItemHeader}>
            <span className={styles.sameDayHeader} data-testid="same-day">
              {option.availableLabel}
            </span>
          </div>
          <OrderWithinHrsMinArrivesMessage
            estDeliveryDateString={option.estimatedDeliveryDate}
            cutOffDateString={option.fullfillmentCutOffDate}
            orderByLabel={fulfillmentLabels.label_shipping_cutoff_ampm_head}
            arrivesByLabel={fulfillmentLabels.label_shipping_cutoff_ampm_tail}
            displayAmPmFormat={true}
          />
        </div>
      </div>
    </>
  );
}
