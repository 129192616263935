/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import NextImage from '@/components/NextImage';
import { useHeaderData } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { useState, useEffect, useRef } from 'react';
import { shareLink } from '../utils/createSharableLink';
import styles from './ShareLinkButton.module.scss';
import cx from 'classnames';
import { Popper } from '@/components/AZCustomComponent/Popper/Popper';
import { useLabels } from '@/hooks/useLabels';
import { isMobile } from '@/utils/common';

type Props = {
  pageName: 'pdp' | 'shelf';
  classes?: {
    container?: string;
    button?: string;
  };
};

const labelMap = {
  lblLinkCopied: 'label_link_copied',
  lblSharePage: 'label_share_page',
  lblCopyLink: 'label_copy_link',
};

export const ShareLinkButton = ({ classes, pageName }: Props) => {
  const labels = useLabels(labelMap);

  const { data: headerData } = useHeaderData();
  const preferredVehicleData = getPreferredVehicle(headerData);
  const isMobileDevice = isMobile();
  const vehicleId = preferredVehicleData?.catalogVehicleId;
  const [buttonLabel, setButtonLabel] = useState(labels.lblSharePage);

  const [showLinkCopiedNotification, setShowLinkCopiedNotification] = useState(false);
  const [clickedClassName, setClickedClassName] = useState('');
  const [timeoutId, setTimeoutId] = useState<number | undefined>();

  const handleOnCopyLink = async () => {
    await shareLink(isMobileDevice, pageName, vehicleId);
    if (!isMobileDevice) {
      setShowLinkCopiedNotification(true);
      setClickedClassName(styles.clicked);
      const newTimeoutId = window.setTimeout(() => {
        setShowLinkCopiedNotification(false);
        setClickedClassName('');
      }, 6000);
      setTimeoutId(newTimeoutId);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowLinkCopiedNotification(false);
        setClickedClassName('');
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        buttonRef.current &&
        !(event.target instanceof Node && buttonRef.current.contains(event.target))
      ) {
        setShowLinkCopiedNotification(false);
        setClickedClassName('');
      }
    };
    if (showLinkCopiedNotification || !!clickedClassName) {
      document.addEventListener('keydown', handleKeyDown);
      window.addEventListener('mousedown', handleClickOutside);
    } else {
      window.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [clickedClassName, showLinkCopiedNotification]);

  const handleOnKeyDown = async (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === 'Space') {
      await handleOnCopyLink();
    }
  };

  const buttonRef = useRef<HTMLButtonElement>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isMobileDevice) {
      setButtonLabel(labels.lblSharePage);
    } else {
      setButtonLabel(labels.lblCopyLink);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileDevice]);

  return (
    <div className={cx(styles.container, classes?.container)} ref={containerRef}>
      <button
        ref={buttonRef}
        disabled={showLinkCopiedNotification}
        title={buttonLabel}
        onKeyDown={handleOnKeyDown}
        onClick={handleOnCopyLink}
        onMouseDown={() => {
          if (!isMobileDevice) setClickedClassName(styles.clicked);
        }}
        className={cx(styles.button, classes?.button, clickedClassName)}
      >
        <NextImage
          alt=""
          src="/images/share-icon.svg"
          width={40}
          height={40}
          className={styles.shareIcon}
          loading="lazy"
        />
      </button>

      {!isMobileDevice && containerRef.current && (
        <Popper
          popperRole={undefined}
          elevation={4}
          isOpen={showLinkCopiedNotification}
          anchorEl={containerRef.current}
          classes={{ popper: styles.popperStyles, paper: styles.paperStyles }}
        >
          <div id="popperContent" role="alert" aria-atomic="true" tabIndex={0}>
            {labels.lblLinkCopied}
          </div>
        </Popper>
      )}
    </div>
  );
};
