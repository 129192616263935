/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import ProductDetail from '@/components/ProductDetail';
import { getGlobalConfigFromCache, getFeatureFlag } from '@/features/globalConfig';
import { getHeaderDataFromCache } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { prefetchPreFooterData, getPageDependentPrefooterParams } from '@/features/prefooter';
import {
  prefetchProductDetails,
  getProductDetailsFromCache,
} from '@/features/product/api/getProductDetails';
import { prefetchProductSkuDeals } from '@/features/product/api/getProductDeals';
import { prefetchProductSkuDetails } from '@/features/product/api/getProductSkuDetails';
import { prefetchProductComparison } from '@/features/product/api/getProductComparison';
import { hasCMSPremiumTable } from '@/features/pdp';
import { prefetchProductVideos } from '@/features/pdp/api/getProductVideos';
import { prefetchGcpProductVideos } from '@/features/pdp/api/getGcpProductVideos';
import {
  prefetchContentStackDefaultPDPContent,
  getContentStackDefaultPDPContentFromCache,
} from '@/features/pdp/api/getContentStackDefaultProductContent';
import {
  prefetchContentStackPremiumPDPContent,
  getContentStackPremiumPDPContentFromCache,
} from '@/features/pdp/api/getContentStackPremiumProductContent';
import { mutateVehicles } from '@/features/ymme/api/postVehicles';
import type { PageContextType } from '@/next-types';
import { isEmptyObject } from '@/utils/common';
import { configureSharedPageData } from '@/utils/configureSharedPageData.server';
import { getPathnameFromAsPath, parseUrl } from '@/utils/urlHelpers';
import { dehydrate } from '@tanstack/react-query';
import { ProductListProvider } from '@/features/shelf/context/ProductListProvider';
import logger from '@/utils/logger';
import { prefetchDynamicLinkMappings } from '@/features/dlm';
import environmentConfig from '@/config/environment';
import { getDevice } from '@/utils/getDeviceType.server';
import { SisterStoreProvider } from '@/features/fulfillment/context/SisterStoreProvider';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { countryCodes } from '@/constants/locale';
import { prefetchSylvaniaVideos } from '@/features/product/api/getSylvaniaVideos';
import { getKiboDecisionFlag } from '@/features/kibo';

export const config = {
  unstable_JsPreload: false,
};

function ProductDetailsPage() {
  return (
    <SisterStoreProvider>
      <ProductDetail />
    </SisterStoreProvider>
  );
}

ProductDetailsPage.getInitialProps = async (context: PageContextType) => {
  const { req, store, query, res, asPath } = context;

  if (__IS_SERVER__) {
    const { slug } = query;
    const device = getDevice(req);

    if (!Array.isArray(slug)) {
      throw new Error('The slug for ProductDetails must be an array');
    }

    const { axios, queryClient, initialAppState } = await configureSharedPageData(context);
    const { locale } = initialAppState;

    // FIXME: this doesn't feel like the right place to do this
    if (query.vehicleId && query.vehicleId !== '0') {
      // Vehicles are saved with the prefix `3` in endeca, so we need to add it here
      try {
        await mutateVehicles(
          queryClient,
          {
            vehicleId: `${query.vehicleId}`,
            locale,
          },
          axios
        );
      } catch (err) {
        logger.error({
          message: `Error when adding vehicle ${query.vehicleId} from query param in PDP`,
          meta: {
            url: asPath,
          },
        });
      }
    }

    // After updating vehicles, header data is refetched, so we should read it after
    const headerData = getHeaderDataFromCache(queryClient);
    const catalogVehicleId = getPreferredVehicle(headerData)?.catalogVehicleId ?? '0';
    const storeNumber = headerData?.storeNumber ?? '';
    const globalConfig = getGlobalConfigFromCache(queryClient);
    const seoUrl = `/${slug.join('/')}`;

    const productDetailsOptions = {
      vehicleId: Number(catalogVehicleId),
      locale,
      lineCode: undefined,
      productDetailsPageUrl: seoUrl,
      userSegment: undefined,
      partNumber: undefined,
      itemIds: undefined,
      storeId: storeNumber ? Number(storeNumber) : undefined,
      preview: environmentConfig.SHOW_XM_PREVIEW_DATE,
    };

    // Disable PDP for Brazil while ecomm disabled
    if (
      initialAppState.locale === countryCodes.ptBr &&
      globalConfig?.BRAZIL_PHASE_TWO_ENABLED !== 'true'
    ) {
      res?.writeHead(301, { Location: '/errorPath' }).end();
      return {
        initialAppState,
        dehydratedState: dehydrate(queryClient),
      };
    }

    await prefetchProductDetails(queryClient, productDetailsOptions, axios);
    const productDetails = getProductDetailsFromCache(queryClient, productDetailsOptions);

    if (productDetails?.redirectURL) {
      const redirectPath = productDetails.redirectURL;

      if (redirectPath) {
        const parsedPath = parseUrl(redirectPath);
        let sanitizedPath = redirectPath;

        if (!isEmptyObject(parsedPath?.query)) {
          sanitizedPath = parsedPath.url;
        }

        const { appData } = store.getState();
        const isBot = appData.deviceType === 'bot';
        // Do not send full path with query params for bots: We explicitly append recsPerPage: 24 for bots when constructing the completeURL for the ATG call in this file.
        // However, even though we are requesting 24 recsPerPage, SEO does not want this query param to show up in the redirect url since bots are technically not be able to change recsPerPage.
        const path = isBot ? sanitizedPath : redirectPath;

        if (path !== undefined) {
          res?.writeHead(301, { Location: path }).end();
          return {
            initialAppState,
            dehydratedState: dehydrate(queryClient),
          };
        }
      }
    }

    let productContentsPromise: Promise<void> | undefined;
    let skuComparisonPromise: Promise<void> | undefined;
    let cmsPremiumContentPromise: Promise<void> | undefined;
    let dynamicLinksPromise: Promise<void> | undefined;
    let sylvaniaVideosPromise: Promise<void> | undefined;

    const taxonomyPath = productDetails?.product?.cmsContentPath ?? '';
    const brandName =
      productDetails?.product?.subBrandName ?? productDetails?.product?.brandName ?? '';

    productContentsPromise = prefetchContentStackDefaultPDPContent(
      queryClient,
      taxonomyPath,
      context,
      headerData?.segments?.sort() ?? []
    );

    productContentsPromise.then(() => {
      const pdpDefault = getContentStackDefaultPDPContentFromCache(
        queryClient,
        taxonomyPath,
        headerData?.segments?.sort() ?? []
      );

      if (
        productDetails?.product?.brandName &&
        ((pdpDefault?.premiumBrands?.brands ?? []) as string[]).includes(
          productDetails.product.brandName
        )
      ) {
        cmsPremiumContentPromise = prefetchContentStackPremiumPDPContent(
          queryClient,
          taxonomyPath,
          context,
          headerData?.segments?.sort() ?? [],
          brandName
        );
      }
    });

    if (
      Boolean(productDetails?.product?.vehicleFitFlag) &&
      Boolean(catalogVehicleId) &&
      catalogVehicleId !== '0' &&
      productDetails?.product?.itemId &&
      productDetails?.product?.lineCode === 'SYL' &&
      getFeatureFlag(globalConfig, 'IS_YMME_INSTALL_VIDEOS_ENABLED') === 'true'
    ) {
      const sylvaniaVideosParams = {
        productId: productDetails.product.itemId,
        locale,
        vehicleId: catalogVehicleId,
      };
      sylvaniaVideosPromise = prefetchSylvaniaVideos(axios, queryClient, sylvaniaVideosParams);
    }

    if (
      device.type === 'bot' &&
      getFeatureFlag(globalConfig, 'PDP_COMPARISON_ENABLED') === 'true' &&
      productDetails?.product?.pdpComparisonEnabled &&
      productDetails.product.brandName &&
      productDetails.product.ecommProductId
    ) {
      const productComparisonParams = {
        parentBrandName: productDetails.product.brandName,
        subBrandName: productDetails.product.subBrandName,
        productId: productDetails.product.ecommProductId,
        skuId: productDetails.product.itemId,
        storeNumber: storeNumber || undefined,
        vehicleId: catalogVehicleId,
      };

      await productContentsPromise;
      await cmsPremiumContentPromise;
      const premiumPDPContent = getContentStackPremiumPDPContentFromCache(
        queryClient,
        taxonomyPath,
        headerData?.segments?.sort() ?? [],
        brandName
      );
      if (!premiumPDPContent || !premiumPDPContent.pageBody?.some(hasCMSPremiumTable)) {
        skuComparisonPromise = prefetchProductComparison(
          productComparisonParams,
          queryClient,
          axios
        );
      }
    }

    if (
      device.type === 'bot' &&
      productDetails?.product?.itemId &&
      getFeatureFlag(globalConfig, 'DLM_PDP_ENABLED') === 'true' &&
      catalogVehicleId === '0'
    ) {
      dynamicLinksPromise = prefetchDynamicLinkMappings(
        {
          type: 'generic',
          country: getCountryFromLocale(locale),
          seoRepositoryId: productDetails.product.itemId,
          gcpUrlEnabled: getFeatureFlag(globalConfig, 'SEO_GCP_URL_ENABLED') === 'true',
        },
        queryClient,
        axios
      );
    }

    await Promise.all([
      productContentsPromise,
      cmsPremiumContentPromise,
      !!productDetails?.product?.itemId
        ? prefetchProductSkuDetails(axios, queryClient, {
            skuIds: [productDetails.product.itemId],
            storeNumber,
            vehicleId: catalogVehicleId,
            isNewDealsApiEnabled:
              getFeatureFlag(globalConfig, 'IS_NEW_DEALS_API_ENABLED') === 'true',
          })
        : undefined,
      productDetails?.product?.itemId &&
        locale === countryCodes.us &&
        (getKiboDecisionFlag(queryClient, 'pdGcpUrlEnabled')
          ? prefetchGcpProductVideos({
              queryClient,
              skuId: productDetails.product.itemId,
              axiosInstance: axios,
            })
          : prefetchProductVideos(queryClient, productDetails!.product!.itemId, axios)),
      prefetchPreFooterData(
        queryClient,
        {
          locale: initialAppState.locale,
          seourl: getPathnameFromAsPath(asPath).replace(/(\/bn\/|\/b\/).*/, ''),
          vehicleId: catalogVehicleId,
          isCMSEnabled: true,
          ...getPageDependentPrefooterParams({
            isPDP: true,
            pageTypeData: undefined,
            productDetails,
          }),
        },
        axios
      ),
      productDetails?.product?.itemId &&
        getFeatureFlag(globalConfig, 'IS_NEW_DEALS_API_ENABLED') === 'true' &&
        prefetchProductSkuDeals(
          {
            skuIds: [productDetails.product.itemId],
            storeNumber,
            vehicleId: catalogVehicleId,
          },
          queryClient,
          axios
        ),
      skuComparisonPromise,
      dynamicLinksPromise,
      sylvaniaVideosPromise,
    ]);

    return {
      initialAppState,
      dehydratedState: dehydrate(queryClient),
    };
  }

  return {};
};

ProductDetailsPage.getLayout = (page: React.ReactNode) => {
  return <ProductListProvider>{page}</ProductListProvider>;
};

export default ProductDetailsPage;
