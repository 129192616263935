/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import Skeleton from '../../Skeleton/Skeleton';
import styles from './ProductSpecificationSkeleton.module.scss';
const baseSkeletonProps: {
  animation: 'pulse' | 'wave' | false;
  variant: 'text' | 'rect';
} = {
  animation: 'wave',
  variant: 'rect',
};

export default function ProductSpecificationSkeleton() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  return isMobile ? (
    <div className={styles.container}>
      <Skeleton className={styles.title} width="70%" height={25} {...baseSkeletonProps} />
      {new Array(4).fill(1).map((_, index) => (
        <div key={index} className={styles.descriptionContainer}>
          <Skeleton className={styles.description} width="40%" height={25} {...baseSkeletonProps} />

          <Skeleton
            className={styles.description}
            key={index}
            width="100%"
            height={25}
            {...baseSkeletonProps}
          />
        </div>
      ))}
    </div>
  ) : (
    <div className={styles.container}>
      <Skeleton className={styles.title} width="30%" height={25} {...baseSkeletonProps} />
      {new Array(4).fill(1).map((_, index) => (
        <div key={index} className={styles.descriptionContainer}>
          <Skeleton className={styles.description} width="40%" height={25} {...baseSkeletonProps} />

          <Skeleton
            className={styles.description}
            key={index}
            width="55%"
            height={25}
            {...baseSkeletonProps}
          />
        </div>
      ))}
    </div>
  );
}
