/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type SegmentMessages } from '@/features/header';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useLabel } from '@/hooks/useLabels';

export const getLocalizedSegmentMessages = (segmentMessageContent: SegmentMessages) => {
  if (!segmentMessageContent) {
    return {};
  }

  if ('en_US' in segmentMessageContent) {
    return segmentMessageContent.en_US;
  } else if ('es_MX' in segmentMessageContent) {
    return segmentMessageContent.es_MX;
  }

  return {};
};

export const useSegmentMessage = (label: string) => {
  const defaultLabel = useLabel(label);

  const messages = useHeaderData({ select: (headerData) => headerData.segmentMessageContent });

  if (!messages.data) {
    return defaultLabel;
  }

  const localizedMessages = getLocalizedSegmentMessages(messages.data);

  return localizedMessages?.[label] ? localizedMessages[label] : defaultLabel;
};
