/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import styles from './styles.module.scss';
import BundleDealConstraint from '../BundleDealConstraint';
import type {
  DealConstraint,
  DealConstraintSkuDetails,
  ConstraintSelectionHash,
} from '../../../interface';

type Props = {
  secondaryConstraints: DealConstraint[];
  constraintSelections: ConstraintSelectionHash;
  onConstraintSwapClick: (
    constraint: DealConstraint,
    constraintSkuDetails: DealConstraintSkuDetails
  ) => void;
  dealId: string;
  lblCompleteDeal: string;
  lblCompleteDesc: string;
};

const SecondaryConstraints = ({
  secondaryConstraints,
  constraintSelections,
  onConstraintSwapClick,
  dealId,
  lblCompleteDeal,
  lblCompleteDesc,
}: Props) => {
  return (
    <div className={styles.section}>
      <h2 className={styles.sectionTitle}>{lblCompleteDeal}</h2>
      <div className={styles.sectionDescription}>{lblCompleteDesc}</div>
      <div className={styles.separator} />
      {secondaryConstraints.map((constraint, index) => {
        const { id: constraintId } = constraint;
        const constraintSkuDetails = constraintSelections[constraintId];
        return (
          <BundleDealConstraint
            key={`${dealId}-constraint${constraintId}`}
            index={index}
            constraint={constraint}
            skuDetails={constraintSkuDetails}
            onSwapClick={() => onConstraintSwapClick(constraint, constraintSkuDetails)}
          />
        );
      })}
    </div>
  );
};

export default SecondaryConstraints;
