/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { fulfillmentLabels } from '../../constants/fulfillmentConstants';
import styles from './styles.module.scss';
import type { FulfillmentOption } from '@/types/reduxStore/skuDetails';
import cx from 'classnames';
import { useLabels } from '@/hooks/useLabels';
import { OrderWithinHrsMinArrivesMessage } from './FulfillmentCutoff';
import { SmartLink as Link } from '@/utils/smartLink';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';
import NextImage from '@/components/NextImage';
import { useMonetateDecisionFlag } from '@/features/kibo';

const sddIcon = {
  src: '/images/sddIcon-19-17.svg',
  alt: '',
};

const labels = {
  label_sdd_shipping_price: 'label_fulfilment_options_sdd_shipping_price',
  label_today: 'label_fulfilment_options_today',
  label_sdd_promos: 'label_shipping_infoPopper_sdd_promos',
  in_stock_label: 'label_cart_lineItem_InStock',
};

export default function SameDayOption({
  option,
}: {
  option: FulfillmentOption;
  skuId: string | undefined;
  productName: string;
}) {
  const router = useRouter();
  const labelMap = useLabels(labels);
  const showNewFfmText = useMonetateDecisionFlag('displayNewFulfillmentText');
  const sddText = labelMap.label_sdd_promos.match(/same day delivery/gi)?.[0];
  const isCartPage = router.asPath.includes(routePaths.cart);

  return (
    <>
      <div
        className={cx(styles.NextDaylistItemContainer, { [styles.unsetHeight]: !showNewFfmText })}
        data-testid="same-day-delivery"
      >
        {showNewFfmText ? (
          <div>
            <div data-testid="same-day" className={styles.inStockLabel}>
              <div className={styles.inStockLabelContainer}>
                <div className={styles.tickmarkContainer}>
                  <img src="/images/green-tickmark.svg" alt="" />{' '}
                </div>
                <span>
                  <span className={styles.successBoldedMessage}>{labelMap.in_stock_label}</span>
                  <span> | </span>
                  <OrderWithinHrsMinArrivesMessage
                    estDeliveryDateString={option.estimatedDeliveryDate}
                    cutOffDateString={option.fullfillmentCutOffDate}
                    orderByLabel={fulfillmentLabels.label_shipping_cutoff_ampm_head}
                    arrivesByLabel={fulfillmentLabels.label_shipping_cutoff_ampm_tail}
                    displayAmPmFormat={true}
                    isCartPage={isCartPage}
                    isNdd={false}
                  />
                  {isCartPage && (
                    <div className={styles.shippingOptionDetailV3}>
                      <span className={styles.cartAdditionalMessageText}>
                        {sddText ? (
                          <>
                            {labelMap.label_sdd_promos.split(/same day delivery/gi)[0]} {sddText}
                            {labelMap.label_sdd_promos.split(/same day delivery/gi)[1]}
                          </>
                        ) : (
                          <Link to="/lp/same-day-delivery">{labelMap.label_sdd_promos}</Link>
                        )}
                      </span>
                    </div>
                  )}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={styles.listItemIcon}>
              <NextImage
                role="presentation"
                src={sddIcon.src}
                alt={sddIcon.alt}
                width={19}
                height={17}
              />
            </div>
            <div>
              <div className={styles.listItemHeader}>
                <span className={styles.sameDayHeader} data-testid="same-day">
                  {option.availableLabel}
                </span>{' '}
              </div>
              <OrderWithinHrsMinArrivesMessage
                estDeliveryDateString={option.estimatedDeliveryDate}
                cutOffDateString={option.fullfillmentCutOffDate}
                orderByLabel={fulfillmentLabels.label_shipping_cutoff_ampm_head}
                arrivesByLabel={fulfillmentLabels.label_shipping_cutoff_ampm_tail}
                displayAmPmFormat={true}
                isCartPage={isCartPage}
                isNdd={false}
              />
              {isCartPage && (
                <div className={styles.shippingOptionDetailV3}>
                  <span>
                    {sddText ? (
                      <>
                        {labelMap.label_sdd_promos.split(/same day delivery/gi)[0]}{' '}
                        <Link
                          target="_blank"
                          to="/lp/same-day-delivery"
                          className={styles.desktopBoldText}
                        >
                          {sddText}
                        </Link>{' '}
                        {labelMap.label_sdd_promos.split(/same day delivery/gi)[1]}
                      </>
                    ) : (
                      <Link to="/lp/same-day-delivery">{labelMap.label_sdd_promos}</Link>
                    )}
                  </span>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
