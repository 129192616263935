/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { type AxiosInstance } from 'axios';
import type { AddToCartData, AddToCartResponse } from '@/types/reduxStore/cartUpdate';
import { useMutation } from '@tanstack/react-query';

const URL = `${requestBaseURL}/ecomm/b2c/order/v4/skus`;

type SelectedConstraints = {
  skuId: string;
  constraintId: string;
  productId: string;
  originalPartType: string;
  vehicleId: string | undefined;
  storeNumber: string | undefined;
  fulfillmentTypeId: string | number | null | undefined;
  quantity: number;
  price: number;
  brandName: string;
};

type DealToCartPayLoad = {
  skuId: string;
  dealId: string;
  selectedConstraints: SelectedConstraints[];
};

type Options = DealToCartPayLoad;

const postDealAddToCart = async (options: Options, axiosInstance?: AxiosInstance) => {
  const { skuId, dealId, selectedConstraints } = options;

  const fullApiUrl = `${URL}/${skuId}/deals/${dealId}`;
  const response = await getAxios(axiosInstance).post<AddToCartResponse>(fullApiUrl, {
    itemsList: selectedConstraints,
  });
  return selector(response.data);
};

const selector = (data: AddToCartResponse): AddToCartData => data;

export const usePostDealToCart = () => {
  return useMutation({
    mutationFn: ({ skuId, dealId, selectedConstraints }: DealToCartPayLoad) =>
      postDealAddToCart({
        skuId,
        dealId,
        selectedConstraints,
      }),
  });
};
