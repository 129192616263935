/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import styles from './styles.module.scss';

type Props = {
  toggleClass: string;
  applyNegativeMargin?: boolean;
  optionsMapObj: any;
  storeModal: any;
  closeStoreModal: () => void;
  modalOpen: boolean;
};

const Availability = (props: Props) => {
  const { optionsMapObj, modalOpen, toggleClass, applyNegativeMargin = true, storeModal } = props;
  return (
    <div>
      <div
        className={`${styles.toggleContainer} ${applyNegativeMargin ? styles.resetMarginTop : ''}`}
        data-testid="fulfillment-toggle-container"
      >
        <div className={toggleClass}>{optionsMapObj}</div>
      </div>
      {storeModal(modalOpen)}
    </div>
  );
};

export default Availability;
