/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import Image from '@/components/NextImage';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  label: string;
  src: string;
  vehicleName: string;
};
const SelectedVehicleCard = ({ label, src, vehicleName }: Props) => (
  <div className={styles.vehicleSection}>
    <h2 className={cx(styles.sectionTitle, styles.currentVehicleSectionTitle)}>{label}</h2>
    <div className={styles.selectedVehicleCard}>
      <div className={styles.selectedVehicleImg}>
        <Image src={src} priority={true} width={27} height={23} alt="" />
      </div>
      <div className={styles.selectedVehicleText}>{vehicleName}</div>
    </div>
  </div>
);

export default SelectedVehicleCard;
