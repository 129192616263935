/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { useControlled } from '../../hooks/useControlled';
import { useFormControl } from '../FormControl/FormControlContext';
import { IconButton } from '../IconButton/IconButton';
import styles from './SwitchBase.module.scss';

type Props = React.InputHTMLAttributes<HTMLElement> & {
  /**
   * If `true`, the `input` element will be focused during the first mount.
   */
  autoFocus?: boolean;
  /**
   * If `true`, the component is checked.
   */
  checked?: boolean;
  /**
   * The icon to display when the component is checked.
   */
  checkedIcon: React.ReactElement;
  /**
   * @ignore
   */
  className?: string;
  /**
   * @ignore
   */
  defaultChecked?: boolean;
  /**
   * If `true`, the switch will be disabled.
   */
  disabled?: boolean;
  /**
   * The icon to display when the component is unchecked.
   */
  icon: React.ReactElement;
  /**
   * The id of the `input` element.
   */
  id?: string;
  /**
   * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.
   */
  inputProps?: any;
  /**
   * Pass a ref to the `input` element.
   */
  inputRef?: Function | any;
  /**
   * @ignore
   */
  name?: string;
  /**
   * It prevents the user from changing the value of the field
   * (not from interacting with the field).
   */
  readOnly?: boolean;
};
/**
 * @ignore - internal component.
 */

export const SwitchBase = React.forwardRef<HTMLElement, Props>(function SwitchBase(props, ref) {
  const {
    autoFocus,
    checked: checkedProp,
    checkedIcon,
    className,
    defaultChecked,
    disabled: disabledProp,
    icon,
    id,
    inputProps,
    inputRef,
    name,
    onBlur,
    onChange,
    onFocus,
    readOnly,
    required,
    tabIndex,
    type,
    value,
    ...other
  } = props;
  const [checked, setCheckedState] = useControlled({
    controlled: checkedProp,
    default: Boolean(defaultChecked),
    name: 'SwitchBase',
    state: 'checked',
  });
  const muiFormControl = useFormControl();

  const handleFocus = (event: React.FocusEvent<HTMLElement>) => {
    if (onFocus) {
      onFocus(event);
    }

    if (muiFormControl?.onFocus) {
      muiFormControl.onFocus(event);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
    if (onBlur) {
      onBlur(event);
    }

    if (muiFormControl?.onBlur) {
      muiFormControl.onBlur(event);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = event.target.checked;
    // @ts-expect-error refine
    setCheckedState(newChecked);

    if (onChange) {
      // @ts-expect-error investigate second argument
      onChange(event, newChecked);
    }
  };

  let disabled = disabledProp;

  if (muiFormControl) {
    if (typeof disabled === 'undefined') {
      disabled = muiFormControl.disabled;
    }
  }

  const hasLabelFor = type === 'checkbox' || type === 'radio';
  return (
    <IconButton
      component="span"
      className={cx(
        styles.root,
        {
          [styles.checked]: checked,
          [styles.disabled]: disabled,
        },
        className
      )}
      disabled={disabled}
      // @ts-expect-error null passes to not trigger default argument assignment
      tabIndex={null}
      role={undefined}
      onFocus={handleFocus}
      onBlur={handleBlur}
      ref={ref}
      {...other}
    >
      <input // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        checked={checkedProp}
        defaultChecked={defaultChecked}
        className={styles.input}
        disabled={disabled}
        id={hasLabelFor && id}
        name={name}
        onChange={handleInputChange}
        readOnly={readOnly}
        ref={inputRef}
        required={required}
        tabIndex={tabIndex}
        type={type}
        value={value}
        {...inputProps}
      />
      <div className={styles.inputFocus} />
      {checked ? checkedIcon : icon}
    </IconButton>
  );
});
