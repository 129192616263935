/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export function createSharableLink(
  href: string,
  source: 'pdp' | 'shelf',
  vehicleId?: string
): string {
  const url = new URL(href);
  const [base] = href.split('?');

  url.searchParams.set('cmpid', `SPL:US:EN:AD:NL:1000000:GEN:${source}`);

  if (vehicleId && !url.searchParams.has('vehicleId')) {
    url.searchParams.set('vehicleId', vehicleId);
  }

  const stringParams = decodeURIComponent(url.searchParams.toString());

  return `${base}?${stringParams}`;
}

export async function shareLink(
  showShareAPI: boolean | undefined,
  source: 'pdp' | 'shelf',
  vehicleId?: string
) {
  const sharableLink = createSharableLink(window.location.href, source, vehicleId);

  try {
    if (showShareAPI) {
      await navigator.share({
        url: sharableLink,
      });
    } else {
      await navigator.clipboard.writeText(sharableLink);
    }
  } catch (error) {
    //Determine what to do if none navigator APIS are available
  }
}
