/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import NextImage from '@/components/NextImage';
import { greyCarImg } from '@/constants/images/greyCarImg';
import { countryCodes } from '@/constants/locale';
import { useLocale } from '@/hooks/useLocale';
import React from 'react';
import styles from './styles.module.scss';

const rightArrowFitment = {
  src: '/images/fitment-right-arrow.svg',
  alt: 'view buyers guide',
};

type BuyersGuideBannerProps = {
  openBuyersGuide: () => void;
  lblFitmentOne: string;
  lblFitmentTwo: string;
};

export const BuyersGuideBanner = ({
  openBuyersGuide,
  lblFitmentOne,
  lblFitmentTwo,
}: BuyersGuideBannerProps) => {
  const locale = useLocale();

  return (
    <button
      className={styles.bannerHeader}
      onClick={openBuyersGuide} // open modal
      id="PDPFitment"
      data-testid="PDPFitment"
    >
      <div className={styles.blackCar}>
        <NextImage
          id="PDPFitmentCar"
          src={greyCarImg.src}
          alt={greyCarImg.alt}
          width={24}
          height={18}
        />
      </div>
      {locale === countryCodes.us && (
        <>
          <div className={styles.titleOne}>{lblFitmentOne}</div>
          <div className={styles.titleTwo}>{lblFitmentTwo}</div>
        </>
      )}
      {(locale === countryCodes.mx || locale === countryCodes.ptBr) && (
        <>
          <div className={styles.titleMx}>{`${lblFitmentOne} ${lblFitmentTwo}`}</div>
        </>
      )}
      <div className={`${styles.arrow} ${styles.arrowRight}`}>
        <NextImage src={rightArrowFitment.src} alt={rightArrowFitment.alt} width={12} height={20} />
      </div>
    </button>
  );
};
