/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type {
  ContentStackPdpSlot,
  FormattedContentStackPremiumPDP,
} from '@/features/contentstack/interface';

export function hasCMSPremiumTable(
  contentItem:
    | NonNullable<FormattedContentStackPremiumPDP['pageBody']>[number]
    | NonNullable<ContentStackPdpSlot['pdp_slot_content']>[number]
): boolean {
  if ('product_chart' in contentItem && contentItem.product_chart?.product_chart?.[0]) {
    return true;
  }

  if ('pdp_slot1' in contentItem && contentItem.pdp_slot1?.pdp_slot1?.[0]?.pdp_slot_content) {
    return contentItem.pdp_slot1.pdp_slot1[0].pdp_slot_content.some(hasCMSPremiumTable);
  }

  if ('pdp_slot2' in contentItem && contentItem.pdp_slot2?.pdp_slot2?.[0]?.pdp_slot_content) {
    return contentItem.pdp_slot2.pdp_slot2[0].pdp_slot_content.some(hasCMSPremiumTable);
  }

  return false;
}
