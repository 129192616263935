/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useMemo } from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTommorow from 'dayjs/plugin/isTomorrow';
import { fulfillmentLabels } from '../../constants/fulfillmentConstants';
import { dayjsFormats } from '../../constants/date';
import { useLabel } from '@/hooks/useLabels';
dayjs.extend(isToday);
dayjs.extend(isTommorow);

const EstDeliveryDate = ({
  estimatedDeliveryDate,
  checkoutNonSddOrNDD = false,
}: {
  estimatedDeliveryDate: string;
  checkoutNonSddOrNDD?: boolean;
}): any | string => {
  const labelToday = useLabel(fulfillmentLabels.label_shipping_Today);
  const labelTomorrow = useLabel(fulfillmentLabels.label_shipping_Tomorrow);
  const formattedArrivesLabel = useMemo(() => {
    const estDeliveryDateTime = dayjs(estimatedDeliveryDate);

    if (estDeliveryDateTime.isValid()) {
      if (checkoutNonSddOrNDD) {
        // in checkout only do 'today' and 'tomorrow' for sdd/ndd
        return estDeliveryDateTime.format(dayjsFormats.weekDayMonthAndDay);
      }

      if (estDeliveryDateTime.isToday()) {
        return labelToday;
      }

      if (estDeliveryDateTime.isTomorrow()) {
        return labelTomorrow;
      }

      return estDeliveryDateTime.format(dayjsFormats.weekDayMonthAndDay);
    }

    return estDeliveryDateTime.toString();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatedDeliveryDate]);
  return formattedArrivesLabel;
};

export default EstDeliveryDate;
