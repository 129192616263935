/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import azCommonStyles from '../../../theme/globals.module.scss';
import { Button as IncDecButton } from '@/components/Button';
import {
  quantityComponent,
  quantityComponentBrazil,
} from '../../../constants/images/quantityComponent';
import { keyCodes } from '../../../constants/keyCodes';
import styles from './styles.module.scss';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';
import Image from '@/components/NextImage';
import { useFeatureFlag } from '@/features/globalConfig';
import { countryCodes } from '@/constants/locale';

const labelMap = {
  dollars: 'label_shelf_resultsList_price_dollars',
  cents: 'label_shelf_resultsList_price_cents',
  and: 'labels_order_and',
  totalPriceIs: 'label_shelf_resultsList_price_totalPrice',
  totalPriceIncludingRefundableCoreDepositPriceIs:
    'label_Total_price_including_refundable_core_deposit_price_is',
  andTheQuantityIs: 'label_and_the_quantity_is',
  decrease: 'label_Decrease',
  increase: 'label_Increase',
};

type Props = {
  count: number | string;
  maxLength?: number;
  onInputChange: (b: number, a: boolean) => void;
  handleZeroInput?: () => Promise<void>;
  onIncrementDecrement: (count: number, requireAnalytics: boolean, showQtyError?: boolean) => void;
  hasError?: boolean;
  minQuantity?: number;
  disableIncrementBtn?: boolean;
  moreMargin?: boolean;
  isIncOrDecBtnTouched?: boolean;
  isCorePriceAvailable?: boolean;
  ariaLabelPrice?: number;
  disabled?: boolean;
  maxQuantity?: number;
  filterproduct?: any;
};

export const handleKeyDown = async (
  event: any,
  count: number | string,
  handleZeroInput?: () => Promise<void>
) => {
  if ((event.keyCode === keyCodes.enter && count === 0) || count === '') {
    await handleZeroInput?.();
  }
};

export const handleOnBlur = async (
  count: string | number,
  handleZeroInput?: () => Promise<void>
) => {
  if (count === 0 || count === '') {
    await handleZeroInput?.();
  }
};

export const handleKeyPress = (
  event: any,
  onInputChange: Function,
  hasError: boolean,
  maxQuantity?: number
): any | void => {
  const numberPattern = /\d/g;
  const valueTest = numberPattern.test(event.target.value);
  if (maxQuantity !== null && maxQuantity !== undefined && maxQuantity === 0) {
    return onInputChange(0, false, hasError);
  }
  if (valueTest || event.target.value === '0' || event.target.value === '') {
    if (!isNaN(event.target.value)) {
      return onInputChange(Number(event.target.value), false, hasError);
    }
  }
};

export const QuantityComponent = (props: Props) => {
  const {
    onIncrementDecrement,
    count,
    minQuantity = 1,
    hasError = false,
    onInputChange,
    maxLength = 2,
    disableIncrementBtn = false,
    moreMargin = false,
    isIncOrDecBtnTouched,
    handleZeroInput,
    ariaLabelPrice,
    isCorePriceAvailable,
    maxQuantity,
    disabled = false,
    filterproduct,
  } = props;
  const locale = useLocale();
  const {
    increaseSvg: { alt: increaseAlt, src: increaseSrc },
    decreaseSvg: { alt: decreaseAlt, src: decreaseSrc },
  } = locale === countryCodes.ptBr ? quantityComponentBrazil : quantityComponent;

  const labels = useLabels(labelMap);

  const ariaPriceToFixed = ariaLabelPrice?.toFixed(2);
  const [ariaLabelDollars, ariaLabelCents] = ariaPriceToFixed?.toString().split('.') || ['', ''];
  const ariaPriceTranslated = ariaLabelPrice
    ? `${ariaLabelDollars} ${labels.dollars} ${labels.and} ${ariaLabelCents} ${labels.cents}`
    : '';
  const router = useRouter();
  const isCartPage = router.asPath === routePaths.cart;

  const decrementAmount: number = maxQuantity
    ? Number(count) > maxQuantity
      ? maxQuantity
      : Number(count) - 1
    : Number(count) - 1;

  const disableDecrementBtn = (): boolean => {
    const countLessThanMin = Number(count) <= minQuantity;
    let storeHasNoQoh: boolean = false;
    if (maxQuantity !== undefined) {
      storeHasNoQoh = maxQuantity <= 0;
    }
    return countLessThanMin || storeHasNoQoh;
  };

  const strikeThroughPricingEnabled = useFeatureFlag('SHOW_STRIKE_THROUGH_PRICING') === 'true';
  let showStrikeThroughPricing = false;
  if (strikeThroughPricingEnabled && filterproduct?.skuPricingAndAvailability?.discountedPrice) {
    showStrikeThroughPricing = true;
  }

  return (
    <div
      data-testid="quantity-component"
      className={cx(styles.quantityMainContainer, {
        [styles.cartPageQuantityContainer]: isCartPage && locale !== 'es-MX',
      })}
    >
      <div className={styles.quantityContainer}>
        <IncDecButton
          customClass={styles.round}
          data-testid="quantity-decrement"
          value=""
          disabled={disableDecrementBtn()}
          onClick={() => {
            onIncrementDecrement(decrementAmount, true, hasError);
          }}
          aria-label={
            isCorePriceAvailable
              ? `${labels.totalPriceIncludingRefundableCoreDepositPriceIs} ${ariaPriceTranslated} ${labels.andTheQuantityIs} ${count}. ${labels.decrease}`
              : `${labels.totalPriceIs} ${ariaPriceTranslated} ${labels.andTheQuantityIs} ${count}. ${labels.decrease}`
          }
        >
          <Image src={decreaseSrc} alt={decreaseAlt} width={10} height={3} />
        </IncDecButton>
        <input
          name="itemQuantity"
          data-testid="quantityInput"
          aria-label="Product Quantity"
          type="text"
          onBlur={async () => await handleOnBlur(count, handleZeroInput)}
          inputMode="numeric"
          className={cx(
            azCommonStyles['center-align'],
            azCommonStyles['az-title-5-medium'],
            styles.qtyTextBox,
            hasError && isIncOrDecBtnTouched ? styles.errorInput : styles.normalInput,
            !moreMargin
              ? showStrikeThroughPricing
                ? styles.inputStyleStrikeThrough
                : styles.inputStyle
              : styles.inputStyleMoreMargin
          )}
          value={Math.abs(Number(count))}
          onChange={(evt) => handleKeyPress(evt, onInputChange, hasError, maxQuantity)}
          onKeyDown={async (evt) => await handleKeyDown(evt, count, handleZeroInput)}
          maxLength={maxLength}
          disabled={disabled}
        />
        <IncDecButton
          customClass={styles.round}
          data-testid="quantity-increment"
          onClick={() => {
            onIncrementDecrement(Number(count) + 1, true, hasError);
          }}
          disabled={disableIncrementBtn}
          aria-label={
            isCorePriceAvailable
              ? `${labels.totalPriceIncludingRefundableCoreDepositPriceIs} ${ariaPriceTranslated} ${labels.andTheQuantityIs} ${count}. ${labels.increase}`
              : `${labels.totalPriceIs} ${ariaPriceTranslated} ${labels.andTheQuantityIs} ${count}. ${labels.increase}`
          }
        >
          <Image src={increaseSrc} alt={increaseAlt} width={10} height={11} />
        </IncDecButton>
      </div>
    </div>
  );
};

export default QuantityComponent;
