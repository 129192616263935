/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

// This should be removed for 6.7.0
export const mapAprimoUrlToBeRemoved = (text: string | undefined) => {
  if (!text) return '';
  const regex = /https:\/\/p1(\.[A-Za-z]*)*.aprimocdn.net\/autozone/i;

  return text.replace(regex, 'https://videos.autozone.com/aprimo');
};
