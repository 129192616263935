/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import styles from './quantityExceed.module.scss';
import { useLabels } from '@/hooks/useLabels';

const labelMap = {
  lblQuantityExceedInfo: 'label_Quantity_Exceed_info',
};

function QuantityExceedInfo() {
  const labels = useLabels(labelMap);
  return (
    <div className={styles.infoContainer}>
      <img src="/images/blueToolTip.svg" alt="info" />
      <div className={styles.infoLabel} role={'status'}>
        {labels.lblQuantityExceedInfo}
      </div>
    </div>
  );
}

export default QuantityExceedInfo;
