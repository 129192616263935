/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const quantityComponent = {
  decreaseSvg: {
    src: '/images/decresvg.svg',
    alt: 'decrease',
  },
  increaseSvg: {
    src: '/images/incresvg.svg',
    alt: 'increase',
  },
};

export const quantityComponentBrazil = {
  decreaseSvg: {
    src: '/images/br/minus.svg',
    alt: 'decrease',
  },
  increaseSvg: {
    src: '/images/br/plus.svg',
    alt: 'increase',
  },
};
