/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxios } from '@/lib/axios';
import { AxiosInstance } from 'axios';
import { DealSkuDetailsData, DealSkuDetailsResponse } from '../interface';
import { requestBaseURL } from '@/config/serviceAPI';
import { createQuery } from '@/utils/createReactQuery';

const URL = `${requestBaseURL}/ecomm/b2c/browse/v3/skus`;

type Options = {
  skuIds: string[];
};

const getDealSkuDetails = async (options: Options, axiosInstance?: AxiosInstance) => {
  const { skuIds } = options;

  const response = await getAxios(axiosInstance).get<DealSkuDetailsResponse>(
    `${URL}/${skuIds.join(',')}`
  );

  return selector(response.data);
};

const selector = (data: DealSkuDetailsResponse): DealSkuDetailsData => data;

const { useData } = createQuery<DealSkuDetailsData, Options>('dealSkuDetails', getDealSkuDetails);

export const useDealSkuDetails = ({ enabled = true, skuIds }: Options & { enabled?: boolean }) => {
  return useData({
    enabled: enabled && skuIds.length > 0,
    skuIds,
  });
};
