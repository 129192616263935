/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import CheckIcon from '@/public/images/check.svg';
import iconStyles from '@/theme/iconStyles.module.scss';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  label: string;
  index: number;
};
const ConstraintItemHeader = ({ label, index }: Props) => (
  <div className={styles.constraintItemHeader}>
    <CheckIcon className={cx(iconStyles.defaultIconStyle, styles.constraintItemIcon)} />
    <div className={styles.constraintItemTitle}>
      {label} {index + 1}
    </div>
  </div>
);

export default ConstraintItemHeader;
