/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  GetProductListResultsUsingGetParamsModel,
  ProductShelfResponseModel,
} from '@/api/types/browse-search-types';
import { requestBaseURLGCP } from '@/config/serviceAPI';
import { createQuery } from '@/utils/createReactQuery';
import { ProductShelfResponse } from '../interface';
import { AxiosInstance } from 'axios';
import { QueryClient } from '@tanstack/react-query';
import { getAxios } from '@/lib/axios';
import { parseUrl } from '@/utils/urlHelpers';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { pageTypes } from '@/constants/page';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { useRouter } from 'next/router';
import { useAppState } from '@/stores/AppState';
import { useHeaderData } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { usePageType } from './getPageType';
import type { Store } from '@/types';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
import { useCMSShelfPageConfig } from '@/features/contentstack/hooks/useCMSShelfPageConfig';
import { useContentStackPLPData } from '@/features/productListingPage/api/getContentStackProductListingPageData';

const CATEGORY_SHELF_URL = `${requestBaseURLGCP}/external/product-discovery/browse-search/v1/product-shelves`;

type Options = {
  locale?: Locale;
  vehicleId: string | undefined;
  taxonomyPath?: string;
  tag?: string;
  canonicalPath?: string;
  pageType?: string;
  botEnabledFacetPath?: string;
  path?: string | undefined;
  recsPerPage?: string | undefined;
  makeModelYearPath?: string | undefined;
  storeId?: string | undefined;
  preview: boolean;
  ignoreVehicleSpecificProductsCheck?: boolean;
  rewardsId: string;
  eCookieId: string;
};

export const {
  useData: useProductShelfResultsData,
  prefetch: prefetchProductShelfResultsData,
  query: productsShelfQuery,
} = createQuery<ProductShelfResponse, Options>(
  'productshelf-results',
  async (options, axiosInstance) => getProductShelfResults(options, axiosInstance)
);

const getProductShelfResults = async (options: Options, axiosInstance?: AxiosInstance) => {
  const {
    eCookieId,
    locale,
    taxonomyPath,
    vehicleId,
    canonicalPath,
    pageType,
    preview,
    path,
    recsPerPage,
    botEnabledFacetPath,
    makeModelYearPath,
    ignoreVehicleSpecificProductsCheck,
    rewardsId,
    tag,
    storeId,
  } = options;
  const country = getCountryFromLocale(locale || 'en-US');
  const customerType = 'B2C';
  const salesChannel = 'ECOMM';

  if (!canonicalPath) {
    throw new Error('canonicalPath must be provided in getCategoryShelfResults');
  }

  if (pageType !== pageTypes.ProductShelf && !tag) {
    throw new Error('No product shelf data on categories pages');
  }

  const parsedUrl = parseUrl(path ?? '');

  const params: GetProductListResultsUsingGetParamsModel = {
    country,
    customerType,
    salesChannel,
    preview,
    canonicalPath,
    pageNumber: parsedUrl.query.pageNumber ? Number(parsedUrl.query.pageNumber) : 1,
    recordsPerPage: recsPerPage
      ? Number(recsPerPage)
      : parsedUrl.query.recsPerPage
      ? Number(parsedUrl.query.recsPerPage)
      : undefined,
    sort: parsedUrl.query.sort,
    vehicleId,
    storeId,
    ...(tag && {
      ruleTags: tag,
      isLandingPage: true,
      facet: parsedUrl.query.facet ?? undefined,
    }),
    ...(!tag && {
      taxonomyPath,
      botEnabledFacetPath,
      searchedKeyword: parsedUrl.query.filterByKeyWord ?? undefined,
      makeModelYearPath: makeModelYearPath === '' ? undefined : makeModelYearPath,
      facet: parsedUrl.query.facet ?? undefined,
      partNumberSearch: Boolean(parsedUrl.query.partNumberSearch) ?? undefined,
      minPrice: parsedUrl.query.minPrice,
      maxPrice: parsedUrl.query.maxPrice,
      ...((!!ignoreVehicleSpecificProductsCheck && { ignoreVehicleSpecificProductsCheck: true }) ||
        (!!parsedUrl.query.ignoreVehicleSpecificProductsCheck && {
          ignoreVehicleSpecificProductsCheck: true,
        })),
    }),
  };

  const response = await getAxios(axiosInstance).get<ProductShelfResponseModel>(
    CATEGORY_SHELF_URL,
    {
      ...(country === 'USA' && {
        headers: {
          eCookieId,
          ...(!!rewardsId && { rewardsId }),
        },
      }),
      params,
    }
  );
  return productShelfResultsSelector(response.data);
};

const productShelfResultsSelector = ({
  productShelfResults,
  redirectUrl,
  xFusionQueryId,
}: ProductShelfResponseModel): ProductShelfResponse => {
  return { productShelfResults, redirectLocation: redirectUrl ?? '', xFusionQueryId };
};

export const useProductShelfResults = ({
  enabled = true,
  fromRedirect = false,
  ...rest
}: {
  enabled?: boolean;
  fromRedirect?: boolean;
} = {}) => {
  const router = useRouter();
  const headerResult = useHeaderData();
  const preferredVehicle = getPreferredVehicle(headerResult.data);
  const locale = useLocale();
  const appState = useAppState();
  const { recordsPerPage } = useCMSShelfPageConfig();
  const isMerchShelfPage = router.route === '/deals/[cmsPath]/[[...slug]]';
  const { data: plpCMSData, isSuccess: isPLPCMSDataSuccess } = useContentStackPLPData({
    enabled: true,
  });

  // TODO: replace with router.asPath when Next bug is fixed
  // https://github.com/vercel/next.js/issues/46876
  const reqUrl = __IS_SERVER__ ? appState.state.reqUrl : router.asPath;

  if (reqUrl == null) {
    throw new Error(`A 'path' option is required`);
  }

  const { data: pageTypeData } = usePageType();
  const parsedUrl = parseUrl(reqUrl);

  const lwTag = !!plpCMSData?.additional_attributes_merch_shelf_page?.lucidworks_fusion_tag
    ? plpCMSData.additional_attributes_merch_shelf_page.lucidworks_fusion_tag
    : undefined;

  const taxonomyPath = pageTypeData?.taxonomyPath || undefined;

  return useProductShelfResultsData({
    rewardsId: headerResult.data?.myAccountMap?.rewardsId ?? '',
    eCookieId: appState.state.eCookieId,
    enabled: isMerchShelfPage
      ? isPLPCMSDataSuccess && !!lwTag
      : enabled &&
        pageTypeData?.pageType === 'ProductShelf' &&
        (router.route === '/[...seoUrlPath]' || router.route === '/cms/taxonomy-selector'),
    locale,
    vehicleId: preferredVehicle?.catalogVehicleId,
    pageType: pageTypeData?.pageType || undefined,
    canonicalPath: pageTypeData?.canonicalPath || parsedUrl.url,
    taxonomyPath,
    tag: lwTag,
    botEnabledFacetPath: pageTypeData?.botEnabledFacetPath || undefined,
    makeModelYearPath: pageTypeData?.makeModelYearPath || undefined,
    recsPerPage: recordsPerPage ? String(recordsPerPage) : undefined,
    storeId: headerResult.data?.storeNumber ?? undefined,
    path: router.route === '/cms/taxonomy-selector' ? taxonomyPath : reqUrl || '',
    preview: showXMPreviewDate(),
    staleTime: 30 * 60000,
    onSuccess: async (data) => {
      if (data.redirectLocation) {
        await router.replace(data.redirectLocation);
      }
    },
    ...(!!fromRedirect && { ignoreVehicleSpecificProductsCheck: fromRedirect }),
    ...rest,
    onError: async (error) => {
      try {
        // eslint-disable-next-line no-console
        console.log(`An error occurred while requesting product shelf results: `, error);
        await router.replace('/errorPage');
        return;
      } catch (error) {
        throw new Error(`There was a problem redirecting to /errorPage`);
      }
    },
  });
};

export const prefetchProductShelfResults = async (
  axiosInstance: AxiosInstance,
  queryClient: QueryClient,
  options: Options
) => {
  return prefetchProductShelfResultsData(queryClient, options, axiosInstance);
};

export const getProductShelfResultsFromCache = (
  queryClient: QueryClient,
  store: Store,
  options: Options
) => {
  const { appData } = store.getState();
  return queryClient.getQueryData<ProductShelfResponse>(
    productsShelfQuery.getFullKey({
      recsPerPage: appData.deviceType === 'bot' ? '24' : undefined,
      ...options,
      locale: 'en-US',
    })
  );
};

export const useProduleShelfFacets = () => {
  const router = useRouter();
  const headerResult = useHeaderData();
  const locale = useLocale();
  const appState = useAppState();

  // TODO: replace with router.asPath when Next bug is fixed
  // https://github.com/vercel/next.js/issues/46876
  const reqUrl = __IS_SERVER__ ? appState.state.reqUrl : router.asPath;

  if (reqUrl == null) {
    throw new Error(`A 'path' option is required`);
  }

  const { data: pageTypeData } = usePageType();

  return useProductShelfResultsData({
    rewardsId: headerResult.data?.myAccountMap?.rewardsId ?? '',
    eCookieId: appState.state.eCookieId,
    locale,
    vehicleId: undefined,
    pageType: pageTypeData?.pageType ?? 'ProductShelf',
    canonicalPath: pageTypeData?.canonicalPath ?? parseUrl(reqUrl).url,
    taxonomyPath: pageTypeData?.taxonomyPath,
    botEnabledFacetPath: undefined,
    makeModelYearPath: undefined,
    recsPerPage: '1',
    storeId: headerResult.data?.storeNumber ?? undefined,
    path: reqUrl,
    preview: showXMPreviewDate(),
    staleTime: 30 * 60000,
    onSuccess: async (data) => {
      if (data.redirectLocation) {
        await router.replace(data.redirectLocation);
      }
    },
  });
};
