/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import Image from '@/components/NextImage';
import { getPriceArray } from '../../utils/getPriceArray';
import { useLabels } from '@/hooks/useLabels';
import Price from '@/components/AZCustomComponent/Price';
import type { SkuDetails } from '../../../interface';
import styles from './styles.module.scss';

export type ParentSkuCardProps = {
  skuDetails: SkuDetails;
  skuPrice?: string | number | string[] | number[];
  skuRetailPrice: string | number | string[] | number[];
  skuQuantity: number | string;
};

const labelMap = {
  label_deal_bundle_parent_sku_quantity: 'label_deal_bundle_parent_sku_quantity',
  label_deals_sku_location: 'label_deals_sku_location',
};

export const ParentSkuCard = ({
  skuDetails,
  skuQuantity,
  skuPrice,
  skuRetailPrice,
}: ParentSkuCardProps) => {
  const labels = useLabels(labelMap);
  const { productImageUrl, description, locationFilter } = skuDetails;

  return (
    <div className={styles.parentSkuCard}>
      <div className={styles.parentSkuImage}>
        <Image src={productImageUrl} alt="" width={32} height={32} />
      </div>
      <div className={styles.parentSkuDescriptionSection}>
        <div className={styles.parentSkuDescription}>{description}</div>
        {Boolean(locationFilter) && (
          <div className={styles.locationSection}>
            <span className={styles.locationLabel}>{labels.label_deals_sku_location}</span>
            <span className={styles.locationValue}>{locationFilter}</span>
          </div>
        )}
      </div>

      <div className={styles.parentSkuPriceDetails}>
        <div className={styles.parentSkuQuantity}>
          <span className={styles.parentSkuQuantityLabel}>
            {labels.label_deal_bundle_parent_sku_quantity}
          </span>
          <span className={styles.parentSkuQuantityValue}>{skuQuantity}</span>
        </div>
        <div className={styles.parentSkuPrice}>
          {skuPrice ? (
            <Price
              priceType="small"
              dealPrice={getPriceArray(skuPrice)}
              wasDealPrice={getPriceArray(skuRetailPrice)}
              each={+skuQuantity > 1}
            />
          ) : (
            <Price
              priceType="small"
              dealPrice={getPriceArray(skuRetailPrice)}
              each={+skuQuantity > 1}
            />
          )}
        </div>
      </div>
    </div>
  );
};
