/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { YmmeSelectComponent } from '@/features/ymme/components/YMMESelectComp';
import { useRedirectAfterVehicleChanged } from '@/features/category/hooks/useRedirectAfterVehicleChanged';

type Props = {
  pageType: string;
  forceMobile?: boolean;
  onSelectVehicle?: (a: string) => void;
};

export const StandaloneYmmeSelect = ({ pageType, forceMobile, onSelectVehicle }: Props) => {
  const redirectAfterVehicleChanged = useRedirectAfterVehicleChanged();
  return (
    <YmmeSelectComponent
      pageType={pageType}
      forceMobile={forceMobile}
      onSelectVehicle={onSelectVehicle}
      onUpdateVehicleSuccess={redirectAfterVehicleChanged}
    />
  );
};
