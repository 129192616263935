/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

type Props = {
  wasRetailPrice?: string | number | undefined;
  retailPriceInt: number;
  discountedPrice: string | null;
  count: number;
  staticPrice: number;
  dealPrice: string[];
};

export const getPopoverPrices = ({
  wasRetailPrice,
  retailPriceInt,
  discountedPrice,
  count,
  staticPrice,
  dealPrice,
}: Props) => {
  const updatedDiscountedPrice = discountedPrice
    ? parseFloat(discountedPrice) * (count === 0 ? 1 : count)
    : retailPriceInt;

  const updatingStaticPrice = staticPrice * (count === 0 ? 1 : count);
  const strikethroughPrice = wasRetailPrice;
  const strikethroughPriceInt = parseInt((strikethroughPrice as string) ?? undefined);

  const currentPrice = discountedPrice ? updatedDiscountedPrice : updatingStaticPrice;
  const wasPrice = strikethroughPrice ? strikethroughPrice : retailPriceInt.toFixed(2);

  const updatingRetailPrice = strikethroughPriceInt
    ? wasPrice && parseFloat(wasPrice as string) * (count === 0 ? 1 : count)
    : wasPrice;

  const counterPrice = discountedPrice ? parseFloat(discountedPrice) : staticPrice;

  const [dollars, cents] = `${updatingRetailPrice}`.split('.');
  const discountPriceArr = `${updatedDiscountedPrice}`.split('.');
  const popoverDealPrice = discountedPrice ? discountPriceArr : dealPrice;

  return {
    popoverDiscountedPrice: currentPrice,
    popoverUpdatedPrice: updatedDiscountedPrice,
    popoverRetailPrice: [dollars, cents],
    popoverRetailPriceInt: parseFloat(`${dollars}.${cents}`),
    counterPrice,
    popoverDealPrice,
  };
};
