/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useMemo, forwardRef } from 'react';
import cx from 'classnames';
import azCommonStyles from '../../theme/globals.module.scss';
import ContentContainer from '../AZCustomComponent/ContentContainer';
import { FULFILLMENT_METHODS } from '../../constants/fulfillmentConstants';
import { defaultStoreUS } from '@/constants/locale';
import styles from './styles.module.scss';
import NextDayOption from './NextDayOption';
import SameDayOption from './SameDayOption';
import { StorePickupOption } from './StorePickupOption';
import { StandardOption } from './StandardOption';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import type { FulfillmentGroup, FulfillmentOption } from '@/types/reduxStore/skuDetails';
import { useLabels } from '@/hooks/useLabels';
import { ExclamationTriangle, Icon } from '@az/starc-ui';

type Props = {
  fulfillmentOptionsGroupList: Array<FulfillmentGroup>;
  itemData?: ItemData;
  skuId: string | undefined;
  productName: string;
  groupId: number;
  handleInstockAnalytics?: () => any;
  isDealDrawer?: boolean;
  itemPresentInCart?: boolean;
  toggleSisterStoreFlyout?: () => void;
  nddOptionAvailable?: boolean;
  nextDayDeliverySelected?: boolean;
  sameDayDeliverySelected?: boolean;
  storePickUpSelected?: boolean;
};

type ItemData = {
  shipToHome: boolean;
  itemQuantity: number;
};

const labels = {
  label_item_currently_available: 'label_fulfilment_options_item_currently_unavailable',
};

export const SubGroupText = forwardRef<HTMLTextAreaElement, Props>(function SubGroupText(
  {
    fulfillmentOptionsGroupList,
    itemData,
    skuId,
    productName,
    groupId,
    handleInstockAnalytics,
    isDealDrawer,
    itemPresentInCart,
    toggleSisterStoreFlyout,
    nddOptionAvailable,
    nextDayDeliverySelected,
    sameDayDeliverySelected,
    storePickUpSelected,
  }: Props,
  ref: React.Ref<any>
) {
  const labelMap = useLabels(labels);
  const { data: storeDetailsData } = useStoreDetailsData();
  const isStoreSet = storeDetailsData?.storeNumber !== defaultStoreUS;
  const isAvailableAtSelectedQty = (option: FulfillmentOption) => {
    // currently itemData will only exists when mounted in mini-cart/cart
    if (itemData?.shipToHome && itemData?.itemQuantity) {
      if (itemData?.itemQuantity > option.availableQty || !option.available) {
        return false;
      }
    }

    return option.available;
  };

  const homeDeliveryAvailable = (groupFulfillmentOptions: FulfillmentOption[]) => {
    return groupFulfillmentOptions.some(
      (option) => !!option.available && option.fulfillmentTypeId !== FULFILLMENT_METHODS.SAMEDAY
    );
  };

  const allFulfillmentOptions = fulfillmentOptionsGroupList.flatMap(
    (group) => group.groupFulfillmentOptions
  );

  const optionsToDisplay = getOptionsToDisplay(allFulfillmentOptions);

  function getOptionsToDisplay(groupFulfillmentOptions: FulfillmentOption[]) {
    const arrayOfSubGroupOptions: FulfillmentOption[] = [];
    groupFulfillmentOptions.forEach((option) => {
      if (option.fulfillmentTypeId === FULFILLMENT_METHODS.STOREORDER) {
        arrayOfSubGroupOptions.push(option);
        return;
      }
      if (!isAvailableAtSelectedQty(option)) {
        return;
      }

      const isSameDayDelivery =
        option.fulfillmentTypeId === FULFILLMENT_METHODS.SAMEDAY &&
        isStoreSet &&
        sameDayDeliverySelected;
      if (isSameDayDelivery || (!sameDayDeliverySelected && !storePickUpSelected)) {
        arrayOfSubGroupOptions.push(option);
      }
    });
    return arrayOfSubGroupOptions;
  }

  const renderSwitch = (option: FulfillmentOption) => {
    switch (option.fulfillmentTypeId) {
      case FULFILLMENT_METHODS.STOREORDER:
        return (
          storePickUpSelected &&
          isStoreSet && (
            <StorePickupOption
              option={option}
              productName={productName}
              skuId={skuId}
              handleInstockAnalytics={handleInstockAnalytics}
              isDealDrawer={isDealDrawer}
              itemPresentInCart={itemPresentInCart}
              toggleSisterStoreFlyout={toggleSisterStoreFlyout}
            />
          )
        );

      case FULFILLMENT_METHODS.NEXTDAY:
        return (nddOptionAvailable || nextDayDeliverySelected) && <NextDayOption option={option} />;

      case FULFILLMENT_METHODS.SAMEDAY:
        return (
          sameDayDeliverySelected && (
            <SameDayOption option={option} productName={productName} skuId={skuId} />
          )
        );

      default:
        return <StandardOption option={option} nddAvailable={nddOptionAvailable} />;
    }
  };

  const sisterStoreAvailable = useMemo(() => {
    return allFulfillmentOptions.some((option) => option.sisterStoreAvailable);
  }, [allFulfillmentOptions]);

  if (
    groupId === FULFILLMENT_METHODS.ONLINEORDER &&
    !homeDeliveryAvailable(allFulfillmentOptions) &&
    !sameDayDeliverySelected &&
    !storePickUpSelected &&
    !sisterStoreAvailable
  ) {
    return (
      <div className={styles.notAvailable}>
        <div className={styles.notAvailableIconContainer}>
          <Icon svg={ExclamationTriangle} color="error" size={3.5} />
        </div>
        <span data-testid="availability">{labelMap.label_item_currently_available}</span>
      </div>
    );
  }

  return (
    <div ref={ref}>
      {optionsToDisplay.map((option: FulfillmentOption) => {
        return (
          <ContentContainer
            className={cx(
              azCommonStyles['az-caption'],
              azCommonStyles['az-align-left'],
              styles.toggleListItemContainer
            )}
            key={option.fulfillmentTypeId}
            data-testid="sub-group-item"
          >
            {renderSwitch(option)}
          </ContentContainer>
        );
      })}
    </div>
  );
});
