/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect, useState } from 'react';
import cx from 'classnames';
import DynamicLinksStyles from './DynamicLinks.module.scss';
import azCommonStyles from '@/theme/globals.module.scss';
import { useDynamicLinkMappings } from '../api/getDynamicLinkMappings';
import { SmartLink } from '@/utils/smartLink';
import { useLabel } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import root from 'window-or-global';
import { sessionStorage } from '@/utils/sessionStorage';
import type { DLMType, VehicleDynamicLinkOptions } from '../interface';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { useFeatureFlag } from '@/features/globalConfig';

export type DynamicLinksProps = {
  seoRepositoryId: string;
  type?: DLMType;
  vehicleData?: VehicleDynamicLinkOptions;
};

export const DynamicLinks = ({
  seoRepositoryId,
  type: dlmType = 'generic',
  vehicleData,
}: DynamicLinksProps) => {
  const locale = useLocale();
  const preferredVehicleId = usePreferredVehicle()?.catalogVehicleId;
  const gcpUrlEnabled = useFeatureFlag('SEO_GCP_URL_ENABLED') === 'true';
  const { data, isSuccess } = useDynamicLinkMappings({
    type: dlmType,
    country: getCountryFromLocale(locale),
    seoRepositoryId,
    vehicleOptions: vehicleData,
    enabled: !preferredVehicleId && (dlmType === 'vehicle' ? !!vehicleData?.make : true),
    gcpUrlEnabled,
  });

  const popularSearchesLabel = useLabel('dlm_popular_searches');
  const [shouldFocusTop, setShouldFocusTop] = useState(false);

  useEffect(() => {
    if (shouldFocusTop && typeof root !== 'undefined' && typeof document !== 'undefined') {
      const azLogoFixed = document.getElementById('azLogoLinkFixed');
      azLogoFixed?.focus();
      setShouldFocusTop(false);
    }
  }, [shouldFocusTop]);

  if (!isSuccess || !data.length || preferredVehicleId) {
    return null;
  }

  return (
    <section
      className={cx(
        azCommonStyles['az-padding-left-xxs'],
        azCommonStyles['az-padding-right-xxs'],
        DynamicLinksStyles.popularSearchesOuterContainer
      )}
      id="popular-searches-container"
    >
      <div className={DynamicLinksStyles.popularSearchesInnerContainer}>
        <h2
          className={cx(
            azCommonStyles['az-margin-right-xs'],
            DynamicLinksStyles.popularSearchHeading
          )}
        >
          {popularSearchesLabel}:
        </h2>

        <ul>
          {data
            .sort(
              ({ mappingSequenceNumber: currentSeqNum }, { mappingSequenceNumber: nextSeqNum }) =>
                currentSeqNum - nextSeqNum
            )
            .map((dynamicLink) => (
              <li key={dynamicLink.keywordTerm}>
                <SmartLink
                  to={dynamicLink.keywordLinkUrl}
                  className={cx(
                    azCommonStyles['az-body-1-link'],
                    azCommonStyles['az-margin-right-xs'],
                    DynamicLinksStyles.popularSearchLink
                  )}
                  handleClick={() => {
                    sessionStorage.setItem('popularSearchClicked', 'true');
                    setShouldFocusTop(true);
                  }}
                >
                  {dynamicLink.keywordTerm}
                </SmartLink>
              </li>
            ))}
        </ul>
      </div>
    </section>
  );
};
