/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { PageBodyItem } from '../interface';

export const filterCTJBlocks = (
  content: PageBodyItem[],
  hasCustomCTJ: boolean | undefined
): PageBodyItem[] | undefined => {
  if (hasCustomCTJ) {
    return content?.filter((block) => !block.completethejob || block.completethejob?.taxonomy);
  }

  let hasFoundADefaultBlock = false;
  const filteredContent = content?.filter((block) => {
    if (block.completethejob && !block.completethejob.taxonomy) {
      if (!hasFoundADefaultBlock) {
        hasFoundADefaultBlock = true;
        return true;
      }
      return false;
    }
    return true;
  });

  return filteredContent;
};
