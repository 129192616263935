/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import azCommonStyles from '../../../theme/globals.module.scss';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import InfoToolTip from '../../AZCustomComponent/InfoToolTip';
import styles from './styles.module.scss';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { formatPrice } from '../../../utils/validator/formatPrice';
import { getCurrencySymbol } from '@/utils/priceHelpers';

type Props = {
  corePrice: number;
};

const labelMap: any = {
  lbllineItemPlus: 'label_confirmation_lineItem_Plus',
  lbllineItemWhatIsIt: 'label_cart_lineItem_WhatIsIt',
  lblCoreDetails: 'pagecontent_cart_corePopup_CoreDetails',
  lblRefundable: 'label_refundable',
  lblOrderSummaryCoreDeposit: 'label_cart_orderSummary_coreDeposit',
};

const CorePrice = ({ corePrice }: Props) => {
  const labels = useLabels(labelMap);
  const locale = useLocale();
  const { data: storeData } = useStoreDetailsData();
  const isBopusEnabled = storeData?.bopusEnabled ?? false;
  const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;

  const currencySymbol = getCurrencySymbol(locale);

  if (!corePrice) {
    return null;
  }

  return (
    <div
      className={cx(azCommonStyles['az-caption'], styles.corePrice)}
      data-testid="core-price"
      role="presentation"
    >
      {labels.lbllineItemPlus}
      {currencySymbol}
      {formatPrice(corePrice)}
      {isBopusMexicoStoreEnabled
        ? `${' '}${labels.lblOrderSummaryCoreDeposit}${' '}${labels.lblRefundable}`
        : `${' '}${labels.lblRefundable}${' '}
      ${labels.lblOrderSummaryCoreDeposit}`}
      <InfoToolTip
        classes={{
          icon: styles.coreDepositInfo,
        }}
        header={labels.lbllineItemWhatIsIt}
        body={labels.lblCoreDetails}
      />
    </div>
  );
};

export default CorePrice;
