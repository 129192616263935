/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import NextImage from '@/components/NextImage';
import { sessionStorage } from '@/utils/sessionStorage';
import { SmartLink as Link } from '@/utils/smartLink';
import type { MappedAffinityProduct } from '../../../../interface';
import styles from './RelatedPartsPictureList.module.scss';
import { View } from '@az/starc-ui';

type Props = {
  parts: MappedAffinityProduct[];
};

const plusSignBlack = {
  src: '/images/plus-sign-black.svg',
  alt: 'plus-sign',
};

export function RelatedPartsPictureList({ parts }: Props) {
  const handleImageClick = () => {
    sessionStorage.setItem('pdpUtagViewAffinity', 'true');
  };

  return (
    <div className={styles.relatedPartsPictureList}>
      {parts.map(
        ({ seoUrl, productImageUrl, isPartSelected, productName, skuId, isAvailable }, index) => (
          <div key={`${skuId ?? ''}_${index}`} className={styles.relatedPartPictureWithPlusSign}>
            <Link to={seoUrl || ''}>
              <View padding={1} backgroundColor="secondary" className={styles.imageContainer}>
                <NextImage
                  onClick={handleImageClick}
                  className={cx(styles.relatedProductImage, {
                    [styles.relatedPartDeselected]: !isAvailable || !isPartSelected,
                  })}
                  src={productImageUrl}
                  alt={productName}
                  width={64}
                  height={64}
                />
              </View>
            </Link>

            {index === parts.length - 1 ? null : (
              <div className={styles.relatedPartPlusSign}>
                <NextImage src={plusSignBlack.src} alt={plusSignBlack.alt} width={18} height={18} />
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
}
